import { useState } from "react";

import tw from "utils/tw";

import { Icon } from "./icons";

const CollapsibleSection = ({
  children,
  collapsed = false,
  label,
}: WithChildren<{
  collapsed?: boolean;
  label: string;
}>) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  return (
    <div className="flex flex-col">
      <button
        className="group/collapsible-section-label flex items-center justify-start"
        onClick={() => setIsCollapsed(!isCollapsed)}
        type="button"
      >
        <Icon
          className={tw(
            "mr-6 text-icon-secondary group-hover/collapsible-section-label:text-icon-secondary-hover",
            "duration-350 ease-spring",
            {
              "-rotate-90": isCollapsed,
            }
          )}
          icon="ChevronDown"
          size={20}
        />
        <span className="text-body-bold text-text-subtle group-hover/collapsible-section-label:text-text-subtle-hover">
          {label}
        </span>
      </button>
      <div
        className="grid"
        style={{
          gridTemplateRows: isCollapsed ? "0fr" : "1fr",
          transition: "grid-template-rows 0.2s ease-in-out",
        }}
      >
        <div className="overflow-hidden">{children}</div>
      </div>
    </div>
  );
};

export default CollapsibleSection;
