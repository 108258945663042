import { GlueFile } from "@utility-types";
import { Icon } from "components/design-system/icons";
import { FileIcon } from "components/helper";
import ImageElement from "components/Media/Image";

type Props = {
  quotedAttachments: GlueFile[];
};

const QuotedAttachments = ({
  quotedAttachments,
}: Props): JSX.Element | null => {
  if (!quotedAttachments.length) return null;
  return (
    <div className="flex overflow-hidden flex-row">
      {quotedAttachments
        .filter(({ fileType }) => fileType === "image")
        .map(({ id, metadata, url }) => (
          <ImageElement
            key={id}
            alt={""}
            blurHash={metadata?.blurHash ?? undefined}
            className="object-cover overflow-hidden mr-10 !w-48 !h-48 rounded border border-background-subtle"
            figureClassName="flex-shrink-0"
            resize={{ fit: "max", h: 48 }}
            src={url}
          />
        ))}
      {quotedAttachments
        .filter(({ fileType }) => fileType === "video")
        .map(({ id }) => (
          <AttachmentContainer key={id}>
            <Icon
              className="text-interactive-subtle"
              icon="PlayCircle"
              size={30}
              strokeWidth={2}
            />
          </AttachmentContainer>
        ))}
      {quotedAttachments
        .filter(({ fileType }) => fileType === "audio")
        .map(({ id }) => (
          <AttachmentContainer key={id}>
            <Icon
              className="text-interactive-subtle"
              icon="Volume2"
              size={30}
              strokeWidth={2}
            />
          </AttachmentContainer>
        ))}
      {quotedAttachments
        .filter(({ fileType }) => fileType === "file")
        .map(({ contentType, id }) => (
          <AttachmentContainer key={id}>
            <FileIcon mimeType={contentType} iconSize={36} />
          </AttachmentContainer>
        ))}
    </div>
  );
};

const AttachmentContainer = ({ children }: WithChildren): JSX.Element => (
  <div
    className="flex justify-center items-center mr-10 bg-cover rounded border border-background-subtle"
    style={{
      minHeight: "48px",
      minWidth: "48px",
    }}
  >
    {children}
  </div>
);

export default QuotedAttachments;
