import { FadeInOut } from "components/Animated";
import { Button } from "components/design-system/Button";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import tw from "utils/tw";

import { useShowDownloadApp } from "./hooks/useShowDownloadApp";

const MobileReminder = (): JSX.Element => {
  const { showMobileReminder } = useShowDownloadApp();

  return (
    <FadeInOut show={!!showMobileReminder}>
      <Button
        buttonStyle="icon-primary"
        buttonType="none"
        className={tw(
          "block relative p-10 -my-8",
          "before:block before:absolute before:top-6 before:right-8 before:z-2 before:w-10 before:h-10 before:bg-background-alert-strong before:rounded-full before:border-2 before:border-background-list"
        )}
        icon="Smartphone"
        iconStroke={2}
        iconSize={18}
        onClick={() =>
          useLocalSettingsStore.setState({ dismissedDownloadBanner: false })
        }
      />
    </FadeInOut>
  );
};

export default MobileReminder;
