import { cloneElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import Icon from "components/design-system/icons/Icon";
import { variantProps } from "components/helper/classNameVariants";
import tw from "utils/tw";

import Undo from "./Undo";
import useToastStore, { ToastType } from "./useToastStore";

const className = {
  base: "",
  defaultVariants: {
    type: ToastType.ARCHIVE as const,
  },

  variants: {
    type: {
      [ToastType.ARCHIVE]: "bg-background-list-done text-text-action-inverse",
      [ToastType.DRAFT]: "bg-background-list-delete text-text-inverse",
      [ToastType.READ]: "bg-background-list-done text-text-action-inverse",
      [ToastType.STARRED]: "bg-background-list-favorite text-text-info",
      [ToastType.SUBSCRIPTION]: "bg-background-list-delete text-text-inverse",
    },
  },
};

export const toastProps = variantProps(className);

const Toast = () => {
  const history = useHistory();

  const {
    resetToastConfig,
    toastConfig: { content, dismiss, icon, id, show, type, undo },
  } = useToastStore(({ resetToastConfig, toastConfig }) => ({
    resetToastConfig,
    toastConfig,
  }));

  const dismissTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [isShowing, setIsShowing] = useState(show);

  const handleCloseToast = () => {
    clearTimeout(dismissTimeoutRef.current);
    useToastStore.setState({
      toastConfig: {
        ...useToastStore.getState().toastConfig,
        show: false,
      },
    });
  };

  useEffect(() => {
    handleCloseToast();
  }, [history.location]);

  useEffect(() => {
    if (!type || !content || !id) return;

    if (dismiss) {
      clearTimeout(dismissTimeoutRef.current);
      dismissTimeoutRef.current = setTimeout(handleCloseToast, dismiss);
    }
  }, [content, id, dismiss, type]);

  const renderContent = () => {
    if (content) {
      if (typeof content === "string") {
        return content;
      }
      if (typeof content === "function") {
        return content({});
      }
      return cloneElement(content, {});
    }

    return null;
  };

  return (show || isShowing) && type ? (
    <div
      className={tw(
        "absolute inset-0 top-auto",
        "bottom-[calc(env(safe-area-inset-bottom)_+_78px)] md:bottom-0",
        "flex items-center justify-center",
        "md:pb-16 px-16 w-full z-100",
        show ? "animate-fadeIn" : "animate-fadeOut"
      )}
      onAnimationEnd={() => {
        if (!show) {
          resetToastConfig();
        }
        setIsShowing(show);
      }}
    >
      <div
        {...toastProps({
          className: tw(
            "flex items-center justify-between",
            "grow min-h-[44px] p-8 pl-16 rounded-md shadow-level1",
            "font-normal leading-[22px]"
          ),
          type,
        })}
      >
        <div className="flex items-center justify-start">
          {icon && <Icon className="mr-8" icon={icon} size={24} />}
          <div>{renderContent()}</div>
        </div>

        {undo && (
          <Undo
            buttonType={type}
            onUndo={() => {
              handleCloseToast();
              undo?.();
            }}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default Toast;
