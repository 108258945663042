import { useRemirrorContext } from "@remirror/react";
import { useState } from "react";

import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi/Dropdown";
import { Lightning } from "components/Icons";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";

import buttonStyles from "../../buttonStyles";

import useAppsThreadActions from "./hooks/useAppsThreadActions";
import ThreadActionsList from "./ThreadActionsList";

const ThreadActions = ({ readOnly }: { readOnly: boolean }) => {
  const [open, setOpen] = useState(false);
  const { threadID } = useThreadViewState(({ threadID }) => ({ threadID }));
  const appsThreadActions = useAppsThreadActions({ threadID });
  const { commands, getState } = useRemirrorContext();
  if (!appsThreadActions?.length) return null;

  return (
    <Dropdown
      content={
        <ThreadActionsList
          setOpen={setOpen}
          threadActions={appsThreadActions}
        />
      }
      heightAuto={true}
      height="half"
      open={open}
      onStart={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
        commands.focus(getState().selection.$to);
      }}
      setOpen={setOpen}
      modalContentHandlesSafeArea={false}
      placement="top"
      toModal
    >
      <Button
        buttonStyle="subtle"
        className={buttonStyles()}
        disabled={readOnly}
        icon={Lightning}
        iconSize={20}
        name="mention"
        type="button"
      />
    </Dropdown>
  );
};

export default ThreadActions;
