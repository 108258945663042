import { useEffect } from "react";
import { useHistory } from "react-router";

import {
  GroupTabs,
  defaultRoutesStack,
  locationFromRoute,
  routeParams,
  routePath,
  superTabsDefaults,
  useRoutePartition,
} from "components/routing/utils";
import useRoutesStore from "store/useRoutesStore";

type Name =
  | "activity"
  | "ai"
  | "dms"
  | "feed"
  | "groups"
  | "inbox"
  | "search"
  | "threads";

const removeMessageIDs = (route: string) => {
  const { pathname, search } = locationFromRoute(route);
  const params = routeParams({ pathname, search });

  params.d = params.d?.split("/")[0]; // remove messageID from `d` param
  delete params.messageID;

  return routePath(params);
};

const removeTab = (route: string) => {
  const { pathname, search } = locationFromRoute(route);
  const params = routeParams({ pathname, search });
  const tab = params.t;

  delete params.t;

  return {
    route: removeMessageIDs(routePath(params)),
    tab,
  };
};

/**
 * processes the currently selected tab and related routes, and stores them in local storage;
 * allows restoration of prior routing state when the app is reloaded
 */
const useRouteMemoryStack = ({
  isActive,
  name,
  route,
}: {
  isActive: boolean;
  name: Name;
  route: string;
}) => {
  const { routesStack } = useRoutesStore(({ routesStack }) => ({
    routesStack,
  }));

  // initialize default route
  useEffect(() => {
    if (routesStack?.[name] || !superTabsDefaults[name]) return;

    useRoutesStore.setState({
      routesStack: {
        ...routesStack,
        [name]: [superTabsDefaults[name]],
      },
    });
  }, [name, routesStack]);

  // update saved route for the current tab
  useEffect(() => {
    const didUpdate = route === useRoutesStore.getState().restoreRoute;

    if (!isActive || didUpdate || route === "/" || !routesStack) return;

    const baseRoute = defaultRoutesStack[name]?.[0];

    useRoutesStore.setState({
      restoreRoute: route,
    });

    // if the route is the base route, reset the stack
    if (route === baseRoute) {
      useRoutesStore.setState({
        routesStack: {
          ...routesStack,
          [name]: [baseRoute],
        },
      });
      return;
    }

    // add the route to the stack
    const { route: currentRoute, tab: currentTab } = removeTab(route);
    const { route: lastRoute, tab: lastTab } = removeTab(
      routesStack[name]?.[(routesStack[name]?.length ?? 0) - 1] ?? ""
    );

    const nextRoute = removeMessageIDs(route);

    if (
      (currentRoute === lastRoute && currentTab !== lastTab) ||
      currentTab === GroupTabs.Chat
    ) {
      // replace the last route with the current route b/c the tab changed
      const nextStack = [...(routesStack[name] ?? [])];
      nextStack.splice(-1, 1, nextRoute);

      useRoutesStore.setState({
        routesStack: {
          ...routesStack,
          [name]: [...nextStack],
        },
      });
      return;
    }

    if (currentRoute === lastRoute) return;

    useRoutesStore.setState({
      routesStack: {
        ...routesStack,
        [name]: [...(routesStack[name] ?? []), nextRoute],
      },
    });
  }, [isActive, name, route, routesStack]);

  return { routesStack };
};

export const useRouteMemoryStackAction = () => {
  const history = useHistory();

  const { routesStack } = useRoutesStore(({ routesStack }) => ({
    routesStack,
  }));

  const { superTab } = useRoutePartition();

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (!superTab) return;
    const stack = routesStack[superTab];

    if (!stack) return;

    const nextStack = stack.slice(0, -1);

    useRoutesStore.setState({
      routesStack: {
        ...routesStack,
        [superTab]: nextStack,
      },
    });

    history.replace(
      nextStack[nextStack.length - 1] ?? superTabsDefaults[superTab]
    );
  };

  return { handleBack };
};

export default useRouteMemoryStack;
