import { ComponentProps, forwardRef } from "react";

import { Recipient } from "@utility-types";
import { formatGroupName } from "utils/group/formatGroupName";
import tw from "utils/tw";

import GroupAvatar from "./GroupAvatar";

type Props = {
  isRead?: boolean;
  recipient?: Recipient;
  secondaryText?: string;
} & Pick<ComponentProps<typeof GroupAvatar>, "badgeUrl">;

const GroupsListItem = forwardRef<HTMLDivElement, WithChildren<Props>>(
  ({ isRead = true, recipient, secondaryText, ...avatarProps }: Props, ref) => {
    const { emoji, name: nameWithoutEmoji } = formatGroupName({
      ...recipient,
    });

    return (
      <div
        ref={ref}
        className="group/thread-list-item relative flex grow items-center justify-start px-12 select-none overflow-hidden"
      >
        <GroupAvatar
          emoji={emoji}
          icon={!emoji ? "Users" : undefined}
          isRead={isRead}
          recipient={recipient}
          {...avatarProps}
        />

        <div
          className={tw(
            "flex grow items-center justify-between min-w-0 pl-8",
            secondaryText ? "h-40" : "h-24",
            { "pr-15": !isRead }
          )}
        >
          <div className="leading-[22px] truncate w-full">
            {emoji ? nameWithoutEmoji : recipient?.name}

            {secondaryText && (
              <div className="truncate text-text-subtle text-sm h-18 flex items-center">
                {secondaryText}
              </div>
            )}
          </div>
        </div>

        {!isRead && (
          <div
            className={tw(
              "absolute right-20 top-1/2 -translate-y-1/2",
              "before:block before:w-6 before:h-6 before:content-[''] before:rounded-full before:bg-accent-primary"
            )}
          />
        )}
      </div>
    );
  }
);

export default GroupsListItem;
