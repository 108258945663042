import { type ComponentProps } from "react";

import { type StreamGlueMessage } from "@utility-types";
import { MemberRoleBadge } from "components/design-system/Badge";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { MessageableBy } from "generated/graphql";
import env from "utils/processEnv";

type RoleBadgeProps = {
  role: ComponentProps<typeof MemberRoleBadge>["memberRole"];
  variant: ComponentProps<typeof MemberRoleBadge>["variant"];
};

const useMessageRoleBadge = (message: StreamGlueMessage) => {
  const { messageableBy, threadStarterID } = useThreadViewState(
    ({ messageableBy, threadStarterID }) => ({
      messageableBy,
      threadStarterID,
    })
  );

  // Show badges for apps
  const isApp =
    message.user?.name?.endsWith(" (App)") ||
    message.user?.id === env.glueAIBotID;

  // TODO: `message.user.role` is always set to `user` so we can only show the badge for thread starters currently.
  // When we have the ability to access the admin role from `message`, we can show the badge for admins as well.
  const showStarterRoleBadge =
    messageableBy === MessageableBy.Admin &&
    threadStarterID !== undefined &&
    message.user?.id === threadStarterID;

  const badgeRole = isApp
    ? "app"
    : showStarterRoleBadge
      ? "starter"
      : undefined;

  const roleBadge: RoleBadgeProps | undefined =
    badgeRole !== undefined
      ? {
          role: badgeRole,
          variant: message.pinned === true ? "perspective" : "subtle",
        }
      : undefined;

  const authorName = isApp
    ? message.user?.name?.replaceAll(" (App)", "")
    : (message.user?.name ?? "");

  return { roleBadge, authorName };
};

export default useMessageRoleBadge;
