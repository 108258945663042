import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router";

import Icon, { IconName } from "components/design-system/icons/Icon";
import {
  RoutingPartition,
  usePartitionState,
} from "components/routing/RoutingPartition";
import {
  RoutingLocationState,
  TabName,
  routePath,
  superTabNames,
  superTabsDefaults,
  useRoutePartition,
  useValidatedMobileTabRoute,
} from "components/routing/utils";
import useSidebarCounts from "components/SideBar/hooks/useSidebarCounts";
import useRouteMemoryStack from "hooks/__mobile__/useRouteMemoryStack";
import useSuperTabSideEffect from "hooks/__mobile__/useSuperTabSideEffect";
import useAuthData from "hooks/useAuthData";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import useRoutesStore from "store/useRoutesStore";
import tw from "utils/tw";

import { TabCountBadge, TabDotBadge } from "../TabBadge";

const disableMemorizedRoute = ["activity"];

const Tab = ({
  badge,
  name,
  icon,
  iconSelected,
  scrollRef,
  selected,
}: {
  badge?: JSX.Element;
  name: TabName;
  icon: IconName;
  iconSelected: IconName;
  scrollRef: HTMLDivElement | HTMLUListElement | null;
  selected: boolean;
}) => {
  const history = useHistory<RoutingLocationState>();
  const { pathname } = useLocation();

  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const { routesStack } = useRouteMemoryStack({
    isActive: !!isActivePartition,
    name: superTabNames[name],
    route: history.location.state?.setDefaultRoute?.includes(
      superTabNames[name]
    )
      ? superTabsDefaults[superTabNames[name]]
      : route,
  });

  const { superTab } = useRoutePartition();

  const memorizedRoute = useValidatedMobileTabRoute(
    routesStack,
    superTabNames[name]
  );

  const isTabRoot =
    pathname === superTabsDefaults[superTabNames[name]] ||
    (name === TabName.Inbox && pathname === "/inbox");

  const { tabSideEffects } = useSuperTabSideEffect(({ tabSideEffects }) => ({
    tabSideEffects,
  }));

  const onClickTab = () => {
    if (selected) {
      if (isTabRoot) {
        tabSideEffects[superTabNames[name]]?.();
        return scrollRef?.scrollTo(0, 0);
      }

      return history.push(
        routePath({
          d: undefined,
          recipientID: undefined,
          superTab,
          threadID: undefined,
        })
      );
    }

    if (disableMemorizedRoute.includes(superTabNames[name])) {
      return history.push(
        routePath({
          d: undefined,
          recipientID: undefined,
          superTab: superTabNames[name],
          threadID: undefined,
        })
      );
    }

    return history.push(memorizedRoute);
  };

  return (
    <button
      className={tw(
        "font-semibold h-full pointer-events-auto text-xs w-full",
        selected ? "text-text-primary" : "text-text-subtle"
      )}
      onClick={onClickTab}
    >
      {/* this DIV necessary as workaround for what appears to be a Chromium bug, where SVG fails as a click target */}
      <div className="flex flex-col items-center relative">
        {badge}
        <Icon
          className="mb-4 pointer-events-none"
          icon={selected ? iconSelected : icon}
          size={24}
        />
        {name}
      </div>
    </button>
  );
};

const AppTabBar = ({
  scrollRef,
}: { scrollRef: HTMLDivElement | HTMLUListElement | null }) => {
  const { authData } = useAuthData();
  const aiDisabled = authData?.workspaces.edges.length === 0;

  const { routes } = useRoutesStore(({ routes }) => ({
    routes,
  }));
  const {
    unseenFeedCount,
    unreadDirectMessages,
    unreadMentionCount,
    unreadInboxCount,
    unreadNotificationCount,
  } = useSidebarCounts();

  const { superTab } = useRoutePartition();

  const tabBarRef = useRef<HTMLDivElement>(null);

  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          const { current: element } = tabBarRef;
          if (!element) return;
          element.style.display = keyboardHeight > 100 ? "none" : "block";
        }
      ),
    []
  );

  const superTabClassNames =
    "flex h-40 items-start justify-center pointer-events-auto shrink-0 w-48";

  return (
    <div
      ref={tabBarRef}
      className={tw(
        "z-1 relative",
        "bg-background-body pb-safe-area",
        "transition-transform"
      )}
    >
      <div
        className={tw(
          "flex flex-row items-start justify-between shrink-0",
          "border-border-container border-t-thin h-[62px] pt-7 px-24 z-1"
        )}
      >
        <RoutingPartition
          className={superTabClassNames}
          classNames={{}} // disable default classNames
          defaultRoute={routes.inbox}
          isActive={superTab === "inbox" || superTab === "threads"}
          unmountInactive={false}
        >
          <Tab
            badge={
              unreadInboxCount ? (
                <TabCountBadge
                  className="absolute right-5 -top-4"
                  count={unreadInboxCount}
                  variant={unreadMentionCount ? "strong" : "default"}
                />
              ) : undefined
            }
            name={TabName.Inbox}
            icon="Inbox"
            iconSelected="InboxFilled"
            scrollRef={scrollRef}
            selected={superTab === "inbox" || superTab === "threads"}
          />
        </RoutingPartition>

        <RoutingPartition
          className={aiDisabled ? "hidden" : superTabClassNames}
          classNames={{}} // disable default classNames
          defaultRoute={routes.ai}
          isActive={superTab === "ai"}
          unmountInactive={false}
        >
          <Tab
            name={TabName.AI}
            icon="SparkleFilled"
            iconSelected="SparkleFilled"
            scrollRef={scrollRef}
            selected={superTab === "ai"}
          />
        </RoutingPartition>

        <RoutingPartition
          className={superTabClassNames}
          classNames={{}} // disable default classNames
          defaultRoute={routes.feed}
          isActive={superTab === "feed"}
          unmountInactive={false}
        >
          <Tab
            badge={
              unseenFeedCount ? (
                <TabDotBadge className="absolute right-9 -top-2" />
              ) : undefined
            }
            name={TabName.Feed}
            icon="MyFeed"
            iconSelected="MyFeedFilled"
            scrollRef={scrollRef}
            selected={superTab === "feed"}
          />
        </RoutingPartition>
        <RoutingPartition
          className={superTabClassNames}
          classNames={{}} // disable default classNames
          defaultRoute={routes.groups}
          isActive={superTab === "groups"}
          unmountInactive={false}
        >
          <Tab
            name={TabName.Groups}
            icon="Groups"
            iconSelected="GroupsFilled"
            scrollRef={scrollRef}
            selected={superTab === "groups"}
          />
        </RoutingPartition>
        <RoutingPartition
          className={superTabClassNames}
          classNames={{}} // disable default classNames
          defaultRoute={routes.dms}
          isActive={superTab === "dms"}
          unmountInactive={false}
        >
          <Tab
            badge={
              unreadDirectMessages ? (
                <TabDotBadge className="absolute right-9 -top-2" />
              ) : undefined
            }
            name={TabName.DMs}
            icon="ChatRounded"
            iconSelected="ChatRoundedFilled"
            scrollRef={scrollRef}
            selected={superTab === "dms"}
          />
        </RoutingPartition>
        <RoutingPartition
          className="hidden"
          classNames={{}} // disable default classNames
          isActive={superTab === "activity"}
          unmountInactive={false}
        >
          <Tab
            badge={
              unreadNotificationCount ? (
                <TabDotBadge className="absolute right-9 -top-2" />
              ) : undefined
            }
            name={TabName.Activity}
            icon="Bell"
            iconSelected="BellFilled"
            scrollRef={scrollRef}
            selected={superTab === "activity"}
          />
        </RoutingPartition>

        <RoutingPartition
          className="hidden"
          classNames={{}} // disable default classNames
          isActive={superTab === "search"}
          unmountInactive={false}
        >
          <Tab
            name={TabName.Search}
            icon="Search"
            iconSelected="Search"
            scrollRef={scrollRef}
            selected={superTab === "search"}
          />
        </RoutingPartition>
      </div>
    </div>
  );
};

export default AppTabBar;
