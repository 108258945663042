import tw from "utils/tw";

import AppHeader from "./AppHeader";
import AppMain from "./AppMain";

const AppLayoutDesktop = () => {
  return (
    <div
      className={tw(
        "bg-background-app-base pt-[env(safe-area-inset-top)] pb-[calc(env(safe-area-inset-bottom)_+_4px)]",
        "flex flex-col grow min-h-0 max-h-full",
        "h-0" // required for flex-grow to work on iPad
      )}
    >
      <AppHeader />
      <AppMain />
    </div>
  );
};

export default AppLayoutDesktop;
