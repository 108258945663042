import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import useFormSubmit from "components/design-system/Forms/hooks/useFormSubmit";
import { Modal, ModalProps } from "components/ModalKit/Modal";
import { useModalDragging } from "components/ModalKit/useModalDragging";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

const buttonClassnames =
  "flex grow justify-center items-center h-[54px] border-t-1 border-border-container";

type Props = {
  confirmLabel?: string;
  contentClassName?: string;
  header: string;
  isDestructive?: boolean;
  message?: React.ReactNode;
  onConfirm: () => Promise<unknown>;
} & ModalProps;

/**
 * This modal opens in the center on large displays,
 * and slides in from the bottom on small displays.
 */
export const ConfirmationModal = ({
  confirmLabel,
  contentClassName,
  header,
  isDestructive,
  message,
  onConfirm,
  ...props
}: Props) => {
  const history = useHistory();
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  // if location changes, close the modal.
  useEffect(
    () => history.listen(() => closeModal(props.modalId)),
    [closeModal, history, props.modalId]
  );

  const { bind, dragStyles } = useModalDragging(props.modalId);

  const contentRef = useRef<HTMLDivElement>(null);

  const handleCloseModal = () => closeModal(props.modalId);

  const { error, handleFormSubmit, submitting } = useFormSubmit(() =>
    onConfirm().then(handleCloseModal)
  );

  return (
    <Modal
      closeButton={false}
      containerScrolling={false}
      contentClassName={tw(
        "overflow-hidden my-50 mx-15 w-[512px] max-w-[calc(100%_-_30px)] rounded-lg border-thin border-border-container", // mobile and up
        "", // desktop
        contentClassName
      )}
      dragStyles={dragStyles}
      {...props}
    >
      <div ref={contentRef} className="flex flex-col">
        <div className="flex flex-col px-32 py-16 text-center">
          <h3
            className="flex justify-center items-center m-0 mb-16 text-balance"
            {...bind()}
          >
            {header}
          </h3>
          <div className="text-md empty:hidden leading-6 text-text-subtle text-balance">
            {message}
          </div>
          {error && (
            <div className="text-md empty:hidden pt-0 leading-6 text-accent-error">
              <span className="font-semibold">Error</span>: {error}
            </div>
          )}
        </div>
        <div className="flex">
          <Button
            buttonStyle="simpleSecondary"
            buttonType="none"
            className={buttonClassnames}
            disabled={submitting}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <span className="w-1 bg-background-subtle border-t-1 border-border-container" />
          <Button
            testId="confirmation-modal-confirm-button"
            buttonStyle={
              isDestructive ? "simpleDestructive" : "simpleSecondary"
            }
            buttonType="none"
            className={tw("font-semibold", buttonClassnames, {
              "text-accent-alert": isDestructive,
            })}
            disabled={submitting}
            onClick={handleFormSubmit}
          >
            {confirmLabel ?? "Okay"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
