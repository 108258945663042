import { format } from "date-fns";

import { WorkspaceSubscriptionFieldsFragment } from "generated/graphql";

const numberFormatter = new Intl.NumberFormat("en-US");

export const formatAmount = (amount: number, showDecimals?: boolean) => {
  const formattedAmount = numberFormatter.format(amount / 100);
  return `$${formattedAmount}${showDecimals && !formattedAmount.includes(".") ? ".00" : ""}`;
};

export const subscriptionTotal = (
  subscription?: WorkspaceSubscriptionFieldsFragment,
  showDecimals?: boolean
) => {
  if (!subscription) return `$0${showDecimals ? ".00" : ""}`;
  return formatAmount(
    (subscription?.quantity ?? 0) * (subscription?.plan?.amount ?? 0),
    showDecimals
  );
};

export const planDate = (date?: string | null) =>
  date ? `${format(new Date(date), "MMMM d, yyyy")}` : undefined;
