import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { Address } from "@utility-types";
import EditableAvatar from "components/Avatar/EditableAvatar";
import CollapsibleSection from "components/design-system/CollapsibleSection";
import {
  Form,
  SubmitButton,
  useDisableSubmit,
} from "components/design-system/Forms";
import { TextInput } from "components/design-system/Forms";
import Hr from "components/design-system/Hr";
import { Icon } from "components/design-system/icons";
import WorkspaceItem from "components/design-system/ui/ProfilePane/WorkspaceItem";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import { AuthConfigQuery, useUpdateProfileMutation } from "generated/graphql";
import type { UpdateMeInput } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

import DeleteAccountModal from "./DeleteAccountModal";

export type FormData = Partial<UpdateMeInput> & {
  email: string | undefined;
  id: string;
};

const EditProfile = (
  props: ModalProps & {
    addresses: Address[];
    authData: AuthConfigQuery;
  }
) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const disabled = useDisableSubmit();
  const { setValue, watch } = useFormContext();

  const { addresses, authData, ...modalProps } = props;
  const avatarURL = watch("avatarURL");

  const handleChangeAvatar = (avatarURL: string) => {
    setValue("avatarURL", avatarURL, { shouldDirty: true });
  };

  return (
    <StandardModal
      contentHandlesSafeArea={false}
      header={
        <Header
          mobileCtaLabel="Save"
          mobileCtaProps={{ disabled, type: "submit" }}
          variant="bordered"
        >
          <h3 className="m-0">Edit Profile</h3>
        </Header>
      }
      {...modalProps}
    >
      <Main className="flex flex-col grow px-32">
        <div className="flex flex-col md:flex-row pt-24 pb-12">
          <div className="mr-24">
            <div className="flex justify-center">
              <EditableAvatar
                avatarURL={avatarURL}
                name={authData.me.name}
                onAvatarUpdate={handleChangeAvatar}
                rounded="rounded-lg"
              />
            </div>
          </div>
          <div className="flex flex-col md:grow">
            <div className="flex flex-col grow md:flex-row">
              <TextInput<FormData>
                config={{ required: true }}
                label="Name"
                name="name"
                wrapperClassName="mt-0 md:mr-24 grow"
              />
              <TextInput<FormData>
                label="Title"
                name="bio"
                wrapperClassName="mt-0 grow"
              />
            </div>
            <TextInput<FormData>
              className="disabled:!bg-background-ghost"
              label="Account Email (private)"
              name="email"
              wrapperClassName="mt-0"
              disabled
            />
          </div>
        </div>

        <Hr />

        <div className="flex flex-col gap-20 my-20">
          <CollapsibleSection label="Workspaces" collapsed>
            <div className="grid grid-rows-1 gap-8 mt-20">
              {authData.workspaces?.edges?.map(wks => (
                <WorkspaceItem key={wks.id} workspaceID={wks.node.id} />
              ))}
            </div>
          </CollapsibleSection>

          <CollapsibleSection label="Account options" collapsed>
            <button
              className="bg-background-body border border-border-container flex items-center justify-start mt-20 px-16 py-14 rounded-lg text-subhead-bold text-text-alert hover:text-text-alert-hover w-full"
              onClick={() =>
                openModal(<DeleteAccountModal parentModalId={props.modalId} />)
              }
              type="button"
            >
              <Icon className="mr-6" icon="Trash" size={20} />
              Delete Account
            </button>
          </CollapsibleSection>
        </div>
      </Main>

      <Footer className="hidden md:flex">
        <SubmitButton>Save</SubmitButton>
      </Footer>
    </StandardModal>
  );
};

const EditProfileModal = (props: ModalProps): JSX.Element | null => {
  const { authData, fetchAuthData } = useAuthData();
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));
  const [updateProfile] = useUpdateProfileMutation({
    errorPolicy: "all",
  });

  const onUpdateProfile = useCallback(
    (data: Partial<UpdateMeInput>) =>
      updateProfile({
        variables: {
          input: data,
        },
      }).then(async () => {
        await fetchAuthData().catch(err => {
          console.warn("Error: [EditProfileModal] -", err);
        });

        closeModal(`${props.modalId}`);
      }),
    [closeModal, fetchAuthData, props.modalId, updateProfile]
  );

  if (!authData) return null;

  const addresses = authData.addresses.edges.map(item => item.node);
  const user = authData.me;

  const onSubmit = (data: FormData) =>
    onUpdateProfile({
      ...data,
    });

  return (
    <Form
      className="contents"
      onSubmit={onSubmit}
      useFormProps={{
        defaultValues: {
          avatarURL: user.avatarURL,
          bio: user.bio,
          email: addresses?.map(a => a.address).join(", "),
          id: user.id,
          name: user.name,
        },
      }}
    >
      <EditProfile addresses={addresses} authData={authData} {...props} />
    </Form>
  );
};

export default EditProfileModal;
