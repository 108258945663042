import { Skeleton } from "components/Skeleton";

const WorkspaceSectionSkeleton = () => (
  <div className="mt-8 flex h-40">
    <Skeleton width="32px" height="36px" className="mr-8" />
    <div>
      <Skeleton width="100px" height="18px" className="block" />
      <Skeleton width="200px" height="15px" className="block" />
    </div>
  </div>
);

export default WorkspaceSectionSkeleton;
