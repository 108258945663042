import { useState } from "react";
import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import {
  defaultRoutesStack,
  superTabsDefaults,
} from "components/routing/utils";
import useRoutesStore from "store/useRoutesStore";

const RouteMemoryReport = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col gap-6">
      <div className="text-subhead-bold">Routes in Memory</div>
      <div className="flex gap-12 items-center">
        <Button
          onClick={() => {
            useRoutesStore.setState({
              restoreRoute: "/inbox",
              routes: superTabsDefaults,
              routesStack: defaultRoutesStack,
            });
            history.push("/inbox");
          }}
          type="button"
        >
          Clear routes
        </Button>
        <Button onClick={() => setOpen(prev => !prev)} type="button">
          {`${open ? "Hide" : "Show"}`} routes in memory
        </Button>
      </div>

      {open && (
        <div className="text-footnote">
          {Object.entries(useRoutesStore.getState().routes).map(entry => {
            const [key, value] = entry;
            return (
              <p key={key}>
                <strong>{`${key}:`}</strong>
                <br />
                {`${value}`}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RouteMemoryReport;
