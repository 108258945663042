import { ComponentProps } from "react";

import { Icon } from "components/design-system/icons";

type SectionContent = {
  content: React.ReactNode;
  iconProps: ComponentProps<typeof Icon>;
};

const TwoTierInfoBox = ({
  upper,
  lower,
}: {
  upper: SectionContent;
  lower: SectionContent;
}) => {
  return (
    <div className="flex flex-col rounded-lg bg-background-body border border-border-container text-text-primary">
      <div className="flex gap-12 items-start p-16 pt-12 rounded-lg bg-background-app border-b border-border-container">
        <div className="shrink-0">
          <Icon size={20} {...upper.iconProps} />
        </div>
        <div className="text-footnote text-text-primary">{upper.content}</div>
      </div>

      <div className="flex gap-12 items-center px-16 py-12 rounded-lg">
        <div className="shrink-0">
          <Icon className="text-icon-subtle" size={20} {...lower.iconProps} />
        </div>
        <div className="text-footnote text-text-secondary">{lower.content}</div>
      </div>
    </div>
  );
};

export default TwoTierInfoBox;
