import { forwardRef, useEffect, useRef } from "react";
import { useWatch } from "react-hook-form";

import { Form, TextInput } from "components/design-system/Forms";

const FindThreadInput = ({
  label,
  onChange,
}: {
  label?: string;
  onChange: (match: string) => void;
}) => {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const query = useWatch({ name: "find" });

  useEffect(() => onChangeRef.current(query), [query]);

  return (
    <TextInput
      className="bg-background-subtle border-none h-32 text-text-secondary placeholder:text-text-subtle md:bg-background-body md:placeholder:text-text-secondary"
      icon="Search"
      name="find"
      placeholder={label ?? "Search..."}
      wrapperClassName="!my-8"
    />
  );
};

type Props = {
  className?: string;
  label?: string;
  onChange: (match: string) => void;
};

const FindThread = forwardRef<HTMLFormElement, Props>(
  ({ className, label, onChange }: Props, ref) => (
    <Form
      formRef={ref}
      className={className}
      useFormProps={{ defaultValues: { find: "" } }}
    >
      <FindThreadInput label={label} onChange={onChange} />
    </Form>
  )
);

export default FindThread;
