import { Recipient } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { RecipientsSelect } from "components/RecipientsSelect";
import { RecipientValue } from "components/RecipientsSelect/types";
import { useConnectWithUsersMutation } from "generated/graphql";

const FindUser = ({
  selectedElement,
  setSelectedElement,
}: {
  selectedElement?: Recipient;
  setSelectedElement: (recipient?: Recipient) => void;
}) => {
  const [connect] = useConnectWithUsersMutation();
  const handleSelectRecipient = (recipients: RecipientValue[]) => {
    const recipient = recipients[0];
    if (recipient?.__typename === "Address") {
      connect({ variables: { users: [recipient.id] } }).then(result =>
        setSelectedElement(result.data?.connectWithUsers.edges[0]?.node)
      );
      return;
    }
    setSelectedElement(recipient);
  };
  return selectedElement ? (
    <div className="flex items-center px-4 py-10">
      <div className="flex items-center h-24 bg-background-subtle rounded-md">
        <Avatar
          avatarURL={selectedElement.avatarURL}
          name={selectedElement.name}
          rounded="rounded-l-md"
          size="small"
        />
        <span className="ml-8 mr-2">{selectedElement?.name}</span>
        <Button
          buttonStyle="simpleSecondary"
          icon="Close"
          onClick={() => {
            setSelectedElement(undefined);
          }}
        />
      </div>
    </div>
  ) : (
    <RecipientsSelect
      borderWidth={0}
      className="h-44"
      onChange={handleSelectRecipient}
      placeholder="Find by name or email"
      selectGroups={false}
      includeGlueAI
      autoFocus
    />
  );
};

export default FindUser;
