import { Placement } from "@floating-ui/react";
import { MentionAtomNodeAttributes } from "@remirror/extension-mention-atom";
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { KeyBindingNames } from "remirror";

import { Recipient } from "@utility-types";
import useEditorIsProcessing from "components/MessageEditor/hooks/useEditorIsProcessing";
import useMentionAtom from "components/MessageEditor/hooks/useMentionAtom";
import { MentionSearchName } from "components/MessageEditor/types";
import {
  useFlattenedResults,
  useInstantSearch,
} from "components/views/search/hooks";
import SearchResults, {
  SelectedSearchResult,
} from "components/views/search/quick-search/SearchResults";
import useAppStateStore from "store/useAppStateStore";
import { formatGroupName } from "utils/group/formatGroupName";

import { Floater } from "../Floater";

type Props = {
  onMention?: (mentioned: Recipient) => void;
};

const dismissKeys: KeyBindingNames[] = ["Escape"];
const submitKeys: KeyBindingNames[] = ["Enter", "Tab"];

const MentionsSuggester = ({ onMention }: Props): JSX.Element | null => {
  const [autoSelectEnabled, setAutoSelectEnabled] = useState(false);

  const [bottomPlacement, setBottomPlacement] = useState(false);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const { activeThreadId } = useAppStateStore(({ activeThreadId }) => ({
    activeThreadId,
  }));

  const { search, searchResults } = useInstantSearch({
    reverse: !bottomPlacement,
  });

  const orderedResults = useFlattenedResults({
    searchResults,
    reverse: !bottomPlacement,
  });

  const recipientsByID = useMemo(
    () =>
      new Map<string, Recipient>(
        orderedResults.flatMap(r =>
          r.__typename === "Thread" ? [] : [[r.id, r]]
        )
      ),
    [orderedResults]
  );

  const itemFromResult = useCallback(
    (result: SelectedSearchResult): MentionAtomNodeAttributes => ({
      id: result.id,
      label:
        result.__typename === "Thread"
          ? result.subject
          : formatGroupName(result).nameWithEmoji,
    }),
    []
  );

  const mentionItems = useMemo(
    () => orderedResults.map(r => itemFromResult(r)),
    [itemFromResult, orderedResults]
  );

  const onMentionItemFinder = useCallback(
    (item: MentionAtomNodeAttributes) => {
      const recipient = recipientsByID.get(item.id);
      if (recipient) onMention?.(recipient);
    },
    [onMention, recipientsByID]
  );

  const { getItemProps, index, setIndex, state } = useMentionAtom({
    dismissKeys,
    items: mentionItems,
    onMention: onMentionItemFinder,
    replacementType: "partial",
    submitKeys,
  });

  const handleItemOnClick = useCallback<
    Exclude<ComponentProps<typeof SearchResults>["onClickResult"], undefined>
  >(
    (edge, index, event) => {
      if (!event || edge.__typename === "Message") return;

      const item = itemFromResult(edge);
      getItemProps({ index, item }).onClick?.(event);
    },
    [getItemProps, itemFromResult]
  );

  const handlePlacementChange = useCallback((placement: Placement) => {
    setBottomPlacement(placement.startsWith("bottom"));
  }, []);

  useEffect(() => {
    if (!state || orderedResults.length === 0) return;

    setAutoSelectEnabled(false);
    setIndex(bottomPlacement ? 0 : orderedResults.length - 1);

    const scrollEl = scrollRef.current;
    if (scrollEl) {
      scrollEl.scrollTop = bottomPlacement ? 0 : scrollEl.scrollHeight;
    }
  }, [bottomPlacement, orderedResults, setIndex, state]);

  useEffect(() => {
    if (!state) return;

    const all = state.name === MentionSearchName.All;

    search({
      match: state.query.full,
      limit: 10,
      limitLocal: 5,
      users: all,
      groups: all,
      threadID: activeThreadId,
      threads: true,
    });
  }, [activeThreadId, search, state]);

  const selectedResultID = orderedResults[index]?.id;

  const setSelectedResultID = useCallback(
    (resultID: string) => {
      if (!autoSelectEnabled) {
        // To prevent auto-select after data changes,
        // we don't react to the first select
        setAutoSelectEnabled(true);
        return;
      }

      const index = orderedResults.findIndex(result => result.id === resultID);
      if (index !== -1) {
        setIndex(index);
      }
    },
    [autoSelectEnabled, orderedResults, setIndex]
  );

  useEditorIsProcessing(!!state);

  return state ? (
    <Floater onPlacementChange={handlePlacementChange} enabled>
      {!searchResults.searching && searchResults.totalCount === 0 ? (
        <div className="px-16 py-8 leading-8 w-[500px] italic text-footnote text-text-subtle">
          No results found. Try simplifying your query.
        </div>
      ) : (
        <SearchResults
          ref={scrollRef}
          className="py-4 rounded-lg w-[500px]"
          onClickResult={handleItemOnClick}
          searchResults={searchResults}
          selectedResultID={selectedResultID}
          setSelectedResultID={setSelectedResultID}
        />
      )}
    </Floater>
  ) : null;
};

export default MentionsSuggester;
