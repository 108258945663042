import { format } from "date-fns";

import { JoinApproval } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Card } from "components/design-system/Card";
import Hr from "components/design-system/Hr";
import useAuthData from "hooks/useAuthData";

import ProfileItem from "../ProfileItem";

export function JoinRequestCard({
  node,
  handleAction,
}: {
  node: JoinApproval & { approvedAt?: Date; declinedAt?: Date };
  handleAction?: (action: "approve" | "ignore", id: string) => void;
}) {
  const { authData } = useAuthData();

  const isInvitation = (joinApproval: JoinApproval) =>
    joinApproval.joining.id === authData?.me.id;

  const isThirdParty = (joinApproval: JoinApproval) =>
    joinApproval.joining.id !== joinApproval.requester.id;

  const Divider = ({ children }: WithChildren) => (
    <div className="relative flex items-center justify-center h-28 w-full">
      <Hr className="w-full !border-background-subtle" />
      <span className="absolute bg-background-body px-10">{children}</span>
    </div>
  );

  return (
    <Card key={node.id} className="mx-auto my-16 pt-16 max-w-[600px]">
      <div className="flex items-center px-16 pb-8">
        <ProfileItem
          recipient={isThirdParty(node) ? node.requester : node.joining}
        />
      </div>
      <Divider>
        {isThirdParty(node)
          ? "wants to add"
          : isInvitation(node)
            ? "invited you to join"
            : "requested to join"}
      </Divider>
      {isThirdParty(node) && (
        <>
          <div className="flex items-center px-16 py-8">
            <ProfileItem recipient={node.joining} />
          </div>
          <Divider>to</Divider>
        </>
      )}
      <div className="px-16 pt-8 pb-16">
        <ProfileItem recipient={node.joinable} />
      </div>
      <div className="flex p-16 border-t-1 border-background-subtle">
        <div className="flex grow items-center">
          <span className="text-interactive-subtle">
            {node.approvedAt
              ? `${
                  isInvitation(node) ? "You accepted on " : "You approved on "
                }${format(node.approvedAt, "M/d/yy")}`
              : node.declinedAt
                ? `You declined on ${format(node.declinedAt, "M/d/yy")}`
                : null}
          </span>
        </div>
        <div className="flex justify-end ">
          {node.approvedAt || node.declinedAt ? (
            <Button
              buttonStyle="subtle"
              icon={node.approvedAt ? "Check" : "Close"}
              disabled
            >
              {node.approvedAt
                ? isInvitation(node)
                  ? "Accepted"
                  : "Approved"
                : "Declined"}
            </Button>
          ) : (
            <>
              <Button
                buttonStyle="subtle"
                className="mr-16"
                onClick={() => handleAction?.("ignore", node.id)}
              >
                Decline
              </Button>
              <Button
                buttonStyle="secondary"
                className="!border-interactive-primary"
                onClick={() => handleAction?.("approve", node.id)}
              >
                {isInvitation(node) ? "Accept invite" : "Approve request"}
              </Button>
            </>
          )}
        </div>
      </div>
    </Card>
  );
}
