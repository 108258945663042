import tw from "utils/tw";

type FieldSetProps = {
  children: React.ReactNode;
  label: string;
};

export const FieldSet = ({ children, label }: FieldSetProps) => {
  return (
    <fieldset className="mb-12">
      <div className="font-bold mb-16">{label}</div>
      {children}
    </fieldset>
  );
};

export const Field = ({
  children,
  label,
  labelPosition = "center",
}: {
  children: React.ReactNode;
  label: React.ReactNode | string;
  labelPosition?: "top" | "center";
}) => (
  <div
    className={tw("grid gap-16 md:grid-cols-[200px_1fr]", {
      "items-center": labelPosition === "center",
    })}
  >
    <div>{label}</div>
    {children}
  </div>
);
