import { ApolloClient, useApolloClient } from "@apollo/client";
import { App as CapApp } from "@capacitor/app";
import { Device as CapDevice } from "@capacitor/device";
import { type Token } from "@capacitor/push-notifications";
import axios from "axios";
import { useEffect, useRef } from "react";

import useNotificationPermissions from "components/devices/hooks/useNotificationPermissions";
import usePushNotificationToken from "components/devices/hooks/usePushNotificationToken";
import {
  NotificationPermissionStatus,
  RegisterDeviceDocument,
  RegisterDeviceMutation,
  RegisterDeviceMutationVariables,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { getPlatform, isNative } from "utils/platform";
import env from "utils/processEnv";
import retryWithBackoff from "utils/retryWithBackoff";

type RegistrationToken = {
  fcmToken?: string; // Android
  pushToken?: string; // iOS
};

const parseToken = (token: Token): RegistrationToken => {
  try {
    return JSON.parse(token.value);
  } catch (_err) {
    return { fcmToken: token.value };
  }
};

const deregisterDevice = async (abortSignal?: AbortSignal) => {
  const { identifier: uuid } = await CapDevice.getId();

  await axios.delete(`${env.glueApiUrl}/device/uuid/${uuid}`, {
    signal: abortSignal,
    validateStatus(status: number) {
      // Do not treat a 404 as an error. It just indicates that the device is not currently registered
      return status < 400 || status === 404;
    },
  });
};

const registerDevice = async (
  apolloClient: ApolloClient<object>,
  fcmToken: string | undefined,
  pushToken: string | undefined,
  permissionStatus: NotificationPermissionStatus,
  abortSignal?: AbortSignal
) => {
  const { build: appBuild, version: appVersion } = await CapApp.getInfo();
  const { manufacturer, model, osVersion } = await CapDevice.getInfo();
  const platform = getPlatform();
  const { identifier: uuid } = await CapDevice.getId();

  await apolloClient.mutate<
    RegisterDeviceMutation,
    RegisterDeviceMutationVariables
  >({
    mutation: RegisterDeviceDocument,
    context: { fetchOptions: { signal: abortSignal } },
    variables: {
      input: {
        appBuild,
        appVersion,
        manufacturer,
        model,
        osVersion,
        platform,
        uuid,
        permissionStatus,
        fcmToken,
        pushToken,
      },
    },
  });
};

const useRegisterDevice = () => {
  const { authNeeded, authInitialized } = useAuthData();
  const { permissionStatus } = useNotificationPermissions();
  const { token, tokenInitialized } = usePushNotificationToken();
  const apolloClient = useApolloClient();

  const abortControllerRef = useRef<AbortController>();

  const { fcmToken, pushToken } = token
    ? parseToken(token)
    : { fcmToken: undefined, pushToken: undefined };

  useEffect(() => {
    if (!isNative()) {
      return;
    }

    const executeRegistration = async () => {
      if (!authInitialized || !tokenInitialized || !permissionStatus) return;

      // Abort previous request if any
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      const signal = abortController.signal;

      // Deregister if not signed in
      if (authNeeded) {
        await retryWithBackoff(() => deregisterDevice(signal), signal);
        return;
      }

      await retryWithBackoff(
        () =>
          registerDevice(
            apolloClient,
            fcmToken,
            pushToken,
            permissionStatus,
            signal
          ),
        signal
      );
    };

    executeRegistration();
  }, [
    authInitialized,
    authNeeded,
    permissionStatus,
    apolloClient,
    fcmToken,
    pushToken,
    tokenInitialized,
  ]);
};

export default useRegisterDevice;
