import {
  GroupPreviewSimple,
  JoinApproval,
  Recipient,
  ThreadPreview,
  User,
  WorkspacePreviewSimple,
  nodeAs,
} from "@utility-types";
import Icon from "components/design-system/icons/Icon";
import { routeToJoinRequest } from "components/routing/utils";
import {
  FeedType,
  useFetchWorkspaceOrPreviewEdgeQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import ActivityItemLayout from "./ActivityItemLayout";

type Props = {
  actor: User;
  isRead: boolean;
  joining: Recipient;
  object: JoinApproval;
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected: boolean;
  showIcons?: boolean;
  target: GroupPreviewSimple | ThreadPreview | WorkspacePreviewSimple;
  timestamp: string;
};

const ActivityItemSentJoinApproval = ({
  actor,
  joining,
  object,
  target,
  ...props
}: Props): JSX.Element => {
  let workspaceID = "";
  let joinableType: string;

  switch (target.__typename) {
    case "GroupPreview":
      joinableType = "group";
      workspaceID = target.workspaceID || "";
      break;
    case "WorkspacePreview":
      joinableType = "workspace";
      workspaceID = target.id;
      break;
    case "ThreadPreview":
      joinableType = "thread";
      for (const edge of target.recipients.edges) {
        const node = edge?.node;
        if (node?.__typename === "GroupPreview" && node.workspaceID) {
          workspaceID = node.workspaceID;
          break;
        }
        if (node?.__typename === "WorkspacePreview") {
          workspaceID = node.id;
          break;
        }
      }
      break;
  }

  const { authData, authReady } = useAuthData();
  const { data: dataWorkspace } = useFetchWorkspaceOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !(workspaceID && authData),
    variables: {
      id: `${workspaceID}-${authData?.me.id}`,
    },
  });

  const workspaceEdge = nodeAs(dataWorkspace?.node, [
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ]);

  const is3rdPartyJoin = joining.id !== actor.id;
  const isMyRequest = actor.id === authData?.me.id;
  const isInvitation = joining.id === authData?.me.id;

  let actorName = actor.name;

  if (!isInvitation && isMyRequest) {
    actorName = "You";
  }

  let requestInfo = (
    <>
      <span className="inline-block mr-5">
        Requested to join the {joinableType}
      </span>
      <span className="inline-block">
        {joinableType === "thread" ? (
          <Icon
            icon="Thread"
            className="h-16 w-16 mr-4 text-icon-secondary inline-block"
          />
        ) : null}
        {target.name}.
      </span>
    </>
  );

  if (isInvitation) {
    requestInfo = (
      <>
        <span className="inline-block mr-5">
          Invited you to join the {joinableType}
        </span>
        <span className="inline-block">{target.name}.</span>
      </>
    );
  }

  if (is3rdPartyJoin) {
    requestInfo = (
      <>
        <span className="inline-block mr-5">
          wants to add {joining.name} to
        </span>
        <span className="inline-block">{target.name}.</span>
      </>
    );
  }

  return (
    <ActivityItemLayout
      avatarProps={{ ...actor }}
      linkTo={toSecondary =>
        routeToJoinRequest({
          joinID: object.id,
          to: toSecondary ? "secondary" : undefined,
        })
      }
      title={`New request in ${workspaceEdge?.node.name || target.name || ""}`}
      type={FeedType.Requests}
      {...props}
    >
      <>
        <div className="text-text-primary text-subhead-bold">{actorName}</div>
        <div className="text-text-primary text-body">{requestInfo}</div>
      </>
    </ActivityItemLayout>
  );
};

export default ActivityItemSentJoinApproval;
