import { useApolloClient } from "@apollo/client";
import { useContext, useState } from "react";
import { GetReactionsAPIResponse, ReactionAPIResponse } from "stream-chat";

import { GlueDefaultStreamChatGenerics, Message } from "@utility-types";
import { MessageFieldsFragmentDoc } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { StreamClientContext } from "providers/StreamClientProvider";

type ReactionsCount = { [key: string]: number } | null | undefined;

const useFeedReactions = ({ message }: { message: Message }) => {
  const { authData } = useAuthData();
  const { streamChannelRecent, streamClient: client } =
    useContext(StreamClientContext);
  const { cache } = useApolloClient();
  const [updatedMessage, setUpdatedMessage] = useState(message);

  const allReactions: GetReactionsAPIResponse["reactions"] =
    updatedMessage.reactions.edges.map(r => ({
      created_at: message.createdAt,
      message_id: message.id,
      type: r.node.type,
      updated_at: message.updatedAt ?? "",
      user: { ...r.node.user, image: r.node.user.avatarURL },
    }));

  const ownReactions: GetReactionsAPIResponse["reactions"] =
    updatedMessage.ownReactions.map(r => ({
      created_at: message.createdAt,
      message_id: message.id,
      type: r.type,
      updated_at: message.updatedAt ?? "",
      user: r.user,
    }));

  let reactionCounts: ReactionsCount = {};
  updatedMessage.reactionCounts.forEach(count => {
    reactionCounts = {
      ...reactionCounts,
      [count.r]: count.c,
    };
  });

  const reactions = Object.keys(reactionCounts || {}).sort();

  const updateMessageCache = (emoji: string, type: "add" | "rm") => {
    const reactions = { ...updatedMessage.reactions };
    const messageData = { ...updatedMessage };
    const emojiCount = updatedMessage.reactionCounts.find(
      count => count.r === emoji
    );

    if (type === "add") {
      const reaction = {
        __typename: "Reaction" as const,
        id: "",
        type: emoji,
        user: updatedMessage.user,
      };
      messageData.ownReactions = [...updatedMessage.ownReactions, reaction];

      reactions.edges = [
        ...reactions.edges,
        { __typename: "ReactionEdge", node: reaction },
      ];
      messageData.reactions = reactions;

      let counts = [...updatedMessage.reactionCounts];
      if (emojiCount) {
        counts = counts.map(count =>
          emojiCount.r === count.r ? { ...count, c: count.c + 1 } : count
        );
      } else {
        counts.push({ __typename: "ReactionCount", c: 1, r: emoji });
      }
      messageData.reactionCounts = counts;
    } else {
      messageData.ownReactions = updatedMessage.ownReactions.filter(
        edge => edge.type !== emoji
      );

      reactions.edges = reactions.edges.filter(
        edge =>
          !(edge.node.type === emoji && edge.node.user.id === authData?.me.id)
      );
      messageData.reactions = reactions;

      let counts = [...updatedMessage.reactionCounts];
      if (emojiCount) {
        counts = counts.map(count =>
          emojiCount.r === count.r
            ? { ...count, c: count.c - 1 >= 0 ? count.c - 1 : 0 }
            : count
        );
      }
      messageData.reactionCounts = counts;
    }

    cache.writeFragment({
      data: messageData,
      fragment: MessageFieldsFragmentDoc,
      fragmentName: "MessageFields",
      id: cache.identify(message),
    });

    setUpdatedMessage(messageData);
  };

  const handleReaction = async (emoji: string) => {
    if (!message.streamID) return;

    const channel = await streamChannelRecent(message.threadID);
    if (!channel) return;

    if (ownReactions.filter(r => r.type === emoji).length) {
      updateMessageCache(emoji, "rm");
      if (channel.initialized) {
        channel
          .deleteReaction(message.streamID, emoji)
          .catch(() => updateMessageCache(emoji, "add"));
        return;
      }
      const url = `${client?.baseURL}/messages/${message.streamID}/reaction/${emoji}`;
      client
        ?.delete<ReactionAPIResponse<GlueDefaultStreamChatGenerics>>(url, {
          user_id: authData?.me.id,
        })
        .catch(() => updateMessageCache(emoji, "add"));
      return;
    }

    updateMessageCache(emoji, "add");
    channel
      .sendReaction(message.streamID, {
        type: emoji,
      })
      .catch(() => updateMessageCache(emoji, "rm"));
  };

  return {
    allReactions,
    handleReaction,
    ownReactions,
    reactionCounts,
    reactions,
  };
};

export default useFeedReactions;
