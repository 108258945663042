import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { nodeAs, nodeIs } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Form, TextToggle } from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import { InformationBubble } from "components/design-system/InformationBubble";
import Skeleton, {
  cloneElementForSkeletons,
} from "components/Skeleton/Skeleton";
import {
  FetchGroupOrPreviewEdgeDocument,
  FetchUsersDocument,
  JoinableBy,
  NotInGroupDocument,
  PersistentChatsDocument,
  WorkspacesAndGroupsListDocument,
  useFetchWorkspaceOrPreviewEdgeQuery,
  useJoinGroupMutation,
  useRequestJoinGroupMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useOnboardingStore from "store/useOnboardingStore";
import filterActiveQueries from "utils/filterActiveQueries";
import { formatGroupName } from "utils/group/formatGroupName";

import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

type Action = "join" | "request";
type GroupKey = `${Action}-${string}`;
type FormValues = Record<GroupKey, boolean>;

const GroupItem = ({
  name,
  admin,
  members,
  children,
  loading = false,
}: WithChildren<{
  name: string;
  admin: string;
  members: number;
  loading?: boolean;
}>) => {
  const { name: groupName, emoji } = formatGroupName({ name });
  return (
    <div className="flex flex-row items-center w-full">
      {!loading ? (
        <Avatar
          size="large"
          rounded="rounded-lg"
          name={groupName}
          emojiProps={{ emoji }}
          background="transparent"
        />
      ) : (
        <Skeleton className="bg-background" height="36px" width="36px" />
      )}
      <div className="w-full overflow-hidden ml-8">
        {!loading ? (
          <span className="text-subhead-bold">{groupName}</span>
        ) : (
          <Skeleton className="bg-background" height="17px" width="150px" />
        )}
        <div className="flex text-footnote text-text-subtle">
          {!loading ? (
            <span className="truncate">Admin: {admin}</span>
          ) : (
            <Skeleton className="bg-background" height="18px" width="110px" />
          )}
          <span className="px-4">·</span>
          {!loading ? (
            <span className="text-nowrap">{members} members</span>
          ) : (
            <Skeleton className="bg-background" height="18px" width="60px" />
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

const JoinGroups = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const {
    setState,
    currentStep,
    workspace,
    groups: currentGroups,
  } = useOnboardingStore(({ setState, currentStep, workspace, groups }) => ({
    setState,
    currentStep,
    workspace,
    groups,
  }));
  const { authData, authReady } = useAuthData();

  const { data, fetchMore, loading } = useFetchWorkspaceOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !(workspace?.id && authData),
    variables: {
      id: `${workspace?.id}-${authData?.me.id}`,
      groupsLimit: 25,
      membersLimit: 0, // the members are not needed for this step
    },
  });

  const workspaceEdge = nodeAs(data?.node, ["WorkspaceEdge"]);
  const { endCursor: afterCursor, hasNextPage } = workspaceEdge?.node.groups
    .pageInfo ?? { endCursor: null, hasNextPage: false };

  const loadGroups = useCallback(
    async (cursor: string) => {
      fetchMore({ variables: { groupsAfter: cursor } }).catch(err => {
        console.warn("Error: [WorkspaceProfile] - ", err.message);
      });
    },
    [fetchMore]
  );

  const [scrollSentryRef, { rootRef: listRef }] = useInfiniteScroll({
    hasNextPage,
    loading,
    onLoadMore: useCallback(() => {
      if (!afterCursor) return;
      loadGroups(afterCursor);
    }, [afterCursor, loadGroups]),
    rootMargin: "0px 0px 200px 0px",
  });

  const apolloClient = useApolloClient();

  const [groupJoin] = useJoinGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: filterActiveQueries(apolloClient, [
      PersistentChatsDocument,
      FetchUsersDocument,
      FetchGroupOrPreviewEdgeDocument,
      WorkspacesAndGroupsListDocument,
    ]),
  });

  const [groupJoinRequest] = useRequestJoinGroupMutation({
    awaitRefetchQueries: true,
    refetchQueries: filterActiveQueries(apolloClient, [NotInGroupDocument]),
  });

  const handleFormSubmit = async (data: FormValues) => {
    setFormSubmitting(true);
    const toJoin: string[] = [];
    const toRequest: string[] = [];
    const groupsJoined: {
      id: string;
      emoji?: string;
      name: string;
      type: "joined";
    }[] = [];

    Object.keys(data)
      .filter(key => data[key as GroupKey] === true)
      .forEach(key => {
        const [action, id] = key.split("-");

        if (!id) return;

        if (action === "join") {
          toJoin.push(id);
        } else {
          toRequest.push(id);
        }

        const group = workspaceEdge?.node.groups.edges.find(
          g => g.node.id === id
        );
        if (!group) return;
        const { emoji, name } = formatGroupName({ name: group.node.name });
        groupsJoined.push({ id, emoji, name, type: "joined" });
      });

    const promises: Promise<unknown>[] = [
      ...toJoin.map(
        id =>
          new Promise(resolve => ({
            data: resolve(groupJoin({ variables: { id } })),
          }))
      ),
      ...toRequest.map(
        joinableID =>
          new Promise(resolve => ({
            data: resolve(groupJoinRequest({ variables: { joinableID } })),
          }))
      ),
    ];

    await Promise.all(promises)
      .then(() => {
        setState({
          view: "InviteMembers",
          currentStep: currentStep + 1,
          groups: [...(currentGroups ?? []), ...groupsJoined],
        });
      })
      .catch(err => {
        console.error("Error: [handleFormSubmit] - ", err);
      })
      .finally(() => setFormSubmitting(false));
  };

  const FormFooter = () => {
    const { watch } = useFormContext<FormValues>();
    const values = watch();
    const submitDisabled =
      !Object.values(values).some(v => !!v) || formSubmitting;
    return (
      <Footer
        onClickBack={() =>
          setState({ view: "JoinWorkspace", currentStep: currentStep - 1 })
        }
        onClickSkip={() =>
          setState({
            view: "InviteMembers",
            currentStep: currentStep + 1,
          })
        }
        fullHeight={false}
        formSubmitting={formSubmitting}
        submitDisabled={submitDisabled}
      />
    );
  };

  return (
    <Form<FormValues> className="w-full" onSubmit={handleFormSubmit}>
      <ContentWrapper
        title={`Join groups in ${workspaceEdge?.node.name ?? "workspace"}`}
        headline="Groups are great for teams, projects or topics."
      >
        <div
          data-testid="join-groups-list"
          ref={listRef}
          className="flex flex-col gap-8 overflow-auto"
        >
          {loading
            ? cloneElementForSkeletons(
                <GroupItem name="" admin="" members={0} loading />,
                3
              )
            : workspaceEdge?.node.groups.edges
                .filter(g => g.node.joinableBy === JoinableBy.Workspace)
                .map(g => {
                  const joinable = g.node.joinableBy === JoinableBy.Workspace;
                  const isMember = nodeIs(g.node, ["Group"]);

                  return (
                    <GroupItem
                      key={g.node.id}
                      admin={g.node.admin?.name ?? ""}
                      members={g.node.members.totalCount}
                      name={g.node.name}
                    >
                      {isMember ? (
                        <span className="flex items-center font-semibold py-6 px-16 text-base text-text-disabled">
                          <Icon icon="Check" className="mr-8" size={20} />
                          Member
                        </span>
                      ) : (
                        <TextToggle
                          labelClassName="!m-0"
                          name={`${joinable ? "join" : "request"}-${g.node.id}`}
                          whenChecked={{
                            className:
                              "font-semibold py-6 px-16 text-base text-interactive-subtle",
                            text: joinable ? "Undo" : "Undo request",
                          }}
                          whenUnchecked={{
                            className:
                              "font-semibold py-6 px-16 text-base text-interactive-primary",
                            text: joinable ? "Join" : "Request",
                          }}
                          wrapperClassName="!m-0"
                        />
                      )}
                    </GroupItem>
                  );
                })}

          {hasNextPage && (
            <div ref={scrollSentryRef} className="mt-10 text-text-subtle">
              Loading…
            </div>
          )}
        </div>
        <InformationBubble className="mt-24 md:mt-32 mb-16">
          You can always join groups later.
        </InformationBubble>
        <div className="grow" />
        <FormFooter />
      </ContentWrapper>
    </Form>
  );
};

export default JoinGroups;
