import { uniqBy } from "lodash-es";

import { Recipient, Sizes } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Skeleton } from "components/Skeleton";
import tw from "utils/tw";

type Face = Pick<Recipient, "avatarURL" | "id" | "name">;

const horizontalSpacings: Record<Exclude<Sizes, "tiny">, `-ml-${number}`> = {
  large: "-ml-16",
  medium: "-ml-12",
  small: "-ml-4",
};

const FacePile = ({
  className,
  limit,
  onClick,
  reverse,
  size = "small",
  users,
}: {
  className?: string;
  limit?: number;
  onClick?: () => void;
  reverse?: boolean;
  size?: Exclude<Sizes, "tiny">;
  users: Face[] | undefined;
}): JSX.Element => {
  const reversedUsers = uniqBy(users, "id")?.slice(0, limit).reverse();

  return (
    <div
      className={tw(
        "flex shrink-0 items-center",
        reverse ? "flex-row-reverse" : "flex-row",
        { "cursor-pointer": !!onClick },
        className
      )}
      onClick={onClick}
    >
      {users ? (
        reversedUsers.map(user => (
          <Avatar
            key={user.id}
            border="background-body-2"
            rounded="rounded-md"
            margin={horizontalSpacings[size]}
            size={size}
            {...user}
          />
        ))
      ) : (
        <Skeleton className="-ml-4" width="64px" />
      )}
    </div>
  );
};

export default FacePile;
