import { CSSProperties, Children, useEffect, useRef, useState } from "react";
import Observer from "resize-observer-polyfill";

import useComponentMounted from "hooks/useComponentMounted";
import hasAncestorWithTagName from "utils/hasAncestorWithTagName";
import tw from "utils/tw";

type ScrollDirections = "left" | "right";

type Props = {
  children: (string | number | JSX.Element)[] | null;
  className?: string;
  columns?: number;
  defaultScrollDirection?: ScrollDirections;
  gutter?: number;
  itemWidth?: number;
  scrollSnapType?: "none" | "x proximity";
  showTail?: boolean;
  startPosition?: number;
  type?: "flex" | "grid";
};

const HorizontalScrollingList = ({
  children,
  className,
  columns = 3,
  defaultScrollDirection = "left",
  gutter = 10,
  itemWidth = 0,
  scrollSnapType = "x proximity",
  showTail: showTailProp = true,
  startPosition,
  type = "grid",
}: WithChildren<Props>): JSX.Element => {
  const isMounted = useComponentMounted();

  const scrollList = useRef<HTMLUListElement>(null);
  const items = Children.map(children, Child => (
    <li className="item">{Child}</li>
  ));
  const itemCount = items?.length || 0;
  const columnWidth =
    100 / columns - (itemCount > columns ? gutter / columns : 0);

  const [directionClicked, setDirectionClicked] = useState<ScrollDirections>(
    defaultScrollDirection
  );

  const [tailLength, setTailLength] = useState(0);

  const showPreview = itemCount > columns || itemWidth > 0;

  const showTail = showTailProp && itemCount > columns;

  useEffect(() => {
    const firstChild = scrollList.current?.firstElementChild;

    const handler = () =>
      firstChild &&
      window.requestAnimationFrame(
        () => isMounted.current && setTailLength(firstChild.clientWidth / 2)
      );

    const observer = new Observer(handler);

    if (firstChild) {
      observer.observe(firstChild);
    }

    handler();

    return () => {
      if (firstChild) {
        observer.unobserve(firstChild);
      }
    };
  }, [isMounted, itemCount]);

  useEffect(() => {
    if (!scrollList.current || !startPosition) {
      return;
    }

    scrollList.current.scrollLeft = startPosition;
  }, [startPosition]);

  useEffect(() => {
    if (!scrollList.current) {
      return;
    }
    const list = scrollList.current;
    const width = list.clientWidth;
    function dragStart(event: MouseEvent) {
      if (
        event.target &&
        (event.target instanceof HTMLElement || event.target instanceof Node) &&
        hasAncestorWithTagName(event.target, "BUTTON")
      ) {
        return;
      }

      const currentScroll = list.scrollLeft;
      const clientRect = list.getBoundingClientRect();
      const clientX = clientRect.left;

      if (width / 2 < event.clientX - clientX) {
        list.scrollLeft = currentScroll + width / columns;
        setDirectionClicked("right");
      } else {
        list.scrollLeft = currentScroll - width / columns;
        setDirectionClicked("left");
      }
    }

    list.addEventListener("mousedown", dragStart, false);
    return () => {
      list.removeEventListener("mousedown", dragStart, false);
    };
  }, [columns]);

  return (
    <div
      className={`horizontal-scrolling${className ? ` ${className}` : ""}`}
      style={
        {
          "--column-width": itemWidth ? `${itemWidth}px` : `${columnWidth}%`,
          "--gutter": `${gutter}px`,
          "--item-count": itemCount,
          "--preview": `${
            showPreview ? "5px" : `${gutter * ((100 - columnWidth) / 100)}px`
          }`,
          "--scroll-snap-type": scrollSnapType,
          "--tail-length": `${tailLength}px`,
          "--tail-show": `${showTail ? "block" : "none"}`,
        } as CSSProperties
      }
    >
      <div className={`scroll-container ${directionClicked}`}>
        <ul
          ref={scrollList}
          className={tw(
            `horizontal-scrolling-${type}`,
            {
              "columns-equal-items": itemCount === columns && !itemWidth,
            },
            {
              "show-item-preview": showPreview,
            },
            {
              "fixed-item-width": itemWidth,
            },
            {
              "show-tail": showTail,
            }
          )}
        >
          {items}
        </ul>
      </div>
    </div>
  );
};

export default HorizontalScrollingList;
