import { memo, useMemo } from "react";
import {
  TDateTimeParser,
  isDate,
  isDayOrMoment,
  isNumberOrString,
  useTranslationContext,
} from "stream-chat-react";

export const defaultTimestampFormat = "h:mmA";

export const notValidDateWarning =
  "MessageTimestamp was called without a message, or message has invalid created_at date.";

export const noParsingFunctionWarning =
  "MessageTimestamp was called but there is no datetime parsing function available";

function getDateString(
  date: string,
  formatDate?: MessageTimestampProps["formatDate"],
  calendar?: boolean,
  tDateTimeParser?: TDateTimeParser,
  format?: string
): string | null {
  if (!date || !Date.parse(date)) {
    console.warn(notValidDateWarning);
    return null;
  }

  if (typeof formatDate === "function") {
    return formatDate(new Date(date));
  }

  if (!tDateTimeParser) {
    console.warn(noParsingFunctionWarning);
    return null;
  }

  const parsedTime = tDateTimeParser(date);

  if (isDayOrMoment(parsedTime)) {
    /**
     * parsedTime.calendar is guaranteed on the type but is only
     * available when a user calls dayjs.extend(calendar)
     */
    return calendar && parsedTime.calendar
      ? parsedTime.calendar()
      : parsedTime.format(format);
  }

  if (isDate(parsedTime)) {
    return (parsedTime as Date).toDateString();
  }

  if (isNumberOrString(parsedTime)) {
    return parsedTime;
  }

  return null;
}

export type MessageTimestampProps = {
  calendar?: boolean;
  customClass?: string;
  date?: string | Date;
  format?: string;
  formatDate?: (date: Date) => string;
  /** Override the default formatting of the date. This is a function that has access to the original date object. Returns a string or Node  */
};

const UnMemoizedMessageTimestamp = (
  props: MessageTimestampProps & {
    reactTestId?: string;
  }
) => {
  const {
    calendar = false,
    customClass = "",
    date,
    format = defaultTimestampFormat,
    formatDate,
    reactTestId,
  } = props;

  const { tDateTimeParser } = useTranslationContext();

  const givenDate = date as string;

  const when = useMemo(
    () =>
      getDateString(givenDate, formatDate, calendar, tDateTimeParser, format),
    [givenDate, formatDate, calendar, tDateTimeParser, format]
  );

  if (!when) return null;

  return (
    <time
      className={customClass}
      data-testid={reactTestId}
      dateTime={givenDate}
      title={givenDate}
    >
      {when}
    </time>
  );
};

export const Timestamp = memo(
  UnMemoizedMessageTimestamp
) as typeof UnMemoizedMessageTimestamp;
