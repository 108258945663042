import { Button } from "components/design-system/Button";
import { environmentFlags } from "components/FeatureFlags";
import { FeatureFlagModal } from "components/FeatureFlags/FeatureFlagModal";
import useModalStore from "store/useModalStore";

const FeatureFlags = () => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  return (
    environmentFlags.length && (
      <div>
        <Button
          onClick={() =>
            openModal(<FeatureFlagModal environmentFlags={environmentFlags} />)
          }
          type="button"
        >
          Open feature flags modal
        </Button>
      </div>
    )
  );
};

export default FeatureFlags;
