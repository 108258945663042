import { Selection } from "@remirror/pm/state";
import {
  EditorState,
  MarkType,
  findNodeAtSelection,
  isAllSelection,
} from "remirror";

export const ENTER_REPLACEMENT_KEY = "Shift-Enter";

export function allowsMarkType(selection: Selection, markType?: MarkType) {
  if (!markType) return true;
  if (isAllSelection(selection)) return false;

  if (!findNodeAtSelection(selection).node.type.allowsMarkType(markType))
    return false;

  return !selection.$from
    .marksAcross(selection.$to)
    ?.find(m => m.type.excludes(markType));
}

export function hasEditorTextContent(state: EditorState) {
  return state.doc.content.size > 2;
}
