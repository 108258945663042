import { ComponentProps, useEffect, useState } from "react";
import { Flipper } from "react-flip-toolkit";

import { Dropdown } from "components/design-system/FloatingUi";
import {
  ExpandableSection,
  Section,
  SectionBody,
  SectionHeader,
  SectionSkeleton,
} from "components/design-system/ui/sections-sidebar";
import { SmallRecipientProfileItem } from "components/ProfileItem";
import useLocalSettingsStore from "store/useLocalSettingsStore";

import { SectionHeaderTitle } from "./SectionHeaderTitle";
import { SectionHeaderTitleButton } from "./SectionHeaderTitleButton";

type Props<T> = {
  addButton?: JSX.Element;
  divider?: "top" | "bottom" | false;
  flipKey: ComponentProps<typeof Flipper>["flipKey"];
  hasPreviousPage?: boolean;
  loadingNextPage?: boolean;
  moreItems: JSX.Element[];
  onClickMore: () => Promise<T> | void;
  sectionFooter?: JSX.Element;
  sectionItems: JSX.Element[];
  sectionKey: string;
  sectionTitle: string;
  sectionTitleDropdown?: JSX.Element;
  selectedItem?: JSX.Element;
  showSkeleton?: boolean;
  unreadCount?: number;
  unreadMentions?: number;
};

const MailboxSection = <T,>({
  addButton,
  divider,
  flipKey,
  hasPreviousPage = false,
  loadingNextPage = false,
  moreItems,
  onClickMore,
  sectionFooter,
  sectionItems,
  sectionKey,
  sectionTitle,
  sectionTitleDropdown,
  selectedItem,
  showSkeleton = false,
  unreadCount,
  unreadMentions,
}: Props<T>) => {
  const [isOpen, setOpen] = useState(false);
  const [moreCollapsed, setMoreCollapsed] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);

  const { collapsedSidebarSections } = useLocalSettingsStore(
    ({ collapsedSidebarSections }) => ({ collapsedSidebarSections })
  );
  const collapsed = collapsedSidebarSections.includes(sectionKey);

  useEffect(() => {
    setMoreLoading(loadingNextPage);
  }, [loadingNextPage]);

  // Collapses the more section if the data got refreshed and theres only one page available
  useEffect(() => {
    if (!moreItems.length) setMoreCollapsed(true);
  }, [moreItems.length]);

  if (showSkeleton)
    return (
      <SectionSkeleton title={sectionTitle}>
        <SmallRecipientProfileItem />
      </SectionSkeleton>
    );

  const handleLoadMore = () => {
    if (!hasPreviousPage && !moreCollapsed) {
      setMoreCollapsed(true);
      return;
    }

    if (moreCollapsed) {
      setMoreCollapsed(false);
      if (moreItems.length) {
        return;
      }
    }

    const response = onClickMore();
    if (response instanceof Promise) {
      setMoreLoading(true);
      response.finally(() => setMoreLoading(false));
      return;
    }
  };

  return (
    <Section
      divider={divider}
      header={
        <SectionHeader
          collapsed={collapsed}
          sectionKey={sectionKey}
          title={
            sectionTitleDropdown ? (
              <Dropdown setOpen={setOpen} content={sectionTitleDropdown}>
                <SectionHeaderTitleButton isOpen={isOpen}>
                  {sectionTitle}
                </SectionHeaderTitleButton>
              </Dropdown>
            ) : (
              <SectionHeaderTitle>{sectionTitle}</SectionHeaderTitle>
            )
          }
          unreadCount={unreadCount}
          unreadMentions={unreadMentions}
        />
      }
    >
      <SectionBody
        collapsed={collapsed}
        flipKey={`${flipKey}${collapsed}${moreCollapsed}`}
        moreCollapsed={moreCollapsed}
        sectionFooter={
          moreCollapsed && !moreItems.length && !hasPreviousPage
            ? sectionFooter
            : undefined
        }
        sectionItems={sectionItems}
        selectedItem={selectedItem}
      >
        <ExpandableSection
          addButton={addButton}
          hasNextPage={hasPreviousPage}
          loading={moreLoading}
          moreCollapsed={moreCollapsed}
          moreFooter={sectionFooter}
          moreItems={moreItems}
          moreUnreadCount={0}
          moreUnreadMentionCount={0}
          onClickLess={() => setMoreCollapsed(true)}
          onClickMore={handleLoadMore}
        />
      </SectionBody>
    </Section>
  );
};

export default MailboxSection;
