import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import { Footer, Main } from "components/ModalKit/Parts";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";

import { StepsProps } from "./types";

const SelectionInfo = ({ cancelButton, onClickNext }: StepsProps) => {
  const { composeThreadToGlue } = useComposeToGlue("support");
  return (
    <>
      <Main className="flex flex-col px-32 py-16">
        <span className="text-body">
          Next, you'll choose how your Slack data is imported into Glue.
          <br />
          <br />
          By default, Slack channels will be imported as named Glue threads and
          added to the General group in your workspace.
          <br />
          <br />
          You can also choose to import specific Slack channels as groups in
          Glue. The channel history will be added to the group chat, and channel
          members will be added to the new group in Glue.
        </span>
        <div className="grow" />
        <InformationBubble>
          Need help?{" "}
          <span
            className="text-footnote-bold text-text-link"
            onClick={() => composeThreadToGlue()}
          >
            Contact support.
          </span>
        </InformationBubble>
      </Main>
      <Footer>
        {cancelButton}
        <Button onClick={onClickNext}>Next</Button>
      </Footer>
    </>
  );
};

export default SelectionInfo;
