import { Icon } from "components/design-system/icons";
import { sidebarHeaderHeight } from "components/SideBar/SidebarHeader/SidebarHeader";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

import { TWPadding } from "./types/padding";

const EmptyThreadListPrimitive = ({
  children,
  isSearch,
  padding,
  primaryText,
  secondaryText,
}: WithChildren<{
  isSearch?: boolean;
  padding?: TWPadding | `${TWPadding} ${TWPadding}`;
  primaryText?: string;
  secondaryText?: string;
}>) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <div
      className={tw("flex items-center justify-center h-full", padding)}
      style={{
        paddingBottom: breakpointMD
          ? `${sidebarHeaderHeight + 48}px`
          : undefined, // sidebar header height + find thread input height (48px)
      }}
    >
      <div className="flex flex-col justify-center items-center text-center">
        <Icon
          className="text-icon-brand w-40 h-40 mb-16"
          icon={isSearch ? "Search" : "ChatRounded"}
          strokeWidth={2}
        />
        {primaryText && (
          <span className="font-bold text-body">{primaryText}</span>
        )}
        {secondaryText && (
          <span className="text-text-secondary">{secondaryText}</span>
        )}
        {children}
      </div>
    </div>
  );
};

export default EmptyThreadListPrimitive;
