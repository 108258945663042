import Avatar from "components/design-system/Avatar/Avatar";
import InviteToGlueModal from "components/InviteToGlue/InviteToGlueModal";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

const FooterInvite = () => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  return (
    <li className="list-none">
      <div
        className={tw(
          "group/thread-item select-none outline-none",
          "cursor-pointer flex items-center min-w-0 overflow-hidden relative w-full",
          "md:py-12 md:px-20 pl-8 hover:bg-background-list-hover",
          "md:border-b-1 md:border-border-container"
        )}
        onClick={() => openModal(<InviteToGlueModal inviteToWorkspace />)}
      >
        <div
          className={tw(
            "flex min-w-0 grow items-center",
            "border-b-1 border-border-container",
            "ml-12 py-12 pr-16 text-body text-text-secondary",
            "md:border-b-none md:ml-0 md:py-0 md:pr-0"
          )}
        >
          <div className="mr-8">
            <Avatar
              className="text-icon-secondary"
              iconProps={{
                icon: "UserAdd",
              }}
              rounded="rounded-md"
              size="medium"
            />
          </div>
          Invite to workspace
        </div>
      </div>
    </li>
  );
};

export default FooterInvite;
