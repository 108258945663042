import AppTabLayout from "components/App/AppLayoutMobile/AppTabLayout";
import NewThreadButton from "components/design-system/ui/NewThreadButton";
import NewThreadFeedWidget from "components/design-system/ui/NewThreadFeedWidget";
import MobileProfilePane from "components/design-system/ui/ProfilePane/MobileProfilePane";
import SidebarAccessoryContainer from "components/design-system/ui/SidebarAccessoryContainer";
import Feed from "components/Feed/Feed";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  TabName,
  routeParams as getRouteParams,
  locationFromRoute,
} from "components/routing/utils";
import ThreadPane from "components/threads/ThreadPane";

const FeedViewMobile = ({
  scrollRef,
}: {
  scrollRef: (el: HTMLDivElement | null) => void;
}) => {
  const { route } = usePartitionState(({ route }) => ({ route }));
  const { pathname, search } = locationFromRoute(route);
  const { d, messageID, threadID } = getRouteParams({ pathname, search });

  const detailContent: React.ReactNode = (() => {
    if (d) {
      return <MobileProfilePane id={d} />;
    }

    if (threadID) {
      return <ThreadPane messageID={messageID} threadID={threadID} />;
    }

    return null;
  })();

  return (
    <AppTabLayout detailChildren={detailContent} name={TabName.Feed}>
      <div
        ref={scrollRef}
        className="w-full grow min-h-0 bg-background-app-secondary overflow-auto"
      >
        <div className="w-full">
          <NewThreadFeedWidget className="my-16" />
          <Feed feedID={"groups"} />
        </div>
      </div>

      <SidebarAccessoryContainer>
        <NewThreadButton />
      </SidebarAccessoryContainer>
    </AppTabLayout>
  );
};

export default FeedViewMobile;
