import { RefObject, useCallback, useEffect, useMemo, useState } from "react";

import useElementTextWidth from "hooks/useElementTextWidth";

type Position = {
  caretAt: number | null;
  selectedText: { end: number; start: number; text: string };
  value: string;
} | null;

const getAdjacentText = (value: string, position: number) => {
  let text = "";
  let start = position;
  let end = position;
  if (position === 0) return { end, start, text };
  for (let i = position - 1; value[i] !== " "; i--) {
    if (!value[i]) break;
    start = i;
    text = `${value[i]}${text}`;
  }
  if (!text.length) return { end, start, text };
  for (end; value[end] !== " "; end++) {
    if (!value[end]) break;
    text = `${text}${value[end]}`;
  }
  return { end, start, text };
};

const useCaretPosition = (inputRef: RefObject<HTMLInputElement>) => {
  const input = inputRef.current;
  const [position, setPosition] = useState<Position>(null);
  const [inputValue, setInputValue] = useState<string>("");

  const { measureText } = useElementTextWidth(inputRef);

  const inputRect = useMemo(() => input?.getBoundingClientRect(), [input]);

  const caretLeft = useMemo(() => {
    const paddingLeft = input
      ? Number.parseFloat(getComputedStyle(input).paddingLeft)
      : 0;
    return (
      paddingLeft + measureText(inputValue.slice(0, position?.caretAt ?? 0))
    );
  }, [input, inputValue, measureText, position?.caretAt]);

  const endLeft = useMemo(() => {
    const paddingRight = input
      ? Number.parseFloat(getComputedStyle(input).paddingRight)
      : 0;
    return (inputRect?.width ?? 0) - paddingRight;
  }, [input, inputRect?.width]);

  const handleUpdateCaret = useCallback(() => {
    if (
      input?.selectionStart == null ||
      input.selectionStart !== input.selectionEnd
    )
      return;
    setPosition({
      caretAt: input.selectionStart,
      selectedText: getAdjacentText(inputValue, input.selectionStart),
      value: inputValue,
    });
  }, [input?.selectionEnd, input?.selectionStart, inputValue]);

  useEffect(() => {
    if (!input) return;
    const handleInputChange = () => setInputValue(input.value);

    input.addEventListener("input", handleInputChange);
    return () => {
      input.removeEventListener("input", handleInputChange);
    };
  }, [input]);

  const left = Math.min(caretLeft, endLeft);

  return {
    left,
    onClick: handleUpdateCaret,
    onFocus: handleUpdateCaret,
    onKeyUp: (e: KeyboardEvent) => {
      e.key !== "Escape" && handleUpdateCaret();
    },
    position,
    resetPosition: () => setPosition(null),
  };
};

export default useCaretPosition;
