import { useApolloClient } from "@apollo/client";
import { useState } from "react";

import {
  WorkspaceSubscriptionBillingQuery,
  WorkspaceSubscriptionBillingQueryDocument,
} from "generated/graphql";
import clickTemporaryLink from "utils/clickTemporaryLink";

const useManageBilling = (workspaceID?: string) => {
  const apolloClient = useApolloClient();
  const [inFlight, setInFlight] = useState(false);

  const openManageBilling = async () => {
    if (!workspaceID) return;

    setInFlight(true);

    const response =
      await apolloClient.query<WorkspaceSubscriptionBillingQuery>({
        query: WorkspaceSubscriptionBillingQueryDocument,
        fetchPolicy: "network-only",
        variables: {
          workspaceID,
        },
      });

    if (response.data?.workspaceSubscriptionBilling?.stripePortalURL) {
      clickTemporaryLink(
        response.data.workspaceSubscriptionBilling.stripePortalURL
      );
    }

    setInFlight(false);
  };

  return { fetchingBillingURL: inFlight, openManageBilling };
};

export default useManageBilling;
