import { VideoHTMLAttributes, useRef } from "react";

import { Button } from "components/design-system/Button";
import { FullScreenOverlay } from "components/Modals";
import useModalStore from "store/useModalStore";
import { isNativeMac } from "utils/platform";
import tw from "utils/tw";

type Props = VideoHTMLAttributes<HTMLVideoElement> & {
  title: string;
  src: string;
  type: string;
};

export default function Video({ className, poster, src, title, type }: Props) {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const videoRef = useRef<HTMLVideoElement | null>(null);
  /* Fix for https://secure.phabricator.com/T13135 */
  type = type.replace("/quicktime", "/mp4");

  const showButtons = isNativeMac();

  return (
    <div
      className={tw(
        "group/video border-1 border-border-container hover:border-border-container-hover overflow-hidden relative",
        className
      )}
    >
      <video
        ref={videoRef}
        className={tw("relative", className)}
        poster={poster}
        title={title}
        controls
      >
        <source
          src={src}
          /* Fix for https://secure.phabricator.com/T13135 */
          type={type}
        />
        <source src={src} />
        Sorry, your browser doesn't support embedded videos.
      </video>
      {showButtons && (
        <div className="z-1 absolute top-8 right-8 hidden gap-8 group-hover/video:flex">
          <Button
            buttonStyle="none"
            className="bg-background-player-controls/50 text-background-white hover:bg-background-player-controls-hover/75 h-32"
            icon="Download"
            title="Download video"
            tooltip="Download video"
            onClick={() => {
              const tempAnchor = document.createElement("a");
              document.body.appendChild(tempAnchor);

              tempAnchor.href = src;
              tempAnchor.download = title;
              tempAnchor.click();

              document.body.removeChild(tempAnchor);
            }}
          />
          <Button
            buttonStyle="none"
            className="bg-background-player-controls/50 text-background-white hover:bg-background-player-controls-hover/75 h-32"
            icon="Maximize"
            tooltip="Full screen"
            onClick={() => {
              const isPlaying =
                videoRef.current &&
                !videoRef.current.paused &&
                videoRef.current.currentTime > 0;
              videoRef.current?.pause();

              openModal(
                <FullScreenOverlay>
                  <video
                    className="absolute max-h-full max-w-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                    onCanPlay={e =>
                      e.target instanceof HTMLVideoElement &&
                      isPlaying &&
                      e.target.play()
                    }
                    onClick={e => e.stopPropagation()}
                    poster={poster}
                    title={title}
                    controls
                  >
                    <source
                      src={`${src}#t=${videoRef.current?.currentTime}`}
                      type={type}
                    />
                    <source src={src} />
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </FullScreenOverlay>
              );
            }}
            title="Full screen"
          />
        </div>
      )}
    </div>
  );
}
