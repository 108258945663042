import { memo, useEffect, useState } from "react";

import NewVersionSnackbar from "components/SnackBar/NewVersionSnackbar";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import useFeatureFlagStore from "store/useFeatureFlagStore";
import { isWeb } from "utils/platform";
import env from "utils/processEnv";

const swUrl = `/${env.glueServiceWorker || "service-worker.js"}`;

const ServiceWorker = memo((): JSX.Element | null => {
  const { SERVICE_WORKER } = useFeatureFlagStore(
    ({ flags: { SERVICE_WORKER } }) => ({ SERVICE_WORKER })
  );
  const serviceWorkerEnabled = SERVICE_WORKER && isWeb();

  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));
  const { authNeeded } = useAuthData();
  const [reload, setReload] = useState(false);

  const [waitingServiceWorker, setWaitingServiceWorker] =
    useState<ServiceWorker | null>(null);

  const [regServiceWorker, setRegServiceWorker] =
    useState<ServiceWorkerRegistration | null>(null);

  useEffect(() => {
    !serviceWorkerEnabled
      ? unregister()
      : register().then(swReg => {
          if (!(swReg instanceof ServiceWorkerRegistration)) return;

          setRegServiceWorker(swReg);

          // incase we already have a waiting worker
          swReg.waiting && setWaitingServiceWorker(swReg.waiting);

          // listen for updates
          swReg.onupdatefound = ({ target }) => {
            if (!(target instanceof ServiceWorkerRegistration)) return;

            if (target.installing === null) return;

            target.installing.onstatechange = () =>
              target.waiting?.state === "installed" &&
              navigator.serviceWorker.controller &&
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              target.waiting &&
              setWaitingServiceWorker(target.waiting);
          };
        });
  }, [serviceWorkerEnabled]);

  useEffect(() => {
    !waitingServiceWorker &&
      regServiceWorker?.waiting &&
      setWaitingServiceWorker(regServiceWorker?.waiting);
  }, [regServiceWorker?.waiting, waitingServiceWorker]);

  useEffect(() => {
    if (!navigator.serviceWorker) return;

    const handler = (event: MessageEvent) => {
      if (event.data === "RELOAD") {
        setReload(true);
      }
    };

    navigator.serviceWorker.addEventListener("message", handler);

    return () => {
      navigator.serviceWorker.removeEventListener("message", handler);
    };
  }, []);

  useEffect(() => {
    if (waitingServiceWorker?.state === "activated") {
      setWaitingServiceWorker(null);
    }
  }, [waitingServiceWorker?.state]);

  useEffect(() => {
    const handler = ({ target }: Event) => {
      if (
        target instanceof window.ServiceWorker &&
        target.state === "activated"
      ) {
        // if we received "RELOAD" message from service worker we reload
        reload && window.location.reload();
      }
    };

    waitingServiceWorker?.addEventListener("statechange", handler);

    return () =>
      waitingServiceWorker?.removeEventListener("statechange", handler);
  }, [reload, waitingServiceWorker]);

  if (authNeeded) return null;

  if (appStatus !== "active") return null;

  if (waitingServiceWorker === null) return null;

  return (
    <NewVersionSnackbar
      clickHandler={() =>
        // post message to service worker to skip waiting and activate waiting service worker
        waitingServiceWorker.postMessage({
          type: "SKIP_WAITING",
        })
      }
    />
  );
});

async function register() {
  if (!navigator.serviceWorker) return;

  return await navigator.serviceWorker
    .register(swUrl)
    .catch(error =>
      console.error("Error during service worker registration:", error)
    );
}

function unregister() {
  if (!navigator.serviceWorker) return;

  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

ServiceWorker.displayName = "ServiceWorker";

export default ServiceWorker;
