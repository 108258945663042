import { useHistory } from "react-router";

import { SlackImportStatus } from "@utility-types";
import CreateGroupModal from "components/group/CreateGroup/CreateGroupModal";
import InviteToGlueModal from "components/InviteToGlue/InviteToGlueModal";
import { routePath, superTabsDefaults } from "components/routing/utils";
import ImportStatusModal from "components/SlackImport/ImportStatusModal";
import SlackImportModal from "components/SlackImport/SlackImportModal";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import ComposeDMModal from "components/views/dms/ComposeDMModal";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

import Hr from "../../Hr";

import CreateMenuAction, { CreateMenuActionType } from "./CreateMenuAction";

export type SlackImportWorkspace = {
  id: string;
  status?: SlackImportStatus;
};

type Props = {
  setOpen: (open: boolean) => void;
  breakpointMD: boolean;
  slackImportWorkspaces?: SlackImportWorkspace[];
};

const CreateMenuContent = ({
  setOpen,
  breakpointMD,
  slackImportWorkspaces,
}: Props) => {
  const { authData } = useAuthData();
  const aiDisabled = authData?.workspaces.edges.length === 0;

  const history = useHistory();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const workspaces = authData?.workspaces;
  const primaryWorkspace = workspaces?.edges[0]?.node;
  const defaultSlackImportWorkspace = slackImportWorkspaces?.at(0);

  const handleClick = (
    e: React.MouseEvent<HTMLLIElement>,
    action: CreateMenuActionType
  ) => {
    e.stopPropagation();
    setOpen(false);
    switch (action) {
      case CreateMenuActionType.Thread:
        openModal(<ThreadComposeModal />);
        break;
      case CreateMenuActionType.AskGlueAI:
        history.push(routePath({ superTab: "ai", view: "compose" }));
        break;
      case CreateMenuActionType.DirectMessage:
        if (breakpointMD) {
          history.push(`${superTabsDefaults.dms}?v=compose-dm`);
        } else {
          openModal(<ComposeDMModal />);
        }
        break;
      case CreateMenuActionType.NewGroup:
        openModal(<CreateGroupModal workspaceID={primaryWorkspace?.id} />);
        break;
      case CreateMenuActionType.InviteTeam:
        openModal(<InviteToGlueModal inviteToWorkspace />);
        break;
      case CreateMenuActionType.SlackImport:
        if (defaultSlackImportWorkspace) {
          if (
            defaultSlackImportWorkspace.status === SlackImportStatus.Running
          ) {
            openModal(
              <ImportStatusModal workspaceID={defaultSlackImportWorkspace.id} />
            );
            return;
          }
          openModal(
            <SlackImportModal workspaceID={defaultSlackImportWorkspace.id} />
          );
        }
        break;
    }
  };

  const showSlackImportAction =
    !!defaultSlackImportWorkspace &&
    !slackImportWorkspaces?.some(
      ({ status }) => status === SlackImportStatus.Completed
    );

  return (
    <div className="min-w-[375px]">
      <div className="text-headline-bold px-16 pt-12 pb-8 w-full select-none">
        Create
      </div>
      <ul className="pb-8">
        <CreateMenuAction
          key={CreateMenuActionType.Thread}
          label="Thread"
          description="Start a new thread with groups or people"
          onClick={e => handleClick(e, CreateMenuActionType.Thread)}
          icon="CreateThread"
          iconClassName="text-text-action"
        />
        {!aiDisabled && (
          <CreateMenuAction
            key={CreateMenuActionType.AskGlueAI}
            label="Ask Glue AI"
            description="Start a thread with Glue AI"
            onClick={e => handleClick(e, CreateMenuActionType.AskGlueAI)}
            icon="SparkleFilled"
            iconClassName="text-text-magic"
          />
        )}
        <CreateMenuAction
          key={CreateMenuActionType.DirectMessage}
          label="Direct message"
          description="Send a message to someone"
          onClick={e => handleClick(e, CreateMenuActionType.DirectMessage)}
          icon="ChatRounded"
          iconClassName="text-brand-tangerine-highlight"
        />
        <CreateMenuAction
          key={CreateMenuActionType.NewGroup}
          label="New group"
          description={`Create a new group in ${primaryWorkspace?.name}`}
          onClick={e => handleClick(e, CreateMenuActionType.NewGroup)}
          icon="Groups"
          iconClassName="text-brand-lemon-highlight"
        />
        {showSlackImportAction && (
          <CreateMenuAction
            key={CreateMenuActionType.SlackImport}
            label="Slack import"
            description={"Add your channels to Glue as threads or groups"}
            onClick={e => handleClick(e, CreateMenuActionType.SlackImport)}
            icon="Download"
            iconClassName="text-brand-watermelon-highlight"
          />
        )}
      </ul>
      <Hr />
      <ul>
        <CreateMenuAction
          key={CreateMenuActionType.InviteTeam}
          label="Invite your team"
          onClick={e => handleClick(e, CreateMenuActionType.InviteTeam)}
          icon="UserAdd"
          iconBackgroundClassName="bg-transparent"
          iconClassName="text-icon-primary"
        />
      </ul>
    </div>
  );
};

export default CreateMenuContent;
