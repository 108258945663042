import { MessageEdge } from "@utility-types";

import { ResultType, Results } from "./types";

export const mergeResultsOfType = <T extends ResultType>(
  allInstantResults: Results[],
  allMoreResults: Results[] | undefined,
  resultType: T
): Results<T> => {
  const instantResults = allInstantResults.find(
    (r): r is Results<T> => r.resultType === resultType
  );
  const moreResults = allMoreResults?.find(
    (r): r is Results<T> => r.resultType === resultType
  );

  let matchedMessages: { edges: MessageEdge[] } | undefined = undefined;
  if (resultType === "threads") {
    matchedMessages = {
      edges: [
        ...(allInstantResults?.find(
          (r): r is Results<"threads"> => r.resultType === resultType
        )?.matchedMessages?.edges || []),

        ...(allMoreResults?.find(
          (r): r is Results<"threads"> => r.resultType === resultType
        )?.matchedMessages?.edges || []),
      ],
    };
  }

  return {
    edges: [...(instantResults?.edges || []), ...(moreResults?.edges || [])],
    matchedMessages,
    resultType,
    totalCount:
      (instantResults?.edges.length || 0) + (moreResults?.edges.length || 0),
  };
};
