import { ComponentProps } from "react";

import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import tw from "utils/tw";

type Props = {
  buttons?: React.ReactNode;
  name: string;
} & Pick<ComponentProps<typeof BackStackButton>, "backPath">;

const AppTabSecondaryHeader = ({ backPath, buttons, name }: Props) => {
  return (
    <div
      className={tw(
        "flex items-center justify-between shrink-0",
        "border-b-1 border-border-container h-44"
      )}
    >
      <div className="w-[92px]">
        <BackStackButton
          backPath={backPath}
          label="Back"
          position="secondaryView"
        />
      </div>

      <h2 className="text-headline-bold text-text-primary">{name}</h2>

      <div className="flex justify-end w-[92px]">{buttons}</div>
    </div>
  );
};

const AppTabSecondaryLayout = ({ children, ...props }: WithChildren<Props>) => {
  return (
    <div className="bg-background-body flex flex-col grow min-h-0 relative w-full">
      <AppTabSecondaryHeader {...props} />

      <div
        className="bg-background-body flex flex-col grow min-h-0 overflow-hidden"
        data-testid="app-tab-main"
      >
        {children}
      </div>
    </div>
  );
};

export default AppTabSecondaryLayout;
