import { Thread } from "@utility-types";

/**
 * If at least this many messages are in a thread,
 * the user can summarize it.
 */
export const MIN_MESSAGES_FOR_SUMMARY = 3;

/**
 * If the first message in a thread is at least this many characters,
 * the user can summarize it.
 */
export const MIN_MESSAGE_LENGTH_FOR_SUMMARY = 600;

export function shouldSummarizeThread(thread: Thread): boolean {
  const firstMessageLength = thread.firstMessage?.text?.length || 0;
  const messageCount = thread.recentMessages?.replyCount || 0;
  return (
    messageCount >= MIN_MESSAGES_FOR_SUMMARY ||
    firstMessageLength >= MIN_MESSAGE_LENGTH_FOR_SUMMARY
  );
}
