import { useEffect } from "react";

import { SuperTabKeys, superTabsDefaults } from "components/routing/utils";
import useRoutesStore from "store/useRoutesStore";

/**
 * processes the currently selected tab and related routes, and stores them in local storage;
 * allows restoration of prior routing state when the app is reloaded
 */
const useRouteMemory = ({
  isActive,
  name,
  route,
}: {
  isActive: boolean;
  name: SuperTabKeys;
  route: string;
}) => {
  const { routes } = useRoutesStore(({ routes }) => ({
    routes,
  }));

  // initialize default route
  useEffect(() => {
    if (routes[name] || !superTabsDefaults[name]) return;

    useRoutesStore.setState({
      routes: {
        ...routes,
        [name]: superTabsDefaults[name],
      },
    });
  }, [name, routes]);

  // update saved route for the current tab
  useEffect(() => {
    if (!isActive || route === "/") return;

    useRoutesStore.setState({
      restoreRoute: route,
    });

    if (routes[name] === route) return;

    useRoutesStore.setState({
      routes: {
        ...routes,
        [name]: route,
      },
    });
  }, [isActive, name, route, routes]);

  return { routes };
};

export default useRouteMemory;
