import { Button } from "components/design-system/Button";
import { UnreadBadge } from "components/UnreadBadge";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

const MoreLessButton = ({
  className,
  hasMore,
  loading,
  onClick,
  unreadCount,
  unreadMentionCount,
}: {
  className?: string;
  hasMore?: boolean;
  loading?: boolean;
  onClick?: () => void;
  unreadCount?: number;
  unreadMentionCount?: number;
}) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <li className={tw("mx-12 py-6", className)} data-testid="More/Less Button">
      <Button
        buttonStyle="subtle"
        className="!p-0 border-none w-full text-sm !font-normal"
        disabled={loading}
        icon={loading ? "Loader" : hasMore ? "ChevronDown" : "ChevronUp"}
        iconClassName="mr-8"
        iconSize={breakpointMD ? 20 : 24}
        onClick={onClick}
      >
        {hasMore || loading ? (
          <>
            More
            {unreadCount ? (
              <UnreadBadge
                className="!m-0 !mx-10 !mt-2"
                important={!!unreadMentionCount}
              />
            ) : null}
          </>
        ) : (
          <>Less</>
        )}
      </Button>
    </li>
  );
};

export default MoreLessButton;
