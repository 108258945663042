import { useApolloClient } from "@apollo/client";
import { cloneDeep } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAbortController } from "use-abort-controller-hook";
import { useDebouncedCallback } from "use-debounce";

import { Recipient } from "@utility-types";
import {
  GlobalSearchDocument,
  GlobalSearchQuery,
  GroupPreviewSimpleFieldsFragmentDoc,
  GroupSimpleFieldsFragmentDoc,
  LocalSearchDocument,
  LocalSearchQuery,
  Node,
  UserEdgeFieldsFragmentDoc,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useSearchStore from "store/useSearchStore";

import { ResultType, SearchQuery, SearchResults, SearchVars } from "../types";

const subtractEdges = (c1: { edges: Node[] }, c2?: { edges: Node[] }) => {
  if (!c2) return;
  const ee2Set = new Set(c2.edges.map(e => e.id));
  c1.edges = c1.edges.filter(e => !ee2Set.has(e.id));
};

const sortEdges = <T extends Node>(
  reverse: boolean,
  ...args: ({ edges: T[] } | undefined)[]
) => args.flatMap(c => (reverse ? c?.edges.slice().reverse() : c?.edges) || []);

const totalEdges = (...args: ({ edges: Node[] } | undefined)[]) =>
  args.reduce((p = 0, c) => p + (c?.edges.length || 0), 0);

const countEdges = (q?: SearchQuery) =>
  totalEdges(q?.threads, q?.groups, q?.workspaces, q?.users);

type Props = {
  exclude?: Recipient[];
  resultsOrder?: ResultType[];
  reverse?: boolean;
  sharedState?: boolean; // whether to share global state with other instances
};

const useInstantSearch = ({
  resultsOrder = ["users", "groups", "threads"],
  reverse = false,
  sharedState,
}: Props = {}) => {
  const apolloClient = useApolloClient();
  const { authData } = useAuthData();

  const workspaceIDsRef = useRef(authData?.me.workspaceIDs ?? []);
  workspaceIDsRef.current = authData?.me.workspaceIDs ?? [];

  const resultsOrderRef = useRef(resultsOrder);
  resultsOrderRef.current = resultsOrder;

  // for global storage of search data
  const {
    instantSearchData: sharedInstantSearchData,
    moreSearchData: sharedMoreSearchData,
    searching: sharedSearching,
    setSearchState,
  } = useSearchStore(
    ({
      instantSearchData,
      moreSearchData,
      searching,
      setState: setSearchState,
    }) => ({
      instantSearchData,
      moreSearchData,
      searching,
      setSearchState,
    })
  );

  const includeTypes = useRef({
    groups: true,
    threads: true,
    users: true,
  });

  /**
   * for local parsing of search data:
   *   - searchData: data currently displayed to the client;
   *   - nextSearchData: data received asynchronously, to be merged into searchData;
   */
  const [searching, setSearching] = useState(
    sharedState ? !!sharedSearching : false
  );

  const [instantSearchData, setInstantSearchData] = useState<
    SearchQuery | undefined
  >(sharedState ? sharedInstantSearchData : undefined);

  const [moreSearchData, setMoreSearchData] = useState<SearchQuery | undefined>(
    sharedState ? sharedMoreSearchData : undefined
  );

  // Sync shared state from local state if enabled
  useEffect(() => {
    if (!sharedState) return;

    setSearchState({ instantSearchData, moreSearchData, searching });
  }, [
    instantSearchData,
    moreSearchData,
    searching,
    setSearchState,
    sharedState,
  ]);

  // Sync local state from shared state if enabled
  useEffect(() => {
    if (!sharedState) return;

    setSearching(!!sharedSearching);
    setInstantSearchData(sharedInstantSearchData);
    setMoreSearchData(sharedMoreSearchData);
  }, [
    sharedState,
    sharedSearching,
    sharedInstantSearchData,
    sharedMoreSearchData,
  ]);

  // update the local cache with the new search results
  // we don't save the full result set, just each edge
  const updateLocalCache = useCallback(
    (data: SearchQuery) => {
      data.users?.edges.forEach(userEdge => {
        apolloClient.cache.writeFragment({
          id: apolloClient.cache.identify(userEdge),
          fragment: UserEdgeFieldsFragmentDoc,
          fragmentName: "UserEdgeFields",
          data: userEdge,
        });
      });
      // we only write the groups not the edge since
      // the edge is not used in the cache search
      data.groups?.edges.forEach(groupEdge => {
        if (groupEdge.node.__typename === "Group") {
          apolloClient.cache.writeFragment({
            id: apolloClient.cache.identify(groupEdge.node),
            fragment: GroupSimpleFieldsFragmentDoc,
            fragmentName: "GroupSimpleFields",
            data: groupEdge.node,
          });
        } else {
          apolloClient.cache.writeFragment({
            id: apolloClient.cache.identify(groupEdge.node),
            fragment: GroupPreviewSimpleFieldsFragmentDoc,
            fragmentName: "GroupPreviewSimpleFields",
            data: groupEdge.node,
          });
        }
      });
      // TODO: add thread results to cache
      // // Will cause cache bloat if we save all results,
      // // without some way of purging when needed.
      // data.threads?.edges.forEach(threadEdge => {
      //   apolloClient.cache.writeFragment({
      //     id: apolloClient.cache.identify(threadEdge),
      //     fragment: ThreadEdgeSimpleAndNodeFieldsFragmentDoc,
      //     fragmentName: "ThreadEdgeSimpleAndNodeFields",
      //     data: threadEdge,
      //   });
      // });
    },
    [apolloClient.cache]
  );

  const searchAbortController = useAbortController();

  // query the api for "deep" results
  const globalSearch = useCallback(
    (variables: SearchVars) => {
      setSearching(true);

      apolloClient
        .query<GlobalSearchQuery>({
          query: GlobalSearchDocument,
          context: { fetchOptions: searchAbortController },
          fetchPolicy: "no-cache",
          variables,
        })
        .then(({ data }) => {
          if (data.threads) {
            const instantThreads = instantSearchData?.threads;
            data.threads.edges.reverse(); // threads returned in reverse order

            subtractEdges(data.threads, instantThreads);

            if (data.threads.matchedMessages) {
              subtractEdges(
                data.threads.matchedMessages,
                instantThreads?.matchedMessages
              );
            }
          }

          if (data.groups) {
            subtractEdges(data.groups, instantSearchData?.groups);
          }

          if (data.workspaces) {
            subtractEdges(data.workspaces, instantSearchData?.workspaces);
          }

          if (data.users) {
            subtractEdges(data.users, instantSearchData?.users);
          }

          setMoreSearchData(data);
          updateLocalCache(data);
        })
        .finally(() => {
          setSearching(false);
        });
    },
    [
      apolloClient,
      instantSearchData?.groups,
      instantSearchData?.threads,
      instantSearchData?.users,
      instantSearchData?.workspaces,
      searchAbortController,
      updateLocalCache,
    ]
  );

  // query the client-side cache for "quick" results
  const localSearch = useCallback(
    (variables: SearchVars) => {
      apolloClient
        .query<LocalSearchQuery>({
          query: LocalSearchDocument,
          variables,
          fetchPolicy: "cache-only",
        })
        .then(({ data }) => {
          setTimeout(() => {
            setInstantSearchData(cloneDeep(data));
          });
        });
    },
    [apolloClient]
  );

  const debouncedGlobalSearch = useDebouncedCallback(globalSearch, 350);

  const search = useCallback(
    (props: SearchVars) => {
      const { limit = 10, limitLocal = 5, match, ...variables } = props;

      if (sharedState) {
        setSearchState({ searchVars: { match, matchMessages: match } });
      }

      searchAbortController.abort();

      if (match === undefined) {
        // clearing / canceling search
        setInstantSearchData(undefined);
        setMoreSearchData(undefined);
        setSearching(false);
        return;
      }

      const { groups = true, threads = true, users = true } = variables;

      includeTypes.current = { groups, threads, users };

      variables.mutualWorkspaceIDs = workspaceIDsRef.current; // always boost mutual workspaces

      if (limitLocal > 0) {
        localSearch({ ...variables, limit: limitLocal, match });
        setSearching(true);
      } else {
        setInstantSearchData(undefined);
      }

      if (match.length === 0) {
        // no filter, just show the quick results
        setMoreSearchData(undefined);
        setSearching(false);
        return;
      }

      debouncedGlobalSearch({ ...variables, limit, match });
    },
    [
      debouncedGlobalSearch,
      localSearch,
      searchAbortController,
      setSearchState,
      sharedState,
    ]
  );

  const toSearchResults = useCallback(
    (searchData: SearchQuery | undefined) =>
      (reverse
        ? resultsOrderRef.current.slice(0).reverse()
        : resultsOrderRef.current
      )
        .filter(resultType => includeTypes.current[resultType])
        .map(resultType => {
          switch (resultType) {
            case "users":
              return {
                edges: sortEdges(reverse, searchData?.users),
                resultType,
                totalCount: totalEdges(searchData?.users),
              };
            case "groups":
              return {
                edges: sortEdges(reverse, {
                  edges: [
                    ...(searchData?.groups?.edges || []),
                    ...(searchData?.workspaces?.edges || []),
                  ],
                }),
                resultType,
                totalCount: totalEdges(
                  searchData?.groups,
                  searchData?.workspaces
                ),
              };
            default:
              return {
                edges: sortEdges(reverse, searchData?.threads),
                matchedMessages: searchData?.threads?.matchedMessages,
                resultType,
                totalCount: totalEdges(searchData?.threads),
              };
          }
        }),
    [reverse]
  );

  const searchResults: SearchResults = useMemo(
    () => ({
      instantResults: toSearchResults(instantSearchData),
      moreResults: searching ? [] : toSearchResults(moreSearchData),
      isReversed: reverse,
      searching,
      totalCount:
        countEdges(instantSearchData) +
        (searching ? 0 : countEdges(moreSearchData)),
    }),
    [instantSearchData, moreSearchData, reverse, searching, toSearchResults]
  );

  return {
    search,
    searchResults,
  };
};

export default useInstantSearch;
