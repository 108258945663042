import { groupBy } from "lodash-es";
import React, { RefObject, useMemo } from "react";

import { type Message, ThreadSimple } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Timestamp } from "components/thread/ThreadView/stream-components/Message/components";
import ThreadItemContentAttachment from "components/threads-list/ThreadItem/ThreadItemContentAttachment";
import { ItemType, parseData } from "components/threads-list/ThreadItem/utils";
import { MessageFieldsFragment } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import { formatDate } from "utils/formatDate";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";
import tw from "utils/tw";

import type { Results } from "../types";

import ResultList from "./ResultList";

type Props = {
  isReversed?: boolean;
  onClickResult?: (
    item: ThreadSimple | Message,
    index: number,
    event: React.MouseEvent<HTMLDivElement | HTMLLIElement, MouseEvent>
  ) => void;
  results: Results<"threads">;
  searching: boolean;
  selectedResultID?: string;
  selectedResultRef?: RefObject<HTMLLIElement>;
  setSelectedResultID?: (id: string) => void;
};

export const ThreadResultsItem = ({
  item,
  lastMatchedMessage,
}: { item: ItemType; lastMatchedMessage?: MessageFieldsFragment }) => {
  const { authData } = useAuthData();
  const data = parseData({ authData, item });

  const timestamp = lastMatchedMessage ? (
    <Timestamp
      customClass="text-caption text-text-subtle"
      date={lastMatchedMessage?.createdAt}
      formatDate={formatDate}
    />
  ) : (
    <span className="text-caption text-text-subtle shrink-0">{data.date}</span>
  );

  return (
    <div className="min-w-0 grow overflow-hidden">
      <div className="flex items-baseline justify-between">
        <div className="flex gap-8 items-baseline overflow-hidden">
          <div className="text-body-bold text-text-primary truncate">
            {data.subject}
          </div>
          <div className="flex items-center text-footnote text-text-secondary">
            {data.recipients}
          </div>
        </div>

        {timestamp && (
          <div className="flex items-center shrink-0">{timestamp}</div>
        )}
      </div>

      <div className="">
        <div className="flex justify-start text-footnote text-text-subtle">
          {lastMatchedMessage ? (
            <>
              <div className="whitespace-nowrap">
                {authData?.me.id === lastMatchedMessage.user.id
                  ? "You"
                  : lastMatchedMessage.user.name}
                :&nbsp;
              </div>
              <div className="min-w-0 truncate">
                {lastMatchedMessage.textPreview}
              </div>
            </>
          ) : data.messageSender ? (
            <>
              {(data.chatType !== "user" || data.messageSender === "You") && (
                <div className="whitespace-nowrap">
                  {data.messageSender}
                  {data.showSkeleton ? "" : ":"}&nbsp;
                </div>
              )}

              {data.message ? (
                <div className="min-w-0 truncate">{data.message}</div>
              ) : (
                <ThreadItemContentAttachment
                  attachmentTypes={data.attachmentTypes}
                />
              )}
            </>
          ) : (
            <div className="truncate">No messages yet.</div>
          )}
        </div>
      </div>
    </div>
  );
};

const ThreadResults = ({
  isReversed,
  onClickResult,
  results,
  searching,
  selectedResultID,
  selectedResultRef,
  setSelectedResultID,
}: Props): JSX.Element | null => {
  const { authData } = useAuthData();
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const messagesByThreadID = useMemo(
    () =>
      groupBy(
        results?.matchedMessages?.edges?.map(e => e.node),
        m => m.threadID
      ),
    [results]
  );

  if (!results.edges.length) return null;

  return (
    <ResultList isReversed={isReversed} label="Threads" searching={searching}>
      {results.edges.map((threadEdge, index) => {
        const isSelected =
          selectedResultID === threadEdge.node.id ||
          selectedResultID === threadEdge.id;

        const otherRecipients =
          threadEdge.node?.recipients.edges
            .map(e => e.node)
            .filter(r => r.id !== authData?.me.id) ?? [];

        const icon = threadEdge.node?.isPersistentChat
          ? "ChatRounded"
          : isGlueAIRecipient(otherRecipients)
            ? "SparkleFilled"
            : "Thread";

        const matchedMessages = messagesByThreadID[threadEdge.node.id] ?? [];
        const lastMatchedMessage = matchedMessages[matchedMessages.length - 1];

        return (
          <React.Fragment key={`search-results-${threadEdge.id}`}>
            <li ref={isSelected ? selectedResultRef : undefined}>
              <div
                className={tw(
                  "flex items-center justify-start px-16 h-56",
                  "cursor-pointer select-none",
                  {
                    "!bg-accent-highlight/25": isSelected,
                  }
                )}
                onClick={e =>
                  onClickResult?.(
                    lastMatchedMessage ?? threadEdge.node,
                    index,
                    e
                  )
                }
                onFocus={() => setSelectedResultID?.(threadEdge.node.id)}
                onMouseEnter={() => setSelectedResultID?.(threadEdge.node.id)}
              >
                <Avatar
                  background="transparent"
                  className="text-icon-secondary"
                  iconProps={{ icon }}
                  margin="mr-8"
                  size={breakpointMD ? "x-small" : "small"}
                />
                <ThreadResultsItem
                  item={threadEdge}
                  lastMatchedMessage={lastMatchedMessage}
                />
              </div>
            </li>
          </React.Fragment>
        );
      })}
    </ResultList>
  );
};

export default ThreadResults;
