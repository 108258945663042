import { useApolloClient } from "@apollo/client";
import { differenceBy } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAbortController } from "use-abort-controller-hook";

import { Message, nodeAs } from "@utility-types";
import {
  FetchRecipientDocument,
  FetchRecipientQuery,
  RecipientsSuggestionDocument,
  RecipientsSuggestionQuery,
  RecipientsSuggestionQueryVariables,
  SaveDraftInput,
} from "generated/graphql";
import useComponentMounted from "hooks/useComponentMounted";
import isDefined from "utils/isDefined";

import { State, formToInput } from "../DraftReducer";

const minTextLength = 80;

type Props = Pick<Partial<State>, "draftForm" | "draftID"> & {
  message?: Message;
};

const useSuggestRecipients = ({ draftID, draftForm, message }: Props) => {
  const abortController = useAbortController();
  const apolloClient = useApolloClient();
  const isMounted = useComponentMounted();

  const [loading, setLoading] = useState(false);

  const variablesRef = useRef<
    RecipientsSuggestionQueryVariables & { input?: Partial<SaveDraftInput> }
  >();
  variablesRef.current = message
    ? { replyToMessageID: message.id }
    : draftForm
      ? { input: formToInput(draftForm) }
      : undefined;

  const suggestRecipients = useCallback(() => {
    setLoading(true);

    return apolloClient
      .query<RecipientsSuggestionQuery>({
        query: RecipientsSuggestionDocument,
        context: {
          batch: false,
          fetchOptions: abortController,
          notifyErrors: false,
        },
        fetchPolicy: "no-cache",
        variables: variablesRef.current,
      })
      .then(({ data }) =>
        Promise.all(
          differenceBy(
            data?.draftSuggestion?.recipients ?? [],
            variablesRef.current?.input?.recipients ?? []
          ).map(id =>
            apolloClient
              .query<FetchRecipientQuery>({
                query: FetchRecipientDocument,
                variables: { id },
              })
              .then(({ data }) =>
                nodeAs(data?.node, [
                  "User",
                  "Group",
                  "GroupPreview",
                  "Workspace",
                  "WorkspacePreview",
                ])
              )
          )
        ).then(recipients => recipients.filter(isDefined))
      )
      .finally(() => {
        if (!isMounted.current) return;
        setLoading(false);
      });
  }, [apolloClient, abortController, isMounted]);

  useEffect(() => {
    if (!draftID) return;
    return () => abortController.abort();
  }, [abortController, draftID]);

  const textPresent = (draftForm?.message?.text?.length ?? 0) >= minTextLength;
  return {
    suggestRecipients: textPresent ? suggestRecipients : undefined,
    recipientsLoading: loading,
  };
};

export default useSuggestRecipients;
