import { ComponentProps, memo, useMemo, useState } from "react";

import { Recipient } from "@utility-types";
import Icon from "components/design-system/icons/Icon";
import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";

type Priorities = "avatar" | "emoji" | "icon" | "name";

const getInitials = (name: string) =>
  name.split(/\s+/, 2).map(n => n.substring(0, 1).toUpperCase());

const useImage = (avatarURL: string, size: number) => {
  const src1x =
    avatarURL &&
    glueImageURL(avatarURL, {
      fit: "min",
      h: size,
      w: size,
    });

  const src2x =
    avatarURL &&
    glueImageURL(avatarURL, {
      fit: "min",
      h: size * 2,
      w: size * 2,
    });

  const isCached = useMemo(() => {
    if (!src1x) return false;

    const img = new Image();
    img.src = src1x;
    img.srcset = `${src1x} 1x, ${src2x} 2x`;

    return img.complete;
  }, [src1x, src2x]);

  return { isCached, src1x, src2x };
};

const AvatarImage = ({
  avatarURL,
  name,
  size,
}: {
  avatarURL: string;
  name?: string;
  size: number;
}) => {
  const { isCached, src1x, src2x } = useImage(avatarURL, size);

  const [error, setError] = useState(false);
  const [ready, setReady] = useState(isCached);

  return (
    <div
      className="flex items-center justify-center relative"
      style={{ height: size, width: size }}
    >
      <span
        className={
          !error && ready ? "opacity-0" : "opacity-100 text-text-subtle"
        }
      >
        {name ? getInitials(name) : null}
      </span>
      <img
        alt={name}
        className={tw(
          "absolute block inset-0 object-cover transition-opacity duration-250",
          !error && ready ? "opacity-100" : "opacity-0"
        )}
        height={size}
        onError={() => {
          setError?.(true);
        }}
        onLoad={() => {
          if (ready) return;
          window.requestAnimationFrame(() => {
            setReady(true);
          });
        }}
        src={src2x}
        srcSet={`${src1x} 1x, ${src2x} 2x`}
        style={{ height: size, width: size }}
        width={size}
      />
    </div>
  );
};

const AvatarPrimitive = memo(
  ({
    avatarURL,
    emojiProps,
    iconProps,
    name,
    priority = ["avatar", "emoji", "icon", "name"],
    size,
  }: Partial<Pick<Recipient, "avatarURL" | "name">> & {
    emojiProps?: {
      emoji?: string;
      size?: number;
    };
    iconProps?: Partial<ComponentProps<typeof Icon>>;
    priority?: Priorities[];
    size: number;
  }) => {
    const item = useMemo(() => {
      const render = {
        avatar: () => {
          return avatarURL ? (
            <AvatarImage avatarURL={avatarURL} name={name} size={size} />
          ) : null;
        },
        emoji: () => {
          return emojiProps?.emoji ? (
            <div
              className="flex items-center justify-center !leading-none"
              style={{
                fontSize: `${emojiProps.size ?? size}px`,
              }}
            >
              {emojiProps.emoji}
            </div>
          ) : null;
        },
        icon: () => {
          return iconProps?.icon ? (
            <Icon
              icon={iconProps?.icon}
              {...iconProps}
              size={iconProps.size ?? size}
            />
          ) : null;
        },
        name: () => {
          return name ? (
            <span className="text-text-subtle">{getInitials(name)}</span>
          ) : null;
        },
      };

      const item = priority.find(p => render[p]() !== null);

      return item ? render[item]() : null;
    }, [avatarURL, emojiProps, iconProps, name, priority, size]);

    return (
      <div
        className={tw(
          "flex items-center justify-center shrink-0",
          "font-semibold"
        )}
        style={{ height: size, width: size }}
      >
        {item}
      </div>
    );
  },
  (prev, next) =>
    prev.avatarURL === next.avatarURL &&
    prev.emojiProps?.emoji === next.emojiProps?.emoji &&
    prev.iconProps?.icon === next.iconProps?.icon &&
    prev.name === next.name &&
    prev.size === next.size
);

AvatarPrimitive.displayName = "AvatarPrimitive";

export default AvatarPrimitive;
