import { last } from "lodash-es";
import { MessageLabel, MessageResponse } from "stream-chat/dist/types/types";
import { StreamMessage } from "stream-chat-react";

import {
  EphemeralMessage,
  GlueDefaultStreamChatGenerics,
  Message,
  QuotedMessage,
  StreamGlueMessage,
  isExternalObject,
  isGlueFile,
  isThreadPreview,
} from "@utility-types";
// TODO: move helpers into utils/stream:
import {
  externalObjectToLinkPreview,
  fileUploadToStreamAttachment,
  glueFileToFileUpload,
  isFileAttachment,
  linkPreviewToStreamAttachment,
  streamAttachmentToFileUpload,
  streamAttachmentToLinkPreviews,
  streamAttachmentToThreadPreview,
  threadToStreamAttachment,
} from "components/MessageEditor/stream-helpers";
import { markdownToPlainText } from "md";
import env from "utils/processEnv";

type hasThreadID = {
  reply_thread_ids?: string[];
};

export const getReplyThreadID = ({
  reply_thread_ids,
}: hasThreadID): string | null => {
  return last(reply_thread_ids) || null;
};

export const glueMessageToStreamMessage = <
  T extends GlueDefaultStreamChatGenerics,
>(
  message: Message
): StreamMessage<T> => streamMessage<T>(message);

export const glueMessageToStreamMessageResponse = <
  T extends GlueDefaultStreamChatGenerics,
>(
  message: Message
): MessageResponse<T> => streamMessage<T>(message);

export const glueEphemeralMessageToStreamResponse = <
  T extends GlueDefaultStreamChatGenerics,
>(
  message: EphemeralMessage
): MessageResponse<T> => ({
  ...(streamMessage(
    {
      ...message,
      __typename: "Message",
      textPreview: "",
      replyThreadID: null,
      quotedMessage: null,
      streamID: null,
      attachments: [],
      reactionCounts: [],
      reactions: {
        __typename: "ReactionConnection",
        edges: [],
      },
      ownReactions: [],
    },
    "ephemeral"
  ) as MessageResponse<T>),
  feedback_type: message.feedbackType,
  thread_id: message.threadID,
  unfurl_message_id: message.unfurlMessageID,
});

export const streamMessageToGlueMessage = (
  streamMessage: StreamGlueMessage
): Message => ({
  __typename: "Message",
  attachments: [
    ...(streamMessage.attachments
      ?.filter(isFileAttachment)
      ?.map(streamAttachmentToFileUpload) || []),
    ...(streamMessage.attachments
      ?.filter(isExternalObject)
      ?.map(streamAttachmentToLinkPreviews) || []),
    ...(streamMessage.attachments
      ?.filter(isThreadPreview)
      ?.map(streamAttachmentToThreadPreview) || []),
  ],
  createdAt: new Date(streamMessage.created_at || Date.now()).toString(),
  id: streamMessage.id,
  ownReactions: [],
  reactionCounts: [],
  reactions: {
    __typename: "ReactionConnection",
    edges: [],
  },
  quotedMessage: streamMessage.quoted_message
    ? {
        ...streamMessageToGlueMessage(streamMessage.quoted_message),
        __typename: "QuotedMessage",
      }
    : null,
  replyThreadID: getReplyThreadID(streamMessage),
  streamID: streamMessage.id,
  text: streamMessage.text || "",
  textPreview: markdownToPlainText(streamMessage.text || ""),
  threadID: streamMessage?.cid?.split(":")[1] || "",
  updatedAt: streamMessage.content_updated_at || null,
  user: {
    __typename: "User",
    addressDomains: [],
    avatarURL: streamMessage.user?.image || null,
    bio: null,
    id: streamMessage.user?.id || "",
    name: streamMessage.user?.name || "",
    workspaceIDs: [],
  },
});

const streamMessage = <T extends GlueDefaultStreamChatGenerics>(
  message: Message,
  type?: MessageLabel
) => ({
  ...streamMessageCommon(message.threadID, message, type),
  reply_thread_ids: message.replyThreadID ? [message.replyThreadID] : undefined,
  quoted_message_id: message.quotedMessage?.id || undefined,
  quoted_message: message.quotedMessage
    ? (streamMessageCommon(
        message.threadID,
        message.quotedMessage
      ) as MessageResponse<T>)
    : undefined,
  in_stream: message.streamID ? true : undefined,
});

const streamMessageCommon = (
  threadID: string,
  message: Message | QuotedMessage,
  type?: MessageLabel
) => ({
  id: message.streamID || message.id,
  cid: `${env.streamChannelType}:${threadID}`,
  created_at: message.createdAt,
  status: "received",
  type: type ?? "regular",
  user: streamUser(message.user),
  text: message.text,
  attachments: streamAttachments(message.attachments),
  updated_at: message.createdAt,
  content_updated_at: message.updatedAt || undefined,
});

const streamAttachments = (attachments: Message["attachments"]) => [
  ...attachments
    .filter(isGlueFile)
    .map(glueFileToFileUpload)
    .map(fileUploadToStreamAttachment),
  ...attachments
    .filter(isExternalObject)
    .map(externalObjectToLinkPreview)
    .map(linkPreviewToStreamAttachment),
  ...attachments
    .filter(isThreadPreview)
    .map(streamAttachmentToThreadPreview)
    .map(threadToStreamAttachment),
];

const streamUser = (user: Message["user"]) => ({
  id: user.id,
  image: user.avatarURL || undefined,
  name: user.name,
});
