import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";
import { useMemo } from "react";

import { Button } from "components/design-system/Button";
import { IconName } from "components/design-system/icons";
import TwoTierInfoBox from "components/design-system/TwoTierInfoBox";
import useNotificationPermissions from "components/devices/hooks/useNotificationPermissions";
import { NotificationPermissionStatus } from "generated/graphql";
import { Platform, getPlatform } from "utils/platform";

import { FieldSet } from "./FieldSet";

type Content =
  | {
      content: React.ReactNode;
      iconProps: {
        className: string;
        icon: IconName;
        size: number;
      };
    }
  | undefined;

const FOR_BEST_EXPERIENCE =
  "For the best experience, we recommend using our desktop and mobile apps.";

const iconProps = {
  granted: {
    className: "text-icon-success",
    icon: "CheckCircle" as const,
    size: 20,
  },
  denied: {
    className: "text-brand-tangerine-highlight",
    icon: "AlertTriangle" as const,
    size: 20,
  },
  prompt: {
    className: "text-icon-action",
    icon: "AlertCircle" as const,
    size: 20,
  },
};

const commonGranted = (platform: Platform) => {
  return {
    content: (
      <div className="flex flex-col gap-2" data-testid={`${platform}-granted`}>
        <div className="text-footnote-bold">Notifications are on</div>
        <div>
          You'll get notifications based on the above preferences and your
          system settings.
        </div>
      </div>
    ),
    iconProps: iconProps.granted,
  };
};

const commonPrompt = (platform: Platform, requestPermissions: () => void) => {
  return {
    content: (
      <div className="flex flex-col gap-2" data-testid={`${platform}-prompt`}>
        <div className="text-footnote-bold">Turn on notifications</div>
        <div>
          Tap below, then select “Allow” to get notifications from Glue.
        </div>
        <div className="mt-12">
          <Button icon="Bell" onClick={requestPermissions}>
            Turn on notifications
          </Button>
        </div>
      </div>
    ),
    iconProps: iconProps.prompt,
  };
};

const getContent = (
  requestPermissions: () => void
): Record<Platform, Record<NotificationPermissionStatus, Content>> => ({
  android: {
    granted: commonGranted("android"),
    denied: {
      content: (
        <div className="flex flex-col" data-testid="android-denied">
          <div className="text-footnote-bold">
            To get notifications from Glue, you'll need to turn them on in your
            iOS Settings:
          </div>
          <ol className="list-decimal list-inside [&>li]:py-2">
            <li>Open App info for Glue.</li>
            <li>Tap Notifications.</li>
            <li>Toggle All Glue notifications on.</li>
          </ol>
          <div className="mt-12">
            <Button
              icon="ExternalLink"
              iconLast
              onClick={() => {
                NativeSettings.openAndroid({
                  option: AndroidSettings.AppNotification,
                });
              }}
            >
              Open App info
            </Button>
          </div>
        </div>
      ),
      iconProps: iconProps.denied,
    },
    prompt: commonPrompt("android", requestPermissions),
  },
  ios: {
    granted: commonGranted("ios"),
    denied: {
      content: (
        <div className="flex flex-col" data-testid="ios-denied">
          <div className="text-footnote-bold">
            To get notifications from Glue, you'll need to turn them on in your
            iOS Settings:
          </div>
          <ol className="list-decimal list-inside [&>li]:py-2">
            <li>Open Settings &gt; Apps &gt; Glue.</li>
            <li>Tap Notifications.</li>
            <li>Toggle Allow Notifications to On.</li>
          </ol>
          <div className="mt-12">
            <Button
              icon="ExternalLink"
              iconLast
              onClick={() => {
                NativeSettings.openIOS({
                  option: IOSSettings.App,
                });
              }}
            >
              Open iOS Settings
            </Button>
          </div>
        </div>
      ),
      iconProps: iconProps.denied,
    },
    prompt: commonPrompt("ios", requestPermissions),
  },
  mac: {
    granted: commonGranted("mac"),
    denied: {
      content: (
        <div className="flex flex-col" data-testid="mac-denied">
          <div className="text-footnote-bold">
            To get notifications from Glue, you'll need to turn them on in your
            System Settings:
          </div>
          <ol className="list-decimal list-inside [&>li]:py-2">
            <li>Open System Settings from the Apple menu.</li>
            <li>Select Notifications in the sidebar.</li>
            <li>Find Glue in the list of apps.</li>
            <li>Toggle Allow Notifications to On.</li>
          </ol>
        </div>
      ),
      iconProps: iconProps.denied,
    },
    prompt: commonPrompt("mac", requestPermissions),
  },
  web: {
    granted: {
      content: (
        <div className="flex flex-col gap-2" data-testid="web-granted">
          <div className="text-footnote-bold">Notifications are on</div>
          You'll get notifications based on the above preferences and your
          system settings.
          <div className="mt-6 text-footnote text-text-subtle">
            {FOR_BEST_EXPERIENCE}
          </div>
        </div>
      ),
      iconProps: iconProps.granted,
    },
    denied: {
      content: (
        <div className="flex flex-col gap-4" data-testid="web-denied">
          <div className="text-footnote-bold">
            To get notifications from Glue, you'll need to turn them on in your
            browser's settings.
          </div>
          <div className="text-footnote text-text-subtle">
            {FOR_BEST_EXPERIENCE}
          </div>
        </div>
      ),
      iconProps: iconProps.denied,
    },
    prompt: {
      content: (
        <div className="flex flex-col gap-4" data-testid="web-prompt">
          <div className="text-footnote-bold">Turn on notifications</div>
          Click below, then select “Allow” to get notifications from Glue.
          <div className="mt-8">
            <Button icon="Bell" onClick={requestPermissions}>
              Turn on notifications
            </Button>
          </div>
          <div className="mt-8 text-footnote text-text-subtle">
            {FOR_BEST_EXPERIENCE}
          </div>
        </div>
      ),
      iconProps: iconProps.prompt,
    },
  },
});

const PushNotificationsPref = () => {
  const { permissionStatus, requestPermissions } = useNotificationPermissions();
  const platform = getPlatform();

  const content: Content = useMemo(() => {
    if (!permissionStatus) return undefined;

    return getContent(requestPermissions)[platform][permissionStatus];
  }, [permissionStatus, platform, requestPermissions]);

  if (!content) return null;
  return (
    <FieldSet
      label={`Push notifications (${platform === "web" ? "this browser" : "this device"})`}
    >
      <TwoTierInfoBox
        upper={content}
        lower={{
          content: (
            <>
              Need help?{" "}
              <a
                className="text-text-action hover:text-text-action-hover"
                href="https://docs.glue.ai/account-setup/push-notifications"
                rel="noreferrer"
                target="_blank"
              >
                Check out our notification guide.
              </a>
            </>
          ),
          iconProps: { icon: "Help", size: 20 },
        }}
      />
    </FieldSet>
  );
};

export default PushNotificationsPref;
