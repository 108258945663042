import { Component, ReactNode } from "react";

interface Options extends NotificationOptions {
  title: string;
}

export type GetNotificationInstance =
  CreateNotification["getNotificationsInstance"];

type DefaultProps = {
  timeout?: number;
};

type Props = {
  getNotificationInstance?: ((M: GetNotificationInstance) => void)[];
  onClick?: (e: Event, tag: string | undefined) => void;
  onClose?: (e: Event, tag: string | undefined) => void;
  onError?: (e: Event, tag: string | undefined) => void;
  onShow?: (e: Event, tag: string | undefined) => void;
  options: Options;
} & DefaultProps;

type State = {
  NotificationInstance: { [key: string]: Notification };
};

const defaultProps: DefaultProps = {
  timeout: 5800,
};

const incrementor = () => {
  let i = 0;
  return () => i++;
};
const seq = incrementor();
export default class CreateNotification extends Component<Props, State> {
  static defaultProps: DefaultProps = defaultProps;
  notifications: {
    [key: string]: Notification | undefined;
  } = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      NotificationInstance: {},
    };

    if (this.props.getNotificationInstance) {
      this.props.getNotificationInstance.forEach(m =>
        m(this.getNotificationsInstance.bind(this))
      );
    }
  }

  doNotification(): void {
    const opt = this.props.options;
    opt.tag =
      opt.tag && typeof opt.tag === "string"
        ? opt.tag
        : `web-notification-${seq()}`;
    if (this.notifications[opt.tag]) {
      return;
    }

    if (!window.Notification) return;

    // we already bail early if not supported
    const n = new window.Notification(opt.title, opt);

    n.onshow = e => {
      if (this.props.onShow) {
        this.props.onShow(e, opt.tag);
      }

      if (this.props.timeout && this.props.timeout > 0) {
        setTimeout(() => {
          this.close(n);
        }, this.props.timeout);
      }
    };
    n.onclick = e => {
      if (this.props.onClick) {
        this.props.onClick(e, opt.tag);
      }
    };
    n.onclose = e => {
      if (this.props.onClose) {
        this.props.onClose(e, opt.tag);
      }
    };
    n.onerror = e => {
      if (this.props.onError) {
        this.props.onError(e, opt.tag);
      }
    };
    this.notifications[opt.tag] = n;
  }

  async componentDidMount() {
    if (
      this.props.options.title &&
      window.Notification?.permission === "granted"
    ) {
      this.doNotification();
    }
  }

  render(): ReactNode {
    return (
      <div
        data-testid={`browser-notification-${this.props.options.tag}`}
        data-title={`${this.props.options.title}`}
      />
    );
  }

  getNotificationsInstance(tag: string): Notification | undefined {
    return this.notifications[tag];
  }

  close(n: Notification): void {
    if (n && typeof n.close === "function") {
      n.close();
    }
  }
}
