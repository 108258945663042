import { Styles } from "components/MessageElements";
import { AppOrigin } from "components/routing/utils";
import useShowActionModal, { isGlueAction } from "hooks/useShowActionModal";
import tw from "utils/tw";

type Actions = Parameters<ReturnType<typeof useShowActionModal>>[0]["action"];

type Params = {
  recipient: string | null;
  workspaceID: string | null;
};

const actionParam = "act";

export const glueHrefAction = (
  href: string
): { action: Actions | null; params: Params | null } => {
  let origin = "";
  let search = "";

  try {
    ({ origin, search } = new URL(href));
  } catch {
    return { action: null, params: null };
  }

  const params = new URLSearchParams(search);
  const action = params.get(actionParam);

  return origin === AppOrigin && action && isGlueAction(action)
    ? {
        action,
        params: {
          recipient: params.get("recipient"),
          workspaceID: params.get("workspaceID"),
        },
      }
    : { action: null, params: null };
};

export const GlueActionButton = ({
  action,
  params,
  children,
}: WithChildren<{
  action: Actions;
  params: Params | null;
}>): JSX.Element => {
  const showActionModal = useShowActionModal();

  return (
    <button
      onClick={e => {
        e.stopPropagation();

        showActionModal({ ...params, action });
      }}
      className={tw("inline", Styles.hyperLink, "font-semibold")}
    >
      {children}
    </button>
  );
};
