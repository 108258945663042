import { useRef } from "react";
import { useHistory } from "react-router-dom";

import { nodeAs } from "@utility-types";
import Icon from "components/design-system/icons/Icon";
import FacePile from "components/design-system/ui/FacePile";
import useIdentityBadgeData from "components/design-system/ui/IdentityBadge/hooks/useIdentityBadgeData";
import NewThreadFeedWidget from "components/design-system/ui/NewThreadFeedWidget";
import Feed from "components/Feed/Feed";
import GroupProfileModal, {
  GroupProfileTab,
} from "components/group/GroupModal/GroupProfileModal";
import { NotInGroup } from "components/group/NotInGroup";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  GroupTabs,
  drawerParams,
  routeParams as getRouteParams,
  locationFromRoute,
  tabPath,
} from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import ThreadView from "components/threads/ThreadView";
import ThreadList from "components/threads-list/ThreadList";
import EmptyView from "components/views/EmptyView";
import {
  WorkspaceModal,
  WorkspaceModalTabs,
} from "components/workspace/WorkspaceModal";
import { ThreadsMailbox } from "generated/graphql";
import { MemberRole } from "generated/graphql";
import useFetchGroupEdge, {
  useFetchGroupEdgeSimple,
} from "hooks/group/useFetchGroupEdge";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import useMemberEdge from "hooks/useMemberEdge";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import { WORKSPACE_GENERAL_DESCRIPTION } from "./consts";
import GroupHeader from "./GroupHeader";
import { SharedFilterProvider } from "./Shared/providers/SharedFilterProvider";
import RecentlySharedLinksFiles from "./Shared/RecentlySharedLinksFiles";
import RecentlySharedMedia from "./Shared/RecentlySharedMedia";
import Shared from "./Shared/Shared";

type Props = { recipientID: string; defaultTab?: GroupTabs };

const Group = ({ recipientID, defaultTab = GroupTabs.Chat }: Props) => {
  const history = useHistory();
  const { route } = usePartitionState(({ route }) => ({ route }));
  const routeParams = getRouteParams(locationFromRoute(route));
  const mainEl = useRef<HTMLDivElement>(null);
  const scrollEl = useRef<HTMLDivElement>(null);
  const isWide = useElementBreakpoint(mainEl, "wide");
  const hasMounted = useRef(false);

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  // use cached data from group list if available
  const groupID = recipientID === "groups" ? undefined : recipientID;
  const { edge, loading } = useFetchGroupEdgeSimple(groupID);
  const { memberEdge, previewEdge } = useMemberEdge(edge);

  const workspaceEdge = nodeAs(edge, ["WorkspaceEdge", "WorkspacePreviewEdge"]);
  const groupEdge = nodeAs(edge, ["GroupEdge", "GroupPreviewEdge"]);

  // fetch full data for header + refetch in case user added/removed
  const { edge: edgeFull } = useFetchGroupEdge(groupID, {
    fetchPolicy: "cache-and-network",
  });
  const { memberEdge: memberEdgeFull } = useMemberEdge(edgeFull);

  const { type, tooltip } = useIdentityBadgeData(groupEdge?.node);
  const externalTooltip = type !== "internal" ? tooltip : undefined;

  const { threadID } = drawerParams(routeParams.d ?? "");

  const showSidebar = !threadID && !!isWide;

  const currentTab = (() => {
    switch (routeParams.t) {
      case "Chat":
        return GroupTabs.Chat;
      case "Threads":
        return GroupTabs.Threads;
      case "Feed":
        return GroupTabs.Feed;
      case "Shared":
        return GroupTabs.Shared;
    }
  })();

  const currentView = previewEdge ? "Preview" : currentTab;

  const handleOpenGroupModal = ({
    groupId,
    defaultTab = GroupProfileTab.Members,
    focusOnDescriptionField,
  }: {
    groupId: string;
    defaultTab?: GroupProfileTab;
    focusOnDescriptionField?: boolean;
  }) => {
    openModal(
      <GroupProfileModal
        defaultTab={defaultTab}
        groupID={groupId}
        isAdmin={memberEdge?.memberRole === MemberRole.Admin}
        focusOnDescriptionField={focusOnDescriptionField}
      />
    );
  };

  const handleOpenWorkspaceModal = ({
    workspaceID,
    defaultTab = WorkspaceModalTabs.Members,
  }: {
    workspaceID: string;
    defaultTab?: WorkspaceModalTabs;
  }) => {
    openModal(
      <WorkspaceModal defaultTab={defaultTab} workspaceID={workspaceID} />
    );
  };

  const handleOpenModal = () => {
    if (workspaceEdge) {
      handleOpenWorkspaceModal({
        workspaceID: workspaceEdge.node.id,
      });
    } else if (groupEdge) {
      handleOpenGroupModal({
        groupId: groupEdge.node.id,
      });
    }
  };

  const handleClickDescription = () => {
    if (!groupEdge) return;
    handleOpenGroupModal({
      groupId: groupEdge.node.id,
      defaultTab: GroupProfileTab.Settings,
      focusOnDescriptionField: true,
    });
  };

  // Empty state, will auto-select first group
  if ((!edge && !loading) || !groupID) {
    return <EmptyView />;
  }

  // Redirect to first tab if no tab, and each time super-tab is loaded
  if (!currentTab || (currentTab !== defaultTab && !hasMounted.current)) {
    history.replace(tabPath(defaultTab));
    return null;
  }

  hasMounted.current = true;

  const MembersFacePile = () => {
    const members = edgeFull?.node.members;
    const users = members?.edges?.slice(0, 5).map(e => e.node);

    return (
      <button disabled={!!previewEdge} onClick={handleOpenModal}>
        <FacePile
          size="medium"
          hasExternal={!!externalTooltip}
          tooltip={externalTooltip || "See all members"}
          totalCount={members?.totalCount ?? 0}
          tooltipDisabled={!!previewEdge}
          users={users}
        />
      </button>
    );
  };

  return (
    <>
      <header className="sticky shrink-0 top-0 bg-background-body border-b-1 border-border-strong p-16 pb-0">
        <GroupHeader
          edge={edge}
          edgeFull={edgeFull}
          currentTab={currentTab}
          onOpenModal={handleOpenModal}
          onOpenGroupModal={handleOpenGroupModal}
        />
      </header>

      <SharedFilterProvider>
        <div
          ref={mainEl}
          className="flex grow bg-background-app-secondary overflow-hidden"
        >
          <main
            className={tw(
              "flex grow min-w-0 min-h-0",
              { "overflow-y-auto": !previewEdge },
              { "pr-16": showSidebar && currentView === GroupTabs.Chat },
              { "px-16": isWide && currentView !== GroupTabs.Chat }
            )}
          >
            {currentView === "Preview" && <NotInGroup groupID={groupID} />}

            {currentView === GroupTabs.Chat && (
              <div className="grow min-w-0 h-full overflow-y-auto bg-background-body border-border-container border-r-1">
                <ThreadView
                  headerType="none"
                  mailbox={ThreadsMailbox.All}
                  messageID={routeParams.messageID}
                  recipientID={groupID}
                  threadID={
                    routeParams.threadID ??
                    memberEdgeFull?.persistentChatEdge.node.id
                  }
                />
              </div>
            )}

            {currentView === GroupTabs.Threads && (
              <div
                className={tw(
                  "flex flex-col grow min-w-0 max-w-[832px] gap-16 overflow-hidden",
                  { "pt-16": isWide }
                )}
              >
                <div
                  className={tw(
                    "bg-background-body shadow-level1 flex flex-col grow min-h-0 overflow-auto",
                    isWide && "md:rounded-t-lg"
                  )}
                >
                  <ThreadList
                    headerType="recent"
                    mailbox={ThreadsMailbox.All}
                    recipient={memberEdge}
                    recipientID={groupID}
                    selectedID={threadID}
                    compact
                    disableBulkSelect
                    excludeChats
                    skipAutoSelect
                    canRemind
                  />
                </div>
              </div>
            )}

            {currentView === GroupTabs.Feed && (
              <div
                className="flex flex-col grow min-w-0 max-w-[832px] gap-16 pt-16"
                ref={scrollEl}
              >
                <NewThreadFeedWidget
                  className={!isWide ? "md:!rounded-none" : undefined}
                />

                <Feed
                  key={groupID}
                  feedID={groupID}
                  roundedCard={!!isWide}
                  scrollEl={scrollEl.current}
                />
              </div>
            )}

            {currentView === GroupTabs.Shared && (
              <Shared isWide={!!isWide} groupID={groupID} />
            )}
          </main>
          {showSidebar && (
            <aside className="flex flex-col shrink-0 w-250 gap-16 py-16 pr-16 overflow-y-auto overflow-x-hidden">
              <div className="bg-background-body py-12 px-16 rounded-lg shadow-level1 shrink-0 min-w-[230px]">
                <header className="flex items-center justify-between text-footnote-bold mb-8 text-text-primary select-none">
                  Description
                  {memberEdge && groupEdge && (
                    <button
                      aria-label="Edit group description"
                      onClick={handleClickDescription}
                    >
                      <Icon
                        icon="EditSimple"
                        size={14}
                        className="text-icon-secondary"
                      />
                    </button>
                  )}
                </header>
                <div className="text-footnote text-text-subtle">
                  {workspaceEdge ? (
                    WORKSPACE_GENERAL_DESCRIPTION
                  ) : groupEdge ? (
                    groupEdge.node.description?.trim() ||
                    (memberEdge && (
                      <button
                        className="select-none"
                        onClick={handleClickDescription}
                      >
                        Add a description...
                      </button>
                    )) || <>&nbsp;</>
                  ) : (
                    <Skeleton />
                  )}
                </div>
              </div>

              <div className="bg-background-body py-12 px-16 rounded-lg shadow-level1 min-w-[230px]">
                <header className="flex items-center justify-between text-footnote-bold mb-8 text-text-primary select-none">
                  Members
                  {memberEdge && (
                    <button aria-label="Edit members" onClick={handleOpenModal}>
                      <Icon
                        icon="Plus"
                        size={16}
                        className="text-icon-secondary"
                      />
                    </button>
                  )}
                </header>
                <MembersFacePile />
              </div>
              {!previewEdge && (
                <>
                  <RecentlySharedLinksFiles groupID={groupID} />
                  <RecentlySharedMedia groupID={groupID} />
                </>
              )}
            </aside>
          )}
        </div>
      </SharedFilterProvider>
    </>
  );
};

export default Group;
