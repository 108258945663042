import { first, last } from "lodash-es";
import { ChangeEvent, ComponentProps, useRef, useState } from "react";

import { Icon } from "components/design-system/icons";
import { fileToFileUpload } from "components/MessageEditor/stream-helpers";
import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import {
  CustomEmojisDocument,
  useCreateCustomEmojiMutation,
  useEmojiNameSuggestionLazyQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useComponentMounted from "hooks/useComponentMounted";
import useFileUploader from "hooks/useFileUploader";
import useModalStore from "store/useModalStore";
import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";

import { Button } from "../Button";
import { Form, SubmitButton } from "../Forms";
import { InformationBubble } from "../InformationBubble";

import EmojiShortcutForm from "./customEmojis/EmojiShortcutForm";
import EmojiSuggestionForm from "./customEmojis/EmojiSuggestionForm";
import EmojiWorkspaceForm from "./customEmojis/EmojiWorkspaceSelect";

type CallBack = Parameters<typeof useFileUploader>[0]["onChange"];
type State = Parameters<CallBack>[0];
type EmojiPreview = Parameters<
  ComponentProps<typeof EmojiSuggestionForm>["setPreview"]
>[0];

const MAX_FILE_SIZE = 1024 * 1024; // Limit file size to 1MB

const CreateEmojiModal = ({
  openManageModal,
  ...props
}: { openManageModal: (modalID?: string) => void } & ModalProps) => {
  const uploadsRef = useRef<State>(new Map());
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));

  const { authData } = useAuthData();
  const [workspaceID, setWorkspaceID] = useState(
    first(authData?.me.workspaceIDs)
  );

  const isMounted = useComponentMounted();

  const { processedUploads } = useFileUploader({
    onChange: _state => null,
    orderedUploads: uploadsRef,
  });

  const [emojiNameSuggestion] = useEmojiNameSuggestionLazyQuery();
  const [createCustomEmoji] = useCreateCustomEmojiMutation();

  const [preview, setPreview] = useState<EmojiPreview | undefined>();
  const [magic, setMagic] = useState(false);
  const [loadingMagic, setLoadingMagic] = useState(false);
  const [showFileSizeError, setShowFileSizeError] = useState(false);

  const setPreviewWithSuggestion = ({
    description,
    ...preview
  }: Partial<EmojiPreview> & {
    description?: string;
  }) => {
    if (!workspaceID) return;
    setPreview(preview);
    emojiNameSuggestion({
      fetchPolicy: "no-cache",
      variables: {
        input: { workspaceID, description, filename: preview.name },
      },
    })
      .then(({ data }) => {
        if (!isMounted.current) return;
        setPreview(preview => ({
          ...preview,
          shortcut: first(data?.emojiNameSuggestion.names) ?? preview?.name,
        }));
      })
      .finally(() => setLoadingMagic(false));
  };

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    setShowFileSizeError(false);

    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      setShowFileSizeError(true);
      return;
    }

    const temp = new Map();
    temp.set(file.name, fileToFileUpload(file));
    uploadsRef.current = temp;

    setPreviewWithSuggestion({
      name: file.name,
      url: URL.createObjectURL(file),
    });
  };

  const ImagePreview = ({
    loading = false,
    margin,
    theme = "light",
  }: {
    loading?: boolean;
    margin?: `${"m" | "mt" | "mb" | "ml" | "mr"}-${number}`;
    theme?: "light" | "dark";
  }) => (
    <div
      className={tw(
        "flex justify-center items-center border-1 border-border-container rounded-md w-64 h-64 p-16",
        margin,
        {
          "bg-background-app-base border-background-app-base": theme === "dark",
        }
      )}
    >
      {preview?.url ? (
        <img
          alt="Uploaded emoji by user"
          width={32}
          height={32}
          style={{ objectFit: "contain" }}
          src={glueImageURL(preview.url, {
            fit: "max",
            h: 32,
            w: 32,
          })}
          srcSet={`${glueImageURL(preview.url, {
            fit: "max",
            h: 32,
            w: 32,
          })} 1x, ${glueImageURL(preview.url, {
            fit: "max",
            h: 64,
            w: 64,
          })} 2x`}
        />
      ) : (
        <Icon
          className={tw("text-icon-secondary", {
            "animate-heartbeat": loading,
          })}
          icon={magic ? "SparkleFilled" : "Image"}
          size={32}
        />
      )}
    </div>
  );

  const onSubmit = () => {
    const fileID = last(processedUploads.current)?.glueId || preview?.id;
    if (!fileID || !preview || !workspaceID || !preview.shortcut) return;
    createCustomEmoji({
      variables: { input: { fileID, name: preview.shortcut, workspaceID } },
      refetchQueries: [CustomEmojisDocument],
    }).then(() => openManageModal(props.modalId));
  };

  return (
    <Form onSubmit={onSubmit}>
      <StandardModal {...props}>
        <Header className="!px-20">Add emoji</Header>
        <Main className="flex flex-col px-20 py-16 border-t-1 border-border-container">
          <span className="text-subhead-bold">Choose an image</span>
          <span className="mt-4 text-subhead">
            Square images under 128KB with transparent backgrounds work best. If
            your image is too large, we'll try to resize it for you
          </span>
          <div className="flex mt-16">
            <ImagePreview loading={magic ? loadingMagic : undefined} />
            <ImagePreview
              loading={magic ? loadingMagic : undefined}
              margin="ml-8"
              theme="dark"
            />
            <div className="flex justify-center items-center border-1 border-border-container rounded-md grow ml-16">
              {magic ? (
                <div className="flex items-center w-full px-16">
                  <EmojiSuggestionForm
                    loading={loadingMagic}
                    setLoading={setLoadingMagic}
                    setPreview={newPreview =>
                      setPreview(preview => ({ ...preview, ...newPreview }))
                    }
                    setPreviewWithSuggestion={setPreviewWithSuggestion}
                  />
                  <Button
                    className="pr-0"
                    buttonStyle="icon-secondary"
                    disabled={loadingMagic}
                    icon="Close"
                    iconSize={24}
                    onClick={() => {
                      setPreview(undefined);
                      setMagic(false);
                    }}
                  />
                </div>
              ) : preview ? (
                <div className="flex justify-between items-center w-full px-16">
                  <span className="text-subhead">{preview.name}</span>
                  <Button
                    className="pr-0"
                    buttonStyle="icon-secondary"
                    icon="Close"
                    iconSize={24}
                    onClick={() => setPreview(undefined)}
                  />
                </div>
              ) : (
                <>
                  <Button
                    icon="ArrowUpCircle"
                    iconSize={20}
                    onClick={() => inputFileRef.current?.click()}
                  >
                    Upload
                  </Button>
                  <input
                    ref={inputFileRef}
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                    className="hidden"
                    onChange={handleUploadFile}
                    type="file"
                  />
                  <span className="mx-16">or</span>
                  <Button
                    icon="SparkleFilled"
                    iconSize={20}
                    onClick={() => setMagic(true)}
                  >
                    Magic
                  </Button>
                </>
              )}
            </div>
          </div>
          {showFileSizeError && (
            <div className="flex items-center mt-14 bg-background-alert-subtle px-16 py-12 rounded-md select-none">
              File is too large, please choose a file smaller than{" "}
              {MAX_FILE_SIZE / 1024 / 1024}MB.
            </div>
          )}
          <div className="flex flex-col mt-24">
            <span className="text-subhead-bold">Name your emoji</span>
            <span className="mt-4 text-subhead">
              This is what you'll type to use this emoji in a message.
            </span>
            <EmojiShortcutForm
              disabled={!preview}
              shortcut={preview?.shortcut}
              setPreview={({ shortcut }) =>
                setPreview(value => ({ ...value, shortcut }))
              }
            />
          </div>
          {authData && (authData?.workspaces.edges.length ?? 0) > 1 ? (
            <div className="flex flex-col mt-24 pb-10">
              <span className="text-subhead-bold">Workspace</span>
              <span className="mt-4 text-subhead mb-10">
                Your emoji will be available in this workspace.
              </span>
              <EmojiWorkspaceForm
                setWorkspace={setWorkspaceID}
                workspaces={authData.workspaces.edges}
              />
            </div>
          ) : null}
          <div className="mt-24">
            <InformationBubble>
              Custom emoji will be available to everyone in this workspace.
              You'll find them in the custom tab of the emoji picker.
            </InformationBubble>
          </div>
        </Main>
        <Footer className="!px-16" flexboxClassName="justify-between">
          <Button
            buttonStyle="subtle"
            onClick={() => openManageModal(props.modalId)}
            type="button"
          >
            Manage emojis
          </Button>
          <div className="flex">
            <Button
              buttonStyle="simplePrimary"
              onClick={() => closeModal(props.modalId)}
              type="button"
            >
              Cancel
            </Button>
            <SubmitButton>Save</SubmitButton>
          </div>
        </Footer>
      </StandardModal>
    </Form>
  );
};

export default CreateEmojiModal;
