import { RefObject, useCallback, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";

const useElementTextWidth = (ref: RefObject<HTMLElement>) => {
  const canvasRef = useRef(document.createElement("canvas"));

  const [elWidth, setElWidth] = useState(0);
  useResizeObserver({
    ref,
    onResize: ({ width }) => setElWidth(width ?? elWidth),
  });

  const measureText = useCallback(
    (value: string, omitElementWidth = false) => {
      const canvasCtx = canvasRef.current.getContext("2d");
      if (!ref.current || !canvasCtx) return 0;

      const style = window.getComputedStyle(ref.current, null);
      canvasCtx.font = `${style.fontStyle} ${style.fontVariant} ${style.fontWeight} ${style.fontSize} / ${style.lineHeight} ${style.fontFamily}`;

      const textWidth = canvasCtx.measureText(value).width;
      return !omitElementWidth ? Math.min(textWidth, elWidth) : textWidth;
    },
    [ref, elWidth]
  );

  return { measureText };
};

export default useElementTextWidth;
