import { ComponentProps } from "react";

import illustrationNotification from "assets/illustration-notification.svg";
import { Button } from "components/design-system/Button";
import useNotificationsPermissions from "components/devices/hooks/useNotificationPermissions";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import localSettingsStore from "store/useLocalSettingsStore";
import modalStore from "store/useModalStore";

const text = {
  preAsk: {
    title: "Turn on notifications",
    headline:
      "Stay in the loop with Glue! Enable push notifications to keep up with real time threads and messages.",
  },
  reAsk: {
    title: "Turn on notifications",
    headline:
      "Enable push notifications to keep up with real time threads and messages.",
  },
};

const NotificationsPermissionsModal = ({
  textKey,
  ...props
}: ModalProps & {
  textKey?: keyof typeof text;
  isPersistent?: ComponentProps<typeof StandardModal>["isPersistent"];
}) => {
  const { closeModal } = modalStore(({ closeModal }) => ({ closeModal }));

  const { title, headline } = text[textKey ?? "preAsk"];
  const { requestPermissions } = useNotificationsPermissions();

  return (
    <StandardModal
      closeable={false}
      contentHandlesSafeArea={false}
      heightAuto
      mobileGap
      {...props}
    >
      <div className="flex flex-col items-center py-32 px-32 md:px-64">
        <img
          alt="Notification bell icon"
          src={illustrationNotification}
          width={56}
          height={56}
        />
        <span className="mt-16 text-center text-title-3">{title}</span>
        <span className="w-full md:w-[420px] h-[66px] md:h-44 text-center text-headline">
          {headline}
        </span>
        <Button
          className="w-full md:w-[420px] mt-32 justify-center"
          onClick={() => {
            requestPermissions();
            closeModal(`${props.modalId}`);
          }}
        >
          Continue
        </Button>
        <Button
          testId="notification-pre-alert-modal-maybe-later-button"
          buttonStyle="subtle"
          className="w-full md:w-[420px] mt-16 justify-center"
          onClick={() => {
            localSettingsStore.setState({ notificationAskedAt: Date.now() });
            closeModal(`${props.modalId}`);
          }}
        >
          Maybe later
        </Button>
      </div>
    </StandardModal>
  );
};

export default NotificationsPermissionsModal;
