import { ComponentProps } from "react";

import { ModalProps } from "components/ModalKit/Modal";
import { Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";

import AICompose from "./AICompose";

type Props = ModalProps & Omit<ComponentProps<typeof AICompose>, "isModal">;

const AIComposeModal = ({ ...props }: Props) => (
  <StandardModal
    closeButtonPosition="right"
    closeButtonTitle="Save & close"
    contentClassName="md:!max-w-[800px]"
    {...props}
  >
    <Main>
      <AICompose isModal />
    </Main>
  </StandardModal>
);

export default AIComposeModal;
