import { useState } from "react";

import { Icon } from "components/design-system/icons";
import { FileIcon } from "components/helper";
import { FileType } from "components/helper/FileIcon";
import glueImageURL from "utils/glueImageURL";
import env from "utils/processEnv";

type FavIconProps = {
  id: string;
};

type MentionIconsProps = {
  children: React.ReactNode | undefined;
  url: string;
};

type MentionPrefix = "fil" | "grp" | "obj" | "thr" | "usr" | "wks";

/**
 * @summary The max length of the first word in a mention before it is considered long.
 */
const maxFirstWordLength = 30;

const truncateString = (str: string, maxLength = 40): string => {
  if (str.length <= maxLength) return str;
  return `${str.slice(0, maxLength)}...`;
};

/**
 * @summary A FavIcon for external objects with a fallback icon in case the favicon is missing.
 */
const FavIcon = ({ id }: FavIconProps) => {
  const [missingFavicon, setMissingFavicon] = useState(false);

  if (missingFavicon) {
    return (
      <Icon
        className="text-icon-link inline mb-3 mr-1"
        icon="Link"
        size={16}
        strokeWidth={1}
      />
    );
  }

  const objUrl = `${env.glueApiUrl}/external/${id}/icon`;

  return (
    <img
      className="mb-4 mr-2 p-1 size-16 inline-block"
      src={glueImageURL(objUrl, { w: 14, h: 14, fit: "max" })}
      onError={() => setMissingFavicon(true)}
    />
  );
};

const renderIcon = (
  fileType: FileType | undefined,
  id: string,
  prefix: MentionPrefix
) => {
  const className = "align-baseline top-2 inline-block relative size-16 mr-2";
  switch (prefix) {
    case "fil":
      return (
        <span className={className}>
          <FileIcon
            className="!text-icon-link"
            fileType={fileType}
            iconSize={16}
            strokeWidth={1}
          />
        </span>
      );
    case "obj":
      return <FavIcon id={id} />;
    case "thr":
      return (
        <span className={className}>
          <Icon icon="Thread" size={16} strokeWidth={1} />
        </span>
      );
  }
};

/**
 * @summary Renders inline icons for mentions of files, objects and threads based on the url prop.
 */
const MentionIcons = ({ children, url }: MentionIconsProps) => {
  const id = url.replace("glue:", "");
  const prefix = id.split("_")[0] as MentionPrefix;

  // don't render for unsupported mentions
  const supportedMentions: MentionPrefix[] = ["fil", "obj", "thr"];
  if (!supportedMentions.includes(prefix)) return <>{children}</>;

  const fileType = new URL(url).searchParams.get("fileType") as FileType;

  const strArr = children?.toString().split(" ") || [""];

  const firstWord = `${strArr[0]} `;
  const isLongFirstWord = firstWord.length > maxFirstWordLength;

  const renderRemainder = () => {
    const remainder = strArr.slice(1).join(" ") || "";
    return truncateString(remainder);
  };

  return (
    <>
      <span className={!isLongFirstWord ? "whitespace-nowrap" : undefined}>
        {renderIcon(fileType, id, prefix)}
        <span className="break-all">{firstWord}</span>
      </span>
      {renderRemainder()}
      {prefix === "obj" && (
        <Icon
          className="text-icon-link inline mb-3 mr-1"
          icon="ExternalLinkMinimal"
          size={16}
          strokeWidth={1}
        />
      )}
    </>
  );
};

export default MentionIcons;
