import { useApolloClient } from "@apollo/client";
import { useCallback, useContext, useRef } from "react";

import {
  CustomEmojisDocument,
  FetchJoinApprovalsDocument,
  FetchUserEdgeDocument,
  FetchUserEdgeQuery,
  FetchUsersDocument,
  GlobalSearchDocument,
  GlobalSearchQuery,
  MailboxCountsDocument,
  NotificationCountsDocument,
  NotificationsDocument,
  PersistentChatsDocument,
  ThreadListDocument,
  WorkspacesAndGroupsListDocument,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import {
  StreamClientContext,
  StreamMessageLimit,
} from "providers/StreamClientProvider";
import filterActiveQueries from "utils/filterActiveQueries";
import { isNative } from "utils/platform";
import env from "utils/processEnv";

let lastStreamSyncAt: string | undefined;
const syncBatch = "sync";

export const useSyncAppState = (): {
  syncAppState: () => Promise<void>;
  syncMailboxState: () => Promise<void>;
  syncNotificationsState: () => Promise<void>;
  syncStreamState: () => Promise<void>;
} => {
  const { authData, authReady, fetchAuthData } = useAuthData();
  const authReadyRef = useRef(authReady);
  authReadyRef.current = authReady;

  const apolloClient = useApolloClient();
  const { streamClient } = useContext(StreamClientContext);

  const syncMailboxState = useCallback(() => {
    if (!authReadyRef.current) {
      return Promise.resolve();
    }

    return apolloClient
      .refetchQueries({
        include: filterActiveQueries(apolloClient, [
          FetchJoinApprovalsDocument,
          ThreadListDocument,
          MailboxCountsDocument,
          NotificationCountsDocument,
          FetchUsersDocument,
          PersistentChatsDocument,
          WorkspacesAndGroupsListDocument,
        ]),
      })
      .catch(err => {
        console.warn("Error: [useSyncAppState] -", err);
      })
      .then(() => undefined);
  }, [apolloClient, authReadyRef]);

  const syncNotificationsState = useCallback(() => {
    if (!authReadyRef.current) {
      return Promise.resolve();
    }

    return apolloClient
      .refetchQueries({
        include: filterActiveQueries(apolloClient, [
          NotificationCountsDocument,
          NotificationsDocument,
        ]),
      })
      .catch(err => {
        console.warn("Error: [useSyncAppState] -", err);
      })
      .then(() => undefined);
  }, [apolloClient, authReadyRef]);

  const syncSearchCacheState = useCallback(() => {
    if (!authReadyRef.current || !authData?.me.id) {
      return Promise.resolve();
    }

    // Pre-fetch Glue AI user
    apolloClient.query<FetchUserEdgeQuery>({
      query: FetchUserEdgeDocument,
      fetchPolicy: "cache-first", // only for initial cache bootstrapping
      context: { batch: syncBatch },
      variables: { id: `${env.glueAIBotID}-${authData?.me.id}` },
    });

    return apolloClient
      .query<GlobalSearchQuery>({
        query: GlobalSearchDocument,
        fetchPolicy: "cache-first", // only for initial cache bootstrapping
        context: { batch: syncBatch },
        variables: {
          cacheKey: "local-search",
          match: "",
          limit: 250,
          threads: false,
        },
      })
      .catch(err => {
        console.warn("Error: [useSyncAppState] -", err);
      })
      .then(() => undefined);
  }, [apolloClient, authData?.me.id, authReadyRef]);

  const syncStreamState = useCallback(() => {
    if (!streamClient?.user?.id) {
      return Promise.resolve();
    }

    return streamClient
      .queryChannels(
        {
          last_message_at: lastStreamSyncAt
            ? { $gte: lastStreamSyncAt }
            : undefined,
          members: { $in: [streamClient.user.id] },
        },
        [{ last_message_at: -1 }],
        { limit: 30, message_limit: StreamMessageLimit, watch: false }
      )
      .catch(err => {
        console.warn("Error: [syncStreamState] -", err);
      })
      .then(() => {
        lastStreamSyncAt = new Date().toISOString();
      });
  }, [streamClient]);

  const syncCustomEmojis = useCallback(() => {
    if (!authReadyRef.current) {
      return Promise.resolve();
    }

    // TODO: use a more efficient way to sync custom emojis
    return apolloClient
      .query({
        query: CustomEmojisDocument,
        fetchPolicy: "cache-first", // only for initial cache bootstrapping
        context: { batch: syncBatch },
      })
      .catch(err => {
        console.warn("Error: [syncCustomEmojis] -", err);
      })
      .then(() => undefined);
  }, [apolloClient, authReadyRef]);

  const syncAppState = useCallback(() => {
    if (!authReadyRef.current) {
      return Promise.resolve();
    }

    return Promise.all([
      fetchAuthData(),
      syncMailboxState(),
      syncSearchCacheState(),
      syncCustomEmojis(),
      // On the web, we have a persistent connection to Stream
      // but on native/mobile the app is suspended in the background
      // so we need to fetch Stream state along with app state.
      isNative() ? syncStreamState() : undefined,
    ])
      .catch(err => {
        console.warn("Error: [useSyncAppState] -", err);
      })
      .then(() => undefined);
  }, [
    authReadyRef,
    fetchAuthData,
    syncCustomEmojis,
    syncMailboxState,
    syncSearchCacheState,
    syncStreamState,
  ]);

  return {
    syncAppState,
    syncMailboxState,
    syncNotificationsState,
    syncStreamState,
  };
};

export default useSyncAppState;
