import { useState } from "react";

import tw from "utils/tw";

type Props = {
  isChecked?: boolean;
  textOn: string;
  textOff: string;
  onChange: (value: boolean) => void;
};

const SelectionToggle = ({
  isChecked: isCheckedProp,
  textOn,
  textOff,
  onChange,
}: Props) => {
  const [isChecked, setIsChecked] = useState(isCheckedProp ?? true);

  const handleChange = (value: boolean) => {
    setIsChecked(value);
    onChange(value);
  };

  const selectedClasses =
    "text-footnote-bold bg-background-body hover:bg-background-body-hover shadow-level1 flex items-center h-24";
  const unselectedClasses =
    "text-footnote text-text-secondary hover:text-text-secondary-hover";
  return (
    <div className="flex items-center h-28 bg-background-subtle rounded-lg p-2 cursor-pointer">
      <span
        className={tw(
          "px-8 py-2 rounded-[6px]",
          isChecked ? selectedClasses : unselectedClasses
        )}
        onClick={() => handleChange(true)}
      >
        {textOn}
      </span>
      <span
        className={tw(
          "flex items-center px-8 py-2 rounded-[6px]",
          !isChecked ? selectedClasses : unselectedClasses
        )}
        onClick={() => handleChange(false)}
      >
        {textOff}
      </span>
    </div>
  );
};

export default SelectionToggle;
