import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

import EmojiShortcutInput from "./EmojiShortcutInput";

const EmojiShortcutForm = ({
  disabled,
  shortcut,
  setPreview,
}: {
  disabled: boolean;
  shortcut?: string;
  setPreview: (props: { shortcut?: string }) => void;
}) => {
  const { watch, setValue } = useFormContext<{ emojiShortcut?: string }>();
  const emojiShortcut = watch("emojiShortcut");
  const prevShortcut = useRef(emojiShortcut);

  useEffect(() => {
    if (!emojiShortcut || emojiShortcut === prevShortcut.current) return;
    setPreview({ shortcut: emojiShortcut });
    prevShortcut.current = emojiShortcut;
  }, [emojiShortcut, setPreview]);

  useEffect(() => {
    if (shortcut === prevShortcut.current) return;
    setValue(
      "emojiShortcut",
      shortcut
        ? `${shortcut?.split(".")[0]?.toLocaleLowerCase().split(" ").join("-")}`
        : undefined,
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
    prevShortcut.current = shortcut;
  }, [setValue, shortcut]);

  return (
    <EmojiShortcutInput
      config={{
        required: true,
        pattern: {
          message:
            "Names must be lowercase and can't contain spaces, periods, or most punctuation.",
          value: /^[a-z0-9_\-+]+$/g,
        },
      }}
      name="emojiShortcut"
      wrapperClassName="mt-10 mb-0"
      disabled={disabled}
      placeholder=":robot:"
    />
  );
};

export default EmojiShortcutForm;
