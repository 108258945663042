import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { useCallback } from "react";

import { isNativeMobile } from "utils/platform";

export const useNativeHaptics = (): { lightImpactHaptic: () => void } => {
  const lightImpactHaptic = useCallback(async () => {
    if (!isNativeMobile()) {
      return;
    }
    await Haptics.impact({ style: ImpactStyle.Light });
  }, []);

  return { lightImpactHaptic };
};

export default useNativeHaptics;
