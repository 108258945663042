import { Recipient } from "@utility-types";
import { Tooltip } from "components/design-system/FloatingUi";
import { Icon } from "components/design-system/icons";
import tw from "utils/tw";

import Avatar from "../../design-system/Avatar/Avatar";

type Face = Pick<Recipient, "avatarURL" | "id" | "name">;

const userSkeletons: Face[] = [
  { id: "1", name: "" },
  { id: "2", name: "" },
  { id: "3", name: "" },
];

const FacePile = ({
  className,
  hasExternal,
  size = "x-small",
  tooltip = "See all users",
  tooltipDisabled = false,
  totalCount,
  users,
}: {
  className?: string;
  hasExternal?: boolean;
  size?: "x-small" | "medium";
  tooltip?: string;
  tooltipDisabled?: boolean;
  totalCount: number;
  users?: Face[];
}) => {
  return (
    <Tooltip
      content={tooltip || "See all users"}
      placement="bottom-end"
      disabled={tooltipDisabled}
      tooltipStyle="inverted"
    >
      <div
        className={tw(
          "flex items-center p-1 select-none border-1 border-border-container gap-12",
          size === "medium" ? "rounded-lg" : "rounded-md",
          size === "medium" ? "h-40" : "h-28",
          className
        )}
      >
        <div className="flex flex-row-reverse">
          {[...(users || userSkeletons)].reverse().map(user => (
            <Avatar
              key={user.id}
              border="background-body-2"
              className="box-content"
              margin="-mr-4"
              size={size}
              rounded={size === "medium" ? "rounded-[6px]" : "rounded-[4px]"}
              avatarURL={user.avatarURL}
              name={user.name}
            />
          ))}
        </div>
        <div className="flex items-center gap-4 pr-6">
          <div className="text-sm text-text-subtle font-semibold">
            {users ? totalCount : "-"}
          </div>

          {hasExternal && (
            <Icon
              className="p-2 text-icon-warning bg-background-warning rounded-sm select-none"
              icon="ExternalAccount"
              size={14}
              strokeWidth={1}
            />
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default FacePile;
