import { useEffect, useLayoutEffect, useRef } from "react";
import { useHistory } from "react-router";

import AppUpdate from "components/App/AppUpdate";
import {
  useAppStateManager,
  useAppStateTracker,
  useDarkModeSetting,
  useDeveloperTools,
  useDragOverListener,
  useNativeDeviceManager,
  useNativeKeyboardManager,
  useSafeAreaInsetsManager,
  useSentryIdentify,
  useSkipPayment,
  useStateSyncManager,
  useViewportHeight,
} from "components/App/hooks";
import useInvitationLink from "components/App/hooks/useInvitationLink";
import ServiceWorker from "components/App/ServiceWorker";
import BrowserNotification from "components/BrowserNotification";
import useRegisterDevice from "components/devices/hooks/useRegisterDevice";
import { FeatureFlags } from "components/FeatureFlags";
import MasonrySessionRenderer from "components/Masonry/MasonrySessionRenderer";
import { ModalRenderer } from "components/ModalKit/ModalRenderer";
import OnboardingMain from "components/OnboardingWizard/OnboardingMain";
import { PhotoSwipeLayout } from "components/PhotoSwipe";
import SignInMain from "components/SignIn/SignInMain";
import useAuthData from "hooks/useAuthData";
import useExecuteURLAction from "hooks/useExecuteURLAction";
import useAppStateStore from "store/useAppStateStore";
import useOnboardingStore from "store/useOnboardingStore";
import useSafeAreaInsetsStore from "store/useSafeAreaInsetsStore";
import {
  isNativeIOS,
  isNativeMac,
  isNativeMobile,
  isWeb,
} from "utils/platform";
import tw from "utils/tw";

import useRoutesStore from "../../store/useRoutesStore";

import AppLayoutDesktop from "./AppLayoutDesktop/AppLayoutDesktop";
import AppLayoutMobile from "./AppLayoutMobile/AppLayoutMobile";

const AppLayout = (): JSX.Element => {
  const appRef = useRef<HTMLDivElement>(null);

  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const { onboarding } = useOnboardingStore(({ onboarding }) => ({
    onboarding,
  }));

  useAppStateManager();
  useAppStateTracker();
  useDarkModeSetting();
  useDeveloperTools();
  useDragOverListener();
  useNativeDeviceManager();
  useNativeKeyboardManager(appRef);
  useRegisterDevice();
  useSafeAreaInsetsManager();
  useSentryIdentify();
  useStateSyncManager();
  useViewportHeight();
  useExecuteURLAction();
  useInvitationLink();
  useSkipPayment();

  const { authNeeded } = useAuthData();

  // Fix for https://bugs.webkit.org/show_bug.cgi?id=191872
  const safeAreaInsetTop = useSafeAreaInsetsStore(({ top }) => top);
  const appLayoutReady = !isNativeIOS() || safeAreaInsetTop !== 0;

  useLayoutEffect(() => {
    document.documentElement.classList.add("day");
    document.body?.classList.add(
      `platform-${isNativeMobile() ? "native" : "web"}`
    );
  }, []);

  const history = useHistory();
  const { restoreRoute } = useRoutesStore(({ restoreRoute }) => ({
    restoreRoute,
  }));

  const route = useRef(restoreRoute);
  route.current = restoreRoute;

  useEffect(() => {
    if (
      !route.current || // if no route to restore, eject
      route.current === window.location.href || // if linking to the current location, don't restore route
      window.location.pathname !== "/" // if linking to a specific thread, don't restore route
    )
      return;
    history.replace(route.current);
  }, [history]);

  const appContainerProps = {
    ref: appRef,
    className: tw(
      "h-screen relative w-screen z-0",
      appLayoutReady ? "opacity-100" : "opacity-0",
      {
        "glue-skeleton-static": authNeeded,
      }
    ),
    id: "app",
  };

  if (onboarding) {
    return (
      <div {...appContainerProps}>
        <OnboardingMain />
        <ModalRenderer />
        <FeatureFlags />
      </div>
    );
  }

  if (authNeeded) {
    return (
      <div key={authNeeded ? "auth-needed" : ""} {...appContainerProps}>
        <ModalRenderer />
        <SignInMain />
      </div>
    );
  }

  return (
    <>
      <ServiceWorker />
      <AppUpdate />
      <FeatureFlags />
      <ModalRenderer />
      <MasonrySessionRenderer />

      <div key={authNeeded ? "auth-needed" : ""} {...appContainerProps}>
        {isWeb() && <BrowserNotification />}
        <PhotoSwipeLayout fullscreenButton={!isNativeMac()} zoomButton={true} />

        {breakpointMD ? <AppLayoutDesktop /> : <AppLayoutMobile />}
      </div>
    </>
  );
};

export default AppLayout;
