import { formatGroupName } from "utils/group/formatGroupName";

type Recipient = {
  id: string;
  name: string;
  avatarURL?: string | null;
};

export type RecipientEnvelope = {
  me: Recipient | undefined;
  recipients: Recipient[] | undefined;
  excludeID?: string;
};

export const isGroupRecipient = (r: Recipient) =>
  r.id.startsWith("grp_") || r.id.startsWith("wks_");

const sortRecipients = (a: Recipient, b: Recipient) => {
  if (isGroupRecipient(a) && !isGroupRecipient(b)) return -1;
  if (!isGroupRecipient(a) && isGroupRecipient(b)) return 1;
  return 0;
};

/**
 * Given recipients data from a thread, generates a string of
 * names in the format: "You, John Doe, Team Group".
 * The thread starter is excluded unless a direct message.
 * In the context of a group, the excludeID is excluded.
 */

const formatRecipientNames = (
  { me, recipients = [], excludeID }: RecipientEnvelope,
  excludeStarter = true
): (Recipient & { emoji?: string; isGroup?: boolean })[] => {
  const starter = recipients[0];
  if (!starter) return [];

  // First, remove starter and me from the rest
  recipients = recipients
    .slice(1)
    .filter(r => r.id !== me?.id)
    .sort(sortRecipients);

  // We only exclude the starter if its me and someone else,
  // or if there's at least one group recipient to show.
  excludeStarter &&=
    (starter.id === me?.id && recipients.length > 0) ||
    recipients.filter(r => isGroupRecipient(r)).length > 0;

  // Finally remove the excludeID
  recipients = recipients.filter(r => r.id !== excludeID);

  const formatYou = (r: Recipient) => (r.id === me?.id ? "You" : r.name);

  return [
    ...(excludeStarter ? [] : [{ ...starter, name: formatYou(starter) }]),
    ...recipients.map(r => {
      let name = "";
      let emoji: string | undefined;

      const isGroup = isGroupRecipient(r);
      if (isGroup) ({ emoji, name } = formatGroupName(r));
      else name = formatYou(r);

      return {
        id: r.id,
        name,
        avatarURL: r.avatarURL,
        emoji,
        isGroup,
      };
    }),
  ];
};

export default formatRecipientNames;
