import { ComponentProps, useEffect, useState } from "react";

import { ModalProps } from "components/ModalKit/Modal";
import { Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import tw from "utils/tw";

import ComposeDM from "./ComposeDM";

type Props = ModalProps & Omit<ComponentProps<typeof ComposeDM>, "isModal">;

const ComposeDMModal = ({ ...props }: Props) => {
  const [safeAreaPadding, setSafeAreaPadding] = useState(false);
  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          setSafeAreaPadding(keyboardHeight > 100);
        }
      ),
    []
  );

  return (
    <StandardModal
      closeButtonPosition="right"
      closeButtonTitle="Save & close"
      contentClassName="md:!max-w-[800px]"
      {...props}
    >
      <Main
        className={tw({
          "native:!pb-safe-area": !safeAreaPadding,
        })}
      >
        <ComposeDM isModal />
      </Main>
    </StandardModal>
  );
};

export default ComposeDMModal;
