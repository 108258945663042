import Toast from "components/design-system/ui/ToastKit/Toast";
import tw from "utils/tw";

type Props = {
  borderless?: boolean;
  id: string;
} & WithChildren;

const Sidebar = ({ borderless, children, id }: Props) => {
  return (
    <div
      className={tw(
        "bg-background-list",
        "flex flex-col w-full h-full rounded-l-lg relative overflow-hidden",
        { "border-border-container border-r-1": !borderless }
      )}
      id={id}
    >
      {children}

      <Toast />
    </div>
  );
};

export default Sidebar;
