import { ComponentProps } from "react";

import Avatar from "components/design-system/Avatar/Avatar";
import { Icon } from "components/design-system/icons";
import tw from "utils/tw";

import { parameters } from "./profileParameters";
import { ProfileItemProps } from "./types";

type AvatarProps = ComponentProps<typeof Avatar>;

type Props = Pick<
  ProfileItemProps,
  "type" | "id" | "name" | "avatarURL" | "size"
> &
  Pick<AvatarProps, "rounded"> & {
    avatarClassName?: string;
    avatarContainerClassName?: string;
    className?: string;
    emoji?: string;
    emojiClassName?: string;
    extraSize?: "x-large" | "xx-large";
    useAvatar?: boolean;
  };

/**
 * @deprecated This component is deprecated and will be removed in a future release.
 *
 * Find replacement Avatar components in the following locations:
 * - components/design-system/ui/Avatar/Avatar
 * - components/design-system/ui/Avatar/BadgedAvatar
 */
const ProfileAvatar = ({
  avatarClassName,
  avatarContainerClassName,
  avatarURL,
  className,
  emoji,
  emojiClassName,
  extraSize,
  name,
  rounded,
  size = "medium",
  type,
  useAvatar,
}: Props): JSX.Element | null => {
  switch (type) {
    case "Workspace":
    case "WorkspacePreview":
      return (
        <div
          className={tw(
            "flex shrink-0 items-center justify-center",
            parameters[size].avatar.groupClassNames,
            avatarClassName,
            className
          )}
          data-testid="avatar-group-icon"
        >
          {avatarURL && useAvatar ? (
            <Avatar
              avatarURL={avatarURL}
              className={avatarContainerClassName}
              name={name}
              rounded={rounded}
              size={size}
            />
          ) : (
            <Icon
              className="glue-avatar text-interactive-subtle"
              icon="Grid"
              size={parameters[size].avatar.iconSize}
            />
          )}
        </div>
      );
    case "Group":
    case "GroupPreview":
      return (
        <div
          className={tw(
            "glue-avatar flex shrink-0 items-center justify-center",
            parameters[size].avatar.groupClassNames,
            avatarClassName,
            className
          )}
          data-testid="avatar-group-icon"
        >
          {emoji ? (
            <span
              className={tw(parameters[size].emojiClassNames, emojiClassName)}
            >
              {emoji}
            </span>
          ) : (
            <Icon
              className="text-interactive-subtle"
              icon="Users"
              size={parameters[size].avatar.iconSize}
            />
          )}
        </div>
      );
    default:
      return (
        <Avatar
          avatarURL={avatarURL}
          className={className}
          name={name}
          rounded={rounded}
          size={extraSize || size}
        />
      );
  }
};

export default ProfileAvatar;
