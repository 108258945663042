import { ComponentProps, PropsWithChildren } from "react";

import tw from "utils/tw";

import AIThread from "./AIThread";
import Draft from "./Draft";
import { className } from "./styles";
import Thread from "./Thread";
import { SwipeStyleProps } from "./types";

type AIThreadComponentProps = { type: "aiThread" } & Omit<
  ComponentProps<typeof AIThread>,
  "dismissDelay"
>;

type DraftComponentProps = { type: "draft" } & Omit<
  ComponentProps<typeof Draft>,
  "dismissDelay"
>;
type ThreadComponentProps = { type: "thread" } & Omit<
  ComponentProps<typeof Thread>,
  "dismissDelay"
>;
type Props = (
  | { type?: undefined }
  | AIThreadComponentProps
  | DraftComponentProps
  | ThreadComponentProps
) &
  SwipeStyleProps;

/**
 * @param children Content of the item
 * @param setSwipedOpenItemId providing this function wraps swipeable thread actions
 * around the children
 * @param canArchive enables right swipe with dismiss action e.g. delete draft or archive
 */
export default function SwipeAction(
  props: PropsWithChildren<Props>
): JSX.Element | null {
  switch (props.type) {
    case "aiThread":
      return <AIThread {...props} />;
    case "draft":
      return <Draft {...props} />;
    case "thread":
      return <Thread {...props} />;
    default:
      return (
        <div
          className={tw(className.listItem, props.borderRadius || "rounded-md")}
        >
          {props.children}
        </div>
      );
  }
}
