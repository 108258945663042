import { ComponentProps } from "react";
import { devtools, persist } from "zustand/middleware";

import { Recipient } from "@utility-types";
import Icon from "components/design-system/icons/Icon";
import {
  GroupTabs,
  PathMatch,
  ThreadsTabs,
  useRouteParams,
} from "components/routing/utils";

import { glueCreateZustand } from "./helper/glueCreate";
const STORE_NAME = "HistoryStore";

type Primary = keyof typeof PathMatch;

type DefaultHistoryItems = {
  recipient?: Recipient;
  path: string;
  primaryType: Primary;
};

type InboxHistoryItem = DefaultHistoryItems & {
  icon?: ComponentProps<typeof Icon>["icon"];
  primaryType: Extract<Primary, "inbox">;
  title: string;
};

type InboxChatHistoryItem = DefaultHistoryItems & {
  name: string;
  primaryType: Extract<Primary, "inbox">;
};

type InboxUserChatHistoryItem = InboxChatHistoryItem & {
  avatarURL?: string | null;
  chatType: "user";
};

type InboxGroupChatHistoryItem = InboxChatHistoryItem & {
  chatType: "group";
  emoji?: string | null;
};

type DMHistoryItem = Omit<DefaultHistoryItems, "primaryType"> & {
  avatarURL?: string | null;
  name: string;
  primaryType: Extract<Primary, "dms">;
};

type GroupHistoryItem = Omit<DefaultHistoryItems, "primaryType"> & {
  emoji?: string | null;
  isExplore: false;
  name: string;
  primaryType: Extract<Primary, "groups">;
  secondaryType: GroupTabs;
  title: string;
};

type ExploreHistoryItem = Omit<GroupHistoryItem, "emoji" | "isExplore"> & {
  imageURL?: string;
  isExplore: true;
  primaryType: Extract<Primary, "groups">;
};

type ThreadHistoryItem = Omit<DefaultHistoryItems, "primaryType"> & {
  icon?: ComponentProps<typeof Icon>["icon"];
  primaryType: Extract<Primary, "threads">;
  secondaryType?: ThreadsTabs;
  title: string;
};

type AIThreadHistoryItem = Omit<DefaultHistoryItems, "primaryType"> & {
  icon?: ComponentProps<typeof Icon>["icon"];
  primaryType: Extract<Primary, "ai">;
  title: string;
};

export type HistoryItem =
  | DMHistoryItem
  | ExploreHistoryItem
  | GroupHistoryItem
  | InboxHistoryItem
  | InboxGroupChatHistoryItem
  | InboxUserChatHistoryItem
  | ThreadHistoryItem
  | AIThreadHistoryItem;

type HistoryStore = {
  backStack: HistoryItem[];
  forwardStack: HistoryItem[];
  navigatedBack: boolean;
};

export function isInboxChat(
  input: InboxHistoryItem | InboxChatHistoryItem
): input is InboxChatHistoryItem {
  return (input as InboxChatHistoryItem).name !== undefined;
}

const tempState: HistoryStore & {
  navigatedBackPrev: boolean;
  navigatedForward: boolean;
  navigatedForwardPrev: boolean;
  urlDataMap: Map<string, HistoryItem>;
} = {
  backStack: [],
  forwardStack: [],
  navigatedBack: false,
  navigatedBackPrev: false,
  navigatedForward: false,
  navigatedForwardPrev: false,
  urlDataMap: new Map(),
};

export const createHistoryPath = (
  input: Pick<
    ReturnType<typeof useRouteParams>,
    "d" | "v" | "t" | "messageID" | "location"
  >
) => {
  const searchParams = `?${input.d ? `d=${input.d?.split("/")[0]}` : ""}${
    input.v ? `&v=${input.v}` : ""
  }${input.t ? `&t=${input.t}` : ""}`.replace(/^\?&/, "?");

  const path = `${
    input?.messageID
      ? input.location.pathname.replace(`/${input.messageID}`, "")
      : input.location.pathname
  }${searchParams !== "?" ? searchParams : ""}`;

  return path;
};

export const addToHistory = (item: HistoryItem) => {
  const state: HistoryStore = useHistoryStore.getState();
  const backStack = state.backStack.slice();
  const forwardStack = state.forwardStack.slice();

  const {
    navigatedBack,
    navigatedBackPrev,
    navigatedForward,
    navigatedForwardPrev,
  } = tempState;
  tempState.urlDataMap.set(item.path, item);

  const indexBack = backStack.findIndex(({ path }) => path === item.path);
  const newItem = tempState.urlDataMap.get(item.path) || item;

  tempState.navigatedBack = false;
  tempState.navigatedForward = false;
  tempState.navigatedBackPrev = false;
  tempState.navigatedForwardPrev = false;
  tempState.backStack = backStack.slice();
  tempState.forwardStack = forwardStack.slice();

  if (
    (navigatedForwardPrev || navigatedBackPrev) &&
    backStack[0]?.path === item.path
  ) {
    return;
  }

  if (navigatedBack && navigatedBackPrev === false) {
    tempState.navigatedBackPrev = true;
    return;
  }

  if (navigatedForward && navigatedForwardPrev === false) {
    tempState.navigatedForwardPrev = true;
    return;
  }

  if (indexBack === 0) {
    backStack.splice(0, 1, newItem);
    useHistoryStore.setState({
      backStack,
      forwardStack,
    });
    return;
  }

  indexBack > 0 && backStack.splice(indexBack, 1);

  backStack.unshift(newItem);
  backStack.length = Math.min(backStack.length, 12);

  useHistoryStore.setState({
    backStack,
    forwardStack: [],
    navigatedBack: false,
  });
};

export const goBackHistory = (steps: number): HistoryItem | undefined => {
  const state: HistoryStore = useHistoryStore.getState();
  const backStack = state.backStack.slice().reverse();
  const forwardStack = state.forwardStack.slice();

  while (backStack.length > 1 && steps-- > 0) {
    const backItem = backStack.pop();
    backItem && forwardStack.push(backItem);
  }

  backStack.reverse();

  useHistoryStore.setState({
    backStack,
    forwardStack,
    navigatedBack: forwardStack.length !== 0,
  });

  tempState.navigatedBack = true;

  return backStack[0];
};

export const goForwardHistory = (steps: number): HistoryItem | undefined => {
  const state: HistoryStore = useHistoryStore.getState();
  const backStack = state.backStack.slice();
  const forwardStack = state.forwardStack.slice();

  while (forwardStack.length > 0 && steps-- > 0) {
    const forwardItem = forwardStack.pop();
    forwardItem && backStack.unshift(forwardItem);
  }

  useHistoryStore.setState({
    backStack,
    forwardStack,
    navigatedBack: forwardStack.length !== 0,
  });

  tempState.navigatedForward = true;

  return backStack[0];
};

const useHistoryStore = glueCreateZustand<HistoryStore>({
  name: STORE_NAME,
})(
  devtools(
    persist(
      () => ({
        backStack: [],
        forwardStack: [],
        navigatedBack: false,
      }),
      {
        migrate: (s, v) => {
          if (v <= 2) {
            return {
              backStack: [],
              forwardStack: [],
              navigatedBack: false,
            };
          }
          return s as HistoryStore;
        },
        name: STORE_NAME,
        version: 3,
      }
    ),
    {
      name: STORE_NAME,
    }
  )
);

export default useHistoryStore;
