import { useApolloClient } from "@apollo/client";
import {
  PushNotificationSchema,
  PushNotifications,
} from "@capacitor/push-notifications";
import { Badge } from "@capawesome/capacitor-badge";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { readThreadEdge } from "apollo/cache/threadHelpers";
import { routeToGroup, routeToThread } from "components/routing/utils";
import useSidebarCounts from "components/SideBar/hooks/useSidebarCounts";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import { isNative } from "utils/platform";

import useNotificationPermissions from "../../devices/hooks/useNotificationPermissions";
import usePushNotificationToken from "../../devices/hooks/usePushNotificationToken";

export const useNativeDeviceManager = (): void => {
  const apolloClient = useApolloClient();
  const { authData } = useAuthData();
  const history = useHistory();
  const { unreadInboxCount } = useSidebarCounts();
  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));
  const { permissionStatus } = useNotificationPermissions();
  const { tokenInitialized } = usePushNotificationToken();

  // Listen to all notification events
  useEffect(() => {
    if (!isNative()) {
      return;
    }

    const listeners = [
      // PushNotifications.addListener(
      //   "pushNotificationReceived",
      //   ({ id, title, body, data }) => {
      //     console.info(id, title, body, data);
      //   }
      // ),
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        ({ notification: { data } }) => {
          const { target } = data;
          if (typeof target !== "string") return;

          switch (target.split("_")[0]) {
            case "thr":
              const threadPath = routeToThread({
                threadID: target,
                superTab: "inbox",
                to: "primary",
              });

              history.push(threadPath);
              break;
            case "usr":
            case "grp":
            case "wks":
              history.push(routeToGroup({ groupID: target }));
              break;
          }
        }
      ),
    ];

    return () => {
      listeners.forEach(async l => (await l).remove());
    };
  }, [history]);

  // Set badge count and remove notifications after reading
  const badgeCount = unreadInboxCount;
  useEffect(() => {
    if (!isNative()) {
      return;
    }

    const setBadge = async () => {
      if (permissionStatus !== "granted") return;

      Badge.set({ count: badgeCount }).catch(_ => {
        if (badgeCount === 0) {
          // If for some reason setting badge count fails, we can only
          // safely clear the badge by removing all notifications.
          PushNotifications.removeAllDeliveredNotifications();
        }
      });
    };

    setBadge();

    // There's a check on the Capacitor plugin side to make sure that a token
    // has been generated before calling the functions below. If not it will
    // generate a "capacitorDidRegisterForRemoteNotifications not called" error.
    if (!tokenInitialized) return;

    PushNotifications.getDeliveredNotifications().then(({ notifications }) => {
      PushNotifications.removeDeliveredNotifications({
        notifications: notifications
          .map(notification => {
            const target = notification.data?.target;
            if (typeof target !== "string" || !target.match(/^thr_/))
              return null;
            const threadEdgeID = `${target}-${authData?.me.id}`;
            return readThreadEdge(threadEdgeID, apolloClient.cache)?.isRead
              ? notification
              : null;
          })
          .filter((n): n is PushNotificationSchema => n !== null),
      });
    });
  }, [
    appStatus,
    authData,
    badgeCount,
    apolloClient.cache,
    permissionStatus,
    tokenInitialized,
  ]);
};
