import { Icon } from "components/design-system/icons";
import { sidebarHeaderHeight } from "components/SideBar/SidebarHeader/SidebarHeader";
import { FeedType } from "generated/graphql";
import useAppStateStore from "store/useAppStateStore";

export const renderIcon = (type: FeedType) => {
  switch (type) {
    case FeedType.All:
      return "Bell";
    case FeedType.Groups:
      return "Users";
    case FeedType.Mentions:
      return "AtSign";
    case FeedType.Reactions:
      return "ThumbsUp";
    case FeedType.Requests:
      return "Request";
    default:
      throw new Error(`Invalid feed type: ${type}`);
  }
};

export const renderPlaceholder = (type: FeedType) => {
  switch (type) {
    case FeedType.All:
      return {
        primaryText: "No activity… yet!",
        secondaryText: "Stay tuned — new updates will appear here.",
      };
    case FeedType.Groups:
      return {
        primaryText: "No group activity… yet!",
        secondaryText: "You’ll see group updates here when there’s activity.",
      };
    case FeedType.Mentions:
      return {
        primaryText: "No mentions… yet!",
        secondaryText: "You’ll see your mentions here when someone tags you.",
      };
    case FeedType.Reactions:
      return {
        primaryText: "No reactions… yet!",
        secondaryText: "You’ll see reactions here as soon as someone responds.",
      };
    case FeedType.Requests:
      return {
        primaryText: "No requests… yet!",
        secondaryText:
          "You’ll see requests here when someone asks for your approval.",
      };
    default:
      throw new Error(`Invalid feed type: ${type}`);
  }
};

type ActivityEmptyViewProps = {
  type: FeedType;
};

const ActivityEmptyView = ({ type }: ActivityEmptyViewProps) => {
  const { primaryText, secondaryText } = renderPlaceholder(type);

  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <div
      className="flex items-center justify-center h-full px-24 py-32 w-full"
      style={{
        paddingBottom: breakpointMD
          ? `${1.6 * sidebarHeaderHeight}px`
          : undefined,
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <Icon
          className="text-icon-brand w-40 h-40 mb-16"
          icon={renderIcon(type)}
          strokeWidth={2}
        />
        <span className="font-bold text-body">{primaryText}</span>
        <span className="text-text-secondary text-center max-w-325">
          {secondaryText}
        </span>
      </div>
    </div>
  );
};

export default ActivityEmptyView;
