import { ReplyPayload } from "components/AppDrawer/useAppDrawerStore";
import { drawerParams } from "components/routing/utils";

export enum Actions {
  APP = "app",
  JOIN = "join-request",
  DRAFT = "draft",
  REPLY = "reply",
  THREAD = "thread",
  GROUP_PROFILE = "group-profile",
  USER_PROFILE = "user-profile",
}

export type Payload =
  | AppPayload
  | JoinPayload
  | DraftPayload
  | ReplyPayload
  | ThreadPayload
  | GroupProfilePayload
  | UserProfilePayload;

export type AppPayload = {
  action: Actions.APP;
  appID: string;
};

export type DraftPayload = {
  action: Actions.DRAFT;
  threadID: string;
};

export type JoinPayload = {
  action: Actions.JOIN;
  joinID: string;
};

export type ThreadPayload = {
  action: Actions.THREAD;
  messageID?: string;
  threadID?: string;
};

export type GroupProfilePayload = {
  action: Actions.GROUP_PROFILE;
  groupID: string;
};

export type UserProfilePayload = {
  action: Actions.USER_PROFILE;
  userID: string;
};

export function parsePayload(d: string): Payload | undefined {
  if (!d) return;
  const params = drawerParams(d);
  if (params.appID) {
    return {
      ...params,
      action: Actions.APP,
    };
  }

  if (params.userID) {
    return {
      ...params,
      action: Actions.USER_PROFILE,
    };
  }

  if (params.groupID) {
    return {
      ...params,
      action: Actions.GROUP_PROFILE,
    };
  }

  if (params.joinID) {
    return {
      ...params,
      action: Actions.JOIN,
    };
  }

  if (params.threadID?.startsWith("dft")) {
    return {
      ...params,
      threadID: params.threadID,
      action: Actions.DRAFT,
    };
  }

  return {
    ...params,
    action: Actions.THREAD,
  };
}
