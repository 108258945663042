import axios from "axios";
import { User, signInWithCustomToken } from "firebase/auth";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "components/design-system/Button";
import { Form, TextInput } from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import { LoadingSpinner } from "components/design-system/LoadingSpinner";
import FirebaseProxy from "providers/AuthProvider/hooks/useFirebaseAuth/FirebaseProxy";
import env from "utils/processEnv";
import tw from "utils/tw";

import BackButton from "./BackButton";
import { Action } from "./Reducer";

type FormData = {
  code: string;
  email: string;
};

type Error = {
  code: number;
  error: string;
  reason: string;
  remaining?: number;
} | null;

type Props = {
  email: string;
  onSignInSuccess?: (user: User) => void;
  dispatch: Dispatch<Action>;
};

const errorMessage = (error: Error) => {
  if (!error) return;

  const { code, reason, remaining } = error;
  if (code === 401) {
    switch (reason) {
      case "invalid":
        return `Incorrect code. ${remaining} attempts remaining.`;
      case "expired":
        if (remaining === 0) {
          return "Too many incorrect attempts. Account locked for 1 hour";
        }
        return "This code has expired.";
    }
  }

  return "Something went wrong. Please try again.";
};

const FailedVerificationMessage = ({
  email,
  error,
  setError,
  setSubmitting,
}: {
  email?: string;
  error: Error;
  setError: Dispatch<SetStateAction<Error>>;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setFocus, setValue } = useFormContext<FormData>();

  if (error?.reason === "expired" || error?.remaining === 0) {
    return (
      <div className="bg-background-secondary mt-32 px-16 py-12 rounded-md text-footnote text-center">
        Need help? Contact us at{" "}
        <a href="mailto:support@glue.ai" className="text-footnote-bold">
          support@glue.ai
        </a>
      </div>
    );
  }

  if (!email || error?.reason !== "expired") return null;

  const handleAuthStart = () => {
    setValue("code", "");
    setSubmitting(true);
    axios
      .post(`${env.glueApiUrl}/auth/start`, {
        address: email,
      })
      .then(() => {
        setError(null);
        setFocus("code");
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="bg-background-ghost mt-16 p-16 pb-12 rounded">
      Still want to sign in?{" "}
      <Button
        buttonStyle="simpleSecondary"
        buttonType="text"
        className="underline !inline"
        onClick={handleAuthStart}
        type="button"
      >
        Send a new code
      </Button>
      .
    </div>
  );
};

const VerificationCodeInput = ({
  error,
  onSubmit,
  setInputFocus,
  submitting,
  success,
}: {
  error?: Error;
  onSubmit: (data: FormData) => void;
  setInputFocus: Dispatch<SetStateAction<boolean>>;
  submitting: boolean;
  success: boolean;
}) => {
  const { handleSubmit, setFocus, watch } = useFormContext<FormData>();
  const code = watch("code");

  const [lastSubmittedCode, setLastSubmittedCode] = useState<string | null>(
    null
  );

  const focusOnMount = useCallback((el: HTMLInputElement | null) => {
    el?.focus();
  }, []);

  useEffect(() => {
    if (submitting) return;
    setFocus("code");
  }, [setFocus, submitting]);

  useEffect(() => {
    // auto-submit code when 6 digits are entered,
    // but do not submit the same code twice in a row
    if (code?.length === 6 && code !== lastSubmittedCode) {
      setLastSubmittedCode(code);
      handleSubmit(onSubmit)();
    }
  }, [code, handleSubmit, lastSubmittedCode, onSubmit]);

  return (
    <div className="relative">
      <TextInput<FormData>
        testId="verification-code-input"
        autoComplete="one-time-code"
        className={tw(
          "text-xxl leading-8 text-center tracking-widest !py-8 uppercase",
          "border-border-container bg-background-body focus:border-border-active"
        )}
        disabled={error?.reason === "expired" || submitting}
        error={error ? errorMessage(error) : undefined}
        inputRef={focusOnMount}
        maxLength={6}
        name="code"
        onBlur={() => setInputFocus(false)}
        onFocus={() => setInputFocus(true)}
        placeholder="A12 3B4"
        wrapperClassName="!m-0"
        data-1p-ignore
      />
      <LoadingSpinner
        className={tw(
          "absolute top-20 right-10 w-18 h-18 text-accent-primary animate-spin transition-opacity",
          submitting && !success ? "opacity-100" : "opacity-0"
        )}
      />
      <Icon
        className={tw(
          "absolute top-20 right-10 w-18 h-18 text-accent-success transition-opacity",
          success ? "opacity-100" : "opacity-0"
        )}
        icon="Check"
        size={18}
        strokeWidth={3}
      />
    </div>
  );
};

const VerificationCode = ({ email, onSignInSuccess, dispatch }: Props) => {
  const auth = FirebaseProxy.auth;

  const [error, setError] = useState<Error>(null);
  const [inputFocus, setInputFocus] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleFormSubmit = useCallback(
    (data: FormData) => {
      const code = data.code.replace(/ /g, "").toUpperCase(); // remove spaces, convert to uppercase
      if (code.length !== 6 || !inputFocus) return;

      setSubmitting(true);

      axios
        .post(`${env.glueApiUrl}/auth/verify`, {
          address: email ?? "",
          code,
        })
        .then(({ data }) => {
          setSuccess(true);
          setTimeout(() => {
            signInWithCustomToken(auth, data.token)
              .then(({ user }) => {
                onSignInSuccess?.(user);
              })
              .catch(() => {
                setSubmitting(false);
              });
          }, 500);
        })
        .catch(error => {
          setError(
            error.response?.data ?? {
              code: 500,
              error: "unknown",
              reason: "unknown",
            }
          );
          setSubmitting(false);
        });
    },
    [auth, email, inputFocus, onSignInSuccess]
  );

  const codeExpired = error?.reason === "expired" && (error.remaining ?? 0) > 0;
  const handleResendCode = () => {
    setSubmitting(true);
    axios
      .post(`${env.glueApiUrl}/auth/start`, {
        address: email,
      })
      .then(() => setError(null))
      .catch(error => console.info(error))
      .finally(() => setSubmitting(false));
  };

  return (
    <Form
      className="flex flex-col md:justify-center mx-auto max-w-full w-400 pt-0 md:pt-[68px] text-left h-full"
      onSubmit={handleFormSubmit}
      useFormProps={{
        defaultValues: { code: "" },
      }}
    >
      <div className="flex flex-col mb-32">
        <span className="text-title-3">Check your email.</span>
        <span className="text-headline text-text-secondary">
          An email with a verification code was sent to
          <br />
          <strong className="text-headline-bold">{email ?? ""}</strong>
        </span>
      </div>
      <VerificationCodeInput
        error={error}
        onSubmit={handleFormSubmit}
        setInputFocus={setInputFocus}
        submitting={submitting}
        success={success}
      />
      <FailedVerificationMessage
        email={email}
        error={error}
        setError={setError}
        setSubmitting={setSubmitting}
      />
      <div className="grow" />
      <div className="mt-[38px] mb-32 md:mb-0 flex items-center justify-between">
        <BackButton
          disabled={submitting}
          onClick={() => dispatch({ type: "SignIn", email })}
        />
        <Button
          className={tw({
            "text-subhead-bold text-text-subtle hover:text-text-subtle-hover":
              !codeExpired,
          })}
          buttonStyle={codeExpired ? "primary" : "subtle"}
          buttonType={codeExpired ? "default" : "text"}
          onClick={() => {
            codeExpired
              ? handleResendCode()
              : dispatch({ type: "ResendEmail", email });
          }}
          type="button"
        >
          {codeExpired ? "Send new code" : "Didn't get the email?"}
        </Button>
      </div>
    </Form>
  );
};

export default VerificationCode;
