import { DetailedHTMLProps, HTMLAttributes } from "react";
import type { FlatEmoji } from "svgmoji";

import tw from "utils/tw";

type HTMLDivElementWithRef = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type Props = {
  containerProps?: HTMLDivElementWithRef;
  emojis?: FlatEmoji[];
  itemProps?: (index: number, emoji: FlatEmoji) => HTMLDivElementWithRef;
  selectedIndex?: number;
};

const EmojiList = ({
  containerProps,
  emojis = [],
  itemProps,
  selectedIndex,
}: Props) => {
  const { className: containerClassName, ...rest } = containerProps || {};
  return (
    <div
      {...rest}
      className={tw("overflow-y-auto max-h-320", containerClassName)}
    >
      {emojis.map((emoji, index) => (
        <div
          key={emoji.hexcode}
          {...itemProps?.(index, emoji)}
          className={tw(
            "flex items-center py-5 px-10 hover:bg-background-body-hover cursor-pointer focus-visible:bg-background-body-hover outline-none",
            selectedIndex === index && "bg-background-body-hover"
          )}
        >
          <span className="w-24 text-2xl">{emoji.emoji}</span>
          <span className="ml-10">
            :{emoji.shortcodes?.[0] ?? emoji.annotation}:
          </span>
        </div>
      ))}
    </div>
  );
};

export default EmojiList;
