import { format, isToday, isTomorrow } from "date-fns";
import { ComponentProps, useState } from "react";
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";

import Calendar from "components/design-system/Calendar/Calendar";

import { Dropdown } from "../FloatingUi";
import { Icon } from "../icons";

type Props<T extends FieldValues, TName extends Path<T> = Path<T>> = {
  name: TName;
  placeholder: string;
  calendarProps?: ComponentProps<typeof Calendar>;
};

const DatePicker = <T extends FieldValues>({
  name,
  placeholder = "Select a date",
  calendarProps,
}: Props<T>) => {
  const [open, setOpen] = useState(false);

  const { control } = useFormContext<T>();
  const {
    field: { value: date, onChange },
  } = useController<T>({ name, control });

  const formatDisplayedDate = () => {
    if (isToday(date)) return "Today";
    if (isTomorrow(date)) return "Tomorrow";
    return format(date, "MMMM d, yyyy");
  };

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      content={
        <Calendar
          {...calendarProps}
          mode="single"
          onSelect={date => {
            if (!date) return;
            onChange(date);
            setOpen(false);
          }}
          selected={date}
        />
      }
    >
      <div className="flex items-center p-8 rounded-md border-1 border-border-container cursor-pointer gap-4 w-full h-40">
        <Icon className="text-icon-secondary" icon="Calendar" size={20} />
        {date ? (
          <span className="flex grow text-text-primary ml-4">
            {formatDisplayedDate()}
          </span>
        ) : (
          <span className="flex grow text-text-subtle ml-4">{placeholder}</span>
        )}
        <Icon className="text-icon-secondary" icon="DropDown" size={20} />
      </div>
    </Dropdown>
  );
};

export default DatePicker;
