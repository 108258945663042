import { SVGAttributes } from "react";

import tw from "utils/tw";

import * as Icons from "./all-icons";

export type IconName = keyof typeof Icons;

export interface IconProps extends SVGAttributes<SVGElement> {
  color?: string;
  size?: string | number;
}

type Props = {
  inline?: boolean;
  icon: IconName | React.FunctionComponent<IconProps>;
  size?: number | string;
  strokeWidth?: number | string;
} & React.SVGProps<SVGSVGElement>;

export const Icon = ({
  className = "",
  icon,
  inline = false,
  size = 24,
  strokeWidth = 1.5,
  ...props
}: Props) => {
  const { style = {}, ...restProps } = props;
  const IconComponent = typeof icon === "string" ? Icons[icon] : icon;
  return (
    <IconComponent
      className={tw(`glue-icon select-none shrink-0 ${className}`, {
        "inline-block": inline,
      })}
      style={{
        verticalAlign: inline ? "-0.2em" : undefined,
        ...style,
      }}
      height={size}
      strokeWidth={strokeWidth}
      title={typeof icon === "string" ? icon : undefined}
      width={size}
      {...restProps}
    />
  );
};

export default Icon;
