import { differenceInDays } from "date-fns";

import useNotificationsPermissions from "components/devices/hooks/useNotificationPermissions";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import useModalStore from "store/useModalStore";

import NotificationsPermissionsModal from "../NotificationsPermissionsModal";

const useRequestPermission = () => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const { checkPermissions } = useNotificationsPermissions();

  const { notificationAskedAt: askedAt = 0 } = useLocalSettingsStore(
    ({ notificationAskedAt }) => ({ notificationAskedAt })
  );

  const requestPermission = async () => {
    const permissions = await checkPermissions();
    if (permissions !== "prompt") return;
    const daysSince = differenceInDays(new Date(), new Date(askedAt));
    if (daysSince >= 7)
      openModal(<NotificationsPermissionsModal textKey="reAsk" isPersistent />);
  };

  return { requestPermission };
};

export default useRequestPermission;
