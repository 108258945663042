import { find } from "lodash-es";
import { useMemo } from "react";

import { useDraftListQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

const useThreadDrafts = ({ threadID }: { threadID?: string }) => {
  const { authData, authReady } = useAuthData();

  const { data } = useDraftListQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    nextFetchPolicy: "cache-only",
    skip: !authData?.me.id,
  });

  const drafts = useMemo(
    () =>
      data?.drafts?.edges.filter(
        ({ node }) => node.replyToMessage?.threadID === threadID
      ) || undefined,
    [data, threadID]
  );

  const replyDraft = useMemo(
    () =>
      find(
        data?.drafts?.edges,
        ({ node }) => node.replyToMessage?.replyThreadID === threadID
      ) || undefined,
    [data, threadID]
  );

  return { drafts, replyDraft };
};

export default useThreadDrafts;
