import { useState } from "react";
import Select from "react-select";

import useOnce from "hooks/useOnce";
import useModalStore from "store/useModalStore";

import { BlockComponentProps } from "./BlockComponent";
import BlockKitConfirm from "./BlockConfirm";
import { formatOptionLabel } from "./BlockSelectOption";
import { Option, StaticSelectControl } from "./blockTypes";

type BlockStaticSelectProps = BlockComponentProps & {
  block: StaticSelectControl;
};

const recipientSelectStubs = {
  getContactsPermission: (): Promise<boolean> => {
    throw new Error("Function not implemented.");
  },
  onSelectRecipient: (): void => {
    throw new Error("Function not implemented.");
  },
  searchResults: {
    instantResults: [],
    searching: false,
    totalCount: undefined,
  },
  valueFixed: (): boolean => {
    throw new Error("Function not implemented.");
  },
};

const BlockStaticSelect = ({
  block,
  handlers: { onAction, onValueChanged },
}: BlockStaticSelectProps) => {
  const [selectedOption, setSelectedOption] = useState(
    block.initialOption || null
  );
  const { openModal } = useModalStore(({ closeModal, openModal }) => ({
    closeModal,
    openModal,
  }));

  useOnce(() => {
    if (block.initialOption) {
      onValueChanged(block, block.initialOption.value);
    }
  });

  const handleSelection = (newOption: Option | null) => {
    if (block.confirm && newOption) {
      // Open confirmation modal before setting the new option
      openModal(
        <BlockKitConfirm
          confirm={block.confirm}
          onConfirm={() => handleConfirm(newOption)} // Handle confirmation here
        />
      );
    } else {
      handleConfirm(newOption); // Directly handle confirmation if no modal is required
    }
  };

  const handleConfirm = (option: Option | null) => {
    if (option) {
      setSelectedOption(option); // Set the selected option only after confirmation
      onValueChanged(block, option.value);
      onAction(block, option.value);
    }
  };

  return (
    <div className="min-w-200 flex flex-col">
      <Select
        {...recipientSelectStubs}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={option => option.text.text}
        getOptionValue={option => option.value}
        menuPortalTarget={document.body}
        onChange={handleSelection}
        options={block.options}
        placeholder={block.placeholder?.text}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        value={selectedOption} // Bind the Select component to the selected option state
      />
    </div>
  );
};

export default BlockStaticSelect;
