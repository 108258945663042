import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";

import { Button } from "components/design-system/Button";
import {
  MemberRole,
  SlackImportDocument,
  SlackImportQuery,
  useFetchWorkspaceOrPreviewEdgeLazyQuery,
  useSlackImportLazyQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

import { Dropdown } from "../../FloatingUi";

import CreateMenuContent, { SlackImportWorkspace } from "./CreateMenuContent";

const CreateMenuButton = () => {
  const [open, setOpen] = useState(false);
  const apolloClient = useApolloClient();
  const [slackImportWorkspaces, setSlackImportWorkspaces] = useState<
    SlackImportWorkspace[] | undefined
  >();
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authData, authReady } = useAuthData();

  const [fetchWorkspaceOrPreviewEdge] =
    useFetchWorkspaceOrPreviewEdgeLazyQuery();

  const [fetchSlackImport] = useSlackImportLazyQuery();

  useEffect(() => {
    const fetchSlackImports = async () => {
      if (!authData || !authReady || !!slackImportWorkspaces) {
        return;
      }

      const workspaces = authData.workspaces.edges;
      const slackStatuses: SlackImportWorkspace[] = [];
      for (const workspace of workspaces) {
        const isAdmin = workspace.memberRole === MemberRole.Admin;
        if (!isAdmin) {
          continue;
        }

        const { data } = await apolloClient.query<SlackImportQuery>({
          fetchPolicy: "no-cache",
          query: SlackImportDocument,
          variables: { workspaceID: workspace.node.id },
        });

        slackStatuses.push({
          id: workspace.node.id,
          status: data?.slackImport?.status,
        });
      }

      setSlackImportWorkspaces(slackStatuses);
    };

    fetchSlackImports();
  }, [
    fetchSlackImport,
    fetchWorkspaceOrPreviewEdge,
    authData,
    slackImportWorkspaces,
    authReady,
    apolloClient,
  ]);

  if (!breakpointMD) {
    return null;
  }

  return (
    <Dropdown
      content={
        <CreateMenuContent
          setOpen={setOpen}
          breakpointMD={breakpointMD}
          slackImportWorkspaces={slackImportWorkspaces}
        />
      }
      className="rounded-xl min-w-400"
      modalContentHandlesSafeArea={false}
      heightAuto={true}
      height="half"
      placement="bottom"
      open={open}
      offsetMain={-56}
      position={{ left: 72 }}
      setOpen={setOpen}
      onStart={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="flex items-center justify-center h-44 w-44">
        <Button
          buttonStyle="none"
          buttonFont="normal"
          className={tw(
            "justify-center px-16 py-8 rounded-full shrink-0 text-text-action-inverse z-1",
            "h-44 w-44",
            "relative right-auto bottom-auto",
            "transition-all duration-300 ease-in-out",
            "hover:bg-background-action-hover active:h-40 active:w-40",
            open ? "bg-background-action-hover" : "bg-background-action"
          )}
          icon="Plus"
          iconSize={24}
          iconStroke={1.5}
          iconClassName="transition-all rotate-0"
          iconStyle={{
            transition: "transform .3s cubic-bezier(.34,1.56,.64,1)",
            "--tw-rotate": open ? "135deg" : undefined,
          }}
          type="button"
        />
      </div>
    </Dropdown>
  );
};

export default CreateMenuButton;
