import {
  useFetchThreadMetadataQuery,
  useUserSettingsQuery,
} from "generated/graphql";

export type UseLlmModelResult = {
  defaultModel?: string;
  defaultModelLoading?: boolean;
};

const useLlmModel = (threadID = ""): UseLlmModelResult => {
  const { data: userSettings } = useUserSettingsQuery({
    fetchPolicy: "cache-and-network",
  });

  const { data: threadMetadata } = useFetchThreadMetadataQuery({
    fetchPolicy: "cache-and-network",
    skip: !threadID,
    variables: { threadID },
  });

  return {
    defaultModel:
      threadMetadata?.threadMetadata?.aiSettings?.chatModel ||
      userSettings?.settings.llmSettings.chatModel,
    defaultModelLoading: !threadMetadata,
  };
};

export default useLlmModel;
