import { useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";

import { LinksCategory } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Tooltip } from "components/design-system/FloatingUi";
import { Icon } from "components/design-system/icons";
import FilePreview, {
  FilePreviewRef,
} from "components/FilePreview/FilePreview";
import { FileIcon } from "components/helper";
import { GroupTabs, tabPath } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import { FileFieldsFragment, useLinksQuery } from "generated/graphql";

import { useSharedFilterState } from "./providers/SharedFilterProvider";
import { SharedFilters } from "./types";

type ListItemProps = {
  avatarURL?: string;
  file?: FileFieldsFragment;
  id: string;
  mimeType?: string;
  name: string;
  onClick?: () => void;
  tooltip: string;
  url?: string;
  type: "file" | "link";
};

const ListItem = ({
  avatarURL,
  id,
  mimeType,
  name,
  onClick,
  tooltip,
  url,
  type,
}: ListItemProps) => (
  <Tooltip content={tooltip} tooltipStyle="inverted">
    <a
      key={id}
      className="h-32 flex items-center pl-4 rounded-md hover:bg-background-body-hover cursor-pointer"
      href={url}
      target={type === "link" ? "_blank" : undefined}
      rel={type === "link" ? "noopener noreferrer" : undefined}
      onClick={
        onClick
          ? e => {
              e.preventDefault();
              onClick();
            }
          : undefined
      }
      {...(type === "file" && { download: true })}
    >
      {type === "link" &&
        (avatarURL ? (
          <Avatar
            className="mx-2"
            size="tiny"
            background="transparent"
            avatarURL={avatarURL}
          />
        ) : (
          <Icon icon="Link" className="text-icon-secondary mx-2" size={16} />
        ))}
      {mimeType && (
        <div className="flex items-center justify-center min-w-16 mx-2">
          <FileIcon mimeType={mimeType} iconSize={16} strokeWidth={1} />
        </div>
      )}
      <span className="ml-4 text-subhead truncate">{name}</span>
    </a>
  </Tooltip>
);

const Skeletons = () => (
  <div className="h-32 flex items-center pl-4 rounded-md">
    <Skeleton className="mx-2" height="16px" width="16px" />
    <Skeleton className="ml-4" height="20px" width="130px" />
  </div>
);

const EmptyState = () => {
  return (
    <div className="pl-12 pr-16 pb-8">
      <span className="text-subhead italic text-text-secondary ml-4">
        No recently shared items.
      </span>
    </div>
  );
};

const recentlySharedLimit = 5;
const RecentlySharedLinksFiles = ({ groupID }: { groupID: string }) => {
  const filePreviewRef = useRef<FilePreviewRef>(null);
  const history = useHistory();
  const { setState: setSharedFilterState } = useSharedFilterState();

  const { data } = useLinksQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        recipientIDs: [groupID],
        linkedCategories: [LinksCategory.File, LinksCategory.Link],
      },
      last: recentlySharedLimit - 1,
    },
  });

  const edges = data?.links.edges.map(e => e.node.linked).reverse();

  const { processedLinks, previewFiles } = useMemo(() => {
    const processedLinks: ListItemProps[] = [];
    const previewFiles: { id: string; name: string; url: string }[] = [];

    edges?.forEach(e => {
      switch (e.__typename) {
        case "ExternalObject":
          processedLinks.push({
            avatarURL: e.icon?.url ?? "",
            id: e.id,
            name: e.title,
            tooltip: e.url,
            url: e.url,
            type: "link",
          });
          break;
        case "File": {
          processedLinks.push({
            file: e,
            id: e.id,
            mimeType: e.contentType,
            name: e.name,
            url: e.url,
            tooltip: e.name,
            onClick: e.previewable
              ? () =>
                  filePreviewRef.current?.openPreview({
                    id: e.id,
                    name: e.name,
                    url: e.url,
                  })
              : undefined,
            type: "file",
          });

          if (e.previewable) {
            previewFiles.push({
              id: e.id,
              name: e.name,
              url: e.url,
            });
          }
          break;
        }
      }
    });

    return {
      processedLinks: processedLinks.slice(0, recentlySharedLimit),
      previewFiles,
    };
  }, [edges]);

  return (
    <div className="bg-background-body rounded-lg shadow-level1 min-w-[230px]">
      <div className="flex items-center justify-between h-38 px-16 pt-12 pb-8 text-footnote-bold select-none">
        Recently shared
        <Button
          buttonStyle="icon-secondary"
          buttonType="icon"
          icon="ArrowRight"
          iconSize={16}
          onClick={() => {
            setSharedFilterState({ filter: SharedFilters.Recent });
            history.push(tabPath(GroupTabs.Shared));
          }}
        />
      </div>
      {!data ? (
        <div className="pl-12 pr-16 pb-8">
          {cloneElementForSkeletons(<Skeletons />, recentlySharedLimit)}
        </div>
      ) : (data?.links.totalCount ?? 0) > 0 ? (
        <>
          <FilePreview ref={filePreviewRef} files={previewFiles} />
          <ul className="pl-12 pr-16 pb-8">
            {processedLinks.map((linkedItem, index) => (
              // Using index as temporary workaround while waiting for API to return unique results
              <ListItem key={`${linkedItem.id}-${index}`} {...linkedItem} />
            ))}
          </ul>
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default RecentlySharedLinksFiles;
