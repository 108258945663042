import { animated } from "@react-spring/web";
import { useHistory } from "react-router-dom";

import Button from "components/design-system/Button/Button";
import Icon from "components/design-system/icons/Icon";
import useToastStore, {
  ToastType,
} from "components/design-system/ui/ToastKit/useToastStore";
import { useRouteParams } from "components/routing/utils";
import { useDraftsUtils } from "components/views/inbox/InboxMain/hooks";
import { DraftListDocument, useDeleteDraftMutation } from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import useDraftMessagesStore from "store/useDraftMessagesStore";
import { formatDate } from "utils/formatDate";
import tw from "utils/tw";

import { useSwipe } from "./hooks/useSwipe";
import { className } from "./styles";
import { DraftSwipeProps, SwipeStyleProps } from "./types";

const RIGHT_LIMIT = 120;
const LEFT_LIMIT = -130;

const Draft = ({
  actionBarPadding,
  borderRadius,
  children,
  dismissDelay = 200,
  height,
  leftActionPadding,
  setSwipedOpenItemId,
  swipedOpenItemId,
  itemData,
}: DraftSwipeProps & SwipeStyleProps): JSX.Element | null => {
  const history = useHistory();
  const { evictNode } = useCacheEvict();
  const { superTab, threadID: draftID } = useRouteParams();

  const { data, handleUndo } = useDraftsUtils({ pageSize: 1 });

  const [deleteDraft] = useDeleteDraftMutation();

  const { openToast } = useToastStore(({ openToast }) => ({ openToast }));
  const { removeDraft } = useDraftMessagesStore(({ removeDraft }) => ({
    removeDraft,
  }));

  const reversedDrafts = data?.drafts.edges.slice().reverse() ?? [];

  const handleDeleteDraft = () => {
    deleteDraft({
      variables: { id: itemData.node.id },
      refetchQueries: [DraftListDocument],
      update: c => evictNode(itemData, c),
    }).then(() => {
      removeDraft({ draftID: itemData.node.id });
      openToast({
        content: "Draft deleted",
        dismiss: 5000,
        icon: "Trash",
        type: ToastType.DRAFT,
        undo: () => handleUndo(itemData.node.id),
      });
      if (draftID === itemData.node.id && reversedDrafts.length === 1)
        history.push(`/${superTab || "inbox"}`);
    });
  };

  const { bind, pos, swipeState, x } = useSwipe({
    rightSwipe: true,
    rightSwipeAndDismiss: () => {
      setTimeout(() => {
        handleDeleteDraft();
        // TODO: add analytics
        // analytics.track(ThreadArchivedUnarchived, {
        //   archived: !isArchived,
        //   threadId,
        //   uiOrigin: ThreadActionOrigin.SwipedListItem,
        // });
      }, dismissDelay);
      return true;
    },
    leftLimit: LEFT_LIMIT,
    rightLimit: RIGHT_LIMIT,
    children,
    setSwipedOpenItemId,
    swipedOpenItemId,
    threadID: itemData.node.id,
  });

  return (
    <div className={tw(className.listItem, height, borderRadius)}>
      <div className={tw(className.actionBar, actionBarPadding, borderRadius)}>
        <div
          className={tw(
            className.actionLeft,
            "bg-background-list-delete !text-text-inverse",
            {
              hidden: swipeState !== "right",
            },
            {
              "bg-background-list-delete-hover !max-w-full": pos > RIGHT_LIMIT,
            },
            leftActionPadding,
            borderRadius
          )}
        >
          <div className="flex items-center">
            <Icon icon="Trash" className="mr-4" />
            Delete
          </div>
        </div>

        <div
          className={tw(
            className.actionRight,
            {
              hidden: swipeState !== "left",
            },
            borderRadius
          )}
        >
          <span className={className.date}>
            {formatDate(new Date(itemData.node.updatedAt || new Date()))}
          </span>
          <Button
            buttonStyle="none"
            className="w-40 !p-0 flex justify-center items-center text-text-action-inverse"
            icon="Trash"
            iconClassName="w-24 h-24"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleDeleteDraft();
            }}
          />
        </div>
      </div>
      <animated.div
        {...bind()}
        className={tw(className.swipeElement, borderRadius)}
        data-testid="thread-list-item-swipeable"
        style={{ x: pos === 0 ? 0 : x }}
      >
        {children}
      </animated.div>
    </div>
  );
};

export default Draft;
