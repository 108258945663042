import { HeaderButton } from "components/design-system/Button";
import useAppStateStore from "store/useAppStateStore";

type Props = {
  isFollowed: boolean;
  onClick: () => void;
};

export const GroupNotificationsButton = ({ isFollowed, onClick }: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <HeaderButton
      icon={isFollowed ? "BellFilled" : "Bell"}
      onClick={onClick}
      tooltip="View notification settings"
    >
      {isFollowed && breakpointMD ? "Everything" : null}
    </HeaderButton>
  );
};
