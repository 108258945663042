import { matchPath } from "react-router";

import AppTabLayout from "components/App/AppLayoutMobile/AppTabLayout";
import NewThreadButton from "components/design-system/ui/NewThreadButton";
import MobileProfilePane from "components/design-system/ui/ProfilePane/MobileProfilePane";
import SidebarAccessoryContainer from "components/design-system/ui/SidebarAccessoryContainer";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  GroupTabs,
  PathMatch,
  PathnameParams,
  TabName,
  routeParams as getRouteParams,
  locationFromRoute,
} from "components/routing/utils";
import WorkspacesList from "components/SideBar/Groups/WorkspacesList";
import DirectoryButton from "components/SideBar/SidebarHeader/DirectoryButton";
import ThreadPane from "components/threads/ThreadPane";
import Group from "components/views/groups/Group";
import GroupsDirectory from "components/views/groups/GroupsDirectory/GroupsDirectory";

const GroupsMobileMain = ({
  scrollRef,
}: {
  scrollRef: (el: HTMLDivElement | null) => void;
}) => {
  return (
    <ViewScrollContainer className="pt-8 px-4 md:px-8" scrollRef={scrollRef}>
      <WorkspacesList skipAutoSelect />
    </ViewScrollContainer>
  );
};

const GroupsMobile = ({
  scrollRef,
}: {
  scrollRef: (el: HTMLDivElement | null) => void;
}) => {
  const { route } = usePartitionState(({ route }) => ({ route }));
  const { pathname, search } = locationFromRoute(route);
  const { d, messageID, recipientID, t, threadID, v } = getRouteParams({
    pathname,
    search,
  });

  const groupParams = matchPath<PathnameParams>(
    pathname,
    PathMatch.groups
  )?.params;

  const detailContent: React.ReactNode = (() => {
    if (d) {
      return <MobileProfilePane id={d} />;
    }

    let content: React.ReactNode = recipientID ? (
      <Group
        defaultTab={GroupTabs.Chat}
        recipientID={recipientID}
        {...groupParams}
      />
    ) : null;

    if (threadID && t !== GroupTabs.Chat) {
      content = <ThreadPane messageID={messageID} threadID={threadID} />;
    }

    if (v?.match(/directory(\w+)?/)) {
      content = <GroupsDirectory />;
    }

    return content;
  })();

  return (
    <AppTabLayout
      buttons={
        <DirectoryButton
          buttonStyle="action-inverse"
          className="flex items-center justify-center h-44 w-40"
        />
      }
      detailChildren={detailContent}
      name={TabName.Groups}
    >
      <div className="flex flex-col grow h-full min-h-0 relative w-full">
        <GroupsMobileMain scrollRef={scrollRef} />
      </div>

      <SidebarAccessoryContainer>
        <NewThreadButton />
      </SidebarAccessoryContainer>
    </AppTabLayout>
  );
};

export default GroupsMobile;
