import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import { Route as ReactRoute, Router as ReactRouter } from "react-router-dom";

import analytics from "analytics";

export const history = createBrowserHistory();

let previousPath: string;
let previousSearch: string;
history.listen(location => {
  if (
    location.pathname !== previousPath ||
    location.search !== previousSearch
  ) {
    previousPath = location.pathname;
    previousSearch = location.search;
    analytics.page();
  }
});

export const Route = Sentry.withSentryRouting(ReactRoute);

export const Router = ({ children }: WithChildren): JSX.Element => (
  <ReactRouter history={history}>{children}</ReactRouter>
);
