import { useCallback, useEffect } from "react";

import { nodeAs } from "@utility-types";
import MyFeedImg from "assets/icons-jpg/my-feed.jpg";
import {
  GroupTabs,
  type ThreadsTabs,
  useRouteParams,
} from "components/routing/utils";
import { ThreadRecipientEdge } from "generated/graphql";
import { addToHistory } from "store/useHistoryStore";
import { formatGroupName } from "utils/group/formatGroupName";
import { isGroupRecipient } from "utils/thread/formatRecipientNames";

import useAuthData from "./useAuthData";

type UseHistoryItemProps = {
  recipientsEdges?: ThreadRecipientEdge[];
  title?: string;
  isPersistentChat?: boolean;
};

/**
 * @summary Adds items to the history store as users navigate through the app.
 */
const useHistoryItem = ({
  recipientsEdges,
  title,
  isPersistentChat,
}: UseHistoryItemProps = {}) => {
  const { authData } = useAuthData();

  const {
    d: drawer,
    t: tab,
    location: { pathname: path, search },
  } = useRouteParams();

  const addThreadToHistory = useCallback(() => {
    if (!title) return;
    return addToHistory({
      icon: "Thread",
      path: drawer ? `${path}${search}` : path,
      primaryType: "threads",
      secondaryType: tab as ThreadsTabs,
      title: title,
    });
  }, [drawer, path, search, tab, title]);

  useEffect(() => {
    // support dynamic drawers
    if (drawer) {
      // thread drawers
      if (drawer.startsWith("thr_")) return addThreadToHistory();
      // any other drawers
      return;
    }

    // Glue AI Threads
    if (path.startsWith("/ai/thr_")) {
      if (!title) return;
      return addToHistory({
        icon: "SparkleFilled",
        path,
        primaryType: "ai",
        title: title,
      });
    }

    // Drafts
    if (path.startsWith("/inbox/dft_")) {
      if (!title) return;
      return addToHistory({
        icon: "Edit",
        path,
        primaryType: "inbox",
        title: title,
      });
    }

    // Feed
    if (path === "/feed") {
      return addToHistory({
        imageURL: MyFeedImg,
        isExplore: true,
        name: "My Feed",
        path,
        primaryType: "groups",
        secondaryType: GroupTabs.Feed,
        title: "My Feed",
      });
    }

    // Groups/Workspaces
    if (
      path.startsWith("/activity/grp_") ||
      path.startsWith("/activity/wks_") ||
      path.startsWith("/inbox/grp_") ||
      path.startsWith("/inbox/wks_") ||
      path.includes("/grp_") ||
      path.includes("/wks_")
    ) {
      if (!recipientsEdges?.length) return;

      const group = recipientsEdges[0]?.node;
      if (!group || !isGroupRecipient(group)) return;

      const { emoji, name } = formatGroupName(group);
      return addToHistory({
        chatType: "group",
        emoji,
        name,
        path,
        primaryType: "inbox",
        recipient: group,
      });
    }

    // Threads
    if (
      !isPersistentChat &&
      (path.startsWith("/activity/thr_") || path.includes("/thr_"))
    ) {
      return addThreadToHistory();
    }

    // DMs
    if (
      path.includes("/usr_") ||
      path.startsWith("/activity/usr_") ||
      (path.startsWith("/inbox/thr_") && isPersistentChat)
    ) {
      const node =
        recipientsEdges?.length === 1
          ? recipientsEdges?.[0]?.node // support DMs with yourself
          : recipientsEdges?.find(u => u.node.id !== authData?.me.id)?.node;

      const userNode = nodeAs(node, ["User"]);
      if (!userNode) return;

      return addToHistory({
        avatarURL: userNode.avatarURL,
        chatType: "user",
        name: userNode.name,
        path,
        primaryType: "inbox",
        recipient: userNode,
      });
    }
  }, [
    addThreadToHistory,
    authData?.me.id,
    drawer,
    isPersistentChat,
    path,
    recipientsEdges,
    tab,
    title,
  ]);
};

export default useHistoryItem;
