import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Card } from "components/design-system/Card";
import Hr from "components/design-system/Hr";
import { Skeleton } from "components/Skeleton";
import {
  WorkspaceModal,
  WorkspaceModalTabs,
} from "components/workspace/WorkspaceModal";
import type { AppFieldsFragment } from "generated/graphql";
import { RenderMarkdown } from "md";
import useModalStore from "store/useModalStore";
import getRandomInt from "utils/getRandomInt";

import useWorkspaceApps from "../InboxMain/hooks/useWorkspaceApps";

const InboxAppCard = ({ app }: { app?: AppFieldsFragment }) => {
  const { apps, workspaces } = useWorkspaceApps();

  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  return (
    <Card className="mx-auto my-16 w-[600px] max-w-full">
      <div className="flex items-center px-16">
        <div className="flex items-center justify-center h-40 w-40 my-16 mr-8">
          <Avatar avatarURL={app?.avatarURL} name={app?.name} size="large" />
        </div>
        <div className="flex flex-col grow min-w-0">
          <div className="text-subhead-bold text-text-primary">{app?.name}</div>
          {app?.description && (
            <div className="text-footnote text-text-subtle">
              {RenderMarkdown(app?.description)}
            </div>
          )}
        </div>
        {app?.homeURL && (
          <Button
            buttonStyle="subtle"
            icon="ExternalLink"
            iconSize={20}
            onClick={() => {
              window.open(app?.homeURL, "_blank");
            }}
            type="button"
          />
        )}
      </div>

      <Hr className="w-full !border-background-subtle" />

      {app?.instructions && (
        <>
          <div className="flex flex-col p-16">
            {RenderMarkdown(app?.instructions)}
          </div>

          <Hr className="w-full !border-background-subtle" />
        </>
      )}

      <div className="flex flex-col p-16 w-full">
        <div className="text-body-bold text-text-subtle">Installed in:</div>
        {app?.id ? (
          apps.get(app?.id)?.workspaceIDs.map(workspaceID => (
            <div key={workspaceID} className="flex mt-10">
              <div className="flex items-center grow min-w-0">
                <Avatar
                  avatarURL={workspaces.get(workspaceID)?.avatarURL}
                  margin="mr-10"
                  name={workspaces.get(workspaceID)?.name}
                  rounded="rounded-md"
                  size="medium"
                />
                <div className="grow min-w-0 text-body-bold">
                  {workspaces.get(workspaceID)?.name}
                </div>
              </div>
              <Button
                buttonStyle="subtle"
                icon="Settings"
                iconSize={20}
                onClick={() =>
                  openModal(
                    <WorkspaceModal
                      defaultTab={WorkspaceModalTabs.Apps}
                      workspaceID={workspaceID}
                    />
                  )
                }
              />
            </div>
          ))
        ) : (
          <div className="flex mt-10">
            <div className="flex items-center grow min-w-0">
              <Skeleton className="h-32 w-32 mr-10" />
              <Skeleton
                className="text-body-bold"
                width={`${getRandomInt(200, 250)}px`}
              />
            </div>
          </div>
        )}
      </div>

      {app?.docsURL && (
        <>
          <Hr className="w-full !border-background-subtle" />

          <div className="flex items-center justify-between p-16">
            <div className="text-subhead text-text-subtle">
              Want to learn more?
            </div>
            <Button
              buttonStyle="secondary"
              icon="ExternalLink"
              iconClassName="ml-6"
              iconLast
              iconSize={20}
              onClick={() => {
                window.open(app?.docsURL, "_blank");
              }}
              type="button"
            >
              View docs
            </Button>
          </div>
        </>
      )}
    </Card>
  );
};

export default InboxAppCard;
