import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { SectionItem } from "components/design-system/ui/sections-sidebar";
import { UnreadBadge } from "components/design-system/ui/unreadBadge";
import { routeParams, routeToView } from "components/routing/utils";
import { InboxListItem } from "components/views/inbox/InboxMain";
import { useFetchJoinApprovalsQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useElementRelativePosition from "hooks/useElementRelativePosition";
import useUnreadSidebarItemStore from "store/useUnreadSidebarItemStore";

const SidebarItemID = "NewRequests";

const NewRequests = ({ containerID }: { containerID: string }) => {
  const { authReady } = useAuthData();
  const history = useHistory();
  const location = useLocation();
  const item = useRef<HTMLDivElement>(null);
  const { addItem, removeItem } = useUnreadSidebarItemStore();
  const { relativePosition } = useElementRelativePosition(
    item.current,
    containerID
  );

  const { data } = useFetchJoinApprovalsQuery({
    fetchPolicy: "cache-and-network",
  });

  const { joinApprovals } = data ?? {};

  useEffect(() => {
    if (!relativePosition) return removeItem(SidebarItemID);

    const scrollToItem = () => {
      if (!item) return;
      item.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    };

    addItem({
      hasMentions: false,
      id: SidebarItemID,
      position: relativePosition,
      scrollToItem,
    });
  }, [addItem, relativePosition, removeItem]);

  if (!joinApprovals?.totalCount || !authReady) return null;

  return (
    <ul className="mt-8">
      <SectionItem
        className="!px-0 mx-0"
        onClick={() => {
          history.push(
            routeToView({
              superTab: "inbox",
              view: "requests",
            })
          );
        }}
      >
        <InboxListItem
          bulkMode={false}
          ref={item}
          icon="Request"
          isSelected={routeParams(location)?.view === "requests"}
          subject="New requests"
        >
          <div className="flex h-full items-center justify-end">
            <UnreadBadge
              className="mr-4"
              count={joinApprovals?.totalCount}
              important
            />
          </div>
        </InboxListItem>
      </SectionItem>
    </ul>
  );
};

export default NewRequests;
