import { App as CapApp } from "@capacitor/app";

import { isNative } from "utils/platform";
import env from "utils/processEnv";

type VersionInfo = {
  buildNumber?: string; // The build number from CircleCI, undefined for Vercel builds
  buildSha: string; // The sha of the web build
  fullVersionString: string;
  nativeVersionNumber?: string; // The value of .liveupdate/native-version-number for the web build
  version: string; // The version of the web build from package.json
};

type NativeVersionInfo = {
  buildNumber?: string; // A build number from the native build. Comes from `versionCode` for Android and `CFBundleVersion` for iOS/Mac
  version?: string; // The version string for the native app. This is what appears in the App Store.
};

const version = env.version ?? "0.0";
const buildSha = env.vercelGitCommitSHA ?? "unknown";
const buildNumber = env.circleBuildNum;
const nativeVersionNumber = isNative() ? env.nativeVersionNumber : undefined;

const nativeVersionNumberPart = nativeVersionNumber
  ? `-${nativeVersionNumber}`
  : "";

// This must match the format in sentry-release.ts
const fullVersionString = `${version}${nativeVersionNumberPart} (${buildSha.substr(0, 7)})`;

export const versionInfo: VersionInfo = {
  buildNumber,
  buildSha,
  fullVersionString,
  nativeVersionNumber,
  version,
};

export const nativeVersionInfo = (async (): Promise<NativeVersionInfo> => {
  if (!isNative()) {
    return { buildNumber: undefined, version: undefined };
  }
  const { build: buildNumber, version } = await CapApp.getInfo();
  return { buildNumber, version };
})();
