import { useMemo } from "react";

import { Message, ThreadPreview, ThreadSimple } from "@utility-types";

import { useFetchThreadEdgeSimple } from "./useFetchThreadEdge";

type ReplyTo = {
  message: Message;
  thread: ThreadSimple | ThreadPreview;
};

const useFetchThreadReplyTo = (thread?: ThreadSimple): ReplyTo | undefined => {
  const replyToMessage = thread?.replyToMessage;
  const { threadEdge: replyToThreadEdge } = useFetchThreadEdgeSimple(
    thread?.replyToMessage?.threadID
  );
  const replyToThread = replyToThreadEdge?.node;

  return useMemo(
    () =>
      replyToMessage?.threadID && replyToThread
        ? { message: replyToMessage, thread: replyToThread }
        : undefined,
    [replyToThread, replyToMessage]
  );
};

export default useFetchThreadReplyTo;
