import { useContext } from "react";
import { ChatContext } from "stream-chat-react";

import { FadeInOut } from "components/Animated";
import { Button } from "components/design-system/Button";
import useGoToThreadRecent from "hooks/useGoToThreadRecent";
import { animateScrollTo } from "utils/animate";

type Props = {
  historyMode?: boolean;
  isUnread: boolean;
  listRef: React.MutableRefObject<HTMLDivElement | null>;
  show: boolean;
};

const ThreadGoToBottom = ({
  historyMode,
  isUnread,
  listRef,
  show,
}: Props): JSX.Element | null => {
  const context = useContext(ChatContext);

  const goToThreadRecent = useGoToThreadRecent();

  if (!context) return null;

  const scrollToBottom = () => {
    goToThreadRecent();
    if (historyMode) return;
    const list = listRef.current;
    if (!list) return;
    const scrollTop = Math.floor(list.scrollHeight - list.clientHeight);
    animateScrollTo(list, scrollTop);
  };

  return (
    <FadeInOut show={show}>
      <Button
        buttonStyle="none"
        buttonType="none"
        className="relative -top-50 mx-auto -mb-40"
        icon="ArrowDownCircle"
        iconClassName={`bg-background rounded-full ${
          isUnread ? "text-interactive-primary" : "text-interactive-subtle"
        }`}
        iconSize={40}
        iconStroke={2}
        onClick={scrollToBottom}
      />
    </FadeInOut>
  );
};

export default ThreadGoToBottom;
