import { ThreadEdgeSimple } from "@utility-types";
import { MemberRole, MessageableBy } from "generated/graphql";

import useAuthData from "./useAuthData";

const useThreadEdgeMessageable = (edge?: ThreadEdgeSimple) => {
  const { authData } = useAuthData();

  if (!edge) return undefined;

  return (
    edge.node.messageableBy === MessageableBy.Recipient ||
    edge.node.recipients.edges?.[0]?.node.id === authData?.me.id ||
    edge.recipientRole === MemberRole.Admin
  );
};

export default useThreadEdgeMessageable;
