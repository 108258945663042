import { useEffect } from "react";
import { useWatch } from "react-hook-form";

import { ColorSchemeTypes } from "@utility-types";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import useLocalSettingsStore from "store/useLocalSettingsStore";

import { Field, FieldSet } from "./FieldSet";

const schemeColorOptions = [
  { label: "System default", value: ColorSchemeTypes.Default },
  { label: "Light", value: ColorSchemeTypes.Light },
  { label: "Dark", value: ColorSchemeTypes.Dark },
];

const AppearancePref = () => {
  const colorScheme: ColorSchemeTypes = useWatch({
    name: "colorScheme",
  });

  useEffect(() => {
    useLocalSettingsStore.setState({ colorSchemePreview: colorScheme });
  }, [colorScheme]);

  return (
    <FieldSet label="Appearance (this device)">
      <Field label="Color scheme:">
        <SelectDropdown name="colorScheme" options={schemeColorOptions} />
      </Field>
    </FieldSet>
  );
};

export default AppearancePref;
