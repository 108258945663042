import {
  addDays,
  addHours,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  isTomorrow,
  isWithinInterval,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import { useKeenSlider } from "keen-slider/react";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import useAppStateStore from "store/useAppStateStore";

import { Wheel } from "../Wheel";
import { useWheelOptions } from "../Wheel/Wheel";

const roundUpNearestMultiple = (value: number, multiple: number) => {
  return Math.ceil(value / multiple) * multiple;
};

const roundDownNearestMultiple = (value: number, multiple: number) => {
  return Math.floor(value / multiple) * multiple;
};

type DatePickerComponent = "date" | "hour" | "minute" | "meridiem";

type Meridiem = "AM" | "PM";

type MinuteInterval = 1 | 5 | 15 | 30;

export type DateTimePickerWheelProps = {
  /**
   * The earliest valid date that can be selected.
   */
  min: MutableRefObject<Date>;
  /**
   * The latest valid date that can be selected.
   */
  max: MutableRefObject<Date>;
  /**
   * The date to which the sliders should set initially. If it is not within min/max, min will be used.
   *
   * The minutes will be automatically adjusted to match a valid interval.
   */
  initialValue?: Date;
  /** The intervals of minutes that can be selected, (e.g., a value of 15 will provide options 0, 15, 30, and 45) */
  minuteInterval?: MinuteInterval;
  /** Returns the selected date when animation is complete. */
  onValueChange?: (v: Date) => Promise<void> | void;
  /** The height of the wheel container in pixels. */
  height?: number;
};

const getMinuteOptions = (minuteInterval: MinuteInterval) =>
  new Array(60 / minuteInterval).fill(0).map((_, i) => i * minuteInterval);
const hourOptions = new Array(12).fill(0).map((_, i) => (i === 0 ? 12 : i));
const meridiemOptions: Meridiem[] = ["AM", "PM"];

const getInitialPickerValues = (
  min: Date,
  max: Date,
  minuteInterval: MinuteInterval,
  initialValue?: Date
): { date: Date; hour: number; minute: number; meridiem: Meridiem } => {
  let date =
    initialValue && isWithinInterval(initialValue, { start: min, end: max })
      ? initialValue
      : min;

  let minute = roundUpNearestMultiple(date.getMinutes(), minuteInterval);
  if (minute === 60) {
    date = addHours(date, 1);
    minute = 0;
  }
  if (date > max) {
    date = max;
    minute = roundDownNearestMultiple(max.getMinutes(), minuteInterval);
  }

  return {
    date,
    hour: date.getHours() === 12 ? 12 : date.getHours() % 12,
    minute,
    meridiem: date.getHours() < 12 ? "AM" : "PM",
  };
};

export const DateTimePickerWheel = ({
  min,
  max,
  initialValue,
  minuteInterval = 15,
  onValueChange,
  height = 180,
}: DateTimePickerWheelProps) => {
  const { clientWidth } = useAppStateStore(({ clientWidth }) => ({
    clientWidth,
  }));
  const initialPickerValues = useMemo(
    () =>
      getInitialPickerValues(
        min.current,
        max.current,
        minuteInterval,
        initialValue
      ),
    [min, max, minuteInterval, initialValue]
  );
  const [date, setDate] = useState<Date>(initialPickerValues.date);
  const [hour, setHour] = useState<number>(initialPickerValues.hour);
  const [minute, setMinute] = useState<number>(initialPickerValues.minute);
  const [meridiem, setMeridiem] = useState<Meridiem>(
    initialPickerValues.meridiem
  );

  const onPickerComponentChange = (
    component: DatePickerComponent,
    index: number
  ) => {
    switch (component) {
      case "date":
        const date = dateOptions[index];
        if (date !== undefined) {
          setDate(date);
        }
        break;
      case "hour":
        const hour = hourOptions[index];
        if (hour !== undefined) {
          setHour(hour);
        }
        break;
      case "minute":
        const minute = minuteOptions[index];
        if (minute !== undefined) {
          setMinute(minute);
        }
        break;
      case "meridiem":
        const meridiem = meridiemOptions[index];
        if (meridiem !== undefined) {
          setMeridiem(meridiem);
        }
        break;
    }
  };

  // === DATE ===
  const dateOptions = useMemo(() => {
    const result = new Array<Date>();
    let currentDate = min.current;
    while (!isSameDay(currentDate, addDays(max.current, 1))) {
      result.push(new Date(currentDate));
      currentDate = addDays(currentDate, 1);
    }
    return result;
  }, [min, max]);
  const dateSliderOptions = useWheelOptions({
    options: dateOptions,
    loop: false,
    initialIndex: dateOptions.findIndex(v => isSameDay(v, date)),
    onChange: index => onPickerComponentChange("date", index),
  });
  const [dateSliderRef, dateSlider] = useKeenSlider<HTMLDivElement>(
    dateSliderOptions.current
  );

  // === HOUR ===
  const hourSliderOptions = useWheelOptions({
    options: hourOptions,
    loop: true,
    initialIndex: hourOptions.indexOf(hour),
    onChange: index => onPickerComponentChange("hour", index),
  });
  const [hourSliderRef, hourSlider] = useKeenSlider<HTMLDivElement>(
    hourSliderOptions.current
  );

  // === MINUTE ===
  const minuteOptions = useMemo(
    () => getMinuteOptions(minuteInterval),
    [minuteInterval]
  );
  const minuteSliderOptions = useWheelOptions({
    options: minuteOptions,
    loop: minuteInterval <= 6,
    initialIndex: minuteOptions.indexOf(minute),
    onChange: index => onPickerComponentChange("minute", index),
  });
  const [minuteSliderRef, minuteSlider] = useKeenSlider<HTMLDivElement>(
    minuteSliderOptions.current
  );

  // === MERIDIEM ===
  const meridiemSliderOptions = useWheelOptions({
    options: meridiemOptions,
    loop: false,
    initialIndex: meridiemOptions.indexOf(meridiem),
    onChange: index => onPickerComponentChange("meridiem", index),
  });
  const [meridiemSliderRef, meridiemSlider] = useKeenSlider<HTMLDivElement>(
    meridiemSliderOptions.current
  );

  const setSlidersNearest = useCallback(
    (value: Date) => {
      const { date, hour, minute, meridiem } = getInitialPickerValues(
        min.current,
        max.current,
        minuteInterval,
        value
      );
      dateSlider.current?.moveToIdx(
        dateOptions.findIndex(v => isSameDay(v, date))
      );
      hourSlider.current?.moveToIdx(hourOptions.indexOf(hour));
      minuteSlider.current?.moveToIdx(minuteOptions.indexOf(minute));
      meridiemSlider.current?.moveToIdx(meridiemOptions.indexOf(meridiem));
    },
    [
      min,
      max,
      minuteInterval,
      dateSlider,
      dateOptions,
      hourSlider,
      minuteSlider,
      minuteOptions,
      meridiemSlider,
    ]
  );

  useEffect(() => {
    let result = date;
    if (hour === 12) {
      result = setHours(result, meridiem === "AM" ? 0 : 12);
    } else {
      result = setHours(result, meridiem === "AM" ? hour : hour + 12);
    }
    result = setMinutes(result, minute);
    result = setSeconds(result, 0);

    if (isBefore(result, min.current)) {
      setSlidersNearest(min.current);
    } else if (isAfter(result, max.current)) {
      setSlidersNearest(max.current);
    } else {
      onValueChange?.(result);
    }
  }, [
    date,
    hour,
    minute,
    meridiem,
    setSlidersNearest,
    onValueChange,
    min,
    max,
  ]);

  return (
    <div className="h-full w-full flex justify-center items-center select-none overflow-hidden">
      <div style={{ height }}>
        <Wheel
          slider={dateSlider}
          sliderContainerRef={dateSliderRef}
          length={dateOptions.length}
          width={clientWidth * 0.48}
          perspective="left"
          formatValue={optionIndex => {
            const option = dateOptions.at(optionIndex);
            if (!option) {
              return "Unknown";
            }

            if (isToday(option)) {
              return "Today";
            }

            if (isTomorrow(option)) {
              return "Tomorrow";
            }

            return format(option, "iii LLL d");
          }}
        />
      </div>
      <div style={{ height }}>
        <Wheel
          slider={hourSlider}
          sliderContainerRef={hourSliderRef}
          length={hourOptions.length}
          width={clientWidth * 0.14}
          formatValue={i => hourOptions[i]?.toString()}
          perspective="center"
        />
      </div>
      <div style={{ height }}>
        <Wheel
          slider={minuteSlider}
          sliderContainerRef={minuteSliderRef}
          length={minuteOptions.length}
          width={clientWidth * 0.14}
          formatValue={i => minuteOptions[i]?.toString().padStart(2, "0")}
          perspective="center"
        />
      </div>
      <div style={{ height }}>
        <Wheel
          slider={meridiemSlider}
          sliderContainerRef={meridiemSliderRef}
          length={meridiemOptions.length}
          width={clientWidth * 0.24}
          formatValue={i => meridiemOptions[i]}
          perspective="right"
        />
      </div>
    </div>
  );
};
