import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { useCallback, useRef, useState } from "react";

import { Mailbox, ThreadEdgeSimple } from "@utility-types";
import {
  ThreadListQuery,
  ThreadsOrder,
  useThreadListQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import usePrevious from "hooks/usePrevious";
import useLocalSettingsStore from "store/useLocalSettingsStore";

type Props = {
  excludeChats?: boolean;
  excludeStarred?: boolean;
  mailbox: Mailbox;
  order?: ThreadsOrder;
  pageSize: number;
  recipientID?: string;
  skip?: boolean;
};

export type ThreadListData = {
  hasNextPage: boolean;
  loadNextPage: (
    limit?: number
  ) => Promise<void | ApolloQueryResult<ThreadListQuery>>;
  loadingNextPage: boolean;
  pagingReset: boolean;
  refresh: () => Promise<ApolloQueryResult<ThreadListQuery>>;
  result: {
    error: ApolloError | undefined;
    loading: boolean;
    threadEdges: ThreadEdgeSimple[] | undefined;
    totalCount: number | undefined;
  };
};

const useThreadListData = ({
  excludeChats = undefined,
  excludeStarred = undefined,
  mailbox,
  order,
  pageSize,
  recipientID,
  skip,
}: Props): ThreadListData => {
  const [loadingNextPage, setNextPageLoading] = useState(false);
  const { authReady } = useAuthData();

  const { currentThreadSort } = useLocalSettingsStore(
    ({ currentThreadSort }) => ({ currentThreadSort })
  );

  const { data, error, fetchMore, loading, refetch } = useThreadListQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip,
    variables: {
      excludeChats,
      excludeStarred,
      limit: pageSize,
      mailbox,
      order: order || currentThreadSort,
      recipientID,
    },
  });

  const nextCursor = useRef<string | null>(null);
  nextCursor.current = data?.threads.pageInfo.startCursor ?? null;

  const hasNextPage = !!data?.threads.pageInfo.hasPreviousPage;
  const totalCount = data?.threads.totalCount;

  const loadNextPage = useCallback(
    async (limit?: number) => {
      setNextPageLoading(true);
      return fetchMore({
        variables: {
          before: nextCursor.current,
          limit: limit ?? pageSize,
        },
      })
        .catch(err => {
          console.warn("Error: [useThreadListData] - ", err.message); // TODO: handle and retry?
        })
        .finally(() => setNextPageLoading(false));
    },
    [fetchMore, nextCursor, pageSize]
  );

  const threadEdges = data?.threads.edges;

  const edgesLength = threadEdges?.length || 0;
  const pagingReset =
    usePrevious(edgesLength) - edgesLength >= pageSize || !threadEdges;

  return {
    hasNextPage,
    loadingNextPage,
    loadNextPage,
    pagingReset,
    refresh: () => refetch(),
    result: { error, loading, threadEdges, totalCount },
  };
};

export default useThreadListData;
