import { Keyboard } from "@capacitor/keyboard";
import { RefObject, useEffect, useRef } from "react";

import useMessageEditorStore from "store/useMessageEditorStore";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import { animate } from "utils/animate";
import { isNativeIOS, isNativeMobile } from "utils/platform";

export const useNativeKeyboardManager = (
  appRef: RefObject<HTMLDivElement>
): void => {
  const storeRef = useRef(useNativeKeyboardStore);

  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        (keyboardHeight: number) => {
          if (appRef.current) {
            appRef.current.style.paddingBottom = `calc(${Math.ceil(
              keyboardHeight
            )}px - env(safe-area-inset-bottom))`;
          }
        }
      ),
    [appRef]
  );

  useEffect(
    () =>
      useMessageEditorStore.subscribe(({ editors }) => {
        if (!isNativeIOS()) return; // Accessory bar is only supported on iOS

        Keyboard.setAccessoryBarVisible({
          isVisible: editors.size === 0,
        }).then(() =>
          storeRef.current.setState({
            isAccessoryBarVisible: editors.size === 0,
          })
        );
      }),
    []
  );

  useEffect(() => {
    // On iOS, when the keyboard appears, we are notified of the keyboard's
    // position and adjust our UI to accommodate. On Android, the whole
    // window is resized so there is nothing to adjust in the app.
    // In the future, we may be able to adopt the same approach as on iOS,
    // but for now we only toggle the state open | close
    // https://developer.android.com/training/system-ui/sw-keyboard
    // Support is limited to Android 11+
    if (!isNativeMobile()) return;

    let animation: { stop: () => void };
    let prevKeyboardHeight = 0;
    const animateKeyboard = (keyboardHeight: number) => {
      animation?.stop();
      animation = animate({
        from: prevKeyboardHeight,
        onComplete: () => {
          storeRef.current.setState({
            animationState: "complete",
            state: keyboardHeight === 0 ? "close" : "open",
          });
          setTimeout(
            () => storeRef.current.setState({ animationState: "paused" }),
            0
          );
        },
        onPlay: () => storeRef.current.setState({ animationState: "running" }),
        onUpdate: v => storeRef.current.setState({ keyboardHeight: v }),
        to: keyboardHeight,
      });
      prevKeyboardHeight = keyboardHeight;
    };

    const listeners = [
      Keyboard.addListener("keyboardWillShow", ({ keyboardHeight }) => {
        storeRef.current.setState({ state: "opening" });

        if (!isNativeIOS()) return;

        animateKeyboard(keyboardHeight);
      }),

      Keyboard.addListener("keyboardWillHide", () => {
        storeRef.current.setState({ state: "closing" });

        if (!isNativeIOS()) return;

        animateKeyboard(0);
      }),

      Keyboard.addListener("keyboardDidShow", () => {
        if (isNativeIOS()) return; // on IOS we wait for the animation to complete

        storeRef.current.setState({ state: "open" });
      }),

      Keyboard.addListener("keyboardDidHide", () =>
        storeRef.current.setState({ state: "close" })
      ),
    ];

    return () => {
      listeners.forEach(async listener => (await listener).remove());
    };
  }, []);
};
