import { Link } from "react-router-dom";

import downloadAppQR from "assets/downloadAppQR.png";
import { SlideInOut } from "components/Animated";
import { Button } from "components/design-system/Button";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import env from "utils/processEnv";

type DownloadAppBannerProps = {
  show: boolean;
};

const DownloadAppBanner = ({ show }: DownloadAppBannerProps): JSX.Element => {
  return (
    <SlideInOut
      className="sticky bottom-0 flex w-full max-h-fit p-16 bg-background-app-subtle"
      show={!!show}
    >
      <Button
        buttonStyle="icon-secondary"
        className="absolute top-4 right-8 !p-8"
        icon="Close"
        iconSize={16}
        iconStroke={2}
        onClick={() =>
          useLocalSettingsStore.setState({ dismissedDownloadBanner: true })
        }
      />
      <div className="flex flex-col">
        <span className="text-headline-bold">Get the app</span>
        <span className="pr-2 mt-4 text-subhead text-text-strong">
          Install the Glue app for a better mobile experience.
        </span>
      </div>
      <div className="flex justify-end items-center w-1/2 pl-8 pr-24">
        <Link
          className="flex justify-center items-center w-[80px] h-[80px] bg-background-white rounded-lg"
          target="_blank"
          to={{ pathname: env.glueDownloadAppUrl }}
        >
          <img
            alt="download-qr"
            className="w-[72px] h-[72px]"
            src={downloadAppQR}
          />
        </Link>
      </div>
    </SlideInOut>
  );
};

export default DownloadAppBanner;
