import { ConnectionStatus } from "@capacitor/network";
import { devtools, subscribeWithSelector } from "zustand/middleware";

import { Message, ThreadEdge } from "@utility-types";
import { BREAKPOINTS } from "utils/breakpoints";
import { isNative } from "utils/platform";

import { glueCreateZustand } from "./helper/glueCreate";
const STORE_NAME = "AppStateStore";

type AppStatus = "unknown" | "active" | "inactive";

type AppNotification = {
  message: Message;
  threadID: string;
  threadEdge: ThreadEdge;
};

type AppStore = {
  activeThreadId?: string;
  activeNotification: AppNotification | undefined;
  appStatus: AppStatus;
  breakpointMD: boolean;
  clientWidth: number;
  handleAuthSignIn?: (errorHandler?: () => void) => Promise<void>;
  networkStatus: ConnectionStatus;
  setState: (values: Partial<AppStore>) => void;
};

const useAppStateStore = glueCreateZustand<AppStore>({
  name: STORE_NAME,
})(
  devtools(
    subscribeWithSelector(set => {
      const clientWidth = window?.innerWidth || 0;
      return {
        appStatus: isNative() ? "unknown" : "active",
        activeNotification: undefined,
        breakpointMD: clientWidth >= BREAKPOINTS.MD,
        clientWidth,
        networkStatus: {
          connected: true,
          connectionType: "unknown",
        },
        setState: values => set(state => ({ ...state, ...values })),
      };
    }),
    { name: STORE_NAME }
  )
);

export default useAppStateStore;
