import axios from "axios";
import { uniqBy } from "lodash-es";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { FileOrImageUpload, GlueFile } from "@utility-types";
import { glueFileToFileUpload } from "components/MessageEditor/stream-helpers";
import {
  useCreateFileMutation,
  useUploadTicketMutation,
} from "generated/graphql";
import { useSnackbar } from "providers/SnackbarProvider";
import getFormattedFileSizeString from "utils/getFormattedFileSizeString";
import getImageDimensions from "utils/getImageDimensions";
import { computeMD5Sum } from "utils/md5sum";

type State = Map<string, FileOrImageUpload>;

interface Props {
  onChange: (state: State) => void;
  orderedUploads: MutableRefObject<Map<string, FileOrImageUpload>>;
}

type UploadProgress = {
  glueId?: string;
  uploadID: string;
  progress: number;
};

/**
 * @summary The maximum file size limit for uploads (100 MB)
 */
const fileSizeLimit = 100_000_000;

/**
 * @summary Formats fileSizeLimit to a human-readable string
 */
const formattedFileSize = getFormattedFileSizeString(fileSizeLimit);

const useFileUploader = ({ onChange, orderedUploads }: Props) => {
  const [uploadProgress, setUploadProgress] = useState<UploadProgress[]>();
  const [createUploadTicket] = useUploadTicketMutation();
  const [createFile] = useCreateFileMutation();
  const { openSnackbar } = useSnackbar();
  const processedUploads = useRef<{ glueId: string; tempId: string }[]>([]);

  const uploadFile = useCallback(
    async (upload: FileOrImageUpload): Promise<GlueFile | undefined> => {
      const {
        contentType,
        id: uploadID,
        uploadInfo: { file },
      } = upload;

      if (!(file instanceof File)) {
        return undefined;
      }

      let fileToUpload = file;

      if (fileToUpload.size > fileSizeLimit) {
        openSnackbar(
          "error",
          `Files larger than ${formattedFileSize} are not supported.`
        );
        throw new Error(`file size larger than limit of ${formattedFileSize}`);
      }

      // If the file is an .avif image, convert it to png
      if (file.name.endsWith(".avif")) {
        try {
          fileToUpload = await convertAvifToPng(file);
        } catch (error) {
          openSnackbar(
            "error",
            "Sorry, we encountered an issue processing your file."
          );
          throw error;
        }
      }

      const md5Sum = await computeMD5Sum(fileToUpload);
      if (!md5Sum) {
        openSnackbar(
          "error",
          "Sorry, we encountered an issue processing your file."
        );
        throw new Error("unable to calculate md5");
      }

      const objectURL = contentType.startsWith("image")
        ? URL.createObjectURL(fileToUpload)
        : undefined;

      if (objectURL) {
        upload.uploadInfo.previewUri = objectURL;
      }

      // uploadTicketData data will change if the file has been already uploaded
      const { data: uploadTicketData } = await createUploadTicket({
        variables: {
          input: {
            contentLength: fileToUpload.size.toString(),
            contentMD5: md5Sum,
            contentType: await getContentType(fileToUpload, contentType),
            metadata: contentType.startsWith("image")
              ? await getImageDimensions(fileToUpload)
              : undefined,
            name: fileToUpload.name,
          },
        },
      });

      if (!uploadTicketData) throw new Error("failed creating upload ticket");

      if (uploadTicketData.uploadTicket.__typename === "File") {
        const contentType = uploadTicketData.uploadTicket.contentType;
        setUploadProgress(v =>
          uniqBy([{ uploadID, progress: 100 }, ...(v || [])], "uploadID")
        );
        return {
          ...uploadTicketData.uploadTicket,
          contentType: await getContentType(fileToUpload, contentType),
        };
      }

      const {
        uploadTicket: { formData, headers, id: uploadTicketID, url },
      } = uploadTicketData;

      // TODO: Test Axios request and createFile mutation

      const cancelTokenSource = axios.CancelToken.source();
      upload.uploadInfo.axiosCancelToken = cancelTokenSource;

      const uploadOptions = {
        cancelToken: cancelTokenSource.token,
        headers: Object.fromEntries(
          headers
            .filter(({ name }) => name !== "Content-Length") // "Content-Length" is automatically set by the browser
            .map(({ name, value }) => [name, value])
        ),
        onUploadProgress: (e: ProgressEvent) => {
          const progress = Math.round((100 * e.loaded) / e.total);
          setUploadProgress(v =>
            uniqBy([{ uploadID, progress }, ...(v || [])], "uploadID")
          );
        },
      };

      await (formData
        ? axios.post(
            url,
            formData.reduce((data, { name, value }) => {
              data.set(name, value === "@file" ? fileToUpload : value);
              return data;
            }, new FormData()),
            uploadOptions
          )
        : axios.put(url, fileToUpload, uploadOptions)
      ).catch(error => {
        if (!orderedUploads.current.get(uploadID)) {
          throw new Error("upload was canceled");
        }
        throw error;
      });

      const { data: fileData } = await createFile({
        variables: { uploadTicketID: uploadTicketID },
      });

      if (!fileData) return undefined;

      return fileData.createFile;
    },
    [createUploadTicket, createFile, openSnackbar, orderedUploads]
  );

  const cancelUploadsRef = useRef(() =>
    [...orderedUploads.current.values()].forEach(upload =>
      upload.uploadInfo.axiosCancelToken?.cancel()
    )
  );

  useEffect(() => {
    const queued = [...orderedUploads.current.values()].filter(
      ({ uploadInfo }) => uploadInfo.state === "uploading" && uploadInfo.queued
    );
    queued.forEach(upload => {
      uploadFile(upload)
        .then(glueFile => {
          if (!glueFile) return;
          const { id: glueFileId } = glueFile;

          const uploads = [...orderedUploads.current.values()];

          const existing = uploads
            .filter(({ uploadInfo: { state } }) => state === "finished")
            .find(
              ({ id: fileId, uploadInfo: { id: uploadId } }) =>
                glueFileId === fileId || glueFileId === uploadId
            );

          const fileUpload = existing || {
            ...glueFileToFileUpload(glueFile),
            uploadInfo: { ...upload.uploadInfo, state: "finished" },
          };

          if (processedUploads) {
            setUploadProgress(v => {
              const file = v?.find(u => u.uploadID === upload.uploadInfo.id);
              return file
                ? uniqBy(
                    [{ ...file, glueId: fileUpload.id }, ...(v || [])],
                    "uploadID"
                  )
                : v;
            });
            processedUploads.current.push({
              glueId: fileUpload.id,
              tempId: upload.uploadInfo.id,
            });
          }

          const tempOrderedUploads = Array.from(orderedUploads.current).filter(
            u => u[0] !== existing?.uploadInfo.id
          );
          const existingIndex = uploads.findIndex(u => upload.id === u.id);

          existingIndex >= 0 &&
            tempOrderedUploads.splice(existingIndex, 1, [
              glueFileId,
              fileUpload,
            ]);

          orderedUploads.current = new Map(tempOrderedUploads);

          onChange(orderedUploads.current);
        })
        .catch((error: Error) => {
          if (error.message !== "upload was canceled") {
            console.warn("Error: [uploadFile] -", error);
            upload.uploadInfo.state = "failed";

            onChange(orderedUploads.current);
          }
        });

      upload.uploadInfo.queued = false;

      orderedUploads.current.set(upload.id, upload);

      onChange(orderedUploads.current);
    });
  }, [onChange, uploadFile, orderedUploads]);

  useEffect(() => {
    const handler = cancelUploadsRef.current;

    window.addEventListener("offline", handler);

    return () => window.removeEventListener("offline", handler);
  }, [orderedUploads]);

  useEffect(() => () => cancelUploadsRef.current(), []);

  return { uploadProgress, processedUploads, cancelUploadsRef };
};

async function getContentType(file: File, contentType: string) {
  let type = contentType;

  if (contentType.includes("video/")) {
    type = (await new Promise<boolean>(res => {
      const video = document.createElement("video");
      const url = window.URL.createObjectURL(file);

      video.preload = "metadata";

      video.onloadedmetadata = () => {
        res(!!(video.videoHeight && video.videoWidth));

        window.URL.revokeObjectURL(url);

        video.src = "null";
      };

      video.src = url;
    }))
      ? contentType
      : contentType.replace("video", "audio");
  }

  return type;
}

async function convertAvifToPng(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      // Create a regular HTML5 canvas element.
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      // Get the context from the canvas.
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(new Error("Unable to get canvas context"));
        return;
      }

      // Draw the image onto the canvas.
      ctx.drawImage(image, 0, 0);

      // Convert the canvas content to a blob.
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error("Unable to convert to blob"));
          return;
        }

        const pngFile = new File([blob], file.name.replace(/\.avif$/, ".png"), {
          type: "image/png",
        });

        resolve(pngFile);
      }, "image/png");

      // Remove the canvas from the DOM.
      canvas.remove();
    };

    image.onerror = () => {
      reject(new Error("Failed to load image"));
    };
  });
}

export default useFileUploader;
