import axios from "axios";
import { useEffect, useRef } from "react";

import useOnboardingStore from "store/useOnboardingStore";
import env from "utils/processEnv";

const useSkipPayment = () => {
  const { skipSubscribeForDomain } = useOnboardingStore(
    ({ skipSubscribeForDomain }) => ({ skipSubscribeForDomain })
  );
  const domain = useRef<string | null>(null);
  const signature = useRef<string | null>(null);

  const params = new URL(window.location.href).searchParams;
  if (params.size > 0) {
    domain.current = params.get("domain");
    signature.current = params.get("s");
  }

  useEffect(() => {
    if (
      !domain.current ||
      !signature.current ||
      skipSubscribeForDomain?.length
    ) {
      return;
    }
    axios
      .get(`${env.glueApiUrl}/subscription/verify-skip`, {
        params: { domain: domain.current, s: signature.current },
      })
      .then(r => {
        if (r.status !== 200) return;
        useOnboardingStore.setState({
          skipSubscribeForDomain: domain.current ?? undefined,
          view: "CompleteYourProfile",
          currentStep: 1,
        });
      });
  }, [skipSubscribeForDomain]);
};

export default useSkipPayment;
