import { useCallback, useReducer } from "react";

import { Draft, Thread } from "@utility-types";
import useAppUnfurlSetupMessage from "components/threads/hooks/useAppUnfurlSetupMessage";
import { useSendMessageMutation } from "generated/graphql";
import useThreadCacheUpdate from "hooks/thread/useThreadCacheUpdate";
import useComponentMounted from "hooks/useComponentMounted";
import { removeLonelyLinks } from "utils/message/removeLonelyLinks";

import {
  InitialState,
  SendMessageReducer,
  formToInput,
} from "../SendMessageReducer";
import { DraftForm } from "../types";

type Props = {
  draft?: Draft;
  initialDraft?: Partial<DraftForm>;
  onFinish?: (result?: Thread) => void;
};

export function useSendMessage({ initialDraft, onFinish }: Props) {
  const isMounted = useComponentMounted();

  // Draft State

  const [compose, dispatch] = useReducer(SendMessageReducer, {
    ...InitialState,
    draftForm: {
      ...InitialState.draftForm,
      ...initialDraft,
    },
  });

  // Send Draft

  const { onThreadNew } = useThreadCacheUpdate();
  const [sendMessageMutation] = useSendMessageMutation();
  const { sendSetupMessages } = useAppUnfurlSetupMessage();

  const onDraftSend = useCallback(
    (thread: Thread) => {
      onFinish?.(thread);
    },
    [onFinish]
  );

  const sendDraft = useCallback(
    () =>
      new Promise<Thread | void>(resolve => {
        if (compose.pending) return;

        const input = formToInput(compose.draftForm);

        input.message.text = removeLonelyLinks(
          input.message.text.trim(),
          compose.draftForm.message.attachments
        );

        dispatch({ type: "send" });

        sendMessageMutation({
          variables: { input },
          update: (cache, { data }) => {
            if (!data) return;
            onThreadNew(data.sendMessage.thread, cache);
          },
        })
          .catch(err => {
            console.warn("Error: [sendMessage] - ", err);
            dispatch({ type: "error" });
            return { data: undefined };
          })
          .then(({ data }) => {
            if (!data || !isMounted.current) return;
            onDraftSend(data.sendMessage.thread);
            resolve(data.sendMessage.thread);

            const threadID = data.sendMessage.thread.id;
            const messageID = data.sendMessage.message?.id;
            if (messageID) {
              sendSetupMessages(
                messageID,
                threadID,
                compose.draftForm.appUnfurlSetups
              );
            }
          });
      }),
    [
      compose.draftForm,
      compose.pending,
      isMounted,
      onDraftSend,
      onThreadNew,
      sendMessageMutation,
      sendSetupMessages,
    ]
  );

  return {
    compose,
    dispatch,
    sendDraft,
  };
}

export default useSendMessage;
