import { memo } from "react";
import ReactMarkdown, { Components } from "react-markdown";
import remarkGfm from "remark-gfm";

export const reactMarkdownRemarkPlugins = [remarkGfm];

const markdownLinkRenderFn = ({ children }: WithChildren) => (
  <span>{children}</span>
);
const markdownBlockquoteRenderFn = ({ children }: WithChildren) => (
  <div className="px-15 border-l-4 border-border-container">{children}</div>
);
const markdownCodeRenderFn = ({ children }: WithChildren) => (
  <code className="text-sm">{children}</code>
);
const markdownItalicRenderFn = ({ children }: WithChildren) => (
  <span className="italic">{children}</span>
);
const markdownListItemRenderFn = ({ children }: WithChildren) => (
  <li className="ml-20">{children}</li>
);
const markdownOrderedListRenderFn = ({ children }: WithChildren) => (
  <ol className="list-decimal">{children}</ol>
);
const markdownParagraphRenderFn = ({ children }: WithChildren) => (
  <div>{children}</div>
);
const markdownUnderlinedText = ({ children }: WithChildren) => (
  <ul className="list-nested">{children}</ul>
);
const markdownPreFormattedTextRenderFn = ({ children }: WithChildren) => (
  <pre className="text-sm">{children}</pre>
);

export const markdownRenderFnComponents: Components = {
  a: markdownLinkRenderFn,
  blockquote: markdownBlockquoteRenderFn,
  code: markdownCodeRenderFn,
  em: markdownItalicRenderFn,
  li: markdownListItemRenderFn,
  ol: markdownOrderedListRenderFn,
  p: markdownParagraphRenderFn,
  pre: markdownPreFormattedTextRenderFn,
  ul: markdownUnderlinedText,
};

export const MARKDOWN_ALLOWED_ELEMENTS = [
  "a",
  "blockquote",
  "code",
  "del",
  "em",
  "li",
  "ol",
  "p",
  "pre",
  "s",
  "strong",
  "ul",
];

type MarkdownProps = { text: string };

const MemoizedMarkdownComponent = memo((props: MarkdownProps) => (
  <ReactMarkdown
    children={props.text}
    allowedElements={MARKDOWN_ALLOWED_ELEMENTS}
    components={markdownRenderFnComponents}
    remarkPlugins={reactMarkdownRemarkPlugins}
    unwrapDisallowed
  />
));

export default MemoizedMarkdownComponent;
