import { ComponentProps, forwardRef } from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "components/design-system/Button";

export const InputButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>((props: ComponentProps<typeof Button>, ref) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  const disabled = props.disabled || isSubmitting;
  return (
    <Button
      ref={ref}
      buttonStyle="none"
      buttonType="none"
      type="button"
      {...props}
      disabled={disabled}
    />
  );
});
