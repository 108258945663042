import {
  ReactNode,
  ReactPortal,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";

type MapHeadElementTypes = {
  inlineScript: { text: string };
  inlineStyle: { text: string };
  link: { rel?: string; type?: string; url: string };
  script: { url: string };
};

type HeadElements<
  T extends "link" | "script" | "inlineStyle" | "inlineScript",
> = {
  type: T;
  values: MapHeadElementTypes[T];
};

type Props = React.IframeHTMLAttributes<HTMLIFrameElement> & {
  children: ReactNode;
  headElements?: (
    | HeadElements<"link" | "script">
    | HeadElements<"inlineStyle" | "inlineScript">
  )[];
  title: string;
};

const IFrame = forwardRef<HTMLIFrameElement, Props>(
  ({ children, headElements, title, ...props }, forwardedRef) => {
    const ref = useRef<HTMLIFrameElement | null>();
    const [mounted, setMounted] = useState(false);
    const [iFrameLoaded, setIFrameLoaded] = useState(false);
    const srcDoc = useRef("");
    const getDocument = (): Document | null =>
      ref.current ? ref.current.contentDocument : null;
    const renderIFrameContent = (): null | ReactPortal[] => {
      const iFrameDoc = getDocument();
      const iFrameWin = iFrameDoc?.defaultView;

      if (!mounted || !iFrameDoc || !iFrameWin) return null;

      const target = iFrameDoc.body.children[0];

      if (!target) return null;

      return [ReactDOM.createPortal(children, target)];
    };

    if (!mounted) {
      const newDocument =
        document.implementation.createHTMLDocument("New Document");
      try {
        newDocument.body.appendChild(newDocument.createElement("div"));
        if (headElements) {
          headElements
            .filter((item): item is HeadElements<"link" | "script"> =>
              ["link", "script"].includes(item.type)
            )
            .forEach(asset => {
              const { url } = asset.values;
              const element = document.createElement(asset.type);
              if (element instanceof HTMLScriptElement) {
                element.src = url;
              } else if (element instanceof HTMLLinkElement) {
                element.rel =
                  (asset as HeadElements<"link">).values.rel || "stylesheet";
                element.type =
                  (asset as HeadElements<"link">).values.type || "text/css";
                element.href = url;
              }
              newDocument.head.appendChild(element);
            });
          headElements
            .filter(
              (item): item is HeadElements<"inlineStyle"> =>
                item.type === "inlineStyle"
            )
            .forEach(({ values: { text } }) => {
              const element = document.createElement("style");
              element.textContent = text;
              newDocument.head.appendChild(element);
            });
          headElements
            .filter(
              (item): item is HeadElements<"inlineScript"> =>
                item.type === "inlineScript"
            )
            .forEach(({ values: { text } }) => {
              const element = document.createElement("script");
              element.textContent = text;
              newDocument.head.appendChild(element);
            });
        }
      } catch (error) {
        console.warn("Error: iFame -", error);
      }

      srcDoc.current = newDocument.documentElement.innerHTML;
    }

    useEffect(() => {
      const handler = () => {
        setIFrameLoaded(true);
      };
      const { current } = ref;
      const iFrameDoc = getDocument();
      if (iFrameDoc && iFrameDoc.readyState !== "complete" && current) {
        current.addEventListener("load", handler);
      }
      setMounted(true);

      return () => {
        current?.removeEventListener("load", handler);
      };
    }, []);

    return (
      <iframe
        title={title}
        {...{
          ...props,
          children: undefined,
          srcDoc: srcDoc.current,
        }}
        ref={node => {
          ref.current = node;
          if (typeof forwardedRef === "function") {
            forwardedRef(node);
          } else if (forwardedRef) {
            forwardedRef.current = node;
          }
        }}
        onLoad={() => setIFrameLoaded(true)}
      >
        {iFrameLoaded && renderIFrameContent()}
      </iframe>
    );
  }
);

IFrame.displayName = "IFrame";

export default IFrame;
