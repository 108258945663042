import useAppStateStore from "store/useAppStateStore";
import useOnboardingStore from "store/useOnboardingStore";

import PlanAndPaymentDesktop from "./PlanAndPaymentDesktop";
import PlanAndPaymentMobile from "./PlanAndPaymentMobile";

const PlanAndPayment = () => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { workspace, setState } = useOnboardingStore(
    ({ workspace, setState }) => ({
      workspace,
      setState,
    })
  );

  const onComplete = () => {
    setState({
      view: "ChooseApps",
      totalSteps: 5,
      userSubscribed: true,
    });
  };

  return breakpointMD ? (
    <PlanAndPaymentDesktop onComplete={onComplete} workspace={workspace} />
  ) : (
    <PlanAndPaymentMobile onComplete={onComplete} workspace={workspace} />
  );
};

export default PlanAndPayment;
