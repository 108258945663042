import {
  type ButtonHTMLAttributes,
  type ComponentProps,
  forwardRef,
  memo,
} from "react";

import { Tooltip } from "components/design-system/FloatingUi";
import Icon from "components/design-system/icons/Icon";
import tw from "utils/tw";

import styles from "./HeaderButton.module.css";

type HeaderButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: ComponentProps<typeof Icon>["icon"];
  iconPosition?: "leading" | "trailing";
  loading?: boolean;
  tooltip?: string;
  tooltipStyle?: ComponentProps<typeof Tooltip>["tooltipStyle"];
  tooltipPlacement?: Pick<
    ComponentProps<typeof Tooltip>,
    "placement"
  >["placement"];
};

export const HeaderButton = memo(
  forwardRef<HTMLButtonElement, HeaderButtonProps>(
    (
      {
        children,
        disabled,
        icon,
        iconPosition = "leading",
        loading = false,
        tooltip,
        tooltipPlacement,
        tooltipStyle,
        ...props
      },
      ref
    ) => {
      const isIconOnly = !children;

      return (
        <Tooltip
          content={tooltip}
          placement={tooltipPlacement}
          tooltipStyle={tooltipStyle}
          disabled={disabled || tooltip === undefined}
        >
          <button
            {...props}
            ref={ref}
            disabled={loading || disabled}
            className={tw(
              styles.button,
              isIconOnly
                ? styles["icon-only-button"]
                : iconPosition === "trailing"
                  ? styles["trailing-icon-button"]
                  : styles["leading-icon-button"]
            )}
          >
            <Icon
              className={tw(styles.icon, loading && styles.loading)}
              icon={loading ? "LoaderCircle" : icon}
              size={16}
              strokeWidth={1.5}
            />

            {children && <span className={styles.content}>{children}</span>}
          </button>
        </Tooltip>
      );
    }
  )
);
