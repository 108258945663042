import { devtools } from "zustand/middleware";

import { DraftForm } from "components/threads/ThreadCompose/types";
import { glueCreateZustand } from "store/helper/glueCreate";

const STORE_NAME = "AIComposeDraft";

type AIComposeDraft = {
  aiDraft?: DraftForm;
  removeDraft: () => void;
};

const useAIComposeDraft = glueCreateZustand<AIComposeDraft>({
  name: STORE_NAME,
})(
  devtools(
    set => ({
      removeDraft: () => set({ aiDraft: undefined }),
    }),
    {
      name: STORE_NAME,
    }
  )
);

export default useAIComposeDraft;
