import { ComponentProps, useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { Form } from "components/design-system/Forms";
import EditableHeaderInput from "components/design-system/Forms/EditableHeaderInput";
import {
  ThreadFieldsFragment,
  ThreadPreviewFieldsFragment,
  useUpdateThreadMutation,
} from "generated/graphql";
import { formatNameEmoji } from "utils/formatNameEmoji";

type SubjectFormData = { subject: string };

const FormFields = ({
  canEdit,
  handleOnSubmit,
  originalSubject,
}: Omit<ComponentProps<typeof SubjectForm>, "thread"> & {
  handleOnSubmit: (input: { subject: string }) => unknown;
  originalSubject: string;
}) => {
  const { handleSubmit, setValue } = useFormContext<SubjectFormData>();

  const onReset = useCallback(() => {
    setValue("subject", originalSubject);
  }, [originalSubject, setValue]);

  const handleInvalidSubmission = useCallback(() => {
    setValue("subject", originalSubject);
  }, [originalSubject, setValue]);

  const onSubmit = useCallback(() => {
    handleSubmit(handleOnSubmit, handleInvalidSubmission)();
  }, [handleSubmit, handleOnSubmit, handleInvalidSubmission]);

  return (
    <EditableHeaderInput
      name="subject"
      canEdit={canEdit}
      onSubmit={onSubmit}
      onReset={onReset}
    />
  );
};

type SubjectFormProps = {
  canEdit: boolean;
  thread?: ThreadFieldsFragment | ThreadPreviewFieldsFragment;
};

const SubjectForm = ({ canEdit, thread }: SubjectFormProps) => {
  const [updateThread] = useUpdateThreadMutation({
    errorPolicy: "all",
  });

  const handleOnSubmit = (input: { subject: string }) => {
    if (thread?.__typename !== "Thread") {
      return Promise.resolve();
    }

    input.subject = formatNameEmoji({ name: input.subject }).nameWithEmoji;

    if (input.subject === thread.subject) {
      return Promise.resolve();
    }

    return updateThread({
      optimisticResponse: {
        updateThread: {
          ...thread,
          ...input,
        },
      },
      variables: {
        id: thread.id,
        input,
      },
    });
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      useFormProps={{
        defaultValues: {
          subject: thread?.subject || "",
        },
      }}
    >
      <FormFields
        canEdit={canEdit}
        handleOnSubmit={handleOnSubmit}
        originalSubject={thread?.subject || ""}
      />
    </Form>
  );
};

export default SubjectForm;
