import AppTabSecondaryLayout from "components/App/AppLayoutMobile/AppTabSecondaryLayout";

import useApp from "./hooks/useApp";
import InboxAppCard from "./InboxAppCard";

const InboxAppsMobile = ({ appID }: { appID?: string }) => {
  const app = useApp(appID);

  return (
    <AppTabSecondaryLayout backPath="/inbox" name={app?.name || "App"}>
      <div className="bg-background-app flex flex-col items-center grow justify-center min-h-0 relative px-16 w-full">
        <InboxAppCard app={app} />
      </div>
    </AppTabSecondaryLayout>
  );
};

export default InboxAppsMobile;
