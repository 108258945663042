import AppTabSecondaryLayout from "components/App/AppLayoutMobile/AppTabSecondaryLayout";
import ComposeButton from "components/SideBar/SidebarHeader/ComposeButton";
import UnreadFilter from "components/SideBar/SidebarHeader/UnreadFilter";

import ThreadsMain from "./ThreadsMain";

const ThreadsMobile = () => {
  return (
    <AppTabSecondaryLayout
      backPath="/inbox"
      buttons={
        <>
          <UnreadFilter className="flex items-center justify-center h-44 w-44" />
          <ComposeButton className="flex items-center justify-center h-44 w-44" />
        </>
      }
      name="Threads"
    >
      <div className="flex flex-col grow h-full min-h-0 relative w-full">
        <ThreadsMain title="Threads" />
      </div>
    </AppTabSecondaryLayout>
  );
};

export default ThreadsMobile;
