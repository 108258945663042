import { forwardRef, useCallback, useRef } from "react";
import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import Icon from "components/design-system/icons/Icon";
import { routePath } from "components/routing/utils";
import useMenuKeyboardShortcuts from "hooks/useMenuKeyboardShortcuts";
import useSearchStore from "store/useSearchStore";
import tw from "utils/tw";

type Props = {
  className?: string;
  setSearchTerm: (searchTerm: string) => void;
};

const SearchRecents = forwardRef<HTMLDivElement, WithChildren<Props>>(
  ({ className, setSearchTerm }: Props, ref): JSX.Element | null => {
    const history = useHistory();

    const { addSearchHistory, removeSearchHistory, searchHistory } =
      useSearchStore(
        ({ addSearchHistory, removeSearchHistory, searchHistory }) => ({
          addSearchHistory,
          removeSearchHistory,
          searchHistory,
        })
      );

    const selectedItemRef = useRef<HTMLLIElement>(null);

    const handleSelectItem = useCallback(
      (
        searchTerm: string,
        _: number,
        _e: KeyboardEvent | React.MouseEvent<HTMLDivElement | HTMLLIElement>
      ): void => {
        addSearchHistory(searchTerm);
        setSearchTerm(searchTerm);
        history.push(routePath({ superTab: "search" }));
      },
      [addSearchHistory, history, setSearchTerm]
    );

    const { selectedItem, setSelectedIndex } = useMenuKeyboardShortcuts({
      data: searchHistory,
      onSelectItem: handleSelectItem,
      selectedItemRef,
    });

    const handleHighlightItem = useCallback(
      (searchTerm: string) => {
        setSelectedIndex(searchHistory.findIndex(s => s === searchTerm));
      },
      [searchHistory, setSelectedIndex]
    );

    if (!searchHistory.length) return null;

    return (
      <div
        ref={ref}
        className={tw(
          "search-results",
          "md:max-h-[calc(100vh_-_115px)]",
          "overflow-y-scroll overscroll-contain pt-4 pb-8 relative",
          className
        )}
      >
        <div
          className={tw(
            "sticky top-0 z-1 rounded-t-lg md:bg-background-body text-footnote-bold text-text-subtle",
            "flex items-center justify-start h-28 mb-4 px-16"
          )}
        >
          Recent searches
        </div>
        <ul className="[&>li]:cursor-pointer [&>li]:select-none [&>li]:flex [&>li]:items-center [&>li]:justify-start [&>li]:h-36 [&>li]:px-12 [&>li]:md:px-16 [&>li]:text-body-bold">
          {searchHistory.map((searchTerm, index) => {
            const isSelected = searchTerm === selectedItem;
            return (
              <li
                key={searchTerm}
                className={tw({
                  "md:bg-accent-highlight/25": isSelected,
                })}
                onClick={e => handleSelectItem(searchTerm, index, e)}
                onFocus={() => handleHighlightItem(searchTerm)}
                onMouseEnter={() => handleHighlightItem(searchTerm)}
              >
                <Icon
                  icon="Clock"
                  className="text-icon-secondary mr-8"
                  size={20}
                />

                <span className="grow min-w-0 truncate">{searchTerm}</span>

                <Button
                  buttonStyle="icon-secondary"
                  className={tw({ "md:hidden": !isSelected })}
                  icon="Close"
                  onClick={e => {
                    e.stopPropagation();
                    removeSearchHistory(searchTerm);
                  }}
                  iconSize={20}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
);

SearchRecents.displayName = "SearchRecents";
export default SearchRecents;
