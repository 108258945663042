import { ExternalObject, LinksCategory, nodeIs } from "@utility-types/graphql";
import { trimUrl } from "utils/trimUrl";
import tw from "utils/tw";

import useSharedList from "./hooks/useSharedList";
import SharedEmptyState from "./SharedEmptyState";
import SharedListHeader from "./SharedListHeader";
import SharedListItem from "./SharedListItem";
import { FiltersKeys, SharedFilters } from "./types";

type ItemsType = { createdAt: string } & ExternalObject;
const Links = ({
  groupID,
  isWide,
  filter: selectedFilter,
  openDetailedView,
}: {
  groupID: string;
  isWide: boolean;
  filter: FiltersKeys;
  openDetailedView: () => void;
}) => {
  const { items, groupedItems, loadMoreRef, loading, isRecent, hasNextPage } =
    useSharedList({
      category: LinksCategory.Link,
      groupID,
      selectedFilter,
    });

  if (
    selectedFilter !== SharedFilters.Links &&
    selectedFilter !== SharedFilters.Recent
  ) {
    return null;
  }

  if (!groupedItems?.length && !loading) {
    return !isRecent ? <SharedEmptyState filter={SharedFilters.Links} /> : null;
  }

  return (
    <div
      className={tw("pb-4 bg-background-body shadow-level1 overflow-hidden", {
        "rounded-lg": isWide,
      })}
    >
      {loading ? (
        <>
          <div className="px-20">
            <SharedListHeader name="Links" />
          </div>
          <div className="flex flex-col">
            <SharedListItem leftIndent="20px" showSkeleton />
          </div>
        </>
      ) : isRecent ? (
        <>
          <div className="px-20">
            <SharedListHeader
              name="Links"
              onShowAll={isRecent ? openDetailedView : undefined}
            />
          </div>
          {items
            ?.filter((i): i is ExternalObject => nodeIs(i, ["ExternalObject"]))
            .map((link, index) => (
              <SharedListItem
                key={`${link.id}-${index}`} // Using index as temporary workaround while waiting for API to return unique results
                actionType="external"
                name={link.title}
                avatarURL={link.icon?.url ?? undefined}
                icon={!link.icon?.url ? "Link" : undefined}
                description={trimUrl(link.url) ?? ""}
                onClick={() => window.open(link.url, "_blank")}
                tooltip={link.url}
                leftIndent="20px"
              />
            ))}
        </>
      ) : (
        <>
          {groupedItems.map(g => (
            <div className="flex flex-col" key={g.group}>
              <div className="px-20">
                <SharedListHeader name={g.group} />
              </div>
              {g.items
                .filter((i): i is ItemsType => nodeIs(i, ["ExternalObject"]))
                .map(link => (
                  <SharedListItem
                    key={link.id}
                    actionType="external"
                    name={link.title}
                    avatarURL={link.icon?.url ?? undefined}
                    icon={!link.icon?.url ? "Link" : undefined}
                    description={trimUrl(link.url) ?? ""}
                    onClick={() => window.open(link.url, "_blank")}
                    tooltip={link.url}
                    leftIndent="20px"
                  />
                ))}
            </div>
          ))}
          {hasNextPage && (
            <div ref={loadMoreRef}>
              <SharedListItem leftIndent="20px" showSkeleton />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Links;
