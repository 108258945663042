import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import { Modal, ModalProps } from "components/ModalKit/Modal";
import useModalStore from "store/useModalStore";
import useProgressStore from "store/useProgressStore";

type Props = {
  autoCloseDelay?: number;
  header: string;
  onCancel?: () => void;
} & ModalProps;

const ProgressModal = ({
  autoCloseDelay,
  header,
  onCancel,
  ...props
}: Props) => {
  const history = useHistory();
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  const { message, value: progressPercentage } = useProgressStore(
    ({ message, value }) => ({
      message,
      value,
    })
  );

  const onCancelRef = useRef(
    (progressPercentage: number, modalId?: string, reset = false) => {
      progressPercentage !== 100 && onCancel?.();

      modalId && closeModal(modalId);

      reset && useProgressStore.setState({ message: undefined, value: 0 });
    }
  );

  // if location changes, close the modal.
  useEffect(
    () => history.listen(() => onCancelRef.current(0, props.modalId)),
    [history, props.modalId]
  );

  useEffect(() => {
    if (autoCloseDelay === undefined) return;

    let timer: NodeJS.Timeout | undefined;

    const unsubscribe = useProgressStore.subscribe(({ value }) => {
      if (value !== 100) return;

      timer = setTimeout(() => {
        if (useProgressStore.getState().message) return;

        onCancelRef.current(value, props.modalId);
      }, autoCloseDelay);
    });

    return () => {
      unsubscribe?.();
      timer && clearTimeout(timer);
    };
  }, [autoCloseDelay, props.modalId]);

  useEffect(() => {
    const onCancel = onCancelRef.current;

    return () => {
      onCancel(0);
    };
  }, []);

  return (
    <Modal
      afterClose={() =>
        onCancelRef.current(progressPercentage, undefined, true)
      }
      closeButton={false}
      containerScrolling={false}
      contentClassName="rounded"
      {...props}
    >
      <div className="flex flex-col px-32 py-16 text-center">
        <h3 className="flex justify-center items-center m-0 mb-16">{header}</h3>
        <div className="bg-gray-300 h-10 w-full">
          <div
            className={`h-full ${
              message?.type === "error"
                ? "bg-interactive-alert"
                : "bg-interactive-primary"
            }`}
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
        {message && (
          <div className="mb-10">
            <p>{message.text}</p>
            {(message.type === "error" && (
              <Button
                buttonStyle="simpleDestructive"
                buttonType="text"
                onClick={() => onCancelRef.current(0, props.modalId)}
              >
                Cancel
              </Button>
            )) || (
              <Button
                buttonStyle="primary"
                onClick={() => onCancelRef.current(0, props.modalId)}
              >
                Continue
              </Button>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ProgressModal;
