import { uniqBy } from "lodash-es";
import { useCallback, useEffect, useRef, useState } from "react";

import { MessageEdge, ThreadEdgeFeed, nodeAs } from "@utility-types";

const shownMessagesInitialCount = 2;
const useFeedItemData = ({ updatedEdge }: { updatedEdge?: ThreadEdgeFeed }) => {
  const edge = nodeAs(updatedEdge, ["ThreadEdge"]);
  const updatedEdgeRef = useRef(edge);

  const getMessagesList = useCallback(() => {
    if (!edge) return;

    const messagesEdges = [
      ...((edge.node.lastRead ?? []).edges ?? []),
      ...(edge.node.aroundLastRead.edges ?? []),
      ...(edge.node.lastUnread.edges ?? []),
    ];

    const getTimestamp = (dateString: string) => new Date(dateString).getTime();

    /**
     * @summary Feed messages were often duplicated and out of order,
     * this happened because at first they use stream ids before being updated with Glue ids
     * instead of filtering messages by id use createdAt timestamp
     * @see {@link https://linear.app/gluegroups/issue/GLU-156/}
     */
    const messages = uniqBy(
      [edge.node.firstMessage, ...messagesEdges.map(t => t.node)],
      message => message && getTimestamp(message.createdAt)
    );

    const uniqEdges = messages
      .map(m => messagesEdges.find(e => e.node?.id === m?.id))
      .filter((m): m is MessageEdge => m !== undefined);

    const lastReadIndex = uniqEdges.findIndex(
      m => m?.node.id === edge.lastReadID
    );

    const splitIndex =
      lastReadIndex === -1 ? lastReadIndex + 1 : lastReadIndex + 2;

    const readMessages = uniqEdges.slice(0, splitIndex); // Also includes first unread message
    const unreadMessages = uniqEdges.slice(splitIndex);

    return { readMessages, unreadMessages };
  }, [edge]);

  const [aboveLastReadMessages, setAboveLastReadMessages] = useState<
    MessageEdge[] | undefined
  >(() => {
    const readMessages = getMessagesList()?.readMessages ?? [];
    return readMessages.slice(-shownMessagesInitialCount);
  });

  const [belowLastReadMessages, setBelowLastReadMessages] = useState<
    MessageEdge[] | undefined
  >(() => {
    const unreadMessages = getMessagesList()?.unreadMessages ?? [];
    return unreadMessages.slice(-(shownMessagesInitialCount + 1)); // We show 2 unread messages below the fold
  });

  const [lastReadIDs, setLastReadIDs] = useState({
    id: updatedEdge?.lastReadID ?? null,
    streamID: updatedEdge?.lastReadID ?? null,
  });

  // Used to set the unread marker position
  useEffect(() => {
    if (
      updatedEdgeRef.current &&
      edge?.isRead &&
      !updatedEdgeRef.current?.isRead &&
      !lastReadIDs.id &&
      !lastReadIDs.streamID
    ) {
      const lastMessage = edge.node.lastMessage;
      if (!lastMessage) return;
      setLastReadIDs({ id: lastMessage.id, streamID: lastMessage.streamID });
    }
    updatedEdgeRef.current = edge;
  }, [lastReadIDs, edge]);

  return {
    aboveLastReadMessages,
    setAboveLastReadMessages,
    belowLastReadMessages,
    setBelowLastReadMessages,
    lastReadIDs,
  };
};

export default useFeedItemData;
