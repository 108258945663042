import { Group, Recipient, Workspace } from "@utility-types";
import { ModalProps } from "components/ModalKit/Modal";
import { RecipientsForm } from "components/RecipientsForm";
import { MemberRole } from "generated/graphql";
import useModalStore from "store/useModalStore";

import useMemberItemActions from "./hooks/useMemberItemActions";

type Props = {
  currentMembers: Recipient[];
  groupOrWorkspace: Group | Workspace;
} & ModalProps;

const AddMembersModal = ({
  currentMembers,
  groupOrWorkspace,
  ...props
}: Props): JSX.Element => {
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));
  const isWorkspace = groupOrWorkspace.__typename === "Workspace";

  const { onUpdateMembers: addMembers } = useMemberItemActions(
    groupOrWorkspace.id
  );

  const onAddMembers = (items: Recipient[]) => {
    addMembers(items.map(m => ({ member: m.id, role: MemberRole.Default })));
  };

  return (
    <RecipientsForm
      closeModal={() => closeModal(`${props.modalId}`)}
      description={`Search for colleagues to add to the ${
        groupOrWorkspace?.name
      } ${isWorkspace ? "workspace" : "group"}.`}
      excludedRecipients={currentMembers}
      saveFunction={onAddMembers}
      submitText="Add Members"
      title={
        isWorkspace
          ? `Search for colleagues to add to ${groupOrWorkspace.name}.`
          : `Add ${isWorkspace ? "workspace" : "group"} members`
      }
      {...props}
    />
  );
};

export default AddMembersModal;
