import { useCallback } from "react";
import { useAbortController } from "use-abort-controller-hook";

import ProgressModal from "components/Modals/ProgressModal";
import useModalStore from "store/useModalStore";
import useProgressStore from "store/useProgressStore";
import shareFileNativeMobile from "utils/shareFileNativeMobile";

const useDownloadFileOnMobile = () => {
  const abortController = useAbortController();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const showProgress = useCallback(
    () =>
      openModal(
        <ProgressModal
          autoCloseDelay={0}
          header={"Downloading File"}
          onCancel={abortController.abort}
        />
      ),
    [abortController.abort, openModal]
  );

  return useCallback(
    (url: string, name: string) =>
      shareFileNativeMobile({
        abortSignal: abortController.signal,
        dialogTitle: "Downloading file",
        fileName: name,
        onError: () =>
          useProgressStore.setState({
            message: {
              text: "Unknown error while sharing file.",
              type: "error",
            },
          }),
        title: name,
        showProgress,
        url,
      }),
    [abortController.signal, showProgress]
  );
};

export default useDownloadFileOnMobile;
