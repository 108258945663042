import { useMemo } from "react";

import {
  LlmProvider,
  useFetchLlmModelsQuery,
  useFetchWorkspaceSettingsQuery,
} from "generated/graphql";
import glueImageURL from "utils/glueImageURL";

export function useLlmProviders(workspaceID?: string) {
  const { data: llmModelsData } = useFetchLlmModelsQuery({
    fetchPolicy: "cache-and-network",
    skip: !workspaceID,
    variables: {
      workspaceID: workspaceID ?? "",
    },
  });

  const { data: settingsData } = useFetchWorkspaceSettingsQuery({
    fetchPolicy: "cache-and-network",
    skip: !workspaceID,
    variables: {
      workspaceID: workspaceID ?? "",
    },
  });

  return useMemo(
    () =>
      llmModelsData?.llmModels.edges.reduce(
        (
          acc: {
            id?: string;
            keyPreview?: string;
            provider: LlmProvider;
            name: string;
            iconSrc: string;
          }[],
          { node }
        ) => {
          const existingProvider = acc.find(a => a.provider === node.provider);

          if (
            !existingProvider &&
            Object.values(LlmProvider).includes(node.provider)
          ) {
            const currentApiKey =
              settingsData?.workspaceSettings.llmSettings.llmAPIKeys.find(
                k => k.llmProvider === node.provider
              );

            acc.push({
              id: currentApiKey?.id,
              keyPreview: currentApiKey?.keyPreview,
              provider: node.provider,
              name: node.providerName,
              iconSrc: glueImageURL(node.providerLogoURL, {
                fit: "max",
                w: 40,
                h: 40,
              }),
            });
          }

          return acc;
        },
        []
      ),
    [settingsData, llmModelsData]
  );
}
