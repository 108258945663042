import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { animated, useTransition } from "@react-spring/web";
import { trim } from "lodash-es";
import {
  ComponentProps,
  Dispatch,
  SetStateAction,
  cloneElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import CloneElement from "components/design-system/CloneElement";
import type { ModalProps } from "components/ModalKit/Modal";
import { Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import { Portal } from "components/Portal";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import useSafeAreaInsetsStore from "store/useSafeAreaInsetsStore";
import tw from "utils/tw";

type ModalHeight = Pick<
  ComponentProps<typeof StandardModal>,
  "heightAuto" | "height"
>;

const DropdownModal = ({
  content,
  header,
  modalContentHandlesSafeArea,
  height,
  heightAuto = true,
  ...props
}: {
  content: JSX.Element;
  header?: React.ReactNode;
  modalContentHandlesSafeArea?: boolean;
} & ModalProps &
  ModalHeight) => (
  <StandardModal
    contentHandlesSafeArea={modalContentHandlesSafeArea}
    header={<Header>{header}</Header>}
    height={height}
    heightAuto={heightAuto}
    {...props}
  >
    <Main className="pb-[env(safe-area-inset-bottom, 0)]">
      {typeof content === "string" ? (
        <div>{content}</div>
      ) : (
        <CloneElement modalId={props.modalId}>{content}</CloneElement>
      )}
    </Main>
  </StandardModal>
);

export type DropdownProps = ModalHeight & {
  children: JSX.Element;
  className?: string;
  content: JSX.Element;
  disabled?: boolean;
  disableFlip?: boolean;
  header?: JSX.Element;
  keyboardHandlers?: boolean;
  modalContentHandlesSafeArea?: boolean;
  offsetCross?: number;
  offsetMain?: number;
  onClose?: () => void;
  onStart?: () => void;
  onlyRemoteControl?: boolean;
  open?: boolean;
  placement?: Placement;
  position?: { left?: number; top?: number };
  setOpen?: Dispatch<SetStateAction<boolean>>;
  toModal?: boolean;
};

export const Dropdown = ({
  children,
  className,
  content,
  disabled,
  disableFlip,
  header,
  height,
  heightAuto,
  keyboardHandlers = true,
  modalContentHandlesSafeArea,
  offsetCross = 0,
  offsetMain = -5,
  onClose = () => undefined,
  onlyRemoteControl,
  onStart = () => undefined,
  open: openProp = false,
  placement: defaultPlacement = "bottom-start",
  position,
  setOpen: setOpenProp,
  toModal,
}: DropdownProps) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const isModal = !breakpointMD && toModal;
  const modalIDRef = useRef<string>();

  const { closeModal, openModal } = useModalStore(
    ({ closeModal, openModal }) => ({
      closeModal,
      openModal,
    })
  );

  const handleOpenModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    modalIDRef.current = openModal(
      <DropdownModal
        afterClose={onClose}
        afterOpen={onStart}
        content={content}
        header={header}
        height={height}
        heightAuto={heightAuto}
        modalContentHandlesSafeArea={modalContentHandlesSafeArea}
      />
    );
  };

  const firstRenderRef = useRef(true);
  const onStartRef = useRef(onStart);
  const onCloseRef = useRef(onClose);

  const [isOpen, setIsOpen] = useState(openProp);

  const handleDropdown = (open: boolean) => {
    if (onlyRemoteControl) return;
    setOpenProp?.(open) || setIsOpen(open);
  };

  useEffect(() => {
    if (openProp === false && modalIDRef.current) {
      closeModal(modalIDRef.current);
      modalIDRef.current = undefined;
    }

    setIsOpen(openProp);
  }, [closeModal, openProp]);

  useEffect(() => {
    if (firstRenderRef.current) return;
    isOpen ? onStartRef.current() : onCloseRef.current();
  }, [isOpen]);

  useEffect(() => {
    onStartRef.current = onStart;
    onCloseRef.current = onClose;
    firstRenderRef.current = false;
  }, [onClose, onStart]);

  const padding = useSafeAreaInsetsStore();

  const flipMiddleware = disableFlip
    ? []
    : [flip({ crossAxis: false, padding })];

  const { context, refs, strategy, x, y } = useFloating({
    middleware: [
      offset({
        crossAxis: offsetCross,
        mainAxis: offsetMain,
      }),
      ...flipMiddleware,
      shift({ padding }),
    ],
    onOpenChange: handleDropdown,
    open: isOpen,
    placement: defaultPlacement,
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context, { keyboardHandlers });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "dialog" });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const referenceProps = useMemo(() => {
    const originalReferenceProps = getReferenceProps({
      onClick: (event: React.MouseEvent) => {
        event.stopPropagation();
      },
      ref: refs.setReference,
    });
    delete originalReferenceProps.onPointerDown; // prevents clobbering existing onPointerDown events

    return {
      ...originalReferenceProps,
    };
  }, [getReferenceProps, refs.setReference]);

  const transitions = useTransition(isOpen, {
    config: { friction: 100, mass: 2, tension: 2000 },
    enter: { opacity: 1, scale: 1 },
    from: { opacity: 0, scale: 0.75 },
    leave: { opacity: 0, scale: 0.75 },
  });

  return (
    <>
      {cloneElement(
        children,
        disabled
          ? {}
          : !isModal
            ? {
                ...(referenceProps ?? {}),
              }
            : {
                className: trim(tw("select-none", children.props.className)),
                onClick: handleOpenModal,
                ref: refs.setReference,
              }
      )}
      <Portal id="overlays" zIndex="100">
        {transitions(
          (styles, open) =>
            open &&
            !isModal && (
              <animated.div
                ref={refs.setFloating}
                className={tw(
                  "bg-background-body max-w-full min-w-[200px] overflow-hidden rounded-lg",
                  "border border-border-container shadow-level2",
                  className
                )}
                data-testid="dropdown"
                style={{
                  left: position?.left ?? x ?? 0,
                  position: strategy,
                  top: position?.top ?? y ?? 0,
                  ...styles,
                }}
                {...getFloatingProps()}
              >
                {header}
                <CloneElement open={open} setOpen={handleDropdown}>
                  {content}
                </CloneElement>
              </animated.div>
            )
        )}
      </Portal>
    </>
  );
};

export default Dropdown;
