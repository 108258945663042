import { useCallback } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { GroupEdge, nodeAs } from "@utility-types";
import { Form } from "components/design-system/Forms";
import {
  FetchExternalGroupsQueryResult,
  useFetchWorkspaceQuery,
} from "generated/graphql";
import tw from "utils/tw";

import GroupDirectorySkeletons from "./GroupDirectorySkeletons";
import { GroupsDirectoryRowPrimitive } from "./GroupsDirectoryRow";
import GroupsDirectorySearchInput from "./GroupsDirectorySearchInput";

const ExternalGroupsDirectoryRow = ({ edge }: { edge: GroupEdge }) => {
  const { data } = useFetchWorkspaceQuery({
    skip: !edge.node.workspaceID,
    variables: { id: edge.node.workspaceID ?? "" },
  });

  const workspace = nodeAs(data?.node, ["WorkspacePreview"]);

  return (
    <GroupsDirectoryRowPrimitive
      key={edge.node.id}
      badgeProps={{
        avatarURL: workspace?.avatarURL,
        background: "transparent",
        name: workspace?.name,
        size: "tiny",
      }}
      edge={edge}
      workspaceName={workspace?.name}
    />
  );
};

const ExternalGroupsDirectory = ({
  externalGroupsData,
  searchState,
}: {
  externalGroupsData: {
    afterCursor: string | null;
    externalGroups: GroupEdge[];
    fetchMore: FetchExternalGroupsQueryResult["fetchMore"];
    hasNextPage: boolean;
    loading: boolean;
  };
  searchState: [string, React.Dispatch<React.SetStateAction<string>>];
}) => {
  const { afterCursor, externalGroups, fetchMore, hasNextPage, loading } =
    externalGroupsData;
  const [searchTerm, setSearchTerm] = searchState;

  const [scrollSentryRef, { rootRef: listRef }] = useInfiniteScroll({
    hasNextPage,
    loading,
    onLoadMore: useCallback(() => {
      fetchMore({ variables: { groupsAfter: afterCursor } }).catch(err => {
        console.warn(
          "Error: [Group Directory - External Groups] - ",
          err.message
        );
      });
    }, [afterCursor, fetchMore]),
    rootMargin: "0px 0px 200px 0px",
  });

  return (
    <div ref={listRef} className="grow overflow-auto">
      <div className="flex items-center justify-between max-w-[832px] mx-auto px-16 w-full">
        <div className="mt-16 w-full">
          <Form useFormProps={{ defaultValues: { search: searchTerm } }}>
            <GroupsDirectorySearchInput onChange={setSearchTerm} />
          </Form>
        </div>
      </div>

      <div className="max-w-[832px] mx-auto px-16 w-full">
        <div
          className={tw(
            "bg-background-body my-16 rounded-lg shadow-level1 overflow-hidden",
            "[&>div]:border-b-1 [&>div]:border-border-subtle", // Row borders
            "[&>div:last-child]:border-none" // Remove last row border
          )}
        >
          {externalGroups?.map(group => (
            <ExternalGroupsDirectoryRow key={group.node.id} edge={group} />
          ))}

          <GroupDirectorySkeletons
            ref={scrollSentryRef}
            count={4}
            on={(loading && (!externalGroups || !!searchTerm)) || hasNextPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ExternalGroupsDirectory;
