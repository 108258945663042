import { RefetchQueryDescriptor, useApolloClient } from "@apollo/client";
import { useEffect, useRef } from "react";

import useAppStateStore from "store/useAppStateStore";

import useAuthData from "./useAuthData";

const useRefetchOnAppActive = (refetchQueries: RefetchQueryDescriptor[]) => {
  const { authReady } = useAuthData();
  const refetchQueriesRef = useRef(refetchQueries);
  refetchQueriesRef.current = refetchQueries;

  const apolloClient = useApolloClient();

  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));

  useEffect(() => {
    if (appStatus === "inactive" || !authReady) return;
    apolloClient.refetchQueries({ include: refetchQueriesRef.current });
  }, [apolloClient, appStatus, authReady]);
};

export default useRefetchOnAppActive;
