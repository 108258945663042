import { memo, useState } from "react";
import { environment } from "remirror";

import useLocalSettingsStore from "store/useLocalSettingsStore";
import { isNativeMobile } from "utils/platform";
import tw from "utils/tw";

const SendMessageHelperText = memo(({ className }: { className?: string }) => {
  const [showSendMessageHelperText] = useState(
    !isNativeMobile() &&
      !useLocalSettingsStore.getState().hasSentMessageViaCMDEnter
  );
  if (!showSendMessageHelperText) return null;

  return (
    <div
      className={tw(
        "hidden mouse:block",
        "text-right text-xs font-semibold text-text-disabled py-6 px-16",
        className
      )}
    >
      {`${environment.isMac ? "Cmd" : "Ctrl"} + Enter to send`}
    </div>
  );
});

export default SendMessageHelperText;
