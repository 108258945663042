import { type ComponentProps, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { type Recipient } from "@utility-types";
import { usePartitionState } from "components/routing/RoutingPartition";
import { currentPathWithoutDrawer } from "components/routing/utils";
import AddMentionToThread from "components/thread/AddMentionToThread/AddMentionToThread";
import NotInThread from "components/thread/NotInThread/NotInThread";
import ThreadChannel from "components/thread/ThreadView/components/ThreadChannel";
import { ThreadViewProvider } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { ThreadRecipientEdge } from "generated/graphql";
import useFetchThreadEdge, {
  useFetchThreadEdgeSimple,
} from "hooks/thread/useFetchThreadEdge";
import useFetchThreadReplyTo from "hooks/thread/useFetchThreadReplyTo";
import useHistoryItem from "hooks/useHistoryItem";
import useMemberEdge from "hooks/useMemberEdge";
import useThreadEdgeMessageable from "hooks/useThreadEdgeMessageable";
import useAppStateStore from "store/useAppStateStore";

import NotMessageableBanner from "./NotMessageableBanner";
import ChatThreadHeader from "./ThreadHeader/ChatThreadHeader";
import ThreadHeader from "./ThreadHeader/ThreadHeader";
import ThreadReply from "./ThreadReply";

type ThreadViewWithoutFocusProps = Omit<
  ComponentProps<typeof ThreadViewProvider>,
  "threadID"
> & {
  headerType?: "none" | "simple" | "full";
  onSubmit?: () => void;
  secondaryPane?: boolean;
  setFocusedThreadId?: (id: string) => void;
  threadID?: string;
};

export const ThreadViewWithoutFocus = ({
  headerType = "full",
  mailbox,
  messageID,
  onSubmit,
  recipientID,
  secondaryPane,
  setFocusedThreadId,
  threadID,
}: ThreadViewWithoutFocusProps) => {
  const history = useHistory();

  // use cached data from thread list if available
  const { threadEdge, loading } = useFetchThreadEdgeSimple(threadID);
  const { memberEdge, previewEdge } = useMemberEdge(threadEdge);

  // fetch full data for header + refetch in case user added/removed
  const { threadEdge: edgeFull } = useFetchThreadEdge(threadID, {
    fetchPolicy: "cache-and-network",
  });

  const replyTo = useFetchThreadReplyTo(memberEdge?.node);

  const [lastMention, setLastMention] = useState<Recipient>();

  useEffect(() => {
    setLastMention(undefined);
  }, [memberEdge]);

  const ref = useRef<HTMLDivElement>(null);
  const [isActiveThread, setIsActiveThread] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current;
    const pointerHandler = (e: PointerEvent) => {
      // we maybe want to check what was clicked?
      e && setIsActiveThread(true);
    };

    const focusHandler = () => setIsActiveThread(true);

    el.addEventListener("pointerdown", pointerHandler);
    el.addEventListener("focusin", focusHandler);

    return () => {
      el.removeEventListener("focusin", focusHandler);
      el.removeEventListener("pointerdown", pointerHandler);
    };
  }, [isActiveThread, threadID]);

  useEffect(() => {
    if (!isActiveThread) return;
    setFocusedThreadId?.(threadID ?? "");
  }, [isActiveThread, setFocusedThreadId, threadID]);

  useEffect(
    () =>
      useAppStateStore.subscribe(
        ({ activeThreadId }) =>
          activeThreadId !== threadID && setIsActiveThread(false)
      ),
    [threadID]
  );

  const onThreadClose = secondaryPane
    ? () => history.push(currentPathWithoutDrawer())
    : undefined;

  const { recipients, isPersistentChat } = memberEdge?.node || {};
  const threadTitle = threadEdge?.node.subject;

  useHistoryItem({
    // NOTE how to type this correctly?
    recipientsEdges: recipients?.edges as ThreadRecipientEdge[],
    title: threadTitle,
    isPersistentChat,
  });

  const threadStarterID = memberEdge?.node.recipients.edges?.[0]?.node.id;
  const isMessageable = useThreadEdgeMessageable(memberEdge);

  return (
    <div ref={ref} className="flex flex-col grow h-full w-full">
      {headerType !== "none" ? (
        memberEdge?.node.isPersistentChat ? (
          <ChatThreadHeader
            onClose={onThreadClose}
            threadEdge={threadEdge}
            threadEdgeFull={edgeFull}
          />
        ) : (
          <ThreadHeader
            headerType={headerType}
            onClose={onThreadClose}
            replyTo={replyTo}
            threadEdge={threadEdge}
            threadEdgeFull={edgeFull}
          />
        )
      ) : null}

      {threadID ? (
        previewEdge ? ( // don't show "not in thread" until determined
          <NotInThread threadID={threadID} />
        ) : memberEdge || loading ? (
          <ThreadViewProvider
            isMessageable={isMessageable}
            mailbox={mailbox}
            messageID={messageID}
            messageableBy={memberEdge?.node.messageableBy}
            recipientID={recipientID}
            recipientRole={memberEdge?.recipientRole}
            replyTo={replyTo}
            threadStartedAt={
              memberEdge?.node.firstMessage?.createdAt ??
              memberEdge?.node.createdAt
            }
            threadStarterID={threadStarterID}
            threadPane={secondaryPane ? "secondary" : "primary"}
            threadID={threadID}
            threadWorkspaceID={memberEdge?.node.workspaceID ?? undefined}
            isPersistentChat={isPersistentChat}
          >
            <ThreadChannel>
              <AddMentionToThread
                mention={lastMention}
                threadEdge={memberEdge}
              />
              {isMessageable !== false ? (
                <ThreadReply onMention={setLastMention} onSubmit={onSubmit} />
              ) : (
                <NotMessageableBanner
                  isPersistentChat={!!isPersistentChat}
                  variant="thread"
                />
              )}
            </ThreadChannel>
          </ThreadViewProvider>
        ) : null
      ) : null}
    </div>
  );
};

/**
 * use the default export in most cases;
 * use the named export (above) when needing to use the Thread outside of the partition context, such as in a modal.
 */
const ThreadView = (props: ThreadViewWithoutFocusProps) => {
  const { setFocusedThreadId } = usePartitionState(
    ({ setFocusedThreadId }) => ({
      setFocusedThreadId,
    })
  );

  return (
    <ThreadViewWithoutFocus
      setFocusedThreadId={setFocusedThreadId}
      {...props}
    />
  );
};

export default ThreadView;
