import { ApolloError, ApolloQueryResult } from "@apollo/client";
import { useCallback, useRef } from "react";

import { Mailbox, ThreadEdgeFeed } from "@utility-types";
import {
  ThreadFeedQuery,
  ThreadsOrder,
  useThreadFeedQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import usePrevious from "hooks/usePrevious";
import useLocalSettingsStore from "store/useLocalSettingsStore";

type Props = {
  excludeChats?: boolean;
  mailbox: Mailbox;
  order?: ThreadsOrder;
  pageSize: number;
  recipientID: string | undefined;
};

export type ThreadListData = {
  hasNextPage: boolean;
  loadNextPage: () => Promise<ApolloQueryResult<ThreadFeedQuery>>;
  pagingReset: boolean;
  refresh: () => Promise<ApolloQueryResult<ThreadFeedQuery>>;
  result: {
    error: ApolloError | undefined;
    loading: boolean;
    threadEdges: ThreadEdgeFeed[] | undefined;
    totalCount: number | undefined;
  };
};

const useThreadListData = ({
  excludeChats = undefined,
  mailbox,
  order,
  pageSize,
  recipientID,
}: Props): ThreadListData => {
  const { authReady } = useAuthData();

  const { currentThreadSort } = useLocalSettingsStore(
    ({ currentThreadSort }) => ({ currentThreadSort })
  );

  const { data, error, fetchMore, loading, refetch } = useThreadFeedQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: {
      excludeChats,
      limit: pageSize,
      mailbox,
      order: order || currentThreadSort,
      recipientID,
      includeLastRead: true,
    },
  });

  const nextCursor = useRef<string | null>(null);
  nextCursor.current = data?.threads.pageInfo.startCursor || null;

  const loadNextPage = useCallback(
    () => fetchMore({ variables: { before: nextCursor.current } }),
    [fetchMore]
  );

  const threadEdges = data?.threads.edges;
  const edgesLength = threadEdges?.length || 0;
  const pagingReset =
    usePrevious(edgesLength) - edgesLength >= pageSize || !threadEdges;
  const hasNextPage = !!data?.threads.pageInfo.hasPreviousPage;
  const totalCount = data?.threads.totalCount;

  return {
    hasNextPage,
    loadNextPage,
    pagingReset,
    refresh: () => refetch(),
    result: { error, loading, threadEdges, totalCount },
  };
};

export default useThreadListData;
