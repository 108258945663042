import { Emoji, hasEmoji } from "components/design-system/Emoji";
import { Skeleton } from "components/Skeleton";
import { useLongPress } from "hooks/useLongPress";
import { deviceHasMouse } from "utils/deviceHasMouse";
import tw from "utils/tw";

export const reactionClassName = (own: boolean, className?: string) =>
  tw(
    "box-content flex items-center rounded-[16px] cursor-pointer select-none",
    "py-3 px-5 h-16 border-1",
    "text-text-subtle border-background-subtle",
    "hover:border-interactive-subtle",
    {
      " !bg-accent-highlight/20 !border-accent-highlight": own,
    },
    className
  );

type ReactionProps = {
  count: number;
  handleOpenReactionsModal: (emojiID: string) => void;
  handleReaction: (emojiID: string) => void;
  name: string;
  ownReaction: boolean;
  setCurrentTarget: (name: string) => void;
  url?: string;
  readonly: boolean;
};

export const Reaction = ({
  count,
  handleOpenReactionsModal,
  handleReaction,
  name,
  ownReaction,
  setCurrentTarget,
  url,
  readonly,
}: ReactionProps) => {
  const hasMouse = deviceHasMouse();

  const bindLongPress = useLongPress<HTMLButtonElement>(
    event => {
      if (!event || hasMouse) return;
      event.preventDefault();
      event.stopPropagation();
      handleOpenReactionsModal(name);
    },
    {
      cancelOnMovement: 10,
      onShortPress: () => handleReaction(name),
    }
  ).handlers;

  if (!count) return null;

  return (
    <span
      className="inline-block px-2"
      onPointerEnter={() => setCurrentTarget(name)}
    >
      <button
        className={reactionClassName(
          ownReaction,
          readonly ? "!cursor-auto !border-none !bg-background-secondary" : ""
        )}
        type="button"
        {...bindLongPress}
      >
        {hasEmoji(name, true) || url ? (
          <Emoji name={name} size={16} url={url} />
        ) : (
          <Skeleton width="16px" height="16px" />
        )}
        <span className="ml-4 text-xs leading-none">{count}</span>
      </button>
    </span>
  );
};
