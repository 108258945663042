import Sidebar from "components/SideBar/Sidebar";

import ActivityMainTabs from "./ActivityMain/ActivityMainTabs";

const ActivityDesktop = () => {
  return (
    <Sidebar id="mega-tab-sidebar--activity">
      <ActivityMainTabs title="Activity" desktopHeader />
    </Sidebar>
  );
};

export default ActivityDesktop;
