import env from "utils/processEnv";

import { flagsConfig } from "./types";

/**
 *
 * Use the [FLAG_NAME] as key in the config object
 *
 * ```ts
 * const config {
 *  [FLAG_NAME]: FlagConfig
 * },
 * ```
 *
 */

const flags = flagsConfig({
  ARCHIVE_GROUPS_FOR_EVERYONE: {
    environments: { development: false, staging: false },
    toggleable: true,
  },
  LAMBDAS: {
    environments: { development: false, staging: false, production: false },
    toggleable: true,
  },
  READ_RECEIPTS: {
    environments: { development: true, staging: true, production: false },
    toggleable: true,
  },
  PROSEMIRROR_DEVTOOLS: {
    environments: { development: false },
    toggleable: true,
  },
  REACT_SCAN: {
    environments: { development: false },
    toggleable: true,
  },
  SERVICE_WORKER: {
    environments: { development: false, staging: false },
    toggleable: true,
  },
});

export default flags;

export type FlagName = keyof typeof flags;
export const flagNames = Object.keys(flags) as FlagName[];
export const environmentFlags = flagNames.filter(
  name => env.glueEnv in flags[name].environments
);
