import { SlackImportStatus } from "@utility-types/graphql";
import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import ConfirmationRequiredModal from "components/Modals/ConfirmationRequiredModal";
import ImportStatusModal from "components/SlackImport/ImportStatusModal";
import SlackImportModal from "components/SlackImport/SlackImportModal";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";
import {
  MailboxCountsDocument,
  WorkspaceFieldsFragment,
  WorkspacesAndGroupsListDocument,
  useDeleteWorkspaceMutation,
  useSlackImportQuery,
} from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

import useWorkspaceSubscription from "./hooks/useWorkspaceSubscription";

type SectionProps = {
  title: string;
  subtitle: string;
};
const WorkspaceAdvancedSection = ({
  title,
  subtitle,
  children,
}: WithChildren<SectionProps>) => {
  return (
    <div className="flex items-center pb-20 border-b-1 border-border-container">
      <div className="flex flex-col grow pr-8">
        <span className="text-body-bold">{title}</span>
        <span className="text-body text-text-secondary">{subtitle}</span>
      </div>
      {children}
    </div>
  );
};

type Props = {
  workspace: WorkspaceFieldsFragment;
};
const WorkspaceAdvanced = ({ workspace }: Props) => {
  const { signOut, fetchAuthData } = useAuthData();
  const { evictNode } = useCacheEvict();
  const { openModal, closeAllModals } = useModalStore(
    ({ openModal, closeAllModals }) => ({ openModal, closeAllModals })
  );
  const { data: slackImport, loading: slackImportLoading } =
    useSlackImportQuery({
      fetchPolicy: "network-only",
      variables: { workspaceID: workspace.id },
    });
  const { composeThreadToGlue } = useComposeToGlue("support");
  const { data: wksSubscriptionData } = useWorkspaceSubscription(workspace.id);
  const wksSubscription = wksSubscriptionData?.workspaceSubscription;

  const [deleteWorkspace] = useDeleteWorkspaceMutation({ errorPolicy: "all" });
  const handleDeleteWorkspace = () =>
    fetchAuthData().then(data => {
      const hadSingleWorkspace = data.workspaces.edges.length === 1;
      return deleteWorkspace({
        awaitRefetchQueries: true,
        refetchQueries: [
          MailboxCountsDocument,
          WorkspacesAndGroupsListDocument,
        ],
        variables: { id: workspace.id },
      })
        .then(({ data }) => {
          if (!data?.deleteWorkspace) return;
          evictNode(workspace);
        })
        .finally(() => {
          closeAllModals();
          hadSingleWorkspace && signOut();
        });
    });

  const handleSlackImportAction = () => {
    if (slackImport?.slackImport?.status === SlackImportStatus.Running) {
      openModal(<ImportStatusModal workspaceID={workspace.id} />);
      return;
    }
    openModal(<SlackImportModal workspaceID={workspace.id} />);
  };

  return (
    <div className="flex flex-col py-16 gap-20">
      <WorkspaceAdvancedSection
        title="Slack import"
        subtitle="Import your Slack directly to Glue."
      >
        <Button
          className="text-nowrap"
          buttonStyle="simplePrimary"
          onClick={handleSlackImportAction}
          disabled={slackImportLoading}
        >
          {slackImport?.slackImport?.status === SlackImportStatus.Running
            ? "View status"
            : "Get started"}
        </Button>
      </WorkspaceAdvancedSection>

      <WorkspaceAdvancedSection
        title="Delete workspace"
        subtitle={
          wksSubscription?.plan
            ? `Permanently delete ${workspace.name} and cancel subscription.`
            : `Permanently delete ${workspace.name}.`
        }
      >
        <Button
          buttonStyle="simpleDestructive"
          onClick={() =>
            openModal(
              <ConfirmationRequiredModal
                title={
                  wksSubscription?.plan
                    ? `Are you sure you want to permanently delete the ${workspace.name} workspace and cancel your ${wksSubscription.plan.name} plan?`
                    : `Are you sure you want to permanently delete the ${workspace.name} workspace?`
                }
                subtitle={
                  wksSubscription?.plan
                    ? "All workspace groups will be deleted, users will lose access to all threads, and you will no longer be billed."
                    : "All workspace groups will be deleted and users will lose access to all threads."
                }
                confirmButtonText="Delete workspace"
                footerAction={
                  <Button
                    buttonStyle="simpleSecondary"
                    type="button"
                    onClick={() => composeThreadToGlue()}
                  >
                    Contact support
                  </Button>
                }
                onConfirm={handleDeleteWorkspace}
              >
                <InformationBubble iconProps={{ className: "text-icon-alert" }}>
                  This action can't be undone. Once confirmed, all account data
                  will be permanently deleted.
                </InformationBubble>
              </ConfirmationRequiredModal>
            )
          }
        >
          {wksSubscription?.plan ? "Delete & cancel" : "Delete"}
        </Button>
      </WorkspaceAdvancedSection>
    </div>
  );
};

export default WorkspaceAdvanced;
