import { useState } from "react";

import { nodeAs } from "@utility-types";
import { ThreadsApps } from "components/apps";
import { ModalProps } from "components/ModalKit/Modal";
import { Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import { Tabs } from "components/Tabs";
import {
  Addable,
  MemberRole,
  useFetchThreadEdgeQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import useAppStateStore from "store/useAppStateStore";
import { isMobile } from "utils/platform";
import tw from "utils/tw";

import useThreadApps from "./hooks/useThreadApps";
import SubjectForm from "./SubjectForm";
import ThreadRecipients from "./ThreadRecipients";

type Props = {
  threadID?: string;
} & ModalProps;

enum ThreadTab { // in order of UI appearance
  Recipients = "recipients",
  Apps = "apps",
}

const ThreadInfoModal = ({ threadID, ...props }: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const [selectedTab, setSelectedTab] = useState<ThreadTab>(
    ThreadTab.Recipients
  );
  const { authData, authReady } = useAuthData();

  const { data } = useFetchThreadEdgeQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id || !threadID,
    variables: { id: `${threadID}-${authData?.me.id}` },
  });

  const threadEdge = nodeAs(data?.node, ["ThreadEdge", "ThreadPreviewEdge"]);

  const { memberEdge, previewEdge } = useMemberEdge(threadEdge);
  const dataEdge = memberEdge || previewEdge;

  const isAdmin = memberEdge?.recipientRole === MemberRole.Admin;
  const isLocked = memberEdge?.node.recipientsAddable === Addable.None;

  const thread = dataEdge?.node;

  const appsAdded = nodeAs(thread, ["Thread"])?.apps.edges ?? [];

  const { addApp, appsAvailable, editApp, removeApp, workspaceID } =
    useThreadApps(thread);

  const tabs = [
    {
      tab: ThreadTab.Recipients,
    },
    {
      tab: ThreadTab.Apps,
    },
  ];

  const showTabs =
    (!isMobile() || breakpointMD) &&
    (appsAdded?.length || appsAvailable?.length);

  if (!thread) return <div />;

  return (
    <StandardModal {...props} closeButtonPosition="right">
      <Main className="pt-16">
        <div
          className={tw(
            "pb-16 md:border-none px-16 md:pl-28 md:pr-20 mr-20",
            showTabs ? "md:pb-12" : "md:pb-0",
            {
              "border-b-1 border-border-container": !showTabs && !breakpointMD,
            }
          )}
        >
          <SubjectForm canEdit={isAdmin || !isLocked} thread={thread} />
        </div>
        {showTabs ? (
          <Tabs<ThreadTab>
            className="border-b border-border-container px-16 md:px-32"
            onClickTab={tab => setSelectedTab(tab)}
            selectedTab={selectedTab}
            tabs={tabs}
          />
        ) : null}
        <div className="px-16 md:px-32">
          {selectedTab === ThreadTab.Recipients && (
            <div className="py-20">
              <ThreadRecipients
                isAdmin={isAdmin}
                isLocked={isLocked}
                isMember={!!memberEdge}
                modalId={props.modalId}
                thread={thread}
                threadEdge={threadEdge}
              />
            </div>
          )}
          {selectedTab === ThreadTab.Apps && (
            <div className="pt-4 pb-20">
              <ThreadsApps
                addApp={addApp}
                canChangeApps={!isLocked || isAdmin}
                configureApp={app => editApp(app, thread?.id, workspaceID)}
                appsAdded={appsAdded}
                appsAvailable={appsAvailable}
                name={thread.subject}
                removeApp={removeApp}
                workspaceID={workspaceID}
              />
            </div>
          )}
        </div>
      </Main>
    </StandardModal>
  );
};

export default ThreadInfoModal;
