import Avatar from "components/design-system/Avatar/Avatar";
import usePerformThreadAction from "components/Masonry/usePerformThreadAction";
import {
  ThreadAction,
  ThreadActionInput,
  usePerformThreadActionMutation,
} from "generated/graphql";

type ThreadActionType = ThreadActionInput &
  Pick<ThreadAction, "description" | "label" | "path"> & {
    avatarUrl: string;
  };

type Props = {
  setOpen?: (open: boolean) => void;
  threadActions: {
    actions: ThreadActionType[];
    title: string;
  }[];
};

const hasPath = (
  action: ThreadActionType
): action is ThreadActionType & { path: string } => {
  return !!action.path;
};

const ThreadActionsList = ({ setOpen, threadActions }: Props) => {
  const [performThreadAction] = usePerformThreadActionMutation();

  const { perform } = usePerformThreadAction();

  const onClick = (action: ThreadActionType) => {
    if (hasPath(action)) {
      perform(action);
    } else {
      performThreadAction({ variables: { input: { ...action } } });
    }

    setOpen?.(false);
  };

  return (
    <>
      {threadActions.map(threadAction => (
        <div
          key={threadAction.title + threadAction.actions[0]?.appID}
          className="py-8 min-w-[375px]"
        >
          <span className="font-semibold text-text-subtle px-12">
            {threadAction.title}
          </span>
          <ul className="mt-2">
            {threadAction.actions.map(action => (
              <li
                key={action.label}
                className="flex items-center px-12 py-4 hover:bg-accent-highlight/25 hover:cursor-pointer"
                onClick={() => onClick(action)}
              >
                <Avatar avatarURL={action.avatarUrl} size="medium" />
                <div className="flex flex-col ml-8">
                  <span className="font-semibold leading-5">
                    {action.label}
                  </span>
                  <span className="text-text-subtle">{action.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default ThreadActionsList;
