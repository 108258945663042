import { Route, Switch, matchPath } from "react-router";

import AppTabLayout from "components/App/AppLayoutMobile/AppTabLayout";
import NewThreadButton from "components/design-system/ui/NewThreadButton";
import MobileProfilePane from "components/design-system/ui/ProfilePane/MobileProfilePane";
import SidebarAccessoryContainer from "components/design-system/ui/SidebarAccessoryContainer";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  GroupTabs,
  PathMatch,
  PathnameParams,
  TabName,
  routeParams as getRouteParams,
  locationFromRoute,
  routePath,
} from "components/routing/utils";
import ThreadCompose from "components/threads/ThreadCompose/ThreadCompose";
import ThreadPane from "components/threads/ThreadPane";
import Group from "components/views/groups/Group";
import GroupsDirectory from "components/views/groups/GroupsDirectory/GroupsDirectory";
import tw from "utils/tw";

import ThreadsMobile from "../threads/ThreadsMobile";

import { INBOX_SIDEBAR_ID } from "./InboxMain";
import InboxSidebarAccessories from "./InboxSidebarAccessories";
import InboxSidebarSections from "./InboxSidebarSections";
import InboxAppsMobile from "./InboxViews/InboxAppsMobile";
import InboxJoinRequests from "./InboxViews/InboxJoinRequests";

const InboxMainMobile = ({
  scrollRef,
  setScrollRef,
}: {
  scrollRef: HTMLDivElement | HTMLUListElement | null;
  setScrollRef: (el: HTMLDivElement | null) => void;
}) => {
  return (
    <ViewScrollContainer
      accessories={<InboxSidebarAccessories showEducationCard={false} />}
      className={tw(
        "pt-8 px-4 md:px-8",
        "transform-gpu translate-x-0" // fix Safari bug: https://github.com/PolymerElements/iron-list/issues/18#issuecomment-329519210
      )}
      id={INBOX_SIDEBAR_ID}
      scrollRef={setScrollRef}
    >
      <InboxSidebarSections scrollRef={scrollRef} />
    </ViewScrollContainer>
  );
};

const InboxMobile = ({
  scrollRef,
  setScrollRef,
}: {
  scrollRef: HTMLDivElement | HTMLUListElement | null;
  setScrollRef: (el: HTMLDivElement | null) => void;
}) => {
  const { route } = usePartitionState(({ route }) => ({ route }));
  const { pathname, search } = locationFromRoute(route);
  const { appID, d, messageID, superTab, recipientID, threadID, userID, t, v } =
    getRouteParams({
      pathname,
      search,
    });

  const isView = !!matchPath<PathnameParams>(pathname, PathMatch.inboxViews);

  const detailContent: React.ReactNode = (() => {
    if (d) {
      return <MobileProfilePane id={d} />;
    }

    let content = recipientID ? (
      <Group defaultTab={GroupTabs.Chat} recipientID={recipientID} />
    ) : null;

    if ((threadID || userID) && t !== GroupTabs.Chat) {
      content = threadID?.startsWith("dft") ? (
        <ThreadCompose draftID={threadID} />
      ) : (
        <ThreadPane messageID={messageID} threadID={threadID || userID} />
      );
    }

    if (appID) {
      content = <InboxAppsMobile appID={appID} />;
    }

    if (isView) {
      content = (
        <Switch>
          <Route path={routePath({ superTab: "inbox", view: "requests" })}>
            <InboxJoinRequests />
          </Route>
        </Switch>
      );
    }

    if (superTab === "threads" && !threadID) {
      content = <ThreadsMobile />;
    }

    if (v === "directory") {
      content = <GroupsDirectory />;
    }

    return content;
  })();

  return (
    <AppTabLayout detailChildren={detailContent} name={TabName.Inbox}>
      <div className="flex flex-col grow h-full min-h-0 relative w-full">
        <InboxMainMobile scrollRef={scrollRef} setScrollRef={setScrollRef} />
      </div>

      <SidebarAccessoryContainer>
        <NewThreadButton />
      </SidebarAccessoryContainer>
    </AppTabLayout>
  );
};

export default InboxMobile;
