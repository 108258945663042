import { useCallback } from "react";

import useMasonrySessionStore from "./useMasonrySessionStore";

const useConfigureApp = () => {
  const { startSession } = useMasonrySessionStore(({ startSession }) => ({
    startSession,
  }));

  const configure = useCallback(
    (
      appID: string,
      path: string,
      workspaceID: string,
      configurableID?: string
    ) => {
      (async () => {
        const groupID = configurableID?.startsWith("grp")
          ? configurableID
          : undefined;

        const threadID = configurableID?.startsWith("thr")
          ? configurableID
          : undefined;

        const configureType = threadID
          ? "threadConfigure"
          : groupID
            ? "groupConfigure"
            : "workspaceConfigure";

        const sessionInput = {
          appID,
          groupID,
          threadID,
          type: configureType,
          workspaceID,
        };

        const input = {
          path,
          requestType: `${configureType}`,
        };

        startSession(sessionInput, input);
      })();
    },
    [startSession]
  );

  return { configure };
};

export default useConfigureApp;
