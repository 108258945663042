import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GetReactionsAPIResponse } from "stream-chat/dist/types/types";
import {
  ReactionsListProps,
  useChatContext,
  useMessageContext,
} from "stream-chat-react";

import { ReactionListItems } from "components/Reactions";
import usePrevious from "hooks/usePrevious";
import newSyntheticEvent from "utils/newSyntheticEvent";

type Props = ReactionsListProps & {
  handleReaction?: (
    reactionType: string,
    event: React.BaseSyntheticEvent
  ) => Promise<void>;
};

const UnMemoizedReactionsList = ({
  handleReaction: handleReactionProp,
}: Props) => {
  const { channel } = useChatContext();
  const { message } = useMessageContext("ReactionsList");

  const { latest_reactions: latestReactions, reaction_counts: reactionCounts } =
    message || {};

  const reactionTypes = useMemo(
    () => Object.keys(reactionCounts || {}).sort(),
    [reactionCounts]
  );

  const [allReactions, setAllReactions] = useState<
    GetReactionsAPIResponse["reactions"]
  >(latestReactions || []);

  useEffect(() => {
    if (latestReactions?.length && latestReactions.length < 10) {
      setAllReactions(latestReactions);
    }
  }, [latestReactions]);

  const didGetAllReactions = useRef(false);

  const prevAllReactions = usePrevious(allReactions);
  const prevLatestReactions = usePrevious(latestReactions);

  const getAllReactions: () => Promise<GetReactionsAPIResponse | undefined> =
    useCallback(async () => {
      /**
       *  - if channel is undefined, do not try to getReactions.
       *  - we begin with the latest 10 reactions, via the `reactions` prop;
       *    therefore, if we have less than 10 reactions, we don't need to fetch more.
       *  - if more than 10, get reactions if we haven't already; then if we've fetch at least once,
       *    only refetch reactions if latest reactions have changed.
       */
      if (
        !channel ||
        allReactions.length < 10 ||
        (didGetAllReactions.current && latestReactions === prevLatestReactions)
      ) {
        return Promise.resolve({ duration: "0ms", reactions: allReactions });
      }

      const limit = Object.keys(reactionCounts || {}).reduce(
        (previous, key) => previous + (reactionCounts?.[key] || 0),
        0
      );

      const response = await channel.getReactions(message.id, { limit });

      if (response.reactions.length !== prevAllReactions.length) {
        didGetAllReactions.current = true;
        setAllReactions(response.reactions);
      }

      return response;
    }, [
      allReactions,
      channel,
      latestReactions,
      message,
      prevAllReactions.length,
      prevLatestReactions,
      reactionCounts,
    ]);

  const handleReaction = (emojiID: string) => {
    handleReactionProp?.(emojiID, newSyntheticEvent("reaction"));
  };

  const isOwnReaction = (emojiID: string) =>
    !!message.own_reactions?.find(r => r.type === emojiID);

  return (
    <ReactionListItems
      allReactions={allReactions}
      getAllReactions={getAllReactions}
      handleReaction={handleReaction}
      isOwnReaction={isOwnReaction}
      reactionCounts={reactionCounts}
      reactions={reactionTypes}
    />
  );
};

export const ReactionsList = React.memo(
  UnMemoizedReactionsList
) as typeof UnMemoizedReactionsList;
