import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { App } from "@utility-types";
import { Form } from "components/design-system/Forms";
import Selector from "components/design-system/Forms/Selector";
import { useAddWorkspaceAppMutation, useAppsQuery } from "generated/graphql";
import useOnboardingStore from "store/useOnboardingStore";

import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

type FormValues = Record<string, boolean | undefined>;
const FormContent = ({
  apps,
  formSubmitting,
}: { apps?: App[]; formSubmitting: boolean }) => {
  const { setState, currentStep } = useOnboardingStore(
    ({ setState, currentStep }) => ({ setState, currentStep })
  );
  const { watch } = useFormContext();
  const values = watch();

  const isAnyAppSelected = Object.values(values).some(v => !!v);

  return (
    <>
      <div className="overflow-auto h-full mb-32 flex flex-col gap-8">
        {apps?.map(a => (
          <Selector
            key={a.id}
            name={a.id}
            text={a.name}
            imageSrc={a.avatarURL}
          />
        ))}
      </div>
      <Footer
        fullHeight={false}
        onClickBack={() =>
          setState({ view: "CreateWorkspace", currentStep: currentStep - 1 })
        }
        onClickSkip={() =>
          setState({
            view: "CreateGroup",
            currentStep: currentStep + 1,
          })
        }
        formSubmitting={formSubmitting}
        submitDisabled={!isAnyAppSelected || formSubmitting}
        requireChanges={!isAnyAppSelected}
      />
    </>
  );
};

const ChooseApps = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { data } = useAppsQuery();
  const { setState, currentStep, workspace, apps } = useOnboardingStore(
    ({ setState, currentStep, workspace, apps }) => ({
      setState,
      currentStep,
      workspace,
      apps,
    })
  );
  const [addWorkspaceMutation] = useAddWorkspaceAppMutation();

  const handleAddApps = (v: FormValues) => {
    if (!workspace) return;
    setFormSubmitting(true);
    const addedAppsIDs = Object.entries(v)
      .filter(([_, value]) => value)
      .map(([key]) => key);
    const addedApps = data?.apps.edges.filter(a =>
      addedAppsIDs.includes(a.node.id)
    );
    if (!addedApps) return;
    Promise.all(
      addedApps.map(a =>
        addWorkspaceMutation({
          variables: { input: { appID: a.node.id, workspaceID: workspace.id } },
        })
      )
    )
      .then(() =>
        setState({
          view: "CreateGroup",
          currentStep: currentStep + 1,
          apps: addedApps.map(a => ({
            id: a.node.id,
            avatarURL: a.node.avatarURL,
          })),
        })
      )
      .finally(() => setFormSubmitting(false));
  };

  return (
    <Form<FormValues>
      className="w-full"
      onSubmit={handleAddApps}
      {...(apps
        ? {
            useFormProps: {
              defaultValues: apps.reduce((obj, app) => {
                return Object.assign(obj, { [app.id]: true });
              }, {}),
            },
          }
        : {})}
    >
      <ContentWrapper
        title="What apps do you use?"
        headline="Supercharge your workflow with powerful app integrations"
      >
        <FormContent
          apps={data?.apps.edges
            .filter(a => a.node.name !== "Glue AI")
            .map(a => a.node)}
          formSubmitting={formSubmitting}
        />
      </ContentWrapper>
    </Form>
  );
};

export default ChooseApps;
