import { ComponentProps } from "react";

import { RecipientType } from "@utility-types";
import { variantProps } from "components/helper/classNameVariants";
import tw from "utils/tw";

import Avatar, { AVATAR_DEFAULT_SIZE } from "./Avatar";

type Props = ComponentProps<typeof Avatar> & {
  badge?: JSX.Element;
  badgeProps?: ComponentProps<typeof Avatar>;
  badgeVisible?: boolean;
  type?: RecipientType;
};

type Size = NonNullable<ComponentProps<typeof Avatar>["size"]>;

const badgeRelativePosition: Record<Size, string> = {
  large: "bottom-1 -right-2",
  medium: "-bottom-2 -right-2",
  small: "-bottom-2 -right-2",
  tiny: "-bottom-4 -right-4",
  "x-large": "-bottom-2 -right-2",
  "x-small": "-bottom-4 -right-4",
  "x-tiny": "-bottom-4 -right-4",
  "xx-large": "-bottom-2 -right-2",
};

// Avatar size is key, Badge size is value
const badgeRelativeSize: Record<Size, Size> = {
  large: "tiny",
  medium: "tiny",
  small: "tiny",
  tiny: "x-tiny",
  "x-large": "tiny",
  "x-small": "x-tiny",
  "x-tiny": "x-tiny",
  "xx-large": "tiny",
};

const className = {
  base: "flex items-center justify-center relative",
  defaultVariants: {
    size: `${AVATAR_DEFAULT_SIZE}` as const,
  },
  variants: {
    size: {
      large: "w-36 h-36",
      medium: "w-32 h-32",
      small: "w-24 h-24",
      tiny: "w-16 h-16",
      "x-large": "w-80 h-80",
      "x-small": "w-20 h-20",
      "x-tiny": "w-12 h-12",
      "xx-large": "w-100 h-100",
    },
  },
};

const avatarProps = variantProps(className);

const BadgedAvatar = ({
  badge,
  badgeProps,
  badgeVisible,
  margin,
  ...props
}: Props) => (
  <div
    {...avatarProps({
      className: margin,
      size: props.size,
    })}
    data-testid="badged-avatar"
  >
    <Avatar {...props} />

    {badgeVisible && (
      <div
        className={tw(
          "absolute",
          badgeRelativePosition[props?.size ?? AVATAR_DEFAULT_SIZE]
        )}
      >
        {badge ?? (
          <Avatar
            {...{
              background: "subtle",
              border: "border-container-1",
              size: badgeRelativeSize[props?.size ?? AVATAR_DEFAULT_SIZE],
              ...badgeProps,
            }}
          />
        )}
      </div>
    )}
  </div>
);

export default BadgedAvatar;
