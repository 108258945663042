import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Recipient } from "@utility-types";
import { emailSeparatorsRegex, isValidEmail } from "utils/emailValidations";

import { RecipientValue } from "../types";

type Props = {
  containerRef: RefObject<HTMLInputElement>;
  excludeRecipients?: Recipient[];
  onInsertRecipients: (formattedRecipients: RecipientValue[]) => void;
};

type Response = {
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
};

const useInsertRecipients = ({
  containerRef,
  excludeRecipients,
  onInsertRecipients,
}: Props): Response => {
  const [inputValue, setInputValue] = useState("");

  const inputValueRef = useRef(inputValue);
  inputValueRef.current = inputValue;

  const handleAddRecipient = useCallback(
    (value: string) => {
      const recipients = value
        .split(emailSeparatorsRegex)
        .filter(isValidEmail)
        .map(r => r.replace(/<|>/g, "").toLowerCase())
        .map(r => ({
          __typename: "Address" as const,
          address: r,
          id: r,
          name: r,
        }))
        .filter(r => !excludeRecipients?.find(e => e.id === r.id));

      if (!recipients.length) return;

      onInsertRecipients(recipients);

      setTimeout(() => setInputValue(""), 0);
    },
    [excludeRecipients, onInsertRecipients]
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const key = event.key;
      const triggerKey =
        key === " " || key === "Tab" || key === "," || key === "Enter";
      if (triggerKey && isValidEmail(inputValueRef.current)) {
        handleAddRecipient(inputValueRef.current);
        event.preventDefault();
      }
    };

    const target = containerRef.current;
    target?.addEventListener("keydown", handleKeyPress);
    return () => {
      target?.removeEventListener("keydown", handleKeyPress);
    };
  }, [containerRef, handleAddRecipient]);

  useEffect(() => {
    const containerCurrent = containerRef.current;
    if (!containerCurrent) return;
    const createRecipientsOnPaste = (e: ClipboardEvent) => {
      const pastedValues = e.clipboardData?.getData("text");
      pastedValues && handleAddRecipient(pastedValues);
    };
    containerCurrent.addEventListener("paste", createRecipientsOnPaste);
    return () => {
      containerCurrent.removeEventListener("paste", createRecipientsOnPaste);
    };
  }, [containerRef, handleAddRecipient]);

  return { inputValue, setInputValue };
};

export default useInsertRecipients;
