import { Recipient } from "@utility-types";
import { ActionSheetItemGroups } from "components/design-system/FloatingUi/DropdownActions";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import useModalStore from "store/useModalStore";

const useThreadComposeMenuAction = (
  recipients: Recipient[]
): {
  threadComposeAction: ActionSheetItemGroups["items"][0];
  handleCompose: () => void;
} => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const handleCompose = () =>
    openModal(
      <ThreadComposeModal
        initialDraft={{
          recipients,
        }}
      />
    );

  return {
    threadComposeAction: {
      text: "New thread",
      icon: "Compose",
      onClick: handleCompose,
    },
    handleCompose,
  };
};

export default useThreadComposeMenuAction;
