import { useContext, useEffect } from "react";
import { useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useHistory } from "react-router";
import { UnknownType } from "stream-chat/dist/types/types";

import { HeaderButton } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import { routeParams, routeToThread } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { useMessagePinsQuery } from "generated/graphql";
import useWhichPane from "hooks/__desktop__/useWhichPane";
import useAuthData from "hooks/useAuthData";
import { StreamClientContext } from "providers/StreamClientProvider";
import useAppStateStore from "store/useAppStateStore";

import PinnedMessage from "./PinnedMessage";

const PinnedMessages = ({
  isSecondaryPane,
  setOpen,
  threadID,
}: {
  threadID: string;
  isSecondaryPane: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { authReady } = useAuthData();
  const history = useHistory();

  const [inFlight, setInFlight] = useState(false);

  // rather than receive data via props, we have to query it here so that pinned messages
  // are kept in sync on mobile, within a modal view
  const { data, error, fetchMore, loading } = useMessagePinsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: { threadID, first: 3 },
  });
  const hasNextPage = data?.messagePins.pageInfo.hasNextPage ?? false;

  const [scrollSentryRef, { rootRef: scrollListRef }] = useInfiniteScroll({
    disabled: !!error,
    hasNextPage,
    loading,
    onLoadMore: () => {
      setInFlight(true);
      fetchMore({
        variables: {
          after: data?.messagePins.pageInfo.endCursor,
        },
      })
        .catch(err => console.log(err))
        .finally(() => setInFlight(false));
    },
    rootMargin: "0px 0px 0px 0px",
  });

  const handleOnClick = (messageID: string | null) => {
    if (!messageID) return;
    history.push(
      routeToThread({
        forceTo: isSecondaryPane ? "secondary" : "primary",
        messageID,
        recipientID: routeParams(history.location).recipientID,
        threadID,
      })
    );
    setOpen(false);
  };

  return (
    <div
      ref={scrollListRef}
      className="flex flex-col gap-8 max-h-[75vh] min-h-full md:min-h-none overflow-y-auto p-16 bg-background-secondary"
    >
      {data?.messagePins.edges.map(edge => (
        <PinnedMessage
          key={edge.node.id}
          message={edge.node.message}
          onClick={() => handleOnClick(edge.node.message.streamID)}
        />
      ))}

      {hasNextPage && (
        <div ref={scrollSentryRef}>
          {(inFlight || loading) && (
            <Skeleton
              className="border border-border-container rounded-lg"
              height="100px"
              width="100%"
            />
          )}
        </div>
      )}
    </div>
  );
};

const PinnedButton = ({ threadID }: { threadID: string }) => {
  const { streamClient } = useContext(StreamClientContext);

  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const [isOpen, setOpen] = useState(true);

  const { data, refetch } = useMessagePinsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { threadID, first: 3 },
  });

  const { ref, isSecondaryPane } = useWhichPane<HTMLButtonElement>();

  const totalPinnedMessages = data?.messagePins.totalCount ?? 0;

  useEffect(() => {
    if (!streamClient) return;

    const { unsubscribe } = streamClient.on(async event => {
      if (event.channel_id !== threadID) return;

      switch ((event as UnknownType).type) {
        case "message.updated":
          const e = {
            message_update: {
              change_set: {
                pin: false,
              },
            },
            ...event,
          };
          if (e.message_update?.change_set?.pin) {
            refetch();
          }
          break;
      }
    });

    return () => unsubscribe();
  }, [refetch, streamClient, threadID]);

  if (!data || totalPinnedMessages === 0) return null;

  return (
    <Dropdown
      className="w-400 rounded-lg text-text-primary !shadow-level2"
      content={
        <PinnedMessages
          isSecondaryPane={isSecondaryPane}
          setOpen={setOpen}
          threadID={threadID}
        />
      }
      header={
        breakpointMD ? undefined : (
          <div className="text-headline-bold text-text-primary">{`${totalPinnedMessages ?? 0} pinned message${totalPinnedMessages !== 1 ? "s" : ""}`}</div>
        )
      }
      height="full"
      heightAuto={breakpointMD}
      offsetMain={2}
      placement="bottom-end"
      setOpen={setOpen}
      disableFlip
      toModal
    >
      <HeaderButton
        ref={ref}
        icon="Pin45"
        tooltip={isOpen ? undefined : "Pinned messages"}
        tooltipPlacement="bottom"
        tooltipStyle="inverted"
      >
        {data.messagePins.totalCount ?? 0}
      </HeaderButton>
    </Dropdown>
  );
};

export default PinnedButton;
