import { LocalNotifications } from "@capacitor/local-notifications";
import {
  Channel,
  PermissionStatus,
  PushNotifications,
} from "@capacitor/push-notifications";
import { useCallback, useEffect } from "react";

import { NotificationPermissionStatus } from "generated/graphql";
import useOnce from "hooks/useOnce";
import useAppStateStore from "store/useAppStateStore";
import useNotificationPermissionsStore from "store/useNotificationPermissionsStore";
import { isNative, isNativeAndroid } from "utils/platform";

type PermissionState = PermissionStatus["receive"];

const convertPermissionValue = (
  status: PermissionState | NotificationPermission
): NotificationPermissionStatus => {
  switch (status) {
    case "prompt":
    case "prompt-with-rationale":
    case "default":
      return NotificationPermissionStatus.Prompt;
    case "granted":
      return NotificationPermissionStatus.Granted;
    case "denied":
      return NotificationPermissionStatus.Denied;
  }
};

const useNotificationPermissions = () => {
  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));

  const { permissionStatus, setState } = useNotificationPermissionsStore(
    ({ permissionStatus, setState }) => ({ permissionStatus, setState })
  );

  // Check the notification permissions (push and local)
  const checkPermissions = useCallback(async () => {
    let newStatus = NotificationPermissionStatus.Denied;

    if (isNative()) {
      const result = await LocalNotifications.checkPermissions();
      newStatus = convertPermissionValue(result.display);
    } else if (window.Notification) {
      newStatus = convertPermissionValue(window.Notification.permission);
    }

    setState({ permissionStatus: newStatus });
    return newStatus;
  }, [setState]);

  const requestPermissions = async () => {
    if (isNative()) {
      if (isNativeAndroid()) {
        const channel: Channel = {
          description: "The default channel for Glue notifications.",
          id: "com.gluegroups.notification-channel.default",
          importance: 4, // IMPORTANCE_HIGH
          name: "Default",
        };
        await PushNotifications.createChannel(channel);
      }

      const result = await PushNotifications.requestPermissions();
      if (result.receive === "granted") {
        await PushNotifications.register();
      }

      const newStatus = convertPermissionValue(result.receive);
      setState({ permissionStatus: newStatus });
    } else if (window.Notification) {
      const result = await window.Notification.requestPermission();
      const newStatus = convertPermissionValue(result);
      setState({ permissionStatus: newStatus });
    }
  };

  // Initialize permissionStatus
  useOnce(() => {
    checkPermissions();
  });

  // Check permissions whenever app becomes active
  useEffect(() => {
    if (appStatus === "active") {
      checkPermissions();
    }
  }, [appStatus, checkPermissions]);

  return {
    permissionStatus,
    requestPermissions,
    checkPermissions,
  };
};

export default useNotificationPermissions;
