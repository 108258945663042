import { devtools, persist } from "zustand/middleware";

import {
  defaultRoutesStack,
  superTabsDefaults,
} from "components/routing/utils";
import { glueCreateZustand } from "store/helper/glueCreate";

const STORE_NAME = "RoutesStore";

export type RoutesType = {
  [K in keyof typeof superTabsDefaults]: string;
};

export type RoutesTypeStack = {
  [K in keyof typeof superTabsDefaults]?: string[];
};

type RoutesStore = {
  restoreRoute: string;
  routes: RoutesType;
  routesStack: RoutesTypeStack;
};

const useRoutesStore = glueCreateZustand<RoutesStore>({
  name: STORE_NAME,
})(
  devtools(
    persist(
      () => ({
        restoreRoute: "/",
        routes: superTabsDefaults, // for desktop
        routesStack: defaultRoutesStack, // for mobile
      }),
      {
        // migrate: (s, v) => {
        //   if (v === 0) return s as RoutesStore;
        //   return s as RoutesStore;
        // },
        name: STORE_NAME,
        version: 0,
      }
    ),
    {
      name: STORE_NAME,
      serialize: true,
    }
  )
);

export default useRoutesStore;
