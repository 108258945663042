import { ComponentProps } from "react";
import { useHistory } from "react-router";

import { Button } from "components/design-system/Button";
import Hr from "components/design-system/Hr";
import Icon, { IconName } from "components/design-system/icons/Icon";
import {
  RoutingPartition,
  usePartitionState,
} from "components/routing/RoutingPartition";
import {
  RoutingLocationState,
  superTabsDefaults,
  useRoutePartition,
  useValidatedTabRoute,
} from "components/routing/utils";
import useSidebarCounts from "components/SideBar/hooks/useSidebarCounts";
import { FeedType } from "generated/graphql";
import useRouteMemory from "hooks/__desktop__/useRouteMemory";
import useAuthData from "hooks/useAuthData";
import useShortcut from "hooks/useShortcut";
import useRoutesStore from "store/useRoutesStore";
import { isNativeMac } from "utils/platform";
import tw from "utils/tw";

import { TabCountBadge, TabDotBadge } from "../TabBadge";

export enum ShortcutKey {
  Inbox = "1",
  AI = "2",
  Feed = "3",
  Groups = "4",
  DirectMessages = "5",
  Activity = "6",
}

export enum ShortcutKeyWithoutAI {
  Inbox = "1",
  Feed = "2",
  Groups = "3",
  DirectMessages = "4",
  Activity = "5",
}

const className =
  "cursor-pointer font-semibold leading-[26px] mb-24 text-xl select-none focus-visible-border";

const RailItem = ({
  badgeCount,
  badge = badgeCount ? "alert" : undefined,
  children,
  icon,
  iconIsActive,
  isActive,
  label,
  ...props
}: ComponentProps<typeof Button> & {
  badge?: "alert" | "alert-strong";
  badgeCount?: number;
  icon: IconName;
  iconIsActive: IconName;
  isActive: boolean;
  label?: string;
}) => {
  return (
    <Button
      buttonStyle="none"
      buttonType="none"
      className={tw(
        "group/rail-item !my-0 py-8 w-full justify-center border-none",
        className
      )}
      {...props}
      type="button"
    >
      <div className="relative">
        {badgeCount ? (
          <TabCountBadge
            count={badgeCount}
            className="absolute -right-6 -top-6"
          />
        ) : badge ? (
          <TabDotBadge className="absolute right-4 top-4" />
        ) : null}
        {children}
        <div className="flex flex-col items-center justify-center text-text-action-inverse">
          <span
            className={tw(
              "flex justify-center items-center w-36 h-36 mb-4 rounded-lg transition-colors ease-spring duration-75",
              "group-hover/rail-item:bg-background-tab-hover group-focus-within/rail-item:focus-shadow",
              isActive ? "bg-background-tab-selected" : "transparent"
            )}
          >
            <Icon
              className="transition-transform transform-gpu ease-spring duration-75 group-hover:scale-[1.1]"
              icon={isActive ? iconIsActive : icon}
              size={20}
            />
          </span>
          <span className="font-semibold text-xs">{label}</span>
        </div>
      </div>
    </Button>
  );
};

const InboxTab = ({
  unreadCount,
  mentionCount,
  shortcutKey,
}: {
  unreadCount: number;
  mentionCount: number;
  shortcutKey: ShortcutKey | ShortcutKeyWithoutAI;
}) => {
  const routeKey = "inbox";

  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory<RoutingLocationState>();

  useShortcut(
    ["Meta", `${shortcutKey}`],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: routeKey,
    route: history.location.state?.setDefaultRoute?.includes(routeKey)
      ? superTabsDefaults[routeKey]
      : route,
  });

  const nextRoute = useValidatedTabRoute(routes, routeKey);

  return (
    <RailItem
      testId="rail-item-inbox-tab"
      badge={mentionCount ? "alert-strong" : unreadCount ? "alert" : undefined}
      badgeCount={unreadCount}
      icon="Inbox"
      iconIsActive="InboxFilled"
      isActive={!!isActivePartition}
      label="Inbox"
      onClick={() => {
        return history.push(nextRoute);
      }}
    />
  );
};

const AITab = ({ unreadCount }: { unreadCount: number }) => {
  const routeKey = "ai";

  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory<RoutingLocationState>();

  useShortcut(
    ["Meta", `${ShortcutKey.AI}`],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: routeKey,
    route: history.location.state?.setDefaultRoute?.includes(routeKey)
      ? superTabsDefaults[routeKey]
      : route,
  });

  const nextRoute = useValidatedTabRoute(routes, routeKey);

  return (
    <RailItem
      badge={unreadCount ? "alert" : undefined}
      badgeCount={unreadCount}
      icon="SparkleFilled"
      iconIsActive="SparkleFilled"
      isActive={!!isActivePartition}
      label="Glue AI"
      onClick={() => {
        return history.push(nextRoute);
      }}
    />
  );
};

const FeedTab = ({
  unseenCount,
  shortcutKey,
}: {
  unseenCount: number;
  shortcutKey: ShortcutKey | ShortcutKeyWithoutAI;
}) => {
  const routeKey = "feed";

  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory<RoutingLocationState>();

  useShortcut(
    ["Meta", `${shortcutKey}`],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: routeKey,
    route: history.location.state?.setDefaultRoute?.includes(routeKey)
      ? superTabsDefaults[routeKey]
      : route,
  });

  const nextRoute = useValidatedTabRoute(routes, routeKey);

  return (
    <RailItem
      badge={unseenCount ? "alert" : undefined}
      icon="MyFeed"
      iconIsActive="MyFeedFilled"
      isActive={!!isActivePartition}
      label="Feed"
      onClick={() => history.push(nextRoute)}
    />
  );
};

const GroupsTab = ({
  shortcutKey,
}: { shortcutKey: ShortcutKey | ShortcutKeyWithoutAI }) => {
  const routeKey = "groups";

  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory<RoutingLocationState>();

  useShortcut(
    ["Meta", `${shortcutKey}`],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: routeKey,
    route: history.location.state?.setDefaultRoute?.includes(routeKey)
      ? superTabsDefaults[routeKey]
      : route,
  });

  const nextRoute = useValidatedTabRoute(routes, routeKey);

  return (
    <RailItem
      icon="Groups"
      iconIsActive="GroupsFilled"
      isActive={!!isActivePartition}
      label="Groups"
      onClick={() => history.push(nextRoute)}
    />
  );
};

const DMsTab = ({
  unreadCount,
  shortcutKey,
}: {
  unreadCount: number;
  shortcutKey: ShortcutKey | ShortcutKeyWithoutAI;
}) => {
  const routeKey = "dms";

  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory<RoutingLocationState>();

  useShortcut(
    ["Meta", `${shortcutKey}`],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: routeKey,
    route: history.location.state?.setDefaultRoute?.includes(routeKey)
      ? superTabsDefaults[routeKey]
      : route,
  });

  const nextRoute = useValidatedTabRoute(routes, routeKey);

  return (
    <RailItem
      badge={unreadCount ? "alert" : undefined}
      icon="ChatRounded"
      iconIsActive="ChatRoundedFilled"
      isActive={!!isActivePartition}
      label="DMs"
      onClick={() => history.push(nextRoute)}
    />
  );
};

const ActivityTab = ({
  unseenCount,
  shortcutKey,
}: {
  unseenCount: number;
  shortcutKey: ShortcutKey | ShortcutKeyWithoutAI;
}) => {
  const routeKey = "activity";

  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory<RoutingLocationState>();

  useShortcut(
    ["Meta", `${shortcutKey}`],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: routeKey,
    route: history.location.state?.setDefaultRoute?.includes(routeKey)
      ? superTabsDefaults[routeKey]
      : route,
  });

  const nextRoute = useValidatedTabRoute(routes, routeKey);
  const url = new URL(nextRoute, window.location.origin);
  url.searchParams.set("t", FeedType.All);

  return (
    <RailItem
      badge={unseenCount ? "alert" : undefined}
      icon="Bell"
      iconIsActive="BellFilled"
      isActive={!!isActivePartition}
      label="Activity"
      onClick={() => history.push(url.pathname + url.search)}
    />
  );
};

const AppTabs = () => {
  const { authData } = useAuthData();
  const aiDisabled = authData?.workspaces.edges.length === 0;

  const { routes } = useRoutesStore(({ routes }) => ({
    routes,
  }));

  const { superTab } = useRoutePartition();

  const {
    unseenFeedCount,
    unreadDirectMessages,
    unreadMentionCount,
    unreadInboxCount,
    unreadNotificationCount,
  } = useSidebarCounts();

  return (
    <div className="flex w-full flex-col select-none">
      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.inbox}
        isActive={superTab === "inbox"}
        unmountInactive={false}
      >
        <InboxTab
          unreadCount={unreadInboxCount}
          mentionCount={unreadMentionCount}
          shortcutKey={(aiDisabled ? ShortcutKeyWithoutAI : ShortcutKey).Inbox}
        />
      </RoutingPartition>

      {!aiDisabled && (
        <>
          <RoutingPartition
            className=""
            classNames={{}} // disable default classNames
            defaultRoute={routes.ai}
            isActive={superTab === "ai"}
            unmountInactive={false}
          >
            <AITab unreadCount={0} />
          </RoutingPartition>
          <Hr
            className="mt-16 mx-16 mb-4"
            colorClassName="border-background-app-base-subtle"
          />
        </>
      )}

      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.feed}
        isActive={superTab === "feed"}
        unmountInactive={false}
      >
        <FeedTab
          unseenCount={unseenFeedCount}
          shortcutKey={(aiDisabled ? ShortcutKeyWithoutAI : ShortcutKey).Feed}
        />
      </RoutingPartition>

      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.groups}
        isActive={superTab === "groups"}
        unmountInactive={false}
      >
        <GroupsTab
          shortcutKey={(aiDisabled ? ShortcutKeyWithoutAI : ShortcutKey).Groups}
        />
      </RoutingPartition>

      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.dms}
        isActive={superTab === "dms"}
        unmountInactive={false}
      >
        <DMsTab
          unreadCount={unreadDirectMessages}
          shortcutKey={
            (aiDisabled ? ShortcutKeyWithoutAI : ShortcutKey).DirectMessages
          }
        />
      </RoutingPartition>

      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.activity}
        isActive={superTab === "activity"}
        unmountInactive={false}
      >
        <ActivityTab
          unseenCount={unreadNotificationCount}
          shortcutKey={
            (aiDisabled ? ShortcutKeyWithoutAI : ShortcutKey).Activity
          }
        />
      </RoutingPartition>
    </div>
  );
};

export default AppTabs;
