import { ThreadEdgeSimple } from "@utility-types";
import { Button } from "components/design-system/Button";

import useAIThreadActions from "./hooks/useAIThreadActions";

const AIThreadFloatingMenu = ({
  threadEdge,
}: {
  threadEdge: ThreadEdgeSimple;
}) => {
  const { handleDeleteWithModal } = useAIThreadActions();

  return (
    <div className="grow flex justify-end invisible group-hover/item:visible">
      <Button
        buttonStyle="icon-secondary"
        className="pr-0"
        icon="Trash"
        iconClassName="w-20 h-20"
        onPointerDown={e => {
          e.stopPropagation();
          handleDeleteWithModal(threadEdge);
        }}
      />
    </div>
  );
};

export default AIThreadFloatingMenu;
