import { setUser as setSentryUser } from "@sentry/react";
import { useEffect } from "react";

import "../sentry"; // initialize

import analytics from "analytics";
import useAuthData from "hooks/useAuthData";

const useSentryIdentify = () => {
  const { authData, authInitialized } = useAuthData();

  useEffect(() => {
    if (!authInitialized) return;

    if (authData?.me.id) {
      const user = { id: authData.me.id.split("_").pop() };
      setSentryUser(user);
      analytics.identify(user.id);
    } else {
      setSentryUser(null);
      analytics.identify();
    }
  }, [authData?.me.id, authInitialized]);
};

export default useSentryIdentify;
