import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { ModalProps } from "components/ModalKit/Modal";
import { routeToGroup } from "components/routing/utils";
import {
  AuthConfigDocument,
  WorkspaceSubscriptionQuery,
  WorkspaceSubscriptionQueryDocument,
  WorkspacesAndGroupsListDocument,
  useCreateWorkspaceMutation,
} from "generated/graphql";
import useModalStore from "store/useModalStore";

import PaywallModal from "./PaywallModal";
import WorkspaceModalForm, { WorkspaceInput } from "./WorkspaceModalForm";

const WorkspaceModalCreate = (props: ModalProps): JSX.Element => {
  const { closeAllModals, openModal } = useModalStore(
    ({ closeAllModals, openModal }) => ({
      closeAllModals,
      openModal,
    })
  );

  const history = useHistory();
  const apolloClient = useApolloClient();

  const [createWorkspace] = useCreateWorkspaceMutation({
    refetchQueries: [AuthConfigDocument, WorkspacesAndGroupsListDocument],
  });

  const createNewWorkspace = (_: string, input: WorkspaceInput) =>
    createWorkspace({ variables: { input: input } }).then(({ data }) => {
      if (!data) return;
      openModal(
        <PaywallModal
          workspace={{ id: data.createWorkspace.id, ...input }}
          onComplete={() => {
            apolloClient
              .query<WorkspaceSubscriptionQuery>({
                query: WorkspaceSubscriptionQueryDocument,
                variables: { workspaceID: data.createWorkspace.id },
                fetchPolicy: "network-only",
              })
              .then(() => {
                history.push(
                  routeToGroup({ groupID: data.createWorkspace.id })
                );
                closeAllModals();
              });
          }}
        />
      );
      return;
    });

  return (
    <WorkspaceModalForm
      onSave={createNewWorkspace}
      title="Create a workspace"
      {...props}
    />
  );
};

export default WorkspaceModalCreate;
