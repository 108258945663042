import { Skeleton } from "components/Skeleton";
import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";

type Props = {
  actions?: JSX.Element;
  description?: string;
  icon?: { alt: string; src: string };
  onClick?: () => void;
  title?: string;
};

const AppItem = ({ actions, description, icon, onClick, title }: Props) => (
  <div
    className={tw(
      "flex items-center py-10 px-14 mb-12 max-w-full rounded-[5px] border-1 border-interactive-ghost",
      {
        "cursor-pointer hover:border-interactive-ghost-hover": !!onClick,
      }
    )}
    onClick={onClick}
  >
    <div className="flex items-center mr-15 w-[42px] h-[42px]">
      {icon ? (
        <img
          alt={icon.alt}
          className="object-contain rounded-[5px]"
          src={glueImageURL(icon.src, {
            fit: "max",
            h: 64,
            w: 64,
          })}
        />
      ) : (
        <Skeleton borderRadius="50%" height="42px" width="42px" />
      )}
    </div>
    <div className="grow mr-15 min-w-0">
      <p className="m-0 font-semibold leading-5">
        {title ?? <Skeleton width="140px" />}
      </p>
      <p className="m-0 leading-5 text-text-subtle line-clamp-2">
        {description ?? <Skeleton width="260px" />}
      </p>
    </div>
    {actions && (
      <div className="flex shrink-0 justify-center items-center">{actions}</div>
    )}
  </div>
);

export default AppItem;
