import { Button } from "components/design-system/Button";
import { Footer, Header } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import { useSlackImportQuery } from "generated/graphql";
import useModalStore from "store/useModalStore";

import { ReviewMain } from "./Review";

const ReviewImportModal = ({
  workspaceID,
  ...props
}: ModalProps & { workspaceID: string }) => {
  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));
  const { data } = useSlackImportQuery({ variables: { workspaceID } });

  return (
    <StandardModal {...props} height="half">
      <Header variant="bordered">Review import</Header>
      <ReviewMain
        groups={data?.slackImport?.config?.groups}
        skipChannels={data?.slackImport?.config?.skipChannels}
        threads={data?.slackImport?.config?.includeChannels.filter(
          c => !data?.slackImport?.config?.groups.includes(c)
        )}
        importComplete
      />
      <Footer>
        <Button onClick={() => closeModal(props.modalId)}>Done</Button>
      </Footer>
    </StandardModal>
  );
};

export default ReviewImportModal;
