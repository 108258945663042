import useInfiniteScroll from "react-infinite-scroll-hook";

import { LinksCategory } from "@utility-types/graphql";
import { useLinksQuery } from "generated/graphql";

import { FiltersKeys, SharedFilters } from "../types";
import { groupItemsByDate } from "../utils/groupItemsByDate";

const useSharedList = ({
  category,
  groupID,
  recentCount = 3,
  pageSize = 30,
  selectedFilter,
}: {
  category: LinksCategory;
  groupID: string;
  recentCount?: number;
  pageSize?: number;
  selectedFilter: FiltersKeys;
}) => {
  const isRecent = selectedFilter === SharedFilters.Recent;

  const { data, loading, fetchMore } = useLinksQuery({
    variables: {
      filter: {
        recipientIDs: [groupID],
        linkedCategories: [category],
      },
      last: pageSize,
    },
    fetchPolicy: "cache-and-network",
  });

  const edges = data?.links.edges.map(e => e.node).reverse();

  const items = isRecent
    ? edges?.map(e => e.linked)?.slice(0, recentCount)
    : undefined;

  const groupedItems = groupItemsByDate(
    edges?.map(l => ({
      ...l.linked,
      createdAt: l.message.createdAt,
    })) ?? []
  );

  const hasNextPage = !isRecent && !!data?.links.pageInfo.hasPreviousPage;

  const [loadMoreRef] = useInfiniteScroll({
    hasNextPage,
    loading,
    onLoadMore: () =>
      fetchMore({
        variables: { before: data?.links.pageInfo.startCursor },
      }),
    disabled: isRecent,
  });

  return {
    loadMoreRef,
    loading: loading && !edges?.length,
    items,
    groupedItems,
    isRecent,
    hasNextPage,
  };
};

export default useSharedList;
