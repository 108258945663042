import { KeyBindingCommandFunction, KeyBindings } from "remirror";
import { ListItemExtension } from "remirror/extensions";

import { ENTER_REPLACEMENT_KEY } from "../utils";

export default class GlueListItemExtension extends ListItemExtension {
  createKeymap(): KeyBindings {
    const { enterKeyBehavior } = this.options;
    const keyMap = super.createKeymap() as { Enter: KeyBindingCommandFunction };
    const conditionalKeyMap =
      enterKeyBehavior === "new-line"
        ? keyMap
        : { [ENTER_REPLACEMENT_KEY]: keyMap.Enter };

    return {
      ...conditionalKeyMap,
    };
  }
}
