import { ReactNode, useMemo } from "react";

import { variantProps } from "components/helper/classNameVariants";
import tw from "utils/tw";

import { Icon, IconName } from "../icons";

type Variant = "primary" | "secondary";

type InternalVariant = Variant | "primarySelected" | "secondarySelected";

type BasePillProps<T extends string> = {
  name: T;
  count?: number;
  onClick?: (pillName: T) => void;
  selected?: boolean;
  variant?: Variant;
  children: ReactNode;
};

type PillWithoutIconProps<T extends string> = BasePillProps<T> & {
  icon?: never;
  iconSize?: never;
  iconPosition?: never;
};

type PillWithIconProps<T extends string> = BasePillProps<T> & {
  icon: IconName;
  iconSize?: number;
  iconPosition: "leading" | "trailing";
};

type PillProps<T extends string> =
  | PillWithoutIconProps<T>
  | PillWithIconProps<T>;

const getInternalVariant = (
  variant: Variant,
  selected?: boolean
): InternalVariant => {
  if (selected) {
    return variant === "primary" ? "primarySelected" : "secondarySelected";
  }
  return variant;
};

const getPillVariants = variantProps({
  base: tw(
    "flex flex-row items-center justify-center gap-4",
    "py-5 rounded-half text-nowrap",
    "text-footnote border-1 select-none"
  ),
  variants: {
    background: {
      primary: "bg-background-body hover:bg-background-secondary",
      primarySelected: "bg-background-action",
      secondary: "bg-background-action-subtle hover:bg-transparent",
      secondarySelected: "bg-background-body",
    },
    border: {
      primary: "border-border-container hover:border-border-container",
      primarySelected: "border-transparent",
      secondary: "border-transparent hover:border-border-container-hover",
      secondarySelected: "border-border-action",
    },
    cursor: {
      interactive: "cursor-pointer",
      nonInteractive: "",
    },
    spacing: {
      small: "px-8",
      large: "px-12",
    },
    text: {
      primary: "text-text-secondary hover:text-text-secondary-hover",
      primarySelected: "text-text-action-inverse",
      secondary: "text-text-primary hover:text-text-primary-hover",
      secondarySelected: "text-text-action",
    },
  },
});

const getIconVariants = variantProps({
  variants: {
    text: {
      unselected: "text-icon-primary hover:text-icon-primary-hover",
      selected: "text-icon-primary-selected",
    },
  },
});

const Pill = <T extends string>({
  name,
  onClick,
  selected,
  variant = "primary",
  icon,
  iconSize = 16,
  iconPosition,
  children,
}: PillProps<T>) => {
  const internalVariant = getInternalVariant(variant, selected);
  const pillClassNames = getPillVariants({
    background: internalVariant,
    border: internalVariant,
    cursor:
      onClick === undefined || selected ? "nonInteractive" : "interactive",
    spacing: icon ? "small" : "large",
    text: internalVariant,
  }).className;
  const iconClassNames = getIconVariants({
    text: selected ? "selected" : "unselected",
  }).className;

  const iconComponent = useMemo(
    () =>
      !!icon && <Icon icon={icon} size={iconSize} className={iconClassNames} />,
    [icon, iconClassNames, iconSize]
  );

  return (
    <li
      className={pillClassNames}
      onClick={() => (!selected ? onClick?.(name) : null)}
    >
      {iconPosition === "leading" && iconComponent}
      {children}
      {iconPosition === "trailing" && iconComponent}
    </li>
  );
};

export default Pill;
