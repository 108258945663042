import { PushNotifications, type Token } from "@capacitor/push-notifications";
import { useEffect } from "react";

import useNotificationPermissionsStore from "store/useNotificationPermissionsStore";
import { isNative } from "utils/platform";

/**
 * @summary Registers the app to receive push notifications and adds listeners for push notification events.
 * @see {@link https://capacitorjs.com/docs/apis/push-notifications}
 */
const usePushNotificationToken = () => {
  const { token, tokenInitialized, setState } = useNotificationPermissionsStore(
    ({ token, tokenInitialized, setState }) => ({
      token,
      tokenInitialized,
      setState,
    })
  );

  useEffect(() => {
    if (!isNative()) return;

    const listeners = [
      PushNotifications.addListener("registration", (token: Token) => {
        setState({ token, tokenInitialized: true });
      }),
      PushNotifications.addListener("registrationError", err => {
        console.error("Error: [usePushNotificationToken] -", err);
        setState({ tokenInitialized: true });
      }),
    ];

    PushNotifications.register();

    // cleanup listeners
    return () => listeners.forEach(async l => (await l).remove());
  }, [setState]);

  return {
    token,
    tokenInitialized,
  };
};

export default usePushNotificationToken;
