import { useState } from "react";
import { FieldValues } from "react-hook-form";

const useFormSubmit = <T extends FieldValues>(
  callback: (data: T) => Promise<unknown>
) => {
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleFormSubmit = (data: T) => {
    setError("");
    setSubmitting(true);

    callback(data)
      .catch(err => {
        if (typeof err === "string") {
          setError(err);
        } else if (err instanceof Error) {
          setError(err.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  return { error, handleFormSubmit, submitting };
};

export default useFormSubmit;
