import { useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  ThreadSummaryDocument,
  ThreadSummaryPointFieldsFragment,
  ThreadSummaryQuery,
} from "generated/graphql";

export const MIN_SUMMARY_DELAY_MS = 1000;
export const RANDOM_SUMMARY_DELAY_MS = 2000;

export function artificialSummaryDelay(): number {
  return MIN_SUMMARY_DELAY_MS + Math.random() * RANDOM_SUMMARY_DELAY_MS;
}

const useSummarizeThread = (threadID: string) => {
  const [summarizing, setSummarizing] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [points, setPoints] = useState<ThreadSummaryPointFieldsFragment[]>([]);
  const apolloClient = useApolloClient();
  const isMounted = useRef(true);

  useEffect(() => {
    // When the component mounts
    isMounted.current = true;
    return () => {
      // When the component unmounts
      isMounted.current = false;
    };
  }, []);

  const summarize = useCallback(async () => {
    if (summarizing) {
      return;
    }

    setSummarizing(true);

    let points: ThreadSummaryPointFieldsFragment[] = [];
    try {
      const { data } = await apolloClient.query<ThreadSummaryQuery>({
        fetchPolicy: "no-cache",
        query: ThreadSummaryDocument,
        variables: { level: 2, threadID: threadID },
      });
      points = data.threadSummaryPoints.edges.map(e => e.node);
    } finally {
      const elapsed = Date.now() - Date.now();
      setTimeout(
        () => {
          if (isMounted.current) {
            setPoints(points);
            setSummarizing(false);
            setShowSummary(true);
          }
        },
        Math.max(0, artificialSummaryDelay() - elapsed)
      );
    }
  }, [apolloClient, threadID, setPoints, summarizing]);

  return { points, setShowSummary, showSummary, summarize, summarizing };
};

export default useSummarizeThread;
