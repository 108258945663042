import { useApolloClient } from "@apollo/client";

import { ThreadEdgeSimple } from "@utility-types";
import { mutateThreadList } from "apollo/cache/threadHelpers";
import { ConfirmationModal } from "components/Modals";
// import useToastStore, {
//   ToastType,
// } from "components/design-system/ui/ToastKit/useToastStore";
import { DeleteThreadDocument, ThreadsMailbox } from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import useModalStore from "store/useModalStore";
import env from "utils/processEnv";

const useAIThreadActions = () => {
  const apolloClient = useApolloClient();
  const { evictNode } = useCacheEvict();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  // const { openToast } = useToastStore(({ openToast }) => ({ openToast }));

  // const handleUndo = () => {
  //   // TODO: implement undo, pending an "Undo" mutation for deleting threads.
  // };

  const handleDeleteThread = async (threadEdge: ThreadEdgeSimple) => {
    if (!threadEdge) return;

    return await apolloClient.mutate({
      mutation: DeleteThreadDocument,
      variables: { id: threadEdge?.node.id },
      optimisticResponse: {
        deleteThread: true,
      },
      update: c => {
        const variables = {
          mailbox: ThreadsMailbox.Ai,
          recipientID: env.glueAIBotID,
        };
        mutateThreadList(threadEdge, "rm", variables, c);

        evictNode(threadEdge, c);
      },
    });
    // .then(data => {
    //   openToast({
    //     content: "Draft deleted",
    //     dismiss: 5000,
    //     icon: "Trash",
    //     type: ToastType.DRAFT,
    //     undo: () => handleUndo(),
    //   });
    // })
  };

  const handleDeleteWithModal = async (threadEdge: ThreadEdgeSimple) => {
    if (!threadEdge) return;

    openModal(
      <ConfirmationModal
        confirmLabel="Delete Thread"
        header={`Delete "${threadEdge.node.subject}"?`}
        message="This will delete the thread permanently."
        onConfirm={async () => handleDeleteThread(threadEdge)}
        isDestructive
      />
    );
  };

  return { handleDeleteThread, handleDeleteWithModal };
};

export default useAIThreadActions;
