import { Workspace } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import { useWorkspaceSubscriptionPlansQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import ChoosePlanDropdown from "./ChoosePlanDropdown";

const ChoosePlan = ({
  workspace,
}: { workspace?: Pick<Workspace, "name" | "avatarURL"> }) => {
  const { authData, authReady } = useAuthData();
  const { data: plansData } = useWorkspaceSubscriptionPlansQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-and-network",
    skip: !authData?.me.id,
  });

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center text-text-body text-16 font-medium overflow-hidden">
          <Avatar
            avatarURL={workspace?.avatarURL}
            name={workspace?.name}
            rounded="rounded-md"
            size="medium"
          />
          <span className="text-body-bold ml-10 truncate">
            {workspace?.name}
          </span>
        </div>
        <span className="text-caption text-text-subtle">Workspace</span>
      </div>
      <div className="flex flex-col">
        <span className="text-body-bold mb-4">Choose a plan</span>
        <ChoosePlanDropdown plans={plansData?.workspaceSubscriptionPlans} />
      </div>
      <div className="flex flex-col">
        <span className="text-body-bold">
          Try Glue risk free for 21 days, cancel anytime.
        </span>
        <ul className="list-disc list-inside text-body text-text-secondary mt-4">
          <li>Workspace for your entire team</li>
          <li>Unlimited threads, groups and message history</li>
          <li>Create custom AI-generated emojis</li>
          <li>Limited Glue AI usage included</li>
          <li>All supported app integrations</li>
          <li>Apps for Mac, iOS, and Android</li>
          <li>Full support for top AI models (GPT-4, Claude, and more)</li>
        </ul>
      </div>
      <InformationBubble>
        <div className="flex justify-between items-center w-full">
          <span className="text-footnote">Want to learn more?</span>
          <Button
            className="min-h-28"
            buttonStyle="secondary"
            onClick={() =>
              window.open(
                "https://calendly.com/d/ckhb-vmg-wm4/glue-product-onboarding",
                "_blank"
              )
            }
          >
            Book a demo
          </Button>
        </div>
      </InformationBubble>
    </>
  );
};

export default ChoosePlan;
