import { useContext, useEffect, useState } from "react";
import { Channel } from "stream-chat";

import type {
  EphemeralMessage,
  GlueDefaultStreamChatGenerics,
} from "@utility-types";
import useAuthData from "hooks/useAuthData";
import { StreamClientContext } from "providers/StreamClientProvider";
import useRefreshChannelStore from "store/useRefreshChannelStore";
import env from "utils/processEnv";
import {
  glueEphemeralMessageToStreamResponse,
  streamMessageToGlueMessage,
} from "utils/stream/message";

const timeoutInterval = 500; // 0.5s checking intervale
const maxQueueTime = 30000; // 30s max time to wait for channel to become available

const useEphemeralMessage = () => {
  const { streamClient } = useContext(StreamClientContext);
  const { authData } = useAuthData();
  const { channelID } = useRefreshChannelStore(({ channelID }) => ({
    channelID,
  }));

  const [messageQueue, setMessageQueue] = useState<
    { message: EphemeralMessage; timestamp: number }[]
  >([]);

  useEffect(() => {
    useRefreshChannelStore.setState({ channelID: null });
  }, [channelID]);

  const handleMessage = async (
    message: EphemeralMessage,
    targetChannel: Channel<GlueDefaultStreamChatGenerics>
  ) => {
    const messages = targetChannel.state.messageSets[0]?.messages;
    const oldMessage = messages?.find(item => item.id === message.id);
    const oldDate = oldMessage?.created_at;

    if (oldDate) {
      message.createdAt =
        oldDate instanceof Date ? oldDate.toISOString() : oldDate;
    }

    targetChannel.state.removeMessage({ id: message.id });
    targetChannel.state.addMessageSorted(
      glueEphemeralMessageToStreamResponse(message)
    );
    useRefreshChannelStore.setState({ channelID: message.threadID });
  };

  const checkQueue = () => {
    const now = Date.now();

    // Remove messages older than 1 minute
    setMessageQueue(prevQueue =>
      prevQueue.filter(({ timestamp }) => now - timestamp < maxQueueTime)
    );

    // Scan remaining entries and process messages with available channels
    setMessageQueue(prevQueue => {
      const newQueue = prevQueue.filter(({ message }) => {
        const targetChannel =
          streamClient?.activeChannels[
            `${env.streamChannelType}:${message.threadID}`
          ];
        if (targetChannel) {
          handleMessage(message, targetChannel);
          return false;
        }
        return true;
      });

      if (newQueue.length > 0) {
        setTimeout(checkQueue, timeoutInterval);
      }

      return newQueue;
    });
  };

  const postEphemeralMessage = async (message: EphemeralMessage) => {
    if (!authData) return;

    const targetChannel =
      streamClient?.activeChannels[
        `${env.streamChannelType}:${message.threadID}`
      ];

    if (!targetChannel) {
      const prevMessageQueueLength = messageQueue.length;
      setMessageQueue(prevQueue => [
        ...prevQueue,
        { message, timestamp: Date.now() },
      ]);

      // If we just enqueued the first item, start checking the queue
      if (prevMessageQueueLength === 0) {
        setTimeout(checkQueue, timeoutInterval);
      }

      return;
    }

    await handleMessage(message, targetChannel);
  };

  const updateEphemeralMessage = async (
    message: Pick<
      EphemeralMessage,
      "id" | "threadID" | "text" | "updatedAt"
    > & { createdAt?: string }
  ) => {
    if (!authData) return;

    const queuedMessage = messageQueue.find(item => {
      return (
        item.message.id === message.id &&
        item.message.threadID === message.threadID
      );
    })?.message;
    if (queuedMessage) {
      Object.assign(queuedMessage, message);
      return;
    }

    const targetChannel =
      streamClient?.activeChannels[
        `${env.streamChannelType}:${message.threadID}`
      ];

    if (!targetChannel) {
      return;
    }

    const messages = targetChannel.state.messageSets[0]?.messages;
    const oldMessage = messages?.find(item => item.id === message.id);

    if (!oldMessage) {
      return;
    }

    const updatedMessage = {
      ...streamMessageToGlueMessage(oldMessage),
      ...message,
    };

    targetChannel.state.removeMessage({ id: oldMessage.id });
    targetChannel.state.addMessageSorted(
      glueEphemeralMessageToStreamResponse(updatedMessage)
    );
    useRefreshChannelStore.setState({ channelID: message.threadID });
  };

  return {
    postEphemeralMessage,
    updateEphemeralMessage,
  };
};

export default useEphemeralMessage;
