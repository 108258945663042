import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";

import { Recipient, ThreadEdgeSimple } from "@utility-types";
import { Button } from "components/design-system/Button";
import BaseSnackbar from "components/SnackBar/BaseSnackbar";
import {
  Addable,
  FetchPendingJoinApprovalsDocument,
  FetchThreadEdgeDocument,
  FetchThreadEdgeQuery,
  FetchThreadEdgeQueryVariables,
  MemberRole,
  useAddThreadRecipientsMutation,
  useRequestJoinThreadMutation,
} from "generated/graphql";
import useComponentMounted from "hooks/useComponentMounted";
import env from "utils/processEnv";

type Props = {
  mention?: Recipient;
  threadEdge?: ThreadEdgeSimple;
};

const AddMentionToThread = ({
  mention,
  threadEdge,
}: Props): JSX.Element | null => {
  const isAdmin = threadEdge?.recipientRole === MemberRole.Admin;
  const isLocked = threadEdge?.node.recipientsAddable === Addable.None;
  const isAddable = isAdmin || !isLocked;

  const apolloClient = useApolloClient();
  const isMounted = useComponentMounted();
  const [joinableRecipient, setJoinableRecipient] = useState<Recipient>();
  const [disabled, setDisabled] = useState(false);
  const [requestAddRecipient] = useRequestJoinThreadMutation();
  const [addThreadRecipients] = useAddThreadRecipientsMutation();

  const addRecipient = (threadEdge: ThreadEdgeSimple, recipient: Recipient) =>
    addThreadRecipients({
      variables: {
        id: threadEdge.node.id,
        recipients: [{ recipient: recipient.id, role: MemberRole.Default }],
      },
    });

  const requestToAdd = (threadEdge: ThreadEdgeSimple, recipient: Recipient) =>
    requestAddRecipient({
      refetchQueries: [FetchPendingJoinApprovalsDocument],
      variables: {
        input: {
          joinableID: threadEdge.node.id,
          recipient: recipient.id,
        },
      },
    });

  const handleAddRecipient = (recipient?: Recipient) => {
    const joiningRecipient = recipient || joinableRecipient;

    if (disabled || !joiningRecipient || !threadEdge) return;

    setDisabled(true);

    (isAddable
      ? addRecipient(threadEdge, joiningRecipient)
      : requestToAdd(threadEdge, joiningRecipient)
    )
      .then(() => {
        setJoinableRecipient(undefined);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => setDisabled(false));
  };

  useEffect(() => {
    if (!threadEdge || !mention) return;

    if (mention.id === env.glueAIBotID) {
      return; // Don't suggest adding Glue AI
    }

    if (
      threadEdge?.node.isPersistentChat ||
      threadEdge.node.recipients.edges.find(e => e.node.id === mention.id)
    ) {
      return; // Already a thread recipient
    }

    (async () => {
      // If it's a user, check if it's a recipient through a group.
      if (mention.__typename === "User") {
        const result = await apolloClient.query<
          FetchThreadEdgeQuery,
          FetchThreadEdgeQueryVariables
        >({
          fetchPolicy: "network-only",
          query: FetchThreadEdgeDocument,
          variables: { id: `${threadEdge.node.id}-${mention.id}` },
        });

        // Already in the thread
        if (!isMounted.current || result.data.node) return;
      }

      setJoinableRecipient(mention);
    })();
  }, [apolloClient, isMounted, mention, threadEdge]);

  if (!joinableRecipient) return null;

  return (
    <div className="relative mb-8">
      <BaseSnackbar
        containerClassName="fade-in native:!bottom-10 w-full justify-center"
        type="info"
      >
        <div className="flex flex-col grow">
          <div className="text-text-strong">
            <b>{joinableRecipient.name}</b> is not part of this thread.
          </div>
          <div className="flex justify-between items-center mt-10">
            <Button
              buttonStyle="subtle"
              className="!p-0"
              disabled={disabled}
              onClick={() => setJoinableRecipient(undefined)}
            >
              Dismiss
            </Button>
            <Button
              buttonStyle="simplePrimary"
              className="!p-0"
              disabled={disabled}
              onClick={() => handleAddRecipient()}
            >
              {isAddable ? `Add ${joinableRecipient.name}` : "Request to add"}
            </Button>
          </div>
        </div>
      </BaseSnackbar>
    </div>
  );
};

export default AddMentionToThread;
