import { Deploy } from "@gluegroups/cordova-plugin-ionic";
import { memo, useEffect, useState } from "react";
import { useThrottledCallback } from "use-debounce";

import NewVersionSnackbar from "components/SnackBar/NewVersionSnackbar";
import useAuthData from "hooks/useAuthData";
import useFeatureFlagStore from "store/useFeatureFlagStore";
import useLayerStackStore from "store/useLayerStackStore";
import { isNative, isNativeMac } from "utils/platform";
import env from "utils/processEnv";

const ascTestDomains = new Set([
  "apple.com",
  "asc.gluegroups.com",
  "privaterelay.appleid.com",
]);

const currentVersion = process.env.VITE_VERCEL_GIT_COMMIT_SHA || "123";

export const SIMULATE_APP_UPDATE_AVAILABLE = "SIMULATE_APP_UPDATE_AVAILABLE";

export function useHasAppUpdate() {
  const { authData, clearApolloCache } = useAuthData();
  const userDomains = authData?.me.addressDomains ?? [];

  const [hasUpdate, setHasUpdate] = useState(false);

  const { activeLayerId } = useLayerStackStore(({ activeLayerId }) => ({
    activeLayerId,
  }));

  const { SERVICE_WORKER } = useFeatureFlagStore(
    ({ flags: { SERVICE_WORKER } }) => ({ SERVICE_WORKER })
  );

  const hasServiceWorker = navigator.serviceWorker && SERVICE_WORKER;

  const throttledCheckForUpdateWeb = useThrottledCallback(
    () => {
      !hasServiceWorker &&
        fetch(`${env.glueAppUrl}?time=${Date.now()}`)
          .then(response => response.text())
          .then(data => {
            let latestVersion = "";

            try {
              latestVersion = data.match(/app-version="(\w+)"/)?.[1] || "";
            } catch (_error) {
              return;
            }

            latestVersion &&
              latestVersion !== currentVersion &&
              setHasUpdate(true);
          })
          .catch(err =>
            console.warn(
              "Something went wrong fetching the latest app version.",
              err
            )
          );

      // check for service worker update
      SERVICE_WORKER &&
        navigator.serviceWorker?.getRegistration().then(sw => {
          sw?.active?.scriptURL.endsWith(
            env.glueServiceWorker || "service-worker.js"
          ) && sw?.update().catch((e: Error) => console.warn("sw update", e));
        });
    },
    env.glueEnv === "production" ? 1000 * 60 * 60 * 24 : 1000 * 58
  );

  // Native Mac update fetches updates from the native code
  useEffect(() => {
    if (!isNativeMac()) {
      return;
    }

    let mounted = true;
    const newVersionHandler = () => {
      if (mounted) {
        setHasUpdate(true);
      }
    };

    const effect = (async () => {
      await Deploy.subscribeNewPersistedBasePath(newVersionHandler);

      const pendingVersion = await Deploy.getPendingPersistedVersion();
      if (pendingVersion) {
        newVersionHandler();
      }
    })();

    return () => {
      mounted = false;
      (async () => {
        await effect;
        await Deploy.unsubscribeNewPersistedBasePath(newVersionHandler);
      })();
    };
  }, []);

  activeLayerId && !isNative() && throttledCheckForUpdateWeb();

  return {
    hasAppUpdate:
      !!localStorage.getItem(SIMULATE_APP_UPDATE_AVAILABLE) ||
      (hasUpdate &&
        // We don't support live update for native app without a non-test work domain
        ((isNative() && userDomains.some(d => !ascTestDomains.has(d))) ||
          // we never want to show notification for app update if a service worker is registered
          // service shows notification separately when an update is installed
          (!isNative() && !hasServiceWorker))),
    applyAppUpdate: async () => {
      setHasUpdate(false);

      localStorage.removeItem(SIMULATE_APP_UPDATE_AVAILABLE);

      await clearApolloCache(); // clear cache before update to avoid cache incompatibility issues

      if (isNative()) {
        Deploy.reloadApp();
      } else {
        window.location.reload();
      }
    },
  };
}

const AppUpdate = memo(() => {
  const { hasAppUpdate, applyAppUpdate } = useHasAppUpdate();

  if (!hasAppUpdate) {
    return null;
  }

  return <NewVersionSnackbar clickHandler={applyAppUpdate} />;
});

AppUpdate.displayName = "AppUpdate";

export default AppUpdate;
