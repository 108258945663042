import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";

import { Recipient, Workspace, WorkspacePreview, nodeIs } from "@utility-types";
import { FetchWorkspaceDocument, FetchWorkspaceQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useComponentMounted from "hooks/useComponentMounted";
import useValidateRecipientOrigin from "hooks/useValidateRecipientOrigin";

import {
  CommonWorkspace,
  useGetCommonWorkspaces,
} from "./useGetCommonWorkspaces";

type RecipientWorkspace = Workspace | WorkspacePreview;

const getExternalRecipientData = (
  recipient: Recipient,
  recipientWorkspace?: RecipientWorkspace
) => {
  const workspaceName = recipientWorkspace?.name || "";
  const workspaceDomain = recipientWorkspace?.domains?.[0] || "";
  const fromWorkspaceLabel = workspaceDomain
    ? `From ${workspaceName} (${workspaceDomain})`
    : `From ${workspaceName}`;

  const defaultTooltip = workspaceDomain
    ? fromWorkspaceLabel
    : "From outside your workspace";
  const defaultLabel = workspaceDomain || "External";

  switch (recipient.__typename) {
    case "User":
      return {
        tooltip: defaultTooltip,
        label: recipient.addressDomains?.[0] || defaultLabel,
      };
    case "Group":
    case "GroupPreview":
      return {
        tooltip: defaultTooltip,
        label: defaultLabel,
      };
    case "Workspace":
    case "WorkspacePreview":
      return {
        tooltip: "You're not a member of this workspace",
        label: defaultLabel,
      };
    default:
      return { label: defaultLabel, tooltip: defaultTooltip };
  }
};

const getInternalRecipientData = (
  recipient: Recipient,
  commonWorkspace?: CommonWorkspace,
  isInternalGroupWithGuests = false
) => {
  let label = "Internal";
  let tooltip: string | undefined;

  const workspaceInfo = commonWorkspace
    ? `${isInternalGroupWithGuests ? "Includes guests from outside" : "Part of"} ${commonWorkspace.name}${commonWorkspace.domain ? ` (${commonWorkspace.domain})` : ""}`
    : undefined;

  switch (recipient.__typename) {
    case "User":
      label = recipient.addressDomains?.[0] ?? label;
      tooltip = workspaceInfo;
      break;
    case "Group":
    case "GroupPreview":
      label = commonWorkspace?.domain ?? label;
      tooltip = workspaceInfo;
      break;
    case "Workspace":
    case "WorkspacePreview":
      label = recipient.domains?.[0] ?? label;
      tooltip = "You're a member of this workspace";
      break;
  }

  return { label, tooltip: tooltip ?? label };
};

type Response = {
  type: "external" | "internal" | "internal-with-guests" | undefined;
  label?: string;
  tooltip?: string;
  workspaceLoaded: boolean;
};

const useIdentityBadgeData = (recipient?: Recipient): Response => {
  const { authData } = useAuthData();
  const [recipientWorkspace, setRecipientWorkspace] =
    useState<RecipientWorkspace>();

  // For testing purposes - this tell us if all the async tasks are done
  const [workspaceLoaded, setWorkspaceLoaded] = useState(false);

  const apolloClient = useApolloClient();
  const isMounted = useComponentMounted();
  const commonWorkspaces = useGetCommonWorkspaces(
    recipient,
    setWorkspaceLoaded
  );
  const { containsExternalRecipients } = useValidateRecipientOrigin();
  const { externalRecipients } = containsExternalRecipients(
    recipient ? [recipient] : [],
    true
  );

  const isInternalGroupWithGuests =
    (recipient?.__typename === "Group" ||
      recipient?.__typename === "GroupPreview") &&
    authData?.me.workspaceIDs.includes(recipient.workspaceID ?? "") &&
    !recipient.commonWorkspaceIDs?.includes(recipient.workspaceID ?? "");

  const isExternal =
    externalRecipients.length > 0 && !isInternalGroupWithGuests;

  useEffect(() => {
    if (!recipient || nodeIs(recipient, ["Address"]) || !isExternal) return;

    const workspaceID =
      recipient?.__typename === "Workspace" ||
      recipient?.__typename === "WorkspacePreview"
        ? recipient.id
        : recipient?.__typename === "Group" ||
            recipient?.__typename === "GroupPreview"
          ? recipient.workspaceID
          : recipient.workspaceIDs?.[0];

    if (!workspaceID) return;

    apolloClient
      .query<FetchWorkspaceQuery>({
        query: FetchWorkspaceDocument,
        variables: { id: workspaceID },
      })
      .then(response => {
        if (nodeIs(response.data.node, ["Workspace", "WorkspacePreview"])) {
          isMounted.current && setRecipientWorkspace(response.data.node);
        }
      })
      .finally(() => {
        isMounted.current && setWorkspaceLoaded(true);
      });
  }, [apolloClient, isExternal, isMounted, recipient]);

  if (!recipient) {
    return {
      type: undefined,
      label: undefined,
      tooltip: undefined,
      workspaceLoaded: false,
    };
  }

  return isExternal
    ? {
        type: "external",
        workspaceLoaded,
        ...getExternalRecipientData(recipient, recipientWorkspace),
      }
    : isInternalGroupWithGuests
      ? {
          type: "internal-with-guests",
          workspaceLoaded,
          ...getInternalRecipientData(recipient, commonWorkspaces[0], true),
        }
      : {
          type: "internal",
          workspaceLoaded,
          ...getInternalRecipientData(recipient, commonWorkspaces[0]),
        };
};

export default useIdentityBadgeData;
