import { ComponentProps, useRef } from "react";
import { useFormContext } from "react-hook-form";

import { Workspace } from "@utility-types/graphql";
import { ReactComponent as GlueLogoDark } from "assets/glue/glue-logo-color-dark.svg";
import { ReactComponent as GlueLogoWhite } from "assets/glue/glue-logo-color-white.svg";
import { Form } from "components/design-system/Forms";
import ThemedImage from "components/helper/ThemedImage";
import Payment from "components/Payment/Payment";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import tw from "utils/tw";

import ChoosePlan from "./ChoosePlan";

type Props = {
  onComplete: () => void;
  workspace?: Pick<Workspace, "id" | "name" | "avatarURL">;
  containerClassName?: string;
};

const FormContent = ({
  onComplete,
  workspace,
  containerClassName,
  children,
}: WithChildren<Props>) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const isWideView = useElementBreakpoint(containerRef, 950);

  const { watch } = useFormContext();
  const planID = watch("plan");

  return (
    <>
      <div
        ref={containerRef}
        className={tw(
          "flex overflow-hidden w-full h-full z-1 bg-background-app",
          containerClassName
        )}
      >
        <div
          className={tw(
            "flex flex-col items-center grow min-w-[420px] w-full py-32 overflow-hidden",
            isWideView ? "px-100" : "px-20"
          )}
        >
          <ThemedImage
            className="shrink-0 h-50 my-32"
            LightImage={GlueLogoWhite}
            DarkImage={GlueLogoDark}
          />
          <div className="flex flex-col w-full max-w-[600px] px-56 py-32 gap-16 bg-background-body border-1 border-border-container rounded-[24px] shadow-level2 overflow-y-auto">
            <ChoosePlan workspace={workspace} />
          </div>
          {children}
        </div>
        <div className="flex flex-col shrink-0 w-[485px] grow px-24 py-32 overflow-y-auto bg-background-white">
          <Payment
            workspaceID={workspace?.id}
            planID={planID ?? ""}
            onComplete={onComplete}
          />
        </div>
      </div>
    </>
  );
};

type FormProps = ComponentProps<typeof Form>;
const Paywall = ({
  formProps,
  ...props
}: WithChildren<Props & { formProps?: FormProps }>) => (
  <Form {...formProps}>
    <FormContent {...props} />
  </Form>
);

export default Paywall;
