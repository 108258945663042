import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { routeParams, searchParams } from "components/routing/utils";
import type { PathnameParams, SearchParams } from "components/routing/utils";

const useRouteChange = (
  callback: (routeParams: PathnameParams, searchParams: SearchParams) => void
) => {
  const history = useHistory();
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(
    () =>
      history.listen(location =>
        callbackRef.current(routeParams(location), searchParams(location))
      ),
    [history]
  );
};

export default useRouteChange;
