import { RefObject, useLayoutEffect, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";

const useElementBreakpoint = (
  ref: RefObject<HTMLElement>,
  widthProp: number | "wide"
) => {
  const element = ref.current;
  const breakpointWidth = widthProp === "wide" ? 832 : widthProp;
  const [breakpoint, setBreakpoint] = useState<boolean | null>(() =>
    element ? element.offsetWidth >= breakpointWidth : null
  );

  const onResize = (width?: number) => {
    const isBreakpoint = width ? width >= breakpointWidth : breakpoint;
    if (isBreakpoint === breakpoint) return;
    setBreakpoint(isBreakpoint);
  };
  const onResizeRef = useRef(onResize);
  onResizeRef.current = onResize;

  useLayoutEffect(() => {
    if (!element) return;

    onResizeRef.current(element.offsetWidth);
  }, [element]);

  useResizeObserver({
    ref,
    onResize: ({ width }) => onResize(width),
  });

  return breakpoint;
};

export default useElementBreakpoint;
