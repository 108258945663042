import { useCallback, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { nodeAs } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { Pill } from "components/design-system/Pill";
import CreateGroupModal from "components/group/CreateGroup/CreateGroupModal";
import {
  GroupsOrder,
  useFetchWorkspaceGroupsDirectoryQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useFeatureFlagStore from "store/useFeatureFlagStore";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import {
  WORKSPACE_GENERAL_DESCRIPTION,
  WORKSPACE_GENERAL_NAME,
} from "../consts";

import GroupDirectorySkeletons from "./GroupDirectorySkeletons";
import GroupsDirectoryRow, {
  GroupsDirectoryRowPrimitive,
} from "./GroupsDirectoryRow";
import GroupsDirectorySearchInput from "./GroupsDirectorySearchInput";

enum PrimaryPillNames {
  All = "All",
  New = "New",
  MyGroups = "My groups",
  Recent = "Recent",
}
enum SecondaryPillNames {
  Archived = "Archived",
}
type PillNames = PrimaryPillNames | SecondaryPillNames;

const PillOrders = {
  [PrimaryPillNames.All]: GroupsOrder.Name,
  [PrimaryPillNames.New]: GroupsOrder.Added,
  [PrimaryPillNames.MyGroups]: GroupsOrder.Name,
  [PrimaryPillNames.Recent]: GroupsOrder.Interaction,
  [SecondaryPillNames.Archived]: GroupsOrder.Name,
};

const GroupDirectory = ({
  searchState,
  workspaceID,
}: {
  searchState: [string, React.Dispatch<React.SetStateAction<string>>];
  workspaceID: string;
}) => {
  const { ARCHIVE_GROUPS_FOR_EVERYONE } = useFeatureFlagStore(
    ({ flags: { ARCHIVE_GROUPS_FOR_EVERYONE } }) => ({
      ARCHIVE_GROUPS_FOR_EVERYONE,
    })
  );

  const { authData, authReady } = useAuthData();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const [selectedPill, setSelectedPill] = useState<PillNames>(
    PrimaryPillNames.All
  );

  const pageSize = Math.ceil(window.innerHeight / 64); // 64px is the height of a row

  const [searchTerm, setSearchTerm] = searchState;

  const isMyGroupsSelected = selectedPill === PrimaryPillNames.MyGroups;
  const isRecentSelected = selectedPill === PrimaryPillNames.Recent;

  const { data, fetchMore, loading } = useFetchWorkspaceGroupsDirectoryQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !(workspaceID && authData),
    variables: {
      groupsFirst: isRecentSelected ? undefined : pageSize,
      groupsLast: isRecentSelected ? pageSize : undefined,
      groupsOrder: PillOrders[selectedPill],
      id: `${workspaceID}-${authData?.me.id}`,
      match: searchTerm,
      member: isMyGroupsSelected ? true : undefined,
    },
  });

  const workspaceEdge = nodeAs(data?.node, ["WorkspaceEdge"]);

  const {
    endCursor: afterCursor,
    hasNextPage,
    hasPreviousPage,
    startCursor: beforeCursor,
  } = workspaceEdge?.node.groups.pageInfo ?? {
    endCursor: null,
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
  };

  const loadGroups = useCallback(
    async (cursor: string) => {
      if (!authReady) return;
      fetchMore({
        variables: {
          groupsAfter: isRecentSelected ? undefined : cursor,
          groupsBefore: isRecentSelected ? cursor : undefined,
        },
      });
    },
    [fetchMore, isRecentSelected, authReady]
  );

  const [scrollSentryRef, { rootRef: listRef }] = useInfiniteScroll({
    hasNextPage: isRecentSelected ? hasPreviousPage : hasNextPage,
    loading,
    onLoadMore: useCallback(() => {
      const cursor = isRecentSelected ? beforeCursor : afterCursor;
      if (!cursor) return;
      loadGroups(cursor);
    }, [afterCursor, beforeCursor, isRecentSelected, loadGroups]),
    rootMargin: "0px 0px 200px 0px",
  });

  const handleCreateGroup = () => {
    if (!workspaceEdge) return;
    openModal(<CreateGroupModal workspaceID={workspaceEdge.node.id} />);
  };

  const orderedGroups = useMemo(() => {
    const groupIDs =
      workspaceEdge?.node.groups.edges.map(group => group.node.id) ?? [];
    return isRecentSelected ? groupIDs.slice().reverse() : groupIDs;
  }, [isRecentSelected, workspaceEdge?.node.groups.edges]);

  const searchMatchesGeneralGroup =
    searchTerm &&
    WORKSPACE_GENERAL_NAME.toLowerCase().startsWith(searchTerm.toLowerCase());

  const renderNoSearchResults = () => {
    if (
      loading ||
      (searchTerm && searchMatchesGeneralGroup) ||
      orderedGroups.length
    )
      return null;

    return (
      <div className="w-full flex justify-center mt-16">
        <div className="px-24 py-16 text-center max-w-[375px] cursor-default select-none">
          <p className="m-0 text-body-bold text-text-primary">
            No groups found
          </p>
          <p className="m-0 text-body text-text-secondary">
            Try using different keywords or check for typos.
          </p>
        </div>
      </div>
    );
  };

  const renderGeneralGroupRow = () => {
    if (!workspaceEdge) return null;

    if (!searchTerm || searchMatchesGeneralGroup)
      return (
        <GroupsDirectoryRowPrimitive
          description={WORKSPACE_GENERAL_DESCRIPTION}
          edge={workspaceEdge}
          title={`${workspaceEdge.node.name} ${WORKSPACE_GENERAL_NAME}`}
        />
      );
  };

  const shouldDisplaySkeletons =
    (loading && (!data || !!searchTerm)) ||
    (isRecentSelected ? hasPreviousPage : hasNextPage);

  return (
    <div ref={listRef} className="grow overflow-auto">
      <div className="flex items-center justify-between max-w-[832px] mx-auto px-16 w-full">
        <div className="mt-16 w-full">
          <Form useFormProps={{ defaultValues: { search: searchTerm } }}>
            <GroupsDirectorySearchInput onChange={setSearchTerm} />
          </Form>
        </div>
      </div>

      <div className="flex items-center justify-between max-w-[832px] mx-auto px-16 w-full overflow-x-auto">
        <ul
          className="flex items-center justify-start my-16 gap-8"
          data-testid="Groups Directory Pills"
        >
          {Object.values(PrimaryPillNames).map(pillName => (
            <Pill
              key={pillName}
              name={pillName}
              onClick={setSelectedPill}
              selected={pillName === selectedPill}
            >
              {pillName}
            </Pill>
          ))}
          {ARCHIVE_GROUPS_FOR_EVERYONE && (
            <>
              <div className="border-l-1 w-0 h-20 m-4 border-border-strong" />
              <Pill
                name={SecondaryPillNames.Archived}
                onClick={setSelectedPill}
                selected={selectedPill === SecondaryPillNames.Archived}
                variant="secondary"
                icon="ArchiveFilled"
                iconPosition="leading"
              >
                {SecondaryPillNames.Archived}
              </Pill>
            </>
          )}
        </ul>

        <Button
          buttonStyle="simplePrimary"
          className="font-black text-sm !px-0 text-nowrap ml-8"
          icon="Plus"
          iconClassName="mr-6"
          iconSize={20}
          onClick={handleCreateGroup}
          type="button"
        >
          Create Group
        </Button>
      </div>

      <div className="max-w-[832px] mx-auto px-16 w-full">
        {renderNoSearchResults()}

        <div
          className={tw(
            "bg-background-body mb-16 rounded-lg shadow-level1 overflow-hidden",
            "[&>div]:border-b-1 [&>div]:border-border-subtle", // Row borders
            "[&>div:last-child]:border-none" // Remove last row border
          )}
        >
          {renderGeneralGroupRow()}

          {orderedGroups?.map(groupID => (
            <GroupsDirectoryRow key={groupID} groupID={groupID} />
          ))}

          <GroupDirectorySkeletons
            ref={scrollSentryRef}
            count={4}
            on={shouldDisplaySkeletons}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupDirectory;
