import { ComponentProps } from "react";

import { Mailbox } from "@utility-types";
import ThreadView from "components/threads/ThreadView";
import NoPersistentChat from "components/user/NoPersistentChat";
import EmptyView from "components/views/EmptyView";

const ThreadPane = (props: Partial<ComponentProps<typeof ThreadView>>) => {
  if (!props.threadID) {
    return <EmptyView />;
  }

  const isUser = props.threadID?.match(/^usr_/);
  if (isUser) {
    return <NoPersistentChat userID={props.threadID} />;
  }

  return <ThreadView mailbox={Mailbox.All} {...props} />;
};

export default ThreadPane;
