import { useRemirrorContext } from "@remirror/react";

import { Button } from "components/design-system/Button";
import { PickedEmoji } from "components/design-system/Emoji/types";
import { EmojiSheet } from "components/design-system/EmojiSheet";
import { EmojiPlus } from "components/Icons";
import useNativeHaptics from "hooks/native/useNativeHaptics";
import { useSnackbar } from "providers/SnackbarProvider";
import tw from "utils/tw";

import buttonStyles from "../buttonStyles";

const RemirrorEmojiPicker = ({
  readOnly,
}: {
  readOnly: boolean;
}): JSX.Element => {
  const { commands, getState } = useRemirrorContext();
  const { openSnackbar } = useSnackbar();
  const { lightImpactHaptic } = useNativeHaptics();

  const handleOnEmojiSelect = (emoji: PickedEmoji) => {
    if ("native" in emoji) {
      try {
        commands.focus(getState().selection.$to);

        commands.replaceText({
          content: emoji.native,
        });
      } catch (error) {
        console.warn(`Error: Emoji ${emoji.native} not supported: ${error}`);
        openSnackbar(
          "error",
          `Sorry ${emoji.native} is not supported on your device.`
        );
      }
    }
  };

  return (
    <EmojiSheet
      onClose={() => commands.focus(getState().selection.$to)}
      onEmojiSelect={handleOnEmojiSelect}
      onOpen={lightImpactHaptic}
      placement="bottom"
      skinTonePosition="search"
    >
      <Button
        buttonStyle="subtle"
        className={tw(buttonStyles(), "border-none")}
        data-testid="picker-button"
        disabled={readOnly}
        icon={EmojiPlus}
        iconSize={20}
      />
    </EmojiSheet>
  );
};

export default RemirrorEmojiPicker;
