import mime from "mime/lite";

import { ExternalObjectPreview } from "@utility-types/linkPreview";
import { AppOrigin } from "components/routing/utils";
import {
  AppUnfurlSetup,
  ExternalObjectLayout,
  ExternalObjectType,
  useUnfurlUrlMutation,
} from "generated/graphql";
import generateRandomId from "utils/generateRandomId";
import { hasValidTLD, matchURL } from "utils/matchURL";
import normalizeSharedURL from "utils/normalizeSharedURL";

const validatePreviewableURL = (matchedURL: string): boolean =>
  matchedURL.startsWith("http") &&
  !matchedURL.includes("://localhost") &&
  new URL(matchedURL).origin !== AppOrigin &&
  ((mime.getType(matchedURL) || "text/html") === "text/html" ||
    !!mime.getType(matchedURL)?.startsWith("image/"));

export default function useCreateLinkPreview({
  addLinkPreview,
  addAppUnfurlSetup,
  workspaceID,
}: {
  addLinkPreview: (preview: ExternalObjectPreview) => boolean;
  addAppUnfurlSetup: (setup: AppUnfurlSetup) => void;
  workspaceID?: string;
}): (newLink: string) => Promise<void> {
  const [unfurlURL] = useUnfurlUrlMutation();

  return async newLink => {
    let url = matchURL(newLink.trim());

    if (!url || !validatePreviewableURL(url) || !hasValidTLD(url)) return;

    url = normalizeSharedURL(url);

    const previewId = generateRandomId();

    const loading = {
      __typename: "ExternalObject" as const,
      audio: null,
      description: "",
      icon: null,
      id: previewId,
      image: null,
      objectType: ExternalObjectType.Other,
      previewId,
      siteName: "",
      state: "loading" as const,
      title: "",
      url,
      video: null,
      layout: ExternalObjectLayout.Default,
    };

    if (!addLinkPreview(loading)) return;

    const result = await unfurlURL({
      context: { notifyErrors: false },
      errorPolicy: "all",
      variables: { input: { url, workspaceID } },
    }).catch(error => {
      console.warn("Error: [UnfurlURLMutation] -", error);
      return null;
    });

    const setup = result?.data?.unfurlURL.appUnfurlSetup;
    if (setup) {
      addAppUnfurlSetup(setup);
    }

    if (!result?.data?.unfurlURL.externalObject) {
      addLinkPreview({ ...loading, state: "failed" });
      return;
    }

    addLinkPreview({
      ...result.data.unfurlURL.externalObject,
      previewId,
      state: "finished",
    });
  };
}
