import { memo } from "react";
import { Link } from "react-router-dom";

import { Sizes } from "@utility-types";
import { RecipientTooltip } from "components/RecipientTooltip";
import { routeToUser } from "components/routing/utils";

import Avatar from "../../design-system/Avatar/Avatar";

type Props = {
  clickable?: boolean;
  image: string | undefined;
  isMyMessage: boolean;
  name: string | undefined;
  size?: Sizes | "x-large";
  userID: string | undefined;
};

const MessageAvatar = memo(
  ({ clickable = true, image, isMyMessage, name, userID }: Props) => {
    const avatar = (
      <Avatar avatarURL={image} rounded="rounded-md" name={name} size="large" />
    );

    return clickable && !isMyMessage && userID ? (
      <RecipientTooltip id={userID}>
        <Link className="block" to={routeToUser({ to: "secondary", userID })}>
          {avatar}
        </Link>
      </RecipientTooltip>
    ) : (
      avatar
    );
  }
);

export default MessageAvatar;
