import { useRef } from "react";

import { PaneID } from "components/App/AppLayoutDesktop/SplitView";

const useWhichPane = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);

  const isPrimaryPane = !!ref.current?.closest(
    `[data-panel-id="${PaneID.PRIMARY}"]`
  );
  const isSecondaryPane = !!ref.current?.closest(
    `[data-panel-id="${PaneID.SECONDARY}"]`
  );

  return { ref, isPrimaryPane, isSecondaryPane };
};

export default useWhichPane;
