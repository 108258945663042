import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { routeParams as getRouteParams } from "components/routing/utils";
import {
  FetchThreadEdgeDocument,
  FetchThreadEdgeQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";

const useIsGlueAIThread = (threadID?: string) => {
  const apolloClient = useApolloClient();
  const { authReady, authData } = useAuthData();

  const location = useLocation();
  const { pathname, search } = location;
  const { superTab } = getRouteParams({ pathname, search });

  // initializing as "undefined" to avoid rendering the wrong skeleton on a "false" value
  const [isGlueAIThread, setIsGlueAIThread] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (!authData?.me.id || !threadID || threadID?.match(/^usr_/)) {
      setIsGlueAIThread(false);
      return;
    }

    apolloClient
      .query<FetchThreadEdgeQuery>({
        fetchPolicy: authReady ? "cache-first" : "cache-only",
        query: FetchThreadEdgeDocument,
        variables: { id: `${threadID}-${authData?.me.id}` },
      })
      .then(({ data }) => {
        const recipients =
          data?.node?.__typename === "ThreadEdge"
            ? data.node.node.recipients.edges
                .filter(edge => edge.node.id !== authData?.me.id)
                .map(edge => edge.node)
            : [];

        setIsGlueAIThread(isGlueAIRecipient(recipients));
      })
      .catch(error => {
        console.error("Error checking if thread is Glue AI:", error);
        setIsGlueAIThread(false);
      });
  }, [apolloClient, authData?.me.id, authReady, superTab, threadID]);

  return isGlueAIThread;
};

export default useIsGlueAIThread;
