import { UserResponse } from "stream-chat/dist/types/types";
import { StreamMessage } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";

export type GroupStyle = "" | "middle" | "top" | "bottom" | "single";

const MaximumGroupTime = 15 * 60 * 1000; // 15min in ms

export const getGroupStyles = <T extends GlueDefaultStreamChatGenerics>(
  message: StreamMessage<T>,
  previousMessage?: StreamMessage<T>,
  nextMessage?: StreamMessage<T>,
  noGroupByUser?: boolean
): GroupStyle => {
  if (message.customType === "message.date") return "";
  if (message.customType === "channel.intro") return "";

  if (message.cid !== (nextMessage?.cid ?? message.cid)) return "single"; // reply-to message
  if (message.type === "system" && message.text?.match(/\(glue:(thr_[^)]+\))/))
    return "single"; // thread system message
  if (noGroupByUser || message.type === "error") return "single";

  const isTopMessage = shouldBreakGrouping(message, previousMessage);
  const isBottomMessage = shouldBreakGrouping(message, nextMessage);

  if (isTopMessage && isBottomMessage) return "single";

  if (!isTopMessage && !isBottomMessage) return "middle";

  if (isTopMessage) return "top";

  return "bottom";
};

export const getReadStates = <T extends GlueDefaultStreamChatGenerics>(
  messages: StreamMessage<T>[],
  read: Record<string, { last_read: Date; user: UserResponse<T> }> = {}
) => {
  const readData: Record<string, UserResponse<T>[]> = {};

  Object.values(read).forEach(readState => {
    if (!readState.last_read) return;

    let lastReadId: string | undefined;

    messages.forEach(msg => {
      const updated_at = msg.content_updated_at || msg.created_at;
      if (!updated_at || new Date(updated_at) >= readState.last_read) return;
      lastReadId = msg.id;
    });

    if (!lastReadId) return;

    (readData[lastReadId] ||= []).push(readState.user);
  });

  return readData;
};

const timeFromMessage = <T extends GlueDefaultStreamChatGenerics>(
  message: StreamMessage<T>,
  otherMessage: StreamMessage<T>
) =>
  (message.created_at &&
    otherMessage.created_at &&
    Math.abs(
      new Date(message.created_at).valueOf() -
        new Date(otherMessage.created_at).valueOf()
    )) ||
  0;

const shouldBreakGrouping = <T extends GlueDefaultStreamChatGenerics>(
  message: StreamMessage<T>,
  otherMessage?: StreamMessage<T>
) =>
  !otherMessage ||
  otherMessage.type === "error" ||
  otherMessage.user?.id !== message.user?.id ||
  otherMessage.customType === "channel.intro" ||
  otherMessage.customType === "message.date" ||
  timeFromMessage(message, otherMessage) > MaximumGroupTime;

type ProcessMessagesParams<T extends GlueDefaultStreamChatGenerics> = {
  lastRead: Date;
  messages: StreamMessage<T>[];
  userId: string;
};

export const addUnreadMark = <T extends GlueDefaultStreamChatGenerics>({
  lastRead,
  messages,
  userId,
}: ProcessMessagesParams<T>): (StreamMessage<T> & {
  isUnreadMark?: boolean;
})[] => {
  const newMessages = [];

  let unreadMark = false;

  for (const message of messages) {
    if (!unreadMark) {
      unreadMark =
        (lastRead &&
          message.created_at &&
          message.user?.id !== userId &&
          new Date(lastRead) < message.created_at) ||
        false;

      unreadMark &&
        newMessages.push({
          id: lastRead.toISOString(),
          isUnreadMark: true,
          type: "regular" as const,
        });
    }

    newMessages.push(message);
  }

  return newMessages;
};
