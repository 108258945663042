import { useApolloClient } from "@apollo/client";
import type { Emoji } from "@emoji-mart/data";
import { MutableRefObject, useCallback, useMemo, useState } from "react";
import { StreamMessage } from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types/stream";
import { Button } from "components/design-system/Button";
import { EmojiSheet } from "components/design-system/EmojiSheet";
import { Tooltip } from "components/design-system/FloatingUi";
import { EmojiPlus, QuoteReply } from "components/Icons";
import {
  FetchMessageMetadataDocument,
  FetchMessageMetadataQuery,
} from "generated/graphql";
import newSyntheticEvent from "utils/newSyntheticEvent";
import { isMobile } from "utils/platform";

import { MessageMetadataInfoDropdown } from "./MessageMetadataInfo";

export const MessageActionMenu = ({
  actionWrapperRef,
  handleReaction,
  handleQuoteReply,
  handleReplyInThread,
  handleSendFeedback,
  initialMessage,
  message,
  messageAction,
  showMetadataOptions,
  showReaction,
  showQuoteReply,
  showReplyInThread,
}: {
  actionWrapperRef: MutableRefObject<HTMLDivElement | null>;
  handleReaction?: (
    reactionType: string,
    event: React.BaseSyntheticEvent
  ) => Promise<void> | void;
  handleQuoteReply: () => void;
  handleReplyInThread: () => void;
  handleSendFeedback: () => void;
  initialMessage?: boolean;
  message: StreamMessage<GlueDefaultStreamChatGenerics>;
  messageAction: JSX.Element | null;
  showMetadataOptions?: boolean;
  showReaction?: boolean;
  showQuoteReply?: boolean;
  showReplyInThread?: boolean;
}): JSX.Element | null => {
  const apolloClient = useApolloClient();

  const [modelInfo, setModelInfo] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleOnEmojiSelect = useCallback(
    (emoji: Emoji) => {
      handleReaction?.(emoji.id || "", newSyntheticEvent("emojiSelected"));
    },
    [handleReaction]
  );

  const fetchGlueAIResponseInfo = () => {
    if (modelInfo || loading) return;
    setLoading(true);
    apolloClient
      .query<FetchMessageMetadataQuery>({
        query: FetchMessageMetadataDocument,
        fetchPolicy: "network-only",
        variables: { id: message.id },
      })
      .then(({ data }) => {
        if (!data?.messageMetadata?.aiResponseInfo) return;
        const { model } = data.messageMetadata.aiResponseInfo;
        setModelInfo(`${model.providerName} ${model.name}`);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  };

  const displayActionMenu = useMemo(
    () =>
      !initialMessage &&
      message &&
      message.type !== "error" &&
      message.type !== "system",
    [initialMessage, message]
  );

  if (!displayActionMenu) return null;

  const buttonClassNames = "p-8 h-32 w-32";

  return (
    <div
      ref={actionWrapperRef}
      className="str-chat-message-actions relative z-1 top-2 right-0 flex items-center justify-between float-right h-18 invisible transition-opacity duration-350 opacity-0"
      data-testid="message-glue-actions"
    >
      {showMetadataOptions && !isMobile() && (
        <div className="flex items-center">
          <MessageMetadataInfoDropdown message={message}>
            <Button
              buttonStyle="subtle"
              className={buttonClassNames}
              disabled={message.status !== "received"}
              icon="Info"
              iconSize={20}
              tooltip={
                modelInfo ? `Powered by ${modelInfo}` : "Response details"
              }
              onMouseEnter={fetchGlueAIResponseInfo}
              type="button"
            />
          </MessageMetadataInfoDropdown>
          <Button
            buttonStyle="subtle"
            className={buttonClassNames}
            disabled={message.status !== "received"}
            icon="ThumbsDown"
            iconSize={20}
            tooltip="Bad response"
            onClick={handleSendFeedback}
            type="button"
          />
          <div className="w-1 border-1 border-border-container h-20 mx-1 rounded-lg" />
        </div>
      )}
      {showReaction && (
        <Tooltip content="Reactions">
          <div className="flex">
            <EmojiSheet onEmojiSelect={handleOnEmojiSelect} allowCustomEmojis>
              <Button
                buttonStyle="subtle"
                className={buttonClassNames}
                data-testid="message-glue-reaction-icon"
                disabled={message.status !== "received"}
                icon={EmojiPlus}
                iconSize={20}
                type="button"
              />
            </EmojiSheet>
          </div>
        </Tooltip>
      )}
      {showQuoteReply && (
        <Button
          buttonStyle="subtle"
          className={buttonClassNames}
          disabled={message.status !== "received"}
          icon={QuoteReply}
          iconSize={20}
          onClick={handleQuoteReply}
          tooltip="Quote reply"
          type="button"
        />
      )}
      {showReplyInThread && (
        <Button
          buttonStyle="subtle"
          className={buttonClassNames}
          disabled={message.status !== "received"}
          icon="Thread"
          iconSize={20}
          onClick={handleReplyInThread}
          tooltip="Start new thread"
          type="button"
        />
      )}
      {messageAction}
    </div>
  );
};
