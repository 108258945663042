import { forwardRef, memo } from "react";
import type { ButtonHTMLAttributes, ComponentProps } from "react";

import { Tooltip } from "components/design-system/FloatingUi";
import Icon from "components/design-system/icons/Icon";
import { TWMargin } from "components/design-system/types/margin";
import {
  VariantPropsOf,
  variantProps,
} from "components/helper/classNameVariants";
import tw from "utils/tw";

const className = {
  base: "flex items-center select-none",
  compoundVariants: [
    {
      className: "font-semibold text-base leading-5",
      variants: {
        buttonFont: "default" as const,
        buttonType: "default" as const,
      },
    },
    {
      className: "font-semibold text-base leading-5",
      variants: {
        buttonFont: "default" as const,
        buttonType: "text" as const,
      },
    },
  ],
  defaultVariants: {
    buttonFont: "default" as const,
    buttonStyle: "primary" as const,
    buttonType: "default" as const,
  },
  variants: {
    buttonFont: {
      default: "", // varies depending on buttonType
      normal: "",
      small: "text-sm",
      subhead: "text-subhead",
      subheadBold: "text-subhead-bold",
      xs: "font-semibold text-xs",
    },
    buttonStyle: {
      action: "text-text-action hover:text-text-action-hover",
      "action-inverse": "text-text-action-inverse",
      "action-inverse-subtle":
        "text-text-action-inverse-subtle hover:text-text-action-inverse-subtle-hover",

      "icon-inverse":
        "text-icon-inverse disabled:text-icon-inverse-disabled hover:text-icon-inverse-hover",
      "icon-primary":
        "text-icon-primary disabled:text-icon-disabled hover:text-icon-primary-hover",
      "icon-secondary":
        "text-icon-secondary disabled:text-icon-disabled hover:text-icon-secondary-hover",

      mediaControls:
        "bg-background-player-controls hover:bg-background-player-controls-hover text-icon-action-inverse opacity-50 hover:opacity-100",
      primary: // Filled button
        "hover:bg-interactive-primary-hover disabled:bg-interactive-primary-disabled text-text-action-inverse bg-interactive-primary",
      secondary: // Outlined button
        "hover:text-interactive-primary-hover hover:border-interactive-primary-hover disabled:text-interactive-alert-disabled disabled:!border-interactive-primary-disabled text-interactive-primary !border-interactive-primary",
      simplePrimary: // Text button
        "hover:text-interactive-primary-hover disabled:text-interactive-alert-disabled text-interactive-primary",
      simpleSecondary: // Text button with secondary text color
        "hover:text-interactive-strong-hover disabled:text-interactive-strong-disabled text-interactive-strong",

      primaryDestructive: // Filled button
        "bg-background-alert-strong hover:bg-background-alert-strong-hover text-text-action-inverse disabled:bg-background-disabled",
      secondaryDestructive: // Outlined button
        "text-text-alert hover:text-text-alert-hover !border-border-alert hover:!border-border-alert-hover disabled:text-text-disabled disabled:!border-border-disabled",
      simpleDestructive: // Text button
        "text-text-alert hover:text-text-alert-hover disabled:text-text-disabled",

      subtle:
        "text-interactive-subtle hover:text-interactive-subtle-hover disabled:text-interactive-subtle-disabled",
      none: "",
    },
    buttonType: {
      default: "border-1 border-transparent rounded focus-visible-shadow",
      icon: "",
      none: "",
      sm: "border-1 border-transparent rounded focus-visible-shadow",
      text: "focus-visible-shadow",
    },
  },
};

const buttonProps = variantProps(className);

type ButtonIconProps = {
  icon?: ComponentProps<typeof Icon>["icon"];
  iconClassName?: string;
  iconStyle?: React.CSSProperties & Record<string, unknown>;
  iconLast?: boolean;
  iconMargin?: TWMargin;
  iconSize?: number;
  iconStroke?: number;
};

type ButtonTooltipProps = Pick<
  ComponentProps<typeof Tooltip>,
  "tooltipStyle"
> & {
  tooltipPlacement?: Pick<
    ComponentProps<typeof Tooltip>,
    "placement"
  >["placement"];
};

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className"> &
  VariantPropsOf<typeof buttonProps> &
  ButtonIconProps &
  ButtonTooltipProps & {
    testId?: string;
    tooltip?: string;
  };

const Button = memo(
  forwardRef<HTMLButtonElement, Props>(
    (
      {
        children,
        className: classNameProp,
        icon,
        iconClassName,
        iconStyle,
        iconLast,
        iconMargin = iconLast ? "ml-4" : "mr-10",
        iconSize = 18,
        iconStroke = 1.5,
        testId,
        tooltip,
        tooltipPlacement,
        tooltipStyle,
        ...props
      },
      forwardedRef
    ) => {
      const buttonType = props.buttonType ?? "default";
      const buttonElement = (
        <button
          ref={forwardedRef}
          data-testid={testId ?? tooltip}
          {...buttonProps({
            className: tw(
              buttonType === "default"
                ? {
                    "py-5 pr-16 pl-12": !!(icon && children),
                    "py-5 px-6": !!icon && !children,
                    "py-5 px-16": !icon && !!children,
                  }
                : "",
              buttonType === "sm"
                ? {
                    "py-4 pr-16 pl-12": !!(icon && children),
                    "py-4 px-4": !!icon && !children,
                    "py-4 px-16": !icon && !!children,
                  }
                : "",
              iconLast ? "flex-row-reverse" : "",
              classNameProp
            ),
            ...props,
          })}
        >
          {!!icon && (
            <Icon
              icon={icon}
              className={tw(
                "inline-block",
                children && props.buttonType !== "none" ? iconMargin : "",
                iconClassName
              )}
              style={iconStyle}
              size={iconSize}
              strokeWidth={iconStroke}
            />
          )}
          {children}
        </button>
      );

      return tooltip ? (
        <Tooltip
          content={tooltip}
          placement={tooltipPlacement}
          tooltipStyle={tooltipStyle}
        >
          {buttonElement}
        </Tooltip>
      ) : (
        buttonElement
      );
    }
  )
);

export default Button;
