import type { Emoji } from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { ComponentProps, useEffect } from "react";

import useOnce from "hooks/useOnce";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";

import useCustomEmojisList from "../../emoji/hooks/useCustomEmojisList";
import { Button } from "../Button";
import { emojiSet, spritesheetURL, useEmoji } from "../Emoji";

import CreateEmojiModal from "./CreateEmojiModal";
import usePreserveSkinTone from "./hooks/usePreserveSkinTone";
import ManageEmojisModal from "./ManageEmojisModal";

const FREQUENT_EMOJI = "emoji-mart.frequently";

type Props = {
  allowCustomEmojis: boolean;
  handleCloseFloatingUI?: (open: boolean) => void;
  modalId?: string;
  onEmojiSelect: (emoji: Emoji) => void;
  setOpen?: (open: boolean) => void;
  suggestedEmoji?: string;
} & ComponentProps<typeof Picker>;

export default function EmojiPicker({
  allowCustomEmojis = false, // TODO: Remove once we support custom emojis in editor
  handleCloseFloatingUI,
  modalId,
  onEmojiSelect,
  setOpen,
  skinTonePosition,
  suggestedEmoji,
  ...props
}: Props) {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { data, i18n } = useEmoji(suggestedEmoji);
  const { openModal, closeModal } = useModalStore(
    ({ openModal, closeModal }) => ({
      openModal,
      closeModal,
    })
  );

  const { pickerCustomEmojis } = useCustomEmojisList();

  useOnce(() => {
    const frequentEmoji = localStorage.getItem(FREQUENT_EMOJI);
    const nextFrequentEmoji = frequentEmoji ? JSON.parse(frequentEmoji) : {};

    nextFrequentEmoji["+1"] = 9999;
    nextFrequentEmoji.heart = 9998;
    nextFrequentEmoji.joy = 9997;
    nextFrequentEmoji.astonished = 9996;
    nextFrequentEmoji.pensive = 9995;
    nextFrequentEmoji.angry = 9994;

    localStorage.setItem(FREQUENT_EMOJI, JSON.stringify(nextFrequentEmoji));

    i18n.categories.frequent = "Useful & Frequently Used";
  });

  useEffect(() => {
    if (!suggestedEmoji) return;
    i18n.categories.frequent = "Suggested";
  }, [i18n, suggestedEmoji]);

  const handleClosePicker = () => {
    if (modalId) {
      closeModal(`${modalId}`);
    } else {
      handleCloseFloatingUI?.(false) ?? setOpen?.(false);
    }
  };

  const handleOnEmojiSelect = (emoji: Emoji) => {
    onEmojiSelect(emoji);
    handleClosePicker();
  };

  const openCustomEmojiModal = (modal: "create" | "manage") => {
    handleClosePicker();
    modal === "create" ? openCreateModal() : openManageModal();
  };

  const openCreateModal = (id?: string) =>
    openModal(<CreateEmojiModal openManageModal={openManageModal} />, {
      closeModals: id ? [id] : undefined,
    });

  const openManageModal = (id?: string) =>
    openModal(<ManageEmojisModal openCreateModal={openCreateModal} />, {
      closeModals: id ? [id] : undefined,
    });

  // if skin color can't be changed (skinTonePosition is "none") we default to "0" yellow
  usePreserveSkinTone({ skinTonePosition });

  return (
    <div data-testid="emoji-picker">
      <Picker
        {...props}
        autoFocus={breakpointMD}
        data={data}
        exceptEmojis={
          !allowCustomEmojis
            ? pickerCustomEmojis.flatMap(c => c.emojis.map(e => e.id))
            : []
        }
        getSpritesheetURL={() => spritesheetURL}
        i18n={i18n}
        maxFrequentRows={2}
        onEmojiSelect={handleOnEmojiSelect}
        set={emojiSet}
        skinTonePosition={skinTonePosition}
        custom={allowCustomEmojis ? pickerCustomEmojis : []}
      />
      {allowCustomEmojis && breakpointMD && (
        <div className="flex justify-between items-center px-20 h-70 border-t-1 border-border-subtle">
          <Button
            buttonStyle="secondary"
            onClick={() => openCustomEmojiModal("create")}
          >
            Add emoji
          </Button>
          <Button
            buttonStyle="subtle"
            onClick={() => openCustomEmojiModal("manage")}
          >
            Manage emojis
          </Button>
        </div>
      )}
    </div>
  );
}
