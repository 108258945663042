import { ComponentProps } from "react";

import { ThreadPreview, ThreadSimple } from "@utility-types";
import { MemberRoleBadge } from "components/design-system/Badge";
import OptionalLink from "components/OptionalLink";
import { RecipientTooltip } from "components/RecipientTooltip";
import { routeToThread, routeToUser } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import ThreadName from "components/thread/ThreadName";
import { formatDateWithTime } from "utils/formatDate";
import getRandomInt from "utils/getRandomInt";
import tw from "utils/tw";

import { Timestamp } from "./Timestamp";

type Props = {
  className?: string;
  clickable?: boolean;
  createdAt?: string | Date;
  isMyMessage: boolean;
  messageActionMenu?: JSX.Element | null;
  messageID?: string;
  messageThread?: ThreadSimple | ThreadPreview;
  messageType?: string;
  name?: string;
  onUserClick: (event: React.SyntheticEvent) => void;
  roleBadge?: {
    role: ComponentProps<typeof MemberRoleBadge>["memberRole"];
    variant?: ComponentProps<typeof MemberRoleBadge>["variant"];
  };
  statusMessage?: string;
  userID?: string;
};

export const Author = ({
  className,
  clickable = true,
  createdAt,
  isMyMessage,
  messageActionMenu,
  messageID,
  messageThread,
  messageType,
  name = "",
  onUserClick,
  roleBadge,
  statusMessage,
  userID,
}: Props) =>
  createdAt ? (
    <header
      className={tw("-mt-2 pr-16", className)}
      data-testid="message-glue-author"
      onClick={onUserClick}
    >
      <span className="text-base font-semibold gap-4 inline-flex items-baseline">
        {!isMyMessage && userID ? (
          <RecipientTooltip id={userID}>
            <OptionalLink
              enabled={!!clickable}
              to={routeToUser({ to: "secondary", userID })}
            >
              {name}
            </OptionalLink>
          </RecipientTooltip>
        ) : (
          name
        )}

        {roleBadge && (
          <MemberRoleBadge
            variant={roleBadge.variant ?? "subtle"}
            memberRole={roleBadge.role}
            className="relative -top-1" // We want to align the badge 1px up from baseline
          />
        )}
      </span>
      {statusMessage ? (
        <span
          className="inline-block ml-6 text-text-subtle text-sm italic"
          data-testid="message-glue-author-status"
        >
          {statusMessage}.
        </span>
      ) : null}
      {messageThread ? (
        <>
          {" in "}
          <OptionalLink
            enabled={!!clickable}
            to={routeToThread({
              messageID,
              threadID: messageThread.id,
              to: "secondary",
            })}
            tooltip="Go to original message"
          >
            <span className="text-base font-semibold">
              <ThreadName thread={messageThread} />
            </span>

            <Timestamp
              customClass="ml-6 text-xs text-text-subtle"
              date={createdAt}
              formatDate={formatDateWithTime}
            />
          </OptionalLink>
        </>
      ) : (
        <Timestamp
          customClass="ml-6 text-xs text-text-subtle"
          date={createdAt}
          formatDate={formatDateWithTime}
        />
      )}

      {messageActionMenu}
      {messageType === "error" && <div>Only visible to you</div>}
    </header>
  ) : (
    <Skeleton
      className={tw("block max-w-full my-0", className)}
      height="16px"
      width={`${getRandomInt(60, 120)}px`}
    />
  );
