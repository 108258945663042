import { useRemirrorContext } from "@remirror/react";
import { useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { FromToProps, Selection } from "remirror";

import { Button } from "components/design-system/Button";
import { Form, SubmitButton, TextInput } from "components/design-system/Forms";
import { matchURL } from "utils/matchURL";

import { getLinkData } from "./utils";

type FormData = {
  href: string;
  text: string;
};

const FocusHandler = ({ defaultValues }: { defaultValues: FormData }) => {
  const { setFocus } = useFormContext();

  useEffect(() => {
    /* istanbul ignore next */
    if (!setFocus) return;

    /* istanbul ignore next */
    setFocus(defaultValues.text ? "href" : "text");
  }, [defaultValues, setFocus]);

  return null;
};

const LinkSettingsForm = ({
  defaultValues,
}: {
  defaultValues: FormData;
}) => (
  <>
    <FocusHandler defaultValues={defaultValues} />
    <TextInput<FormData>
      data-testid="link-settings-text"
      label="Link Text:"
      name="text"
      wrapperClassName="my-5"
    />
    <TextInput<FormData>
      config={{
        validate: {
          matchURL: value =>
            value === "" ||
            !!matchURL(value) ||
            "Must be a valid URL, or empty.",
        },
      }}
      data-testid="link-settings-link"
      label="Link URL:"
      name="href"
      wrapperClassName="my-5"
    />
    <SubmitButton className="mt-12" data-testid="link-form-submit-bt">
      Apply
    </SubmitButton>
  </>
);

type Props = {
  selection: Selection | null;
  setOpen?: (open: boolean) => void;
};

const LinkSettings = ({ selection, setOpen }: Props): JSX.Element => {
  const { chain, commands, view } = useRemirrorContext();
  const linkRangeRef = useRef<FromToProps>({ from: 0, to: 0 });

  const { href, text } = useMemo(() => {
    if (!selection) {
      return { href: "", text: "" };
    }

    const { href, range, text } = getLinkData(selection, view);
    linkRangeRef.current = range;
    return { href, text };
  }, [selection, view]);

  const applyLinkValues = (data: FormData) => {
    const { from, to } = linkRangeRef.current;
    const { href, text } = data;
    const linkText = text || href;
    const url = matchURL(href);

    if (url) {
      try {
        chain
          .insertText(linkText, { from, to })
          .updateLink({ href: url }, { from, to: from + linkText.length })
          .focus()
          .run();
        setOpen?.(false);
        return;
      } catch (_) {
        commands.focus();
        return;
      }
    }

    if (!href) {
      try {
        chain
          .insertText(linkText, { from, to })
          .removeLink({ from, to: from + linkText.length })
          .focus()
          .run();
        setOpen?.(false);
      } catch (_) {
        commands.focus();
        return;
      }
    }
  };

  const cancelLinkSettings = () => {
    if (selection) {
      chain.selectText(selection).focus().run();
    }
    setOpen?.(false);
  };

  return (
    <div
      className="w-250 flex flex-col justify-center p-16"
      data-testid="link-settings-wrapper"
    >
      <div className="title flex justify-end">
        <Button
          buttonStyle="simpleSecondary"
          className="!p-0"
          data-testid="link-settings-close"
          icon="Close"
          iconSize={25}
          onClick={cancelLinkSettings}
          type="button"
        />
      </div>
      <Form
        onSubmit={applyLinkValues}
        useFormProps={{
          defaultValues: { href, text },
        }}
      >
        <LinkSettingsForm defaultValues={{ href, text }} />
      </Form>
    </div>
  );
};

export default LinkSettings;
