import notificationBell from "assets/icons-png/bell.png";
import { setFavicon } from "components/helper";
import env from "utils/processEnv";

export default class ShowNotificationFavicon {
  defaultIconPath: string;
  constructor() {
    this.defaultIconPath = `/${env.glueFavicon}`;
  }

  show(bell: boolean): void {
    if (bell) {
      setFavicon(notificationBell);
    } else {
      setFavicon(this.defaultIconPath);
    }
  }
}
