import styles from "./ButtonGroup.module.css";
import { ButtonGroupButton, ButtonGroupButtonProps } from "./ButtonGroupButton";

type ButtonConfig = Omit<ButtonGroupButtonProps, "children"> & {
  text: string;
};

type ButtonGroupProps = {
  buttons: ButtonConfig[];
};

export const ButtonGroup = ({ buttons }: ButtonGroupProps) => {
  return (
    <div className={styles.wrapper}>
      {buttons.map(({ text, ...rest }) => (
        <ButtonGroupButton key={text} {...rest}>
          {text}
        </ButtonGroupButton>
      ))}
    </div>
  );
};
