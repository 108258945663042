import { ComponentProps, useRef } from "react";
import { useHistory } from "react-router";

import { Card } from "components/design-system/Card";
import { HistoryState } from "components/Navigation/HistoryState";
import ThreadCompose from "components/threads/ThreadCompose/ThreadCompose";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import tw from "utils/tw";

const InboxCompose = (props: ComponentProps<typeof ThreadCompose>) => {
  const ref = useRef<HTMLDivElement>(null);
  const isBreakpoint = useElementBreakpoint(ref, 820);

  const history = useHistory<HistoryState>();

  const groupRecipients = history.location.state?.recipients ?? [];

  return (
    <div
      ref={ref}
      className="flex items-center justify-center grow h-full bg-background-app-secondary"
    >
      <Card
        className={tw({
          "w-full h-full !shadow-none !border-none": !isBreakpoint,
          "grow h-full max-w-[800px] max-h-[600px]": isBreakpoint,
        })}
      >
        <ThreadCompose
          {...props}
          initialDraft={{ recipients: groupRecipients }}
        />
      </Card>
    </div>
  );
};

export default InboxCompose;
