import { ApolloError } from "@apollo/client";

type Action<T> = () => Promise<T>;

/**
 * @summary Retry a function with backoff, retry interval will double with each attempt.
 * @param fn Function to retry
 * @param attempts Maximum number of attempts
 * @param initialSleep Initial sleep duration in ms
 * @param signal Optional AbortSignal for cancellation
 */

const retryWithBackoff = <T>(
  fn: Action<T>,
  signal: AbortSignal | undefined = undefined,
  attempts = 10,
  initialSleep = 1000
): Promise<T> => {
  return new Promise((resolve, reject) => {
    if (signal?.aborted) {
      reject(new DOMException("Operation aborted", "AbortError"));
      return;
    }

    let timeoutId: NodeJS.Timeout;

    const cleanup = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

    signal?.addEventListener(
      "abort",
      () => {
        cleanup();
        reject(new DOMException("Operation aborted", "AbortError"));
      },
      { once: true }
    );

    fn()
      .then(resolve)
      .catch(error => {
        if (attempts <= 1) {
          reject(error);
          return;
        }

        if (error instanceof ApolloError && !error.networkError) {
          reject(error);
          return;
        }

        console.warn(`Retrying after error: ${error.message}`);

        timeoutId = setTimeout(() => {
          retryWithBackoff(fn, signal, attempts - 1, initialSleep * 2)
            .then(resolve)
            .catch(reject);
        }, initialSleep);
      });
  });
};

export default retryWithBackoff;
