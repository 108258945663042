import { matchPath } from "react-router";

import AppTabLayout from "components/App/AppLayoutMobile/AppTabLayout";
import JoinRequest from "components/design-system/ui/JoinRequest/JoinRequest";
import NewThreadButton from "components/design-system/ui/NewThreadButton";
import GroupProfilePane from "components/design-system/ui/ProfilePane/GroupProfilePane";
import MobileProfilePane from "components/design-system/ui/ProfilePane/MobileProfilePane";
import UserProfilePane from "components/design-system/ui/ProfilePane/UserProfilePane";
import SidebarAccessoryContainer from "components/design-system/ui/SidebarAccessoryContainer";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  PathMatch,
  PathnameParams,
  TabName,
  routeParams as getRouteParams,
  locationFromRoute,
} from "components/routing/utils";
import ThreadPane from "components/threads/ThreadPane";

import ActivityMainTabs from "./ActivityMain/ActivityMainTabs";

const ActivityMobile = ({
  scrollRef,
}: {
  scrollRef?: (el: HTMLUListElement | null) => void;
}) => {
  const { route } = usePartitionState(({ route }) => ({ route }));
  const { pathname, search } = locationFromRoute(route);
  const params = matchPath<PathnameParams>(
    pathname,
    PathMatch.activity
  )?.params;

  const { d } = getRouteParams({
    pathname,
    search,
  });

  const detailContent = () => {
    if (d) {
      return <MobileProfilePane id={d} />;
    }

    if (params?.threadID) {
      return (
        <ThreadPane messageID={params?.messageID} threadID={params?.threadID} />
      );
    }

    if (params?.joinID) {
      return <JoinRequest joinID={params?.joinID} />;
    }

    if (params?.recipientID) {
      return <GroupProfilePane groupID={params?.recipientID} />;
    }

    if (params?.userID) {
      return <UserProfilePane userID={params?.userID} />;
    }

    return null;
  };

  return (
    <AppTabLayout detailChildren={detailContent()} name={TabName.Activity}>
      <div
        className="flex flex-col grow h-full min-h-0 relative w-full"
        data-testid="for-you-detail-mobile"
      >
        <ViewScrollContainer
          className="flex h-full"
          id="mega-tab-sidebar--activity"
          padding="pb-0"
        >
          <ActivityMainTabs scrollRef={scrollRef} title="Activity" />
        </ViewScrollContainer>
      </div>

      <SidebarAccessoryContainer>
        <NewThreadButton />
      </SidebarAccessoryContainer>
    </AppTabLayout>
  );
};

export default ActivityMobile;
