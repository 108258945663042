import { ApolloCache, Reference, useApolloClient } from "@apollo/client";
import { sortBy, uniqBy } from "lodash-es";
import { useCallback } from "react";

import {
  Message,
  MessageConnection,
  MessageEdge,
  ThreadEdgeSimple,
  ThreadSimple,
} from "@utility-types";
import { readThreadEdge, writeThreadEdge } from "apollo/cache/threadHelpers";
import {
  ThreadActions,
  applyThreadListRules,
} from "apollo/cache/threadListRules";
import { GLUE_URI_SCHEME } from "components/MessageEditor/types";
import {
  MemberRole,
  MessageFieldsFragment,
  PersistentChatsDocument,
  PersistentChatsQuery,
  ThreadSubscription,
  ThreadsOrder,
  UnreadCounts,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useUserCacheUpdate from "hooks/user/useUserCacheUpdate";
import { ksuid } from "utils/ksuid";
import threadChatType from "utils/thread/threadChatType";

const updateCounts = (edge: ThreadEdgeSimple, edgeWas: ThreadEdgeSimple) => {
  // Calculate recipients unread count changes
  let mentioned = 0;
  let total = 0;
  let unseen = 0;

  if (edge.isRead !== edgeWas.isRead) {
    total = edge.isRead && !edgeWas.isRead ? -1 : 1;
  }

  if (edge.isSeen !== edgeWas.isSeen) {
    unseen = edge.isSeen && !edgeWas.isSeen ? -1 : 1;
  }

  if (edge.isMentioned !== edgeWas.isMentioned) {
    mentioned = !edge.isMentioned && edgeWas.isMentioned ? total : 1;
  } else {
    mentioned = edgeWas.isMentioned ? total : 0;
  }

  return { mentioned, total, unseen };
};

const useThreadCacheUpdate: () => {
  onMessageNew: (
    threadEdge: ThreadEdgeSimple,
    threadUpdated: boolean,
    message: Message,
    isMe: boolean,
    isRead: boolean,
    cache?: ApolloCache<unknown>
  ) => void;
  onRecipientsChange: (
    threadEdge: ThreadEdgeSimple,
    cache?: ApolloCache<unknown>
  ) => void;
  onThreadActions: (
    threadEdge: ThreadEdgeSimple,
    threadEdgeWas: ThreadEdgeSimple,
    actions: ThreadActions,
    cache?: ApolloCache<unknown>
  ) => void;
  onThreadNew: (thread: ThreadSimple, cache?: ApolloCache<unknown>) => void;
  onThreadRead: (threadID: string, cache?: ApolloCache<unknown>) => void;
} = () => {
  const apolloClient = useApolloClient();
  const { authData } = useAuthData();

  const { onNewUser } = useUserCacheUpdate();

  const updateRecipientThreadLists = useCallback(
    (
      edge: ThreadEdgeSimple,
      edgeWas: ThreadEdgeSimple,
      sort: boolean,
      cache: ApolloCache<unknown>
    ) => {
      if (!authData?.me.id) return;

      // API doesn't include persistent chats in recipient thread lists
      if (edge.node.isPersistentChat) {
        return;
      }

      const { mentioned, total, unseen } = updateCounts(edge, edgeWas);

      // Update GroupEdge, WorkspaceEdge
      const groupWorkspaceIDs = new Set<string>();
      const recipients = edge.node.recipients.edges.map(e => e.node);
      for (const r of recipients) {
        // Update recipient thread lists
        const vars = { recipientID: r.id, sort };
        applyThreadListRules(edge, vars, undefined, cache, authData.me.id);

        if (r.__typename === "User") continue;

        if (r.__typename === "GroupPreview" && r.workspaceID) {
          groupWorkspaceIDs.add(r.workspaceID);
        }

        // Update group/workspace unreadThreadCounts
        const edgeType = `${r.__typename.replace("Preview", "")}Edge`;
        cache.modify({
          fields: {
            unreadThreadCounts: (counts: UnreadCounts) => ({
              ...counts,
              mentioned: Math.max(0, counts.mentioned + mentioned),
              total: Math.max(0, counts.total + total),
              unseen: Math.max(0, counts.unseen + unseen),
            }),
          },
          id: `${edgeType}:${r.id}-${authData.me.id}`,
        });
      }

      // Update unreadGroupThreadCounts on WorkspaceEdge
      for (const workspaceID of groupWorkspaceIDs) {
        cache.modify({
          fields: {
            unreadGroupThreadCounts: (counts: UnreadCounts) => ({
              ...counts,
              mentioned: Math.max(0, counts.mentioned + mentioned),
              total: Math.max(0, counts.total + total),
              unseen: Math.max(0, counts.unseen + unseen),
            }),
          },
          id: `WorkspaceEdge:${workspaceID}-${authData.me.id}`,
        });
      }
    },
    [authData?.me.id]
  );

  const updatePersistentChats = useCallback(
    (
      edge: ThreadEdgeSimple,
      edgeWas: ThreadEdgeSimple,
      cache: ApolloCache<unknown>
    ) => {
      if (!authData?.me.id || !edge.node.isPersistentChat) return;

      const { mentioned, total } = updateCounts(edge, edgeWas);

      const variables = {
        chatType: threadChatType(edge.node),
        order: ThreadsOrder.LastMessage,
      };

      const list = cache.readQuery<PersistentChatsQuery>({
        query: PersistentChatsDocument,
        variables,
      })?.persistentChats;

      if (!list) return;

      const persistentChats = {
        ...list,
        edges: sortBy(list.edges, "cursor"),
        unreadCounts: {
          ...list.unreadCounts,
          mentioned: Math.max(0, list.unreadCounts.mentioned + mentioned),
          total: Math.max(0, list.unreadCounts.total + total),
        },
      };

      cache.writeQuery<PersistentChatsQuery>({
        data: { persistentChats },
        query: PersistentChatsDocument,
        variables,
      });
    },
    [authData?.me.id]
  );

  // Update the thread and edge with a new message just received from Stream
  // This syncs the user, updates last message, increments unread counts & flags
  const updateThreadEdge = useCallback(
    (
      threadEdge: ThreadEdgeSimple,
      message: Message,
      isMe: boolean,
      isRead: boolean,
      c: ApolloCache<unknown> = apolloClient.cache
    ) => {
      if (!authData?.me.id) return;

      const isMentioned = (message: MessageFieldsFragment) => {
        return message.text?.includes(`](${GLUE_URI_SCHEME}${authData.me.id})`);
      };

      c.batch({
        update(c) {
          // Update user cache
          onNewUser(message.user, c);

          const userRef = { __ref: `User:${message.user.id}` };

          // Update Thread object
          c.modify({
            fields: {
              lastMessage: (): Omit<Message, "user"> & { user: Reference } => ({
                ...message,
                user: userRef,
              }),
              "messages:lastUnread": (messages: MessageConnection) => {
                const edges = messages.edges;
                const newEdge = newMessageEdge(message);
                const replyCount = messages.replyCount + 1;
                return { ...messages, edges: [...edges, newEdge], replyCount };
              },
              "messages:recentMessages": (recent: MessageConnection) => ({
                ...recent,
                replyCount: recent.replyCount + 1,
              }),
              recentMessagesUsers: (cachedUsers: Reference[]): Reference[] =>
                uniqBy([userRef, ...cachedUsers], u => u.__ref),
            },
            id: `Thread:${message.threadID}`,
          });

          // Update ThreadEdge object
          const isMention = isMentioned(message);
          const isSubscribed =
            isMe ||
            isMention ||
            threadEdge.subscription === ThreadSubscription.Inbox;

          c.modify({
            fields: {
              cursor: () => ksuid(new Date(message.createdAt).valueOf()),
              isArchived: wasArchived => (isSubscribed ? false : wasArchived),
              isRead: () => isRead,
              isSeen: () => isRead,
              subscription: wasSubscription =>
                isSubscribed ? ThreadSubscription.Inbox : wasSubscription,
              unreadMessageCounts: (counts: UnreadCounts) => ({
                ...counts,
                mentioned: isRead ? 0 : counts.mentioned + (isMention ? 1 : 0),
                total: isRead ? 0 : counts.total + 1,
                unseen: isRead ? 0 : counts.unseen + 1,
              }),
            },
            id: c.identify(threadEdge),
          });
        },
      });
    },
    [apolloClient.cache, authData?.me.id, onNewUser]
  );

  // Called when a new message is received from Stream events
  // If the threadEdge was just fetched from the network, we skip update to avoid
  // incrementing unread count, but we still need to apply thread list rules
  const onMessageNew = useCallback(
    (
      threadEdge: ThreadEdgeSimple,
      threadIsSynced: boolean,
      message: Message,
      isMe: boolean,
      isRead: boolean,
      cache: ApolloCache<unknown> = apolloClient.cache
    ) => {
      if (!authData?.me.id) return;

      cache.batch({
        update(c) {
          if (!threadIsSynced) {
            updateThreadEdge(threadEdge, message, isMe, isRead, c);
          }

          const updated = readThreadEdge(threadEdge.id, c);
          if (!updated) {
            console.warn("ThreadEdge not found after update"); // shouldn't happen
            return;
          }

          // new messages marked read or unread based on edge state
          const wasRead = threadEdge.isRead;
          const read = !isRead ? false : !wasRead ? true : undefined;
          const actions = { archived: updated.isArchived, read, seen: read };
          const meID = authData.me.id;

          applyThreadListRules(updated, { sort: true }, actions, c, meID);
          updateRecipientThreadLists(updated, threadEdge, true, c);
          updatePersistentChats(updated, threadEdge, c);
        },
      });
    },
    [
      apolloClient.cache,
      authData?.me.id,
      updateRecipientThreadLists,
      updatePersistentChats,
      updateThreadEdge,
    ]
  );

  // Called when a thread's recipients change
  // for thread list rules based on recipient
  const onRecipientsChange = useCallback(
    (
      edge: ThreadEdgeSimple,
      cache: ApolloCache<unknown> = apolloClient.cache
    ) => {
      cache.batch({
        update(c) {
          applyThreadListRules(edge, {}, undefined, c, authData?.me.id);
        },
      });
    },
    [apolloClient.cache, authData?.me.id]
  );

  // Called on thread actions like mark read, mark seen, archive, etc.
  // We need to apply thread list rules to update threads lists
  const onThreadActions = useCallback(
    (
      edge: ThreadEdgeSimple,
      edgeWas: ThreadEdgeSimple,
      actions: ThreadActions,
      cache: ApolloCache<unknown> = apolloClient.cache
    ) => {
      cache.batch({
        update(c) {
          // only sort when marking seen in feed
          const sort = actions.seen !== undefined;
          applyThreadListRules(edge, { sort }, actions, c, authData?.me.id);
          updateRecipientThreadLists(edge, edgeWas, sort, c);
          updatePersistentChats(edge, edgeWas, c);
        },
      });
    },
    [
      apolloClient.cache,
      authData?.me.id,
      updateRecipientThreadLists,
      updatePersistentChats,
    ]
  );

  const onThreadNew = useCallback(
    (
      thread: ThreadSimple,
      cache: ApolloCache<unknown> = apolloClient.cache
    ) => {
      if (!authData) return;
      const meID = authData.me.id;

      const edge =
        readThreadEdge(`${thread.id}-${meID}`, cache) ||
        newThreadEdge(thread, meID);

      cache.batch({
        update: c => {
          writeThreadEdge(edge, c);
          applyThreadListRules(edge, { sort: true }, undefined, c, meID);
          updateRecipientThreadLists(edge, edge, true, c);
        },
      });
    },
    [apolloClient.cache, authData, updateRecipientThreadLists]
  );

  const onThreadRead = useCallback(
    (threadID: string, cache: ApolloCache<unknown> = apolloClient.cache) => {
      if (!authData?.me.id) return;

      const threadEdge = readThreadEdge(`${threadID}-${authData.me.id}`, cache);
      if (!threadEdge) return;

      const { isRead, unreadMessageCounts, node: thread } = threadEdge;
      if (isRead && unreadMessageCounts.total === 0) return;

      const updatedEdge: ThreadEdgeSimple = {
        ...threadEdge,
        isRead: true,
        isSeen: true,
        lastReadID: thread.lastMessage?.id ?? threadEdge.lastReadID,
        lastSeenID: thread.lastMessage?.id ?? threadEdge.lastSeenID,
        unreadMessageCounts: {
          ...unreadMessageCounts,
          mentioned: 0,
          total: 0,
          unseen: 0,
        },
      };

      cache.batch({
        update(c) {
          c.modify({
            fields: {
              isRead: () => updatedEdge.isRead,
              isSeen: () => updatedEdge.isSeen,
              lastReadID: () => updatedEdge.lastReadID,
              lastSeenID: () => updatedEdge.lastSeenID,
              unreadMessageCounts: () => updatedEdge.unreadMessageCounts,
            },
            id: cache.identify(threadEdge),
          });

          onThreadActions(updatedEdge, threadEdge, { read: true }, c);
        },
      });
    },
    [apolloClient.cache, authData?.me.id, onThreadActions]
  );

  return {
    onMessageNew,
    onRecipientsChange,
    onThreadActions,
    onThreadNew,
    onThreadRead,
  };
};

const newMessageEdge = (message: Message): MessageEdge => ({
  __typename: "MessageEdge",
  cursor: message.id,
  id: `${message.id}-${message.threadID}`,
  node: message,
});

const newThreadEdge = (
  thread: ThreadSimple,
  userID: string
): ThreadEdgeSimple => ({
  __typename: "ThreadEdge",
  cursor: thread.lastMessage?.id || thread.id,
  id: `${thread.id}-${userID}`,
  isArchived: false,
  isMentioned: false,
  isRead: true,
  isSeen: true,
  isStarred: false,
  lastReadID: null,
  lastSeenID: null,
  remindAt: null,
  node: thread,
  recipientRole: MemberRole.Admin,
  subscription: ThreadSubscription.Inbox,
  unreadMessageCounts: {
    __typename: "UnreadCounts",
    mentioned: 0,
    total: 0,
    unseen: 0,
  },
});

export default useThreadCacheUpdate;
