import { ElementRef, MutableRefObject, useEffect } from "react";

import { MessageEditor } from "components/MessageEditor";
import useMessageEditorStore from "store/useMessageEditorStore";

const useReadOnlyEditorState = (
  editor: MutableRefObject<ElementRef<typeof MessageEditor> | null>,
  isModal?: boolean
) => {
  useEffect(() => {
    if (editor.current === null || !isModal) return;

    const globalEditors = useMessageEditorStore.getState().editors;
    const temp = new Map(globalEditors);

    globalEditors.forEach(({ editor: globalEditor }) => {
      if (editor.current === globalEditor) return;

      globalEditor.setReadOnly(true);
    });

    return () => {
      globalEditors.forEach(({ editor: globalEditor, uid }) => {
        globalEditor.setReadOnly(temp.get(uid)?.editor.readOnly() || false);
      });
    };
  }, [editor, isModal]);
};

export default useReadOnlyEditorState;
