import { ComponentProps, memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ThreadEdgeFeed, nodeAs } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Card } from "components/design-system/Card";
import Icon from "components/design-system/icons/Icon";
import { LoadingSpinner } from "components/design-system/LoadingSpinner";
import { routeToThread } from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { useFetchThreadEdgePaginatedQuery } from "generated/graphql";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import useAuthData from "hooks/useAuthData";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import useUpdatingState from "hooks/useUpdatingState";
import getRandomInt from "utils/getRandomInt";
import tw from "utils/tw";

import FeedMessage from "./FeedMessage";
import FeedThreadHeader from "./FeedThreadHeader";
import useFeedItemCounts from "./hooks/useFeedItemCounts";
import useFeedItemData from "./hooks/useFeedItemData";
import useFeedItemLoadMore from "./hooks/useFeedItemLoadMore";
import UnreadMarker from "./UnreadMarker";

const HiddenReplies = ({
  count,
  loading = false,
  newReplies = false,
  onClick,
}: {
  count: number;
  loading?: boolean;
  newReplies?: boolean;
  onClick?: () => void;
}) =>
  count > 0 ? (
    <span
      className={tw(
        "flex items-center select-none text-subhead-bold py-2 h-24",
        {
          "hover:underline hover:cursor-pointer": !loading,
          "text-text-action hover:text-text-action-hover":
            newReplies && !loading,
          "text-text-subtle hover:text-text-subtle-hover":
            !newReplies || loading,
        }
      )}
      onClick={!loading ? onClick : undefined}
    >
      {loading ? (
        <LoadingSpinner className="w-16 h-16 ml-12 mr-24 animate-spin text-icon-secondary" />
      ) : (
        <Icon
          className={tw("w-50 pr-10", {
            "text-icon-action": newReplies,
            "text-icon-subtle": !newReplies,
          })}
          icon="MenuHorizontal"
          size={24}
        />
      )}
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          {count} {newReplies ? "more" : "older"}{" "}
          {count > 1 ? "replies" : "reply"}
        </>
      )}
    </span>
  ) : null;

const NewRepliesButton = ({
  onClick,
  replies,
}: ComponentProps<typeof Button> & { replies: number }) => (
  <Button
    buttonStyle="none"
    buttonType="none"
    className="absolute bottom-60 left-1/2 -translate-x-1/2 px-8 py-4 rounded-half font-semibold text-xs text-text-action-inverse bg-background-action"
    onClick={onClick}
  >
    {replies} new {replies > 1 ? "replies" : "reply"}
  </Button>
);

const minCountToSummarize = 3;
const FeedThreadItem = ({
  edge: edgeProp,
  roundedCard = true,
}: {
  edge: ThreadEdgeFeed;
  roundedCard?: boolean;
}) => {
  const { authData, authReady } = useAuthData();

  const [elRef, setElRef] = useState<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [edge, setEdge] = useUpdatingState(edgeProp);

  const { toggleThreadSeen } = useInboxThreadActions();

  const { data, fetchMore } = useFetchThreadEdgePaginatedQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id,
    variables: {
      id: edge.id,
      includeLastRead: !edge.unreadMessageCounts.total,
    },
  });

  // TODO: show updates available
  const updatedEdge = nodeAs(data?.node, ["ThreadEdge"]);

  useIntersectionObserver(
    elRef,
    entry => {
      const visible = entry.isIntersecting ?? false;
      if (visible && !isVisible) setIsVisible(true);
    },
    { threshold: 0.2 }
  );

  const thread = edge.node;
  const threadPath = routeToThread({ threadID: thread.id, to: "secondary" });

  const {
    aboveLastReadMessages,
    setAboveLastReadMessages,
    belowLastReadMessages,
    setBelowLastReadMessages,
    lastReadIDs,
  } = useFeedItemData({
    updatedEdge,
  });

  const showFirstMessage =
    !!thread.firstMessage &&
    !aboveLastReadMessages?.find(m => m.node.id === thread.firstMessage?.id) &&
    !belowLastReadMessages?.find(m => m.node.id === thread.firstMessage?.id);

  const { moreRepliesCount, newRepliesAvailableCount, olderRepliesCount } =
    useFeedItemCounts({
      readMessages: aboveLastReadMessages,
      showFirstMessage,
      threadEdge: edge,
      unreadMessages: belowLastReadMessages,
      updatedEdge,
      lastReadIDs,
    });

  const {
    handleLoadMoreUnreadMessages,
    handleLoadNewAvailableReplies,
    handleLoadOlderReplies,
    loadingMore,
    loadingOlder,
  } = useFeedItemLoadMore({
    aboveLastReadMessages,
    setAboveLastReadMessages,
    belowLastReadMessages,
    setBelowLastReadMessages,
    fetchMore,
    moreRepliesCount,
    olderRepliesCount,
    setEdge,
    oldEdge: edge,
    updatedEdge,
  });

  useEffect(() => {
    if (
      !isVisible ||
      !updatedEdge ||
      updatedEdge.isRead ||
      updatedEdge.isSeen
    ) {
      return;
    }

    const timeout = setTimeout(() => {
      toggleThreadSeen(updatedEdge, true);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [
    isVisible,
    moreRepliesCount,
    newRepliesAvailableCount,
    toggleThreadSeen,
    updatedEdge,
  ]);

  const summarize =
    (edge?.unreadMessageCounts.total ?? 0) > minCountToSummarize ||
    (belowLastReadMessages?.length ?? 0) > minCountToSummarize;

  const resetThreadItemData = () =>
    newRepliesAvailableCount > 0 && handleLoadNewAvailableReplies();

  return (
    <Card
      key={thread.id}
      ref={setElRef}
      className="relative mb-16 last:mb-24"
      roundedClassName={`rounded-none ${roundedCard && "md:rounded-lg"}`}
    >
      <div className="border-b border-border-container">
        <FeedThreadHeader threadEdge={updatedEdge || edge} />
      </div>

      <div className="py-16 px-16 md:px-24">
        {thread.firstMessage && showFirstMessage ? (
          <>
            {thread.replyToMessage ? (
              <>
                <FeedMessage message={thread.replyToMessage} isFirst />
                <FeedMessage message={thread.firstMessage} />
              </>
            ) : (
              <FeedMessage message={thread.firstMessage} isFirst />
            )}
          </>
        ) : null}

        {showFirstMessage && (
          <HiddenReplies
            count={olderRepliesCount}
            loading={loadingOlder}
            onClick={handleLoadOlderReplies}
          />
        )}

        {aboveLastReadMessages?.map((m, i) => {
          return (
            <div key={m.id} className="contents">
              <FeedMessage
                isGrouped={
                  aboveLastReadMessages[i - 1]?.node.user.id === m.node.user.id
                }
                message={m.node}
              />
              {edge &&
              (m.node.id === lastReadIDs.id ||
                m.node.id === lastReadIDs.streamID) &&
              edge.unreadMessageCounts.total > 0 ? (
                <UnreadMarker
                  lastReadMessageID={edge.lastReadID ?? ""}
                  summarize={summarize}
                  threadID={thread.id}
                />
              ) : null}
            </div>
          );
        })}

        <HiddenReplies
          count={moreRepliesCount}
          loading={loadingMore}
          onClick={handleLoadMoreUnreadMessages}
          newReplies
        />

        {belowLastReadMessages?.map((m, i, arr) => (
          <div key={m.id} className="contents">
            <FeedMessage
              isGrouped={
                belowLastReadMessages[i - 1]?.node.user.id === m.node.user.id
              }
              message={m.node}
            />
            {(m.node.id === lastReadIDs.id ||
              m.node.id === lastReadIDs.streamID) &&
            i < arr.length - 1 ? (
              <UnreadMarker
                lastReadMessageID={updatedEdge?.lastReadID ?? ""}
                summarize={summarize}
                threadID={thread.id}
              />
            ) : null}
          </div>
        ))}

        {newRepliesAvailableCount > 0 && updatedEdge ? (
          <NewRepliesButton
            onClick={handleLoadNewAvailableReplies}
            replies={newRepliesAvailableCount}
          />
        ) : null}

        <Link to={threadPath} onClick={() => resetThreadItemData()}>
          <Button
            buttonStyle="none"
            buttonType="none"
            className={tw(
              "mt-8 !p-8 w-full text-subhead text-text-subtle",
              "bg-background-body hover:bg-background-body-hover",
              "border rounded-md border-border-container hover:border-border-container-hover "
            )}
          >
            Reply...
          </Button>
        </Link>
      </div>
    </Card>
  );
};

export const FeedSkeletonItem = () => (
  <Skeleton
    className="mb-8 p-16 md:!rounded-lg"
    height={`${getRandomInt(80, 350)}px`}
  />
);

export default memo(FeedThreadItem);
