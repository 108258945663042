import { SVGAttributes } from "react";

import * as Illustrations from "./all-illustrations";

export type IllustrationName = keyof typeof Illustrations;

export interface IllustrationProps extends SVGAttributes<SVGElement> {
  color?: string;
  size?: string | number;
}

type Props = {
  height?: number | string;
  illustration: IllustrationName | React.FunctionComponent<IllustrationProps>;
  size?: number | string;
  strokeWidth?: number | string;
  width?: number | string;
} & React.SVGProps<SVGSVGElement>;

export const Illustration = ({
  className = "",
  height,
  illustration,
  size = 32,
  strokeWidth = 1.5,
  width,
  ...props
}: Props) => {
  const IllustrationComponent =
    typeof illustration === "string"
      ? Illustrations[illustration]
      : illustration;
  return (
    <IllustrationComponent
      className={`glue-illustration select-none shrink-0 ${className}`}
      height={height ?? size}
      strokeWidth={strokeWidth}
      title={typeof illustration === "string" ? illustration : undefined}
      width={width ?? size}
      {...props}
    />
  );
};

export default Illustration;
