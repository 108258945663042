import { Button } from "components/design-system/Button";
import { Form, SubmitButton } from "components/design-system/Forms";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import useModalStore from "store/useModalStore";

import ConsentContent from "./ConsentContent";

type ConsentModalProps = {
  appID: string;
  onAllow: () => void;
  scopes: string[];
  workspaceID: string;
};

const ConsentModal = (props: ModalProps & ConsentModalProps) => {
  const { closeModal } = useModalStore(({ closeModal, openModal }) => ({
    closeModal,
    openModal,
  }));

  const handleCloseModal = () => {
    closeModal(props.modalId);
  };

  const handleAllow = () => {
    props.onAllow();
    closeModal(props.modalId);
  };

  return (
    <Form className="contents" onSubmit={handleAllow}>
      <StandardModal contentHandlesSafeArea={false} {...props}>
        <Main className="flex flex-col grow px-16 py-8 md:px-32">
          <ConsentContent
            appID={props.appID}
            scopes={props.scopes}
            workspaceID={props.workspaceID}
          />
        </Main>

        <Footer className="hidden md:flex">
          <div className="flex space-x-16">
            <Button
              buttonStyle="simpleSecondary"
              buttonType="none"
              onClick={handleCloseModal}
              type="button"
            >
              Cancel
            </Button>
            <SubmitButton requireChanges={false}>Allow</SubmitButton>
          </div>
        </Footer>
      </StandardModal>
    </Form>
  );
};

export default ConsentModal;
