import { useEffect } from "react";
import { useWatch } from "react-hook-form";

import { Toggle } from "components/design-system/Forms";

import useFeatureFlagStore from "../../store/useFeatureFlagStore";

import flags, { FlagName } from "./flags-config";

export default function FlagToggleSwitch({ keyName }: { keyName: FlagName }) {
  const isEnabled = useWatch({ name: keyName });
  const { toggleFlag } = useFeatureFlagStore(({ toggleFlag }) => ({
    toggleFlag,
  }));

  useEffect(() => {
    toggleFlag(keyName, isEnabled);
  }, [isEnabled, keyName, toggleFlag]);

  return (
    <div
      key={keyName}
      className="flex flex-row justify-between items-center py-16 w-8/12"
    >
      <div className="capitalize select-none">
        {keyName?.replaceAll("_", " ").toLowerCase()}
      </div>
      <Toggle
        disabled={!flags[keyName].toggleable}
        name={keyName}
        wrapperClassName="!m-0"
      />
    </div>
  );
}
