import { useApolloClient } from "@apollo/client";
import { ComponentProps, useEffect, useState } from "react";

import { StreamGlueMessage } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import { DropdownProps } from "components/design-system/FloatingUi/Dropdown";
import { Icon } from "components/design-system/icons";
import useGlueAIFeedback from "components/GlueAIFeedback/hooks/useGlueAIFeedback";
import { Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import { Skeleton } from "components/Skeleton";
import {
  FetchMessageMetadataDocument,
  FetchMessageMetadataQuery,
} from "generated/graphql";
import { hasSources, keepResponseSources } from "md/extractSources";
import { InlineMarkdownComponent } from "md/RenderMarkdown";
import useModalStore from "store/useModalStore";
import { isMobile } from "utils/platform";

type ResponseInfo = {
  avatarURL: string;
  description: string;
  name: string;
};

type MessageMetadataContentProps = {
  children: JSX.Element;
  message: StreamGlueMessage;
  modalId?: string;
  closeParentDropdown?: (value: boolean) => void;
  closeParentModal?: (modalId: string) => void;
};

type MessageFeedbackButtonProps = {
  icon: ComponentProps<typeof Icon>["icon"];
  tooltip: string;
  onClick: () => void;
};

type MessageFeedbackBarProps = {
  messageText: string;
  modalId?: string;
  threadID: string;
  closeParentDropdown?: (value: boolean) => void;
  closeParentModal?: (modalId: string) => void;
};

const MessageFeedbackButton = ({
  icon,
  tooltip,
  onClick,
}: MessageFeedbackButtonProps) => {
  return (
    <Button
      buttonStyle="subtle"
      className={"p-8 h-24 w-24"}
      icon={icon}
      iconSize={20}
      tooltip={tooltip}
      onClick={onClick}
      type="button"
    />
  );
};

const MessageFeedbackBar = ({
  messageText,
  modalId,
  threadID,
  closeParentDropdown,
  closeParentModal,
}: MessageFeedbackBarProps) => {
  const [positiveFeedback, setPositiveFeedback] = useState(false);

  const { sendGeneratedResponseFeedback } = useGlueAIFeedback();

  // TODO not implemented, need details of which mutation to use
  const handlePositiveFeedback = () => {
    setPositiveFeedback(true);
  };

  const handleNegativeFeedback = () => {
    isMobile()
      ? modalId && closeParentModal?.(modalId)
      : closeParentDropdown?.(false);
    sendGeneratedResponseFeedback({
      messageText,
      threadID,
    });
  };

  if (positiveFeedback) {
    return (
      <div className="flex items-center h-24 justify-end text-footnote w-full">
        Thanks for the feedback! 😄
      </div>
    );
  }

  return (
    <div className="flex items-center justify-end text-footnote w-full">
      Was this response accurate?
      <MessageFeedbackButton
        icon="ThumbsUp"
        tooltip="Good response"
        onClick={handlePositiveFeedback}
      />
      <MessageFeedbackButton
        icon="ThumbsDown"
        tooltip="Bad response"
        onClick={handleNegativeFeedback}
      />
    </div>
  );
};

const LoadingSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="flex">
        <Skeleton
          className="bg-background-secondary"
          height="20px"
          width="20px"
        />
        <Skeleton
          className="bg-background-secondary ml-8"
          height="20px"
          width="190px"
        />
      </div>
      <Skeleton
        className="bg-background-secondary mb-16"
        height="24px"
        width="80%"
      />
      <Skeleton className="bg-background-secondary" height="24px" width="20%" />
      <Skeleton className="bg-background-secondary" height="75px" flexGrow />
      <Skeleton
        className="mt-12 bg-background-secondary"
        height="42px"
        flexGrow
      />
      <Skeleton
        className="mt-12 bg-background-secondary"
        height="24px"
        width="50%"
      />
    </div>
  );
};

const MessageMetadataContent = ({
  message,
  modalId,
  closeParentDropdown,
  closeParentModal,
}: Omit<MessageMetadataContentProps, "children">) => {
  const apolloClient = useApolloClient();

  const [responseInfo, setResponseInfo] = useState<ResponseInfo | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchGlueAIResponseInfo = () => {
      if (responseInfo || loading) return;
      setLoading(true);
      apolloClient
        .query<FetchMessageMetadataQuery>({
          query: FetchMessageMetadataDocument,
          fetchPolicy: "network-only",
          variables: { id: message.id },
        })
        .then(({ data }) => {
          if (data && data.messageMetadata !== null) {
            const { aiResponseInfo } = data.messageMetadata;
            if (aiResponseInfo !== null) {
              const { model, sourcesDescription } = aiResponseInfo;
              setResponseInfo({
                avatarURL: model.providerLogoURL,
                description: sourcesDescription,
                name: `${model.providerName} ${model.name}`,
              });
            }
          }
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    };

    fetchGlueAIResponseInfo();
  }, [apolloClient, loading, message.id, responseInfo]);

  if (loading || !responseInfo) return <LoadingSkeleton />;

  const { avatarURL, description, name } = responseInfo;

  const messageText = message.text || "";
  const threadID = message.cid?.split(":")?.pop() || "";

  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-4">
        <Avatar avatarURL={avatarURL} size="x-small" />
        <span className="text-body-bold ml-8">Powered by {name}</span>
      </div>

      <div className="mb-16">{description}</div>

      {hasSources(message.text) && (
        <div className="mb-16">
          <p className="m-0 mb-4 text-body-bold">Sources:</p>
          <span className="leading-[27px]">
            <InlineMarkdownComponent
              text={keepResponseSources(message.text || "")}
            />
          </span>
        </div>
      )}

      <div className="flex items-center bg-background-secondary mb-16 p-12 rounded-lg">
        <Icon className="text-icon-secondary" icon="Info" size={16} />
        <span className="text-footnote text-text-secondary ml-8">
          Mention specific groups, threads, or people to refine Glue AI’s
          response.
        </span>
      </div>

      <MessageFeedbackBar
        messageText={messageText}
        modalId={modalId}
        threadID={threadID}
        closeParentDropdown={closeParentDropdown}
        closeParentModal={closeParentModal}
      />
    </div>
  );
};

export const MessageMetadataInfoDropdown = ({
  children,
  placement = "bottom-end",
  position,
  ...rest
}: Omit<MessageMetadataContentProps, "children"> &
  Omit<DropdownProps, "content">) => {
  const [open, setOpen] = useState(false);

  const className =
    "flex flex-col w-full md:w-[600px] px-32 py-24 rounded-lg z-2 bg-background-body shadow-level2";

  return (
    <Dropdown
      content={
        <div className={className}>
          <MessageMetadataContent {...rest} closeParentDropdown={setOpen} />
        </div>
      }
      open={open}
      placement={placement}
      position={position}
      setOpen={setOpen}
    >
      <div>{children}</div>
    </Dropdown>
  );
};

/**
 * @summary On mobile renders the metadata in a modal.
 */
export const MessageMetadataInfoModal = ({
  message,
  closeParentModal,
  ...modalProps
}: Omit<MessageMetadataContentProps, "children"> & ModalProps) => {
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  return (
    <StandardModal {...modalProps} contentHandlesSafeArea={false} heightAuto>
      <Header variant="bordered" />
      <Main className="px-20 py-24">
        <MessageMetadataContent
          message={message}
          modalId={modalProps.modalId}
          closeParentModal={() => closeModal}
        />
      </Main>
    </StandardModal>
  );
};
