/**
 * @summary Determines if a string contains sources.
 */
export const hasSources = (str: string | undefined): boolean =>
  str ? !!str.match(sourceRegex) : false;

/**
 * @summary Matches on "_Source(s): ... _" or "Source(s): ..."
 */
export const sourceRegex =
  /^([\s\S]*?)(?:_?(?:Source:|Sources:)\s*([\s\S]*?)_?)$/;

const removeUnderscores = (str: string): string =>
  str.replace(/^_?([\s\S]*?)_?$/, "$1");

/**
 * @summary Extracts just the response text from a Glue AI message, excluding sources
 * @param str - The text to extract the response from
 */
export const keepResponseText = (str: string): string => {
  const match = str.match(sourceRegex);
  if (!match) return str;

  const [_, before] = match;
  if (!before) return str;
  return before.trim();
};

/**
 * @summary Extracts just the sources from a Glue AI message
 * @param str - The text to extract sources from
 */
export const keepResponseSources = (str: string): string => {
  const match = str.match(sourceRegex);
  if (!match) return str;

  const [_, __, after] = match;
  if (!after) return str;

  // e.g. "source1,\nsource2,\nsource3" -> "source1 source2 source3"
  return removeUnderscores(after)
    .replace(/,\s*/g, " ")
    .replace(/\n+/g, " ")
    .trim();
};
