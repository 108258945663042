import { useCallback, useState } from "react";

import { Form, SubmitButton } from "components/design-system/Forms";
import Hr from "components/design-system/Hr";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import {
  useUpdateSettingsMutation,
  useUserSettingsQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import useModalStore from "store/useModalStore";

import AppearancePref from "./components/AppearancePref";
import AppVersion from "./components/AppVersion";
import EditorPref from "./components/EditorPref";
import GlobalAIModelPref from "./components/GlobalAIModelPref";
import InboxNotificationsPref from "./components/InboxNotificationsPref";
import PushNotificationsPref from "./components/PushNotificationsPref";
import type { FormData } from "./types";

const PreferencesModal = (props: ModalProps) => {
  const { authData } = useAuthData();
  const workspaceID = authData?.me.workspaceIDs[0];

  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const { colorScheme, colorSchemePreview, enterKeyBehavior } =
    useLocalSettingsStore(
      ({ colorScheme, colorSchemePreview, enterKeyBehavior }) => ({
        colorScheme,
        colorSchemePreview,
        enterKeyBehavior,
      })
    );

  const { data, loading } = useUserSettingsQuery({
    fetchPolicy: "cache-and-network",
  });

  const [updateSettings] = useUpdateSettingsMutation({
    errorPolicy: "all",
  });

  const handleFormSubmit = useCallback(
    ({
      colorScheme,
      enterKeyBehavior,
      notifyMessages,
      chatModel,
    }: FormData) => {
      if (!data || !notifyMessages) return;

      setSubmitDisabled(true);

      useLocalSettingsStore.setState({ colorScheme, enterKeyBehavior });

      const payload = chatModel
        ? {
            llmSettings: {
              ...data.settings.llmSettings,
              chatModel,
            },
            notifyMessages,
          }
        : { notifyMessages };

      return updateSettings({
        optimisticResponse: {
          updateSettings: {
            ...data.settings,
            ...payload,
          },
        },
        variables: {
          settings: payload,
        },
      })
        .then(() => closeModal(`${props.modalId}`))
        .catch(err => {
          console.warn("Error: [UpdateSettings] - ", err.message);
        })
        .finally(() => setSubmitDisabled(false));
    },
    [closeModal, data, props.modalId, updateSettings]
  );

  return (
    <Form<FormData>
      loading={loading}
      onSubmit={handleFormSubmit}
      useFormProps={{
        defaultValues: {
          colorScheme,
          chatModel: data?.settings.llmSettings.chatModel,
          enterKeyBehavior,
          notifyMessages: data?.settings.notifyMessages,
        },
      }}
    >
      <StandardModal
        afterClose={() =>
          colorSchemePreview !== colorScheme &&
          useLocalSettingsStore.setState({ colorSchemePreview: colorScheme })
        }
        contentHandlesSafeArea={false}
        header={
          <Header
            className="md:!px-20"
            mobileCtaLabel="Save"
            mobileCtaProps={{ type: "submit" }}
            variant="bordered"
          >
            <h3>Preferences</h3>
          </Header>
        }
        {...props}
      >
        <Main className="flex flex-col gap-20 p-20">
          <InboxNotificationsPref />
          <PushNotificationsPref />
          <Hr />
          <EditorPref />
          <Hr />
          <AppearancePref />
          {workspaceID && (
            <>
              <Hr />
              <GlobalAIModelPref workspaceID={workspaceID} />
            </>
          )}
        </Main>

        <Footer
          flexboxClassName="justify-between items-center"
          className="!px-20"
        >
          <AppVersion />
          <SubmitButton
            className="hidden md:block"
            disabled={submitDisabled}
            icon="Check"
            iconStroke={3}
          >
            Save Preferences
          </SubmitButton>
        </Footer>
      </StandardModal>
    </Form>
  );
};

export default PreferencesModal;
