import { uniq } from "lodash-es";
import { useState } from "react";

import { Recipient } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import { RecipientsSelect } from "components/RecipientsSelect";
import { UserEdgeStatus } from "generated/graphql";

import { RecipientsList } from ".";

type Props = {
  closeModal: () => void;
  description: string;
  excludedRecipients?: Recipient[];
  saveFunction: (items: Recipient[]) => void;
  submitText: string;
  title: string;
  userEdgeStatus?: UserEdgeStatus[];
};

const RecipientsForm = ({
  closeModal,
  description,
  excludedRecipients = [],
  saveFunction,
  submitText,
  title,
  userEdgeStatus = [],
  ...props
}: Props & ModalProps): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<Recipient[]>([]);

  const onItemSelectedChange = (recipients: Recipient[]) => {
    if (!recipients?.length) return;
    const uniqueRecipients = uniq([...selectedItems, ...recipients]);
    setSelectedItems(uniqueRecipients);
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveFunction(selectedItems);
    closeModal();
  };

  const onDeleteItem = (id?: string) => {
    setSelectedItems(arr => arr.filter(item => item.id !== id));
  };

  return (
    <form
      className="box-border p-10 w-full disabled:bg-background-subtle rounded border border-background-subtle focus:outline-none"
      data-testid="recipients-form"
      onSubmit={onFormSubmit}
    >
      <StandardModal
        contentHandlesSafeArea={false}
        header={
          <Header
            className="px-[74px]"
            mobileCtaLabel="Connect"
            mobileCtaProps={{
              className: "!w-[74px]",
              disabled: selectedItems.length === 0,
              type: "submit",
            }}
            variant="bordered"
          >
            <h3 className="m-0">{title}</h3>
          </Header>
        }
        {...props}
      >
        <Main className="p-16 md:px-32">
          <div className="flex flex-row items-center mb-16">
            <Icon
              className="mr-20 text-interactive-subtle"
              icon="UserAdd"
              size="42"
              strokeWidth={2.5}
            />
            <span className="text-text-subtle align-middle">{description}</span>
          </div>

          <RecipientsSelect
            excludeRecipients={[...selectedItems, ...excludedRecipients]}
            onChange={onItemSelectedChange}
            placeholder="Find a user, or type/paste email(s)..."
            selectGroups={false}
            userEdgeStatus={userEdgeStatus}
          />
          <div className="mt-20 mb-4">
            <RecipientsList items={selectedItems} onDeleteItem={onDeleteItem} />
          </div>
        </Main>
        <Footer className="hidden md:flex">
          <Button disabled={selectedItems.length === 0} type="submit">
            {submitText}
          </Button>
        </Footer>
      </StandardModal>
    </form>
  );
};

export default RecipientsForm;
