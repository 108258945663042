import { ThreadEdgeSimple } from "@utility-types";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import { ReminderOption, getReminderDate, reminderOptions } from "./common";
import ThreadReminderCustomModal from "./ThreadReminderCustomModal";

const ThreadReminderMenu = ({
  setParentDropdownOpen,
  threadEdge,
}: {
  setParentDropdownOpen?: (open: boolean) => void;
  threadEdge: ThreadEdgeSimple;
}) => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const { markThreadEdges } = useInboxThreadActions();

  const handleSelectReminder = (reminder: ReminderOption) => {
    setParentDropdownOpen?.(false);
    if (reminder === ReminderOption.CUSTOM) {
      return openModal(<ThreadReminderCustomModal threadEdge={threadEdge} />);
    }
    markThreadEdges({
      selection: { threadEdges: [threadEdge] },
      remindAt: getReminderDate(reminder).toISOString(),
    });
  };

  return (
    <>
      <DropdownActionButtonGroup>
        <span className="flex items-center text-footnote-bold text-text-subtle h-32 px-16 select-none">
          Remind me
        </span>
        {reminderOptions.map(option => (
          <DropdownActionButton
            key={option.label}
            onClick={() => handleSelectReminder(option.value)}
            onPointerDown={e => {
              // prevents item selection from selecting thread in swipe action
              e.stopPropagation();
            }}
          >
            {option.label}
          </DropdownActionButton>
        ))}
      </DropdownActionButtonGroup>
      <DropdownActionButtonGroup>
        <DropdownActionButton
          disabled={!threadEdge.remindAt}
          onClick={() => {
            setParentDropdownOpen?.(false);
            markThreadEdges({
              selection: { threadEdges: [threadEdge] },
              remindAt: null,
            });
          }}
        >
          <span className={tw({ "text-text-alert": threadEdge.remindAt })}>
            Remove reminder
          </span>
        </DropdownActionButton>
      </DropdownActionButtonGroup>
    </>
  );
};

export default ThreadReminderMenu;
