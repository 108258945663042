import { createZustandStoreContext } from "create-zustand-store-context";
import { useEffect } from "react";

import { Message, ThreadPreview, ThreadSimple } from "@utility-types";
import {
  DraftListQuery,
  MemberRole,
  MessageableBy,
  ThreadsMailbox,
} from "generated/graphql";
import { useLayerState } from "providers/LayerProvider";
import useAppStateStore from "store/useAppStateStore";

import useThreadDrafts from "../hooks/useThreadDrafts";

type ReplyTo = {
  message: Message;
  thread: ThreadSimple | ThreadPreview | undefined;
};

type ThreadDrafts = {
  drafts?: DraftListQuery["drafts"]["edges"];
  replyDraft?: DraftListQuery["drafts"]["edges"][0];
};

type ThreadViewStore = {
  editorIsActive: boolean;
  isMessageable?: boolean;
  pauseLoadMore: boolean;
  mailbox: ThreadsMailbox;
  messageID?: string;
  messageableBy?: MessageableBy;
  prevScrollTop?: number;
  recipientID?: string;
  recipientRole?: MemberRole;
  replyTo?: ReplyTo;
  scrollHeight: number;
  scrollTo?: Element["scrollTo"];
  scrollToBottom?: () => void;
  scrollTop: number;
  scrolledToEnd: boolean;
  scrolledToLinkedMessage?: boolean;
  threadDrafts?: ThreadDrafts;
  threadID: string;
  threadPane: "primary" | "secondary";
  threadStartedAt?: string;
  threadStarterID?: string;
  threadWorkspaceID?: string;
  isPersistentChat?: boolean;
};

type Props = {
  isMessageable?: boolean;
  mailbox?: ThreadsMailbox;
  messageID?: string;
  messageableBy?: MessageableBy;
  recipientID?: string;
  recipientRole?: MemberRole;
  replyTo?: ReplyTo;
  threadStartedAt?: string;
  threadStarterID?: string;
  threadDrafts?: ThreadDrafts;
  threadID: string;
  threadPane?: "primary" | "secondary";
  threadWorkspaceID?: string;
  isPersistentChat?: boolean;
};

const defaultState: ThreadViewStore = {
  isMessageable: undefined,
  editorIsActive: true,
  pauseLoadMore: false,
  mailbox: ThreadsMailbox.All,
  scrolledToEnd: true,
  scrolledToLinkedMessage: false,
  scrollHeight: 0,
  scrollTop: 0,
  threadID: "",
  threadPane: "primary",
  isPersistentChat: undefined,
};

const {
  storeContext,
  useState: useThreadViewState,
  useStore: useThreadViewStore,
} = createZustandStoreContext<ThreadViewStore, keyof Props>({
  defaultState,
  name: "ThreadViewProvider",
});

const ThreadViewStoreContext = storeContext;

function ThreadViewProvider({
  children,
  isMessageable,
  mailbox,
  messageID,
  messageableBy,
  recipientID,
  recipientRole,
  replyTo,
  threadStartedAt,
  threadStarterID,
  threadPane,
  threadID,
  threadWorkspaceID,
  isPersistentChat,
}: WithChildren<Props>) {
  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));

  const { layerIsActive } = useLayerState(({ layerIsActive }) => ({
    layerIsActive,
  }));

  const threadDrafts = useThreadDrafts({
    threadID,
  });

  const currentStore = useThreadViewStore(threadID, {
    isMessageable,
    mailbox,
    messageID,
    messageableBy,
    recipientID,
    recipientRole,
    replyTo,
    threadStartedAt,
    threadStarterID,
    threadDrafts,
    threadID,
    threadPane,
    threadWorkspaceID,
    isPersistentChat,
  });

  useEffect(() => {
    currentStore.setState({
      editorIsActive: layerIsActive,
      isMessageable,
      mailbox,
      messageID,
      messageableBy,
      recipientID,
      recipientRole,
      replyTo,
      threadStartedAt,
      threadStarterID,
      threadDrafts,
      threadWorkspaceID,
      isPersistentChat,
    });
  }, [
    appStatus,
    currentStore,
    isMessageable,
    layerIsActive,
    mailbox,
    messageID,
    messageableBy,
    recipientID,
    recipientRole,
    replyTo,
    threadDrafts,
    threadStartedAt,
    threadStarterID,
    threadWorkspaceID,
    isPersistentChat,
  ]);

  return (
    <ThreadViewStoreContext.Provider value={currentStore}>
      {children}
    </ThreadViewStoreContext.Provider>
  );
}

export { ThreadViewProvider, useThreadViewState };
