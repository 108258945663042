import { ComponentProps } from "react";

import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "components/design-system/FloatingUi/DropdownActions";
import { ThreadsOrder } from "generated/graphql";
import useLocalSettingsStore from "store/useLocalSettingsStore";

const UnreadFilter = ({
  buttonStyle = "icon-primary",
  className = "p-8 -my-8",
  disabled,
}: {
  buttonStyle?: ComponentProps<typeof Button>["buttonStyle"];
  className?: string;
  disabled?: boolean;
}) => {
  const { currentThreadSort } = useLocalSettingsStore(
    ({ currentThreadSort }) => ({ currentThreadSort })
  );

  const handleOnClick = (order: ThreadsOrder) =>
    useLocalSettingsStore.setState({ currentThreadSort: order });

  const actions: ActionSheetItemGroups[] = [
    {
      items: [
        {
          icon: "Clock",
          onClick: () => handleOnClick(ThreadsOrder.LastMessage),
          selected: currentThreadSort === ThreadsOrder.LastMessage,
          text: "Recent",
        },
        {
          icon: "Unread",
          onClick: () => handleOnClick(ThreadsOrder.Unread),
          selected: currentThreadSort === ThreadsOrder.Unread,
          text: "Unread",
        },
      ],
      name: "filters",
    },
  ];

  return (
    <Dropdown
      placement="bottom-end"
      content={<DropdownActions actions={actions} />}
    >
      <Button
        buttonStyle={buttonStyle}
        buttonType="icon"
        className={className}
        disabled={disabled}
        icon="Sort"
        iconSize={24}
      />
    </Dropdown>
  );
};

export default UnreadFilter;
