import Hr from "components/design-system/Hr";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";

import FeatureFlags from "./FeatureFlags";
import NotificationPermission from "./NotificationPermission";
import RouteMemoryReport from "./RouteMemoryReport";
import SimulateAppUpdateAvailable from "./SimulateAppUpdateAvailable";

const DeveloperModal = (props: ModalProps) => {
  return (
    <StandardModal
      header={
        <Header variant="bordered">
          <h3 className="m-0">Developer!</h3>
        </Header>
      }
      {...props}
    >
      <Main className="flex flex-col gap-24 px-16 py-24 md:px-32">
        <FeatureFlags />
        <NotificationPermission />
        <SimulateAppUpdateAvailable />

        <Hr className="w-full" />

        <RouteMemoryReport />
      </Main>
      <Footer flexboxClassName="justify-between items-center" />
    </StandardModal>
  );
};

export default DeveloperModal;
