import { GetReactionsAPIResponse } from "stream-chat";

import { hasEmoji } from "components/design-system/Emoji";
import { Emoji } from "components/design-system/Emoji/Emoji";

type Props = {
  emoji: string;
  emojiName: string;
  emojiURL?: string;
  reactions: GetReactionsAPIResponse["reactions"];
};

const ReactionTooltip = ({ reactions, emoji, emojiName, emojiURL }: Props) => {
  const emojiExists = hasEmoji(emoji, true) || !!emojiURL;
  return (
    <div className="flex flex-col items-center">
      <Emoji name={emoji} url={emojiURL} />

      {emojiExists ? (
        <div>
          {reactions.map(
            ({ user }, i, array) =>
              user && (
                <span key={user.id}>
                  <span className="font-semibold">{user?.name}</span>
                  {i === array.length - 1 ? "" : ", "}
                </span>
              )
          )}{" "}
          reacted with <span className="font-semibold">:{emojiName}:</span>
        </div>
      ) : (
        <span className="text-footnote">
          This custom emoji was removed from the workspace.
        </span>
      )}
    </div>
  );
};

export default ReactionTooltip;
