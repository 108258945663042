import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Channel, StreamChat } from "stream-chat";

import {
  GlueDefaultStreamChatGenerics,
  StreamGlueChannel,
  StreamGlueChat,
} from "@utility-types";
import useAuthData from "hooks/useAuthData";
import useInterval from "hooks/useInterval";
import parseJWT from "utils/parseJWT";
import env from "utils/processEnv";

// Can't load less because of this:
// https://github.com/GetStream/stream-chat-react/blob/04bff7d/src/constants/limits.ts#L1
export const StreamMessageLimit = 25;

type NewStreamChannel = {
  messagesQueryParam: { [key: string]: string | number | Date };
  threadID: string;
};

export type IStreamClientContext = {
  connectStatus?: "connecting" | "connected" | "disconnecting" | "disconnected";
  newStreamChannel: (params: NewStreamChannel) => Promise<StreamGlueChannel>;
  streamChannelRecent: (threadID: string) => StreamGlueChannel;
  streamClient: StreamGlueChat | undefined;
};

export const StreamClientContext = createContext({} as IStreamClientContext);

const StreamClientProvider = ({ children }: WithChildren) => {
  const { authData, authNeeded, fetchAuthData } = useAuthData();

  const streamClient = useMemo(
    () =>
      StreamChat.getInstance<GlueDefaultStreamChatGenerics>(env.streamApiKey, {
        baseURL: "https://chat.stream-io-api.com",
        recoverStateOnReconnect: false,
        timeout: 15000,
        warmUp: true,
      }),
    []
  );

  const [connectStatus, setConnectStatus] = useState<
    IStreamClientContext["connectStatus"]
  >(streamClient.user?.id ? "connected" : "disconnected");

  const validToken = useCallback((streamToken?: string) => {
    if (!streamToken) {
      return false;
    }

    const jwt = parseJWT(streamToken);
    return jwt ? jwt.exp > Date.now() / 1000 + 60 : false;
  }, []);

  const fetchStreamToken = useCallback(async () => {
    if (authData && validToken(authData.config.streamToken)) {
      return authData.config.streamToken;
    }

    return (
      // always return a value... if refresh fails, return expired token
      // otherwise Stream client will hang and won't recover later
      (
        await fetchAuthData({ refresh: true })
          .then(data => {
            if (streamClient.consecutiveFailures > 0) {
              streamClient.consecutiveFailures = 0;
              streamClient.closeConnection().then(streamClient.openConnection);
            }
            return data;
          })
          .catch(_ => authData)
      )?.config.streamToken || ""
    );
  }, [authData, fetchAuthData, streamClient, validToken]);

  const streamChannelRecent = useCallback(
    (threadID: string) => streamClient.channel(env.streamChannelType, threadID),
    [streamClient]
  );

  const newStreamChannel = useCallback(
    async ({ messagesQueryParam, threadID }: NewStreamChannel) => {
      const channel = new Channel(
        streamClient,
        env.streamChannelType,
        threadID,
        {}
      );

      await channel.query({ messages: messagesQueryParam }).catch(err => {
        // TODO: Allow user to step out of broken Channel or recover prev state

        console.warn("Error: [newStreamChannel] -", err);
      });

      return channel;
    },
    [streamClient]
  );

  useInterval(() => {
    if (authNeeded || validToken(authData?.config.streamToken)) return;
    fetchStreamToken().catch(err => {
      console.warn("Error: [StreamClientProvider] -", err);
    });
  }, 5000);

  useEffect(() => {
    if (!streamClient.user?.id && authData?.me.id) {
      setConnectStatus("connecting");
    } else if (streamClient.user?.id && authNeeded) {
      setConnectStatus("disconnecting");
    }
  }, [authData?.me.id, authNeeded, streamClient.user?.id]);

  useEffect(() => {
    if (connectStatus === "connecting" && authData?.me.id !== undefined) {
      streamClient
        .connectUser({ id: authData.me.id }, fetchStreamToken)
        .catch(err => {
          setConnectStatus("disconnected");
          console.warn("Error: [StreamClientProvider connectUser] -", err);
        });

      // can optimistically set as connected since Stream manages the internal
      // state of the WS connection. streamClient.user is set, that's what matters
      setConnectStatus("connected");
      return;
    }

    if (connectStatus === "disconnecting") {
      streamClient
        .disconnectUser()
        .catch(err => {
          console.warn("Error: [StreamClientProvider disconnectUser] -", err);
        })
        .then(() => setConnectStatus("disconnected"));
    }
  }, [authData?.me.id, connectStatus, fetchStreamToken, streamClient]);

  return (
    <StreamClientContext.Provider
      value={{
        connectStatus,
        newStreamChannel,
        streamChannelRecent,
        streamClient,
      }}
    >
      {children}
    </StreamClientContext.Provider>
  );
};

export default StreamClientProvider;
