import tw from "utils/tw";

type Props = {
  children: React.ReactNode;
  className?: string;
  addNewMemberButton?: React.ReactNode;
};

export function MembersListHeader({
  children,
  className,
  addNewMemberButton,
}: Props) {
  return (
    <header className={tw("flex justify-between items-center", className)}>
      <div className="text-subhead-bold">{children}</div>
      {addNewMemberButton}
    </header>
  );
}
