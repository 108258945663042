import { noop } from "lodash-es";
import { useHistory } from "react-router-dom";

import { nodeAs } from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import FacePile from "components/design-system/ui/FacePile";
import useIdentityBadgeData from "components/design-system/ui/IdentityBadge/hooks/useIdentityBadgeData";
import GroupProfileModal from "components/group/GroupModal/GroupProfileModal";
import {
  currentPathWithoutDrawer,
  routeToGroup,
} from "components/routing/utils";
import { Skeleton } from "components/Skeleton";
import { WorkspaceModal } from "components/workspace/WorkspaceModal";
import useGroupMembership from "components/workspace-group/hooks/useGroupMembership";
import { useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import useModalStore from "store/useModalStore";
import { formatGroupName } from "utils/group/formatGroupName";

import WorkspaceItem from "./WorkspaceItem";
import WorkspaceSectionSkeleton from "./WorkspaceSectionSkeleton";

type Props = { groupID: string; pane?: "secondary" };

export default function GroupProfilePane({ groupID, pane }: Props) {
  const { authData, authReady } = useAuthData();
  const history = useHistory();

  const {
    actionPending,
    cancelRequestToJoin,
    joinGroup,
    joinRequested,
    requestToJoin,
    loading,
  } = useGroupMembership(groupID);

  const { data: groupData } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id,
    variables: {
      id: `${groupID}-${authData?.me.id}`,
    },
  });

  const edge = nodeAs(groupData?.node, [
    "GroupEdge",
    "GroupPreviewEdge",
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ]);
  const { memberEdge } = useMemberEdge(edge);
  const node = edge?.node;

  const group = nodeAs(edge, ["GroupEdge", "GroupPreviewEdge"])?.node;
  const workspace = nodeAs(edge, [
    "WorkspaceEdge",
    "WorkspacePreviewEdge",
  ])?.node;

  const { type, tooltip } = useIdentityBadgeData(node);
  const externalTooltip = type !== "internal" ? tooltip : undefined;
  const { name: groupName, emoji } = formatGroupName(node);
  const description = group?.description;
  const totalCount = node?.members.totalCount || 0;
  const users = node?.members.edges.map(e => e.node) || [];

  const {
    buttonAction,
    buttonText,
    buttonStyle,
  }: {
    buttonAction: () => void;
    buttonStyle?: "secondary" | "primary";
    buttonText: JSX.Element | string;
  } = (() => {
    if (!edge || memberEdge) {
      return {
        buttonAction: () =>
          history.push(routeToGroup({ groupID, to: "canonical" })),
        buttonText: "View",
        buttonStyle: "primary",
      };
    }

    if (joinRequested) {
      return {
        buttonAction: cancelRequestToJoin,
        buttonText: "Undo request",
        buttonStyle: "secondary",
      };
    }

    if (joinGroup) {
      return {
        buttonAction: joinGroup,
        buttonText: "Join",
        buttonStyle: "primary",
      };
    }

    return {
      buttonAction: requestToJoin,
      buttonText: "Request",
      buttonStyle: "primary",
    };
  })();

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const openGroupSettingsModal =
    group?.__typename === "Group"
      ? () => {
          openModal(<GroupProfileModal groupID={group.id} />);
        }
      : workspace?.__typename === "Workspace"
        ? () => {
            openModal(<WorkspaceModal workspaceID={workspace.id} />);
          }
        : noop;

  const isLoading = !node || loading;
  const workspaceID = group?.workspaceID ?? workspace?.id;

  const workspaceSection = workspaceID ? (
    <WorkspaceItem workspaceID={workspaceID} />
  ) : null;

  return (
    <div className="flex flex-col h-full w-full">
      <header className="flex flex-col items-center p-24 pt-32 relative w-full border-b-1 border-border-container">
        {pane === "secondary" ? (
          <Button
            buttonStyle="subtle"
            buttonType="text"
            className="absolute left-16 top-16 p-10 -ml-6 mr-8"
            icon="Close"
            iconSize={20}
            onClick={() => history.push(currentPathWithoutDrawer())}
          />
        ) : null}
        <BackStackButton position="profile" />

        <Avatar
          avatarURL={workspace?.avatarURL ?? undefined}
          background="transparent"
          emojiProps={{ emoji: emoji }}
          loading={isLoading}
          name={groupName}
          rounded="rounded-lg"
          size="xx-large"
        />
        <div className="flex items-center mt-10 text-title-1 text-text-primary">
          {!loading ? (
            <>
              {groupName}
              {buttonText === "Request" ? (
                <Icon
                  className="ml-8 text-icon-secondary"
                  icon="LockFilled"
                  size={20}
                />
              ) : null}
            </>
          ) : (
            <Skeleton width="180px" height="32px" />
          )}
        </div>

        <div className="flex mt-8 items-center">
          <button
            onClick={openGroupSettingsModal}
            className="hidden mr-8 md:flex"
          >
            <FacePile
              hasExternal={!!externalTooltip}
              tooltip={externalTooltip}
              totalCount={totalCount}
              users={isLoading ? undefined : users.slice(0, 3)}
            />
          </button>
        </div>
      </header>

      <main className="grow overflow-auto px-24">
        {description && (
          <div className="mt-24">
            <div className="mb-8 text-base font-bold text-text-primary">
              {loading ? <Skeleton width={"20px"} /> : "Description"}
            </div>
            <div className="select-text text-body text-text-secondary truncate">
              {loading ? <Skeleton width={"80px"} /> : description}
            </div>
          </div>
        )}

        {workspaceSection ? (
          <section className="mt-24">
            <div className="mb-8 text-base font-bold text-text-primary">
              Workspaces
            </div>
            <div className="flex flex-col gap-8">
              {loading ? <WorkspaceSectionSkeleton /> : workspaceSection}
            </div>
          </section>
        ) : null}

        <Button
          buttonStyle={buttonStyle}
          className="justify-center mt-24 w-full"
          disabled={loading}
          onClick={buttonAction}
        >
          {actionPending ? (
            <Icon
              className="animate-spin opacity-100 transition-opacity"
              icon="LoaderCircle"
              size={20}
            />
          ) : (
            buttonText
          )}
        </Button>

        {group?.__typename === "Group" ? (
          <Button
            buttonStyle="secondary"
            className="justify-center mt-8 w-full"
            disabled={loading}
            onClick={openGroupSettingsModal}
          >
            Group settings
          </Button>
        ) : null}
      </main>
    </div>
  );
}
