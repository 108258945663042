import { HTMLAttributes } from "react";

import tw from "utils/tw";

import styles from "./Badge.module.css";

export type BadgeProps = HTMLAttributes<HTMLDivElement> & {
  children: string;
  variant: "perspective" | "subtle" | "warning";
};

const Badge = ({ className, children, variant, ...props }: BadgeProps) => {
  return (
    <div className={tw(styles.badge, styles[variant], className)} {...props}>
      {children}
    </div>
  );
};

export default Badge;
