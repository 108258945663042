import { Mailbox, nodeAs } from "@utility-types";
import MyFeedImg from "assets/icons-jpg/my-feed.jpg";
import {
  useThreadListQuery,
  useWorkspacesAndGroupsListQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useHistoryItem from "hooks/useHistoryItem";

const MyFeedHeader = () => {
  const { authReady } = useAuthData();

  useHistoryItem();

  const { data } = useWorkspacesAndGroupsListQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: { groupsLimit: 200 },
  });

  const {
    data: {
      threads: { totalCount },
    } = { threads: {} },
  } = useThreadListQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: {
      excludeChats: true,
      limit: 0,
      mailbox: Mailbox.Unseen,
    },
  });

  const totalUpdateCount = totalCount || 0;
  const unseenGroupsCount =
    data?.groupsByWorkspace.edges?.reduce(
      (c, e) =>
        c +
        (e.node.unseenGroups.edges.length || 0) +
        (nodeAs(e.node.workspaceEdge, ["WorkspaceEdge"])?.unreadThreadCounts
          .unseen
          ? 1
          : 0),
      0
    ) || 0;

  return (
    <div className="pb-16">
      <div
        className="flex items-center select-none"
        data-testid="recipient-heading"
      >
        <div className="flex items-center grow-0 h-24 mr-8">
          <img
            alt="My feed icon"
            className="rounded-sm w-24 h-24 shrink-0"
            src={MyFeedImg}
          />
        </div>
        <div className="grow font-semibold text-text-primary leading-7 text-xl">
          My feed
        </div>
        <div className="text-text-secondary">
          <UpdateContent
            groupsCount={unseenGroupsCount}
            updatesCount={totalUpdateCount}
          />
        </div>
      </div>
    </div>
  );
};

function UpdateContent({
  groupsCount,
  updatesCount,
}: {
  groupsCount: number;
  updatesCount: number;
}) {
  if (groupsCount > 0 && updatesCount > 0) {
    return `${updatesCount} update${
      updatesCount !== 1 ? "s" : ""
    } in ${groupsCount} group${groupsCount !== 1 ? "s" : ""}`;
  }

  return "";
}

export default MyFeedHeader;
