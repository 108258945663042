import { useApolloClient } from "@apollo/client";
import { ComponentProps, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { Button } from "components/design-system/Button";
import { JoinRequestCard } from "components/design-system/ui/JoinRequest/JoinRequestCard";
import { currentPathWithoutDrawer } from "components/routing/utils";
import {
  FetchJoinApprovalsDocument,
  FetchWorkspaceOrPreviewEdgeDocument,
  NotificationsDocument,
  WorkspacesAndGroupsListDocument,
  useApproveJoinApprovalMutation,
  useFetchJoinApprovalsQuery,
  useIgnoreJoinApprovalMutation,
} from "generated/graphql";
import filterActiveQueries from "utils/filterActiveQueries";

type Requests = ComponentProps<typeof JoinRequestCard>["node"];

export const JoinRequest = ({
  joinID,
  pane,
}: { joinID: string; pane?: "secondary" }) => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const [request, setRequest] = useState<Requests>();

  const { data } = useFetchJoinApprovalsQuery({
    fetchPolicy: "cache-and-network",
  });

  const today = new Date();

  const mutationOptions = {
    awaitRefetchQueries: true,
    errorPolicy: "all" as const,
    refetchQueries: filterActiveQueries(apolloClient, [
      FetchJoinApprovalsDocument,
      NotificationsDocument,
      FetchWorkspaceOrPreviewEdgeDocument,
      WorkspacesAndGroupsListDocument,
    ]),
  };

  const [approveJoin] = useApproveJoinApprovalMutation(mutationOptions);

  const [ignoreJoin] = useIgnoreJoinApprovalMutation(mutationOptions);

  const handleAction = (action: "approve" | "ignore", id: string) => {
    if (!request) return;

    const actionAt =
      action === "approve" ? { approvedAt: today } : { declinedAt: today };

    (action === "approve"
      ? approveJoin({ variables: { joinApprovalID: id } })
      : ignoreJoin({ variables: { joinApprovalID: id } })
    ).then(() => {
      setRequest({ ...request, ...actionAt });
    });
  };

  useEffect(() => {
    setRequest(
      data?.joinApprovals?.edges.find(e => joinID === e.node.id)?.node
    );
  }, [request, data?.joinApprovals.edges, joinID]);

  if (!request) return null;

  return (
    <div className="flex flex-col justify-center relative px-20 w-full h-full bg-background-secondary">
      {pane === "secondary" ? (
        <Button
          buttonStyle="subtle"
          buttonType="text"
          className="absolute left-16 top-16 p-10 -ml-6 mr-8"
          icon="Close"
          iconSize={20}
          onClick={() => history.push(currentPathWithoutDrawer())}
        />
      ) : null}
      <BackStackButton position="profile" />
      <div className="overflow-y-auto">
        <JoinRequestCard node={request} handleAction={handleAction} />
      </div>
    </div>
  );
};

export default JoinRequest;
