import { InformationBubble } from "components/design-system/InformationBubble";

type Props =
  | {
      isPersistentChat: boolean;
      isThreadStarter?: never;
      onClickCTA?: () => void;
      variant: "thread";
    }
  | {
      isPersistentChat: boolean;
      isThreadStarter: boolean;
      onClickCTA?: () => void;
      variant: "editor";
    };

const NotMessageableBanner = ({
  isPersistentChat,
  isThreadStarter = false,
  variant = "thread",
  onClickCTA,
}: Props) => {
  const repliesAreOffContent = (
    <>
      {isPersistentChat ? "Chat" : "Thread"} replies are{" "}
      <span className="text-footnote-bold">off</span>.{" "}
    </>
  );

  return (
    <div
      className={
        variant === "thread"
          ? "px-16 pb-16"
          : "flex bg-background-secondary pt-8 pb-16 px-16 md:px-20 -mb-10 rounded-t-xl md:rounded-t-lg text-footnote"
      }
    >
      {variant === "thread" ? (
        <InformationBubble className="!rounded-lg" iconProps={{ size: 16 }}>
          <span>
            {repliesAreOffContent}
            {isPersistentChat
              ? "Only admins can reply."
              : "Only the thread starter and admins can reply."}
          </span>
        </InformationBubble>
      ) : (
        <span>
          {repliesAreOffContent}
          You can reply because you're{" "}
          {isThreadStarter ? "the thread starter" : "an admin"}.{" "}
          <span
            className="text-footnote-bold text-text-link hover:text-text-link-hover hover:underline hover:cursor-pointer"
            onClick={onClickCTA}
          >
            Update settings
          </span>
        </span>
      )}
    </div>
  );
};

export default NotMessageableBanner;
