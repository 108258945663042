import { memo } from "react";

import Avatar from "components/design-system/Avatar/Avatar";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import ThreadListItem from "components/threads-list/ThreadListItem/ThreadListItem";

type SkeletonItemsProps = {
  count?: number;
};

/**
 * @param {number} count - the number of skeleton items to render
 */
const SkeletonItems = memo(({ count = 5 }: SkeletonItemsProps) => (
  <>
    {cloneElementForSkeletons(
      <ThreadListItem
        avatarComponent={<Avatar rounded="rounded-md" size="medium" />}
        avatarSize={32}
        className="!py-12 !h-64 !px-24"
        height="h-67"
      />,
      count
    )}
  </>
));

export default SkeletonItems;
