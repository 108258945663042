import { ComponentProps, memo, useEffect, useRef } from "react";

import { ThreadEdgeSimple, nodeAs } from "@utility-types";
import Icon from "components/design-system/icons/Icon";
import { UnreadBadge } from "components/design-system/ui/unreadBadge";
import { useRoutePartition } from "components/routing/utils";
import { ThreadActionsFloatingMenu } from "components/threads-list/ThreadActions";
import { useFetchThreadEdgeSimpleQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useChatRecipient from "hooks/useChatRecipient";
import useElementRelativePosition from "hooks/useElementRelativePosition";
import useMemberEdge from "hooks/useMemberEdge";
import useDraftMessagesStore from "store/useDraftMessagesStore";
import useUnreadSidebarItemStore from "store/useUnreadSidebarItemStore";
import { formatDate } from "utils/formatDate";
import { formatNameEmoji } from "utils/formatNameEmoji";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";
import tw from "utils/tw";

import useSendReminderMessage from "./hooks/useSendReminderMessage";
import InboxListItem from "./InboxListItem";

import { INBOX_SIDEBAR_ID } from ".";

const InboxThreadListItem = ({
  bulkMode = false,
  canArchive = false,
  canFollow,
  canRemind = false,
  icon,
  isSelected,
  onToggleSelected,
  sectionIsOpen,
  threadID,
}: {
  bulkMode?: false | "default" | "unselected" | "selected";
  canArchive?: boolean;
  canFollow?: boolean;
  canRemind?: boolean;
  icon?: ComponentProps<typeof Icon>["icon"];
  isSelected: boolean;
  onToggleSelected?: (threadEdge: ThreadEdgeSimple, shiftKey: boolean) => void;
  sectionIsOpen: boolean;
  threadID: string;
}) => {
  const { authData, authReady } = useAuthData();

  const { data } = useFetchThreadEdgeSimpleQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id || !threadID,
    variables: { id: `${threadID}-${authData?.me.id}` },
  });

  const threadEdge = nodeAs(data?.node, ["ThreadEdge", "ThreadPreviewEdge"]);
  const { memberEdge } = useMemberEdge(threadEdge);
  const { isRead, node } = memberEdge ?? {};
  const recipient = useChatRecipient()(node);

  const date = formatDate(
    new Date(node?.lastMessage?.createdAt || node?.createdAt || new Date())
  );

  let { emoji, name: subject } = formatNameEmoji({
    name: node?.subject,
  });

  if (!emoji || !node?.isPersistentChat) {
    emoji = undefined;
    subject = node?.subject ?? "";
  }

  if (node?.isPersistentChat) {
    subject = subject.replace(/ ?Chat$/, "");
  }

  const otherRecipients =
    node?.recipients.edges
      .map(e => e.node)
      .filter(r => r.id !== authData?.me.id) ?? [];

  icon ??= node?.isPersistentChat
    ? "ChatRounded"
    : isGlueAIRecipient(otherRecipients)
      ? "SparkleFilled"
      : "Thread";

  const { superTab } = useRoutePartition();
  const { addItem, removeItem } = useUnreadSidebarItemStore();

  const addToUnreadItems =
    memberEdge &&
    !memberEdge.isRead &&
    superTab === "inbox" &&
    sectionIsOpen &&
    !isSelected;

  const item = useRef<HTMLDivElement>(null);
  const { relativePosition } = useElementRelativePosition(
    item.current,
    INBOX_SIDEBAR_ID,
    !addToUnreadItems
  );

  const { getDraft } = useDraftMessagesStore(({ getDraft }) => ({
    getDraft,
  }));

  const mentionCount = memberEdge?.unreadMessageCounts.mentioned ?? 0;
  const hasMentions = mentionCount > 0 || recipient?.__typename === "User";

  useEffect(() => {
    if (!memberEdge?.node.id) return;

    if (!addToUnreadItems || !relativePosition) {
      removeItem(memberEdge.node.id);
      return;
    }

    const scrollToItem = () =>
      item.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });

    addItem({
      hasMentions,
      id: memberEdge.node.id,
      position: relativePosition,
      scrollToItem,
    });
  }, [
    addItem,
    hasMentions,
    relativePosition,
    removeItem,
    addToUnreadItems,
    memberEdge?.node.id,
  ]);

  const handleAvatarClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!memberEdge || !onToggleSelected) return;
    onToggleSelected(memberEdge, e.shiftKey);
  };

  useSendReminderMessage({ threadEdge: memberEdge });

  return (
    <InboxListItem
      ref={item}
      bulkMode={bulkMode}
      emoji={emoji}
      icon={icon}
      isRead={isRead}
      isSelected={isSelected}
      onBulkClick={handleAvatarClick}
      recipient={recipient}
      subject={subject}
    >
      {memberEdge && (
        <div className="flex h-full items-center justify-end">
          {!isSelected && getDraft(threadID) ? (
            <Icon
              icon="Edit"
              className={tw("ml-4 text-icon-secondary", {
                "mx-4": memberEdge.unreadMessageCounts.total !== 0,
              })}
            />
          ) : null}
          {memberEdge.remindAt &&
            new Date(memberEdge.remindAt) < new Date() && (
              <Icon
                icon="ClockAlarm"
                className="text-icon-secondary"
                size={20}
              />
            )}
          <UnreadBadge
            className="ml-4"
            count={memberEdge?.unreadMessageCounts.total}
            important={hasMentions}
          />
          {/* {firstRecipientGroup && <GroupToken name={firstRecipientGroup} />} */}
          {(!bulkMode || bulkMode === "default") && (
            <ThreadActionsFloatingMenu
              canArchive={canArchive}
              canFollow={canFollow}
              canRemind={canRemind}
              date={date}
              threadEdge={memberEdge}
            />
          )}
        </div>
      )}
    </InboxListItem>
  );
};

export default memo(InboxThreadListItem);
