import { findIndex } from "lodash-es";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ThreadEdgeSimple } from "@utility-types";
import analytics from "analytics";
import { ThreadListEnteredBulkEditMode } from "analytics/events/thread";
import useNativeHaptics from "hooks/native/useNativeHaptics";
import { ThreadSelection } from "hooks/thread/useInboxThreadActions";
import usePrevious from "hooks/usePrevious";
import usePreviousRef from "hooks/usePreviousRef";

type Props = ThreadSelection["filter"] & {
  persistentChatEdge?: ThreadEdgeSimple | null;
  threadEdges: ThreadEdgeSimple[];
};

export type ThreadBulkEditData = {
  isSelected: (edge: ThreadEdgeSimple) => boolean | undefined;
  clearExcludedIDs: () => void;
  selectMode: ThreadSelection["selectMode"] | undefined;
  selection: ThreadSelection | undefined;
  setSelectMode: (mode?: ThreadSelection["selectMode"]) => void;
  toggleEdgeSelected: (edge: ThreadEdgeSimple, shiftDown: boolean) => void;
};

const useThreadSelection = ({
  excludeChats,
  excludeStarred,
  mailbox,
  persistentChatEdge,
  recipientID,
  threadEdges,
}: Props): ThreadBulkEditData => {
  const { lightImpactHaptic } = useNativeHaptics();

  const filter = useMemo(
    () => ({ excludeChats, excludeStarred, mailbox, recipientID }),
    [excludeChats, excludeStarred, mailbox, recipientID]
  );

  const [selectMode, setSelectMode] = useState<ThreadSelection["selectMode"]>();
  const [selectedIDs, setSelectedIDs] = useState<Set<string>>(new Set());
  const [excludedIDs, setExcludedIDs] = useState<Set<string>>(new Set());

  const lastSelectedIndexRef = useRef<number>();
  const prevLastSelectedIndexRef = usePreviousRef(lastSelectedIndexRef.current);

  const selection = useMemo<ThreadSelection | undefined>(() => {
    const selectedEdges = threadEdges.filter(e => selectedIDs.has(e.node.id));
    return selectMode === "all"
      ? {
          excludedEdgeIDs: new Set(
            threadEdges.filter(e => excludedIDs.has(e.node.id)).map(e => e.id)
          ),
          filter,
          persistentChatEdge,
          selectMode,
        }
      : selectMode === "some" && selectedEdges[0]
        ? {
            filter,
            selectMode,
            threadEdges: [selectedEdges[0], ...selectedEdges.slice(1)],
          }
        : undefined;
  }, [
    excludedIDs,
    persistentChatEdge,
    filter,
    selectMode,
    selectedIDs,
    threadEdges,
  ]);

  const previousSelectMode = usePrevious(selection?.selectMode);

  const selectedIDsRef = usePreviousRef(selectedIDs);
  const selectModeRef = usePreviousRef(selectMode);
  const threadEdgesRef = usePreviousRef(threadEdges || []);

  const threadIndex = useCallback(
    (threadID: string) => {
      if (!threadID) return -1;

      return findIndex(
        threadEdgesRef.current.slice(0).reverse(),
        e => e.node.id === threadID
      );
    },
    [threadEdgesRef]
  );

  const toggleEdgeSelected = useCallback(
    (edge: ThreadEdgeSimple, shiftDown: boolean) => {
      const toggleState = (
        state: Set<string>,
        edge: ThreadEdgeSimple,
        shiftDown: boolean
      ) => {
        const lastSelectedIndex = lastSelectedIndexRef.current;
        const prevLastSelectedIndex = prevLastSelectedIndexRef.current;
        const temp = new Set(state);

        if (
          !shiftDown ||
          lastSelectedIndex === undefined ||
          prevLastSelectedIndex === undefined
        ) {
          const id = edge.node.id;
          temp.has(id) ? temp.delete(id) : temp.add(id);
        } else {
          const start = Math.min(lastSelectedIndex, prevLastSelectedIndex);
          const end = Math.max(lastSelectedIndex, prevLastSelectedIndex) + 1;

          threadEdgesRef.current
            .slice(0)
            .reverse()
            .slice(start, end)
            .forEach(edge => temp.add(edge.node.id));
        }

        return temp;
      };

      lastSelectedIndexRef.current = threadIndex(edge.node.id);

      if (selectModeRef.current === "all") {
        setExcludedIDs(state => toggleState(state, edge, shiftDown));
      } else {
        setSelectedIDs(state => toggleState(state, edge, shiftDown));
      }

      lightImpactHaptic();
    },
    [
      threadIndex,
      selectModeRef,
      lightImpactHaptic,
      prevLastSelectedIndexRef,
      threadEdgesRef,
    ]
  );

  const isSelected = useCallback(
    (edge: ThreadEdgeSimple) =>
      selectMode
        ? (selectMode === "all" && !excludedIDs.has(edge.node.id)) ||
          selectedIDs.has(edge.node.id)
        : undefined,
    [excludedIDs, selectMode, selectedIDs]
  );

  useEffect(() => {
    if (selectMode === "some") return;
    if (selectMode || selectedIDsRef.current.size > 0) lightImpactHaptic();

    lastSelectedIndexRef.current = prevLastSelectedIndexRef.current = undefined;
    setSelectedIDs(() => new Set());
    setExcludedIDs(() => new Set());
  }, [selectMode, lightImpactHaptic, selectedIDsRef, prevLastSelectedIndexRef]);

  useEffect(() => {
    setSelectMode(
      !selectModeRef.current
        ? "some"
        : selectModeRef.current === "some" && !selectedIDs.size
          ? undefined
          : selectModeRef.current
    );
  }, [selectModeRef, selectedIDs.size]);

  useEffect(() => {
    setSelectMode(undefined);
  }, [filter]);

  useEffect(() => {
    const selectMode = selection?.selectMode;
    if (selectMode && selectMode !== previousSelectMode) {
      analytics.track(ThreadListEnteredBulkEditMode, { selectMode });
    }
  }, [selection, previousSelectMode]);

  const clearExcludedIDs = useCallback(() => setExcludedIDs(new Set()), []);

  return {
    isSelected,
    clearExcludedIDs,
    selection,
    selectMode,
    setSelectMode,
    toggleEdgeSelected,
  };
};

export default useThreadSelection;
