import { useEffect } from "react";
import { useWatch } from "react-hook-form";

import { WorkspaceEdgeSimple } from "@utility-types";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";

const EmojiWorkspaceSelect = ({
  setWorkspace,
  workspaces,
}: {
  setWorkspace: (workspaceID: string) => void;
  workspaces: WorkspaceEdgeSimple[];
}) => {
  const { emojiWorkspace } = useWatch<{ emojiWorkspace: string }>();

  useEffect(() => {
    if (!emojiWorkspace) return;
    setWorkspace(emojiWorkspace);
  }, [emojiWorkspace, setWorkspace]);

  const options = workspaces.map(w => ({
    label: w.node.name,
    value: w.node.id,
  }));

  return <SelectDropdown name="emojiWorkspace" options={options} />;
};

export default EmojiWorkspaceSelect;
