import { format, isToday, isTomorrow } from "date-fns";

import { ThreadEdgeSimple } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";

const ThreadReminderBanner = ({
  threadEdge,
}: { threadEdge: ThreadEdgeSimple }) => {
  const { markThreadEdges } = useInboxThreadActions();

  const remindAt = threadEdge.remindAt;

  if (!remindAt || new Date(remindAt) < new Date()) return null;

  const formatDate = () => {
    const remindAtDate = new Date(remindAt);
    const remindAtTime = format(remindAtDate, "h:mm a");
    if (isToday(remindAtDate)) {
      return `today at ${remindAtTime}`;
    }
    if (isTomorrow(remindAtDate)) {
      return `tomorrow at ${remindAtTime}`;
    }
    return `${format(remindAtDate, "MMM d, yyyy")} at ${remindAtTime}`;
  };

  return (
    <div className="flex items-center h-40 mt-4 rounded-lg pl-16 pr-8 bg-background-app-secondary">
      <Icon
        icon="ClockAlarm"
        className="text-icon-primary-selected"
        size={20}
      />
      <span className="text-footnote ml-8">
        Reminder set for{" "}
        <span className="text-footnote-bold">{formatDate()}</span>
      </span>
      <div className="flex grow" />
      <Button
        buttonStyle="icon-secondary"
        icon="Trash"
        iconSize={20}
        tooltip="Remove reminder"
        tooltipPlacement="bottom-end"
        onClick={() =>
          markThreadEdges({
            selection: { threadEdges: [threadEdge] },
            remindAt: null,
          })
        }
      />
    </div>
  );
};

export default ThreadReminderBanner;
