import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Tooltip } from "components/design-system/FloatingUi";
import { Form } from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import FacePile from "components/design-system/ui/FacePile";
import useNotificationsPermissions from "components/devices/hooks/useNotificationPermissions";
import { Confetti } from "components/Icons";
import { NotificationsPermissionsModal } from "components/Notification";
import { UserMilestone, useCompleteMilestoneMutation } from "generated/graphql";
import useLocalEducationCampaignStore from "store/useLocalEducationCampaignStore";
import useModalStore from "store/useModalStore";
import useOnboardingStore from "store/useOnboardingStore";

import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

const Box = ({ children }: WithChildren) => (
  <div className="flex items-center w-full border border-border-container rounded-lg py-8 pl-8 pr-16">
    {children}
  </div>
);

const BoxItem = ({
  avatars,
  avatarBg = "transparent",
  children,
  name,
  action,
}: WithChildren<{
  avatars?: {
    avatarURL?: string | null;
    emoji?: string;
    name?: string;
  }[];
  avatarBg?: "subtle" | "transparent";
  name?: string;
  action: string;
}>) => (
  <>
    <div className="flex gap-10 overflow-hidden">
      {avatars?.map((a, i) => (
        <Tooltip
          disabled={!a.name}
          content={a.name}
          key={`${a.avatarURL || a.emoji}-${i}`}
        >
          <Avatar
            background={avatarBg}
            avatarURL={a.avatarURL}
            rounded="rounded-md"
            size="medium"
            emojiProps={{ emoji: a.emoji, size: 32 }}
            name={a.name}
          />
        </Tooltip>
      ))}
    </div>
    {children}
    {name && <span className="text-body-bold ml-10 truncate">{name}</span>}
    <div className="grow" />
    <span className="text-caption text-text-subtle whitespace-nowrap ml-10">
      {action}
    </span>
    <Icon className="ml-4 text-background-success" icon="Check" size={12} />
  </>
);

const Review = () => {
  const {
    setState,
    currentStep,
    workspace,
    goBackTo,
    groups,
    apps,
    members,
    reset,
  } = useOnboardingStore(
    ({
      setState,
      currentStep,
      workspace,
      goBackTo,
      groups,
      apps,
      members,
      reset,
    }) => ({
      setState,
      currentStep,
      workspace,
      goBackTo,
      groups,
      apps,
      members,
      reset,
    })
  );

  const createdGroup = groups?.filter(g => g.type === "created")[0];
  const joinedGroups = groups?.filter(g => g.type === "joined");

  const { checkPermissions } = useNotificationsPermissions();
  const { updateEducationCampaignSettings } = useLocalEducationCampaignStore(
    ({ updateSettings }) => ({
      updateEducationCampaignSettings: updateSettings,
    })
  );

  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const [completeMilestone] = useCompleteMilestoneMutation();

  const handleFinishOnboarding = async () => {
    completeMilestone({
      variables: { input: { milestone: UserMilestone.Onboarding } },
    });
    const permissions = await checkPermissions();
    if (permissions === "prompt") {
      openModal(<NotificationsPermissionsModal />);
    }
    updateEducationCampaignSettings({
      inbox: { welcomeCard: { enabled: true } },
      feed: { welcomeCard: { enabled: true } },
      aiSuperTab: { welcomeCard: { enabled: true } },
      groups: { welcomeCard: { enabled: true } },
    });
    reset();
  };

  return (
    <>
      <Confetti className="absolute top-0 left-0 opacity-35 w-[600px] h-[176px] -mt-[67px] -ml-[104px] md:mt-0 md:ml-0" />
      <div className="absolute top-0 left-0 w-[600px] h-[176px] rounded-t-[32px] -mt-[67px] -ml-[104px] md:mt-0 md:ml-0 bg-gradient-mask" />
      <Form className="w-full z-1">
        <ContentWrapper
          title="All set 🥳"
          headline="Your workspace is ready to go. Next, we'll quickly teach you the basics of using Glue."
        >
          <div className="flex flex-col gap-8">
            {workspace && (
              <Box>
                <BoxItem
                  avatars={[
                    { avatarURL: workspace.avatarURL, name: workspace.name },
                  ]}
                  avatarBg="subtle"
                  name={workspace.name}
                  action={
                    workspace.type === "created"
                      ? "Workspace created"
                      : workspace.type === "joined"
                        ? "Joined workspace"
                        : workspace.type === "added"
                          ? "Added to workspace"
                          : "Accepted invite"
                  }
                />
              </Box>
            )}
            {createdGroup && (
              <Box>
                <BoxItem
                  avatars={[
                    { emoji: createdGroup.emoji, name: createdGroup.name },
                  ]}
                  action="Group created"
                  name={createdGroup.name}
                />
              </Box>
            )}
            {!!joinedGroups?.length && (
              <Box>
                <BoxItem
                  avatars={joinedGroups.map(g => ({
                    emoji: g.emoji,
                    name: g.name,
                  }))}
                  action={`Joined ${joinedGroups.length} groups`}
                />
              </Box>
            )}
            {apps && (
              <Box>
                <BoxItem
                  avatars={apps.map(a => ({
                    avatarURL: a.avatarURL,
                  }))}
                  action={`${apps.length} apps added`}
                />
              </Box>
            )}
            {members && (
              <Box>
                <BoxItem action={`${members.length} invites sent`}>
                  <FacePile
                    users={members.map(m => ({ id: m, name: m })).slice(0, 10)}
                    totalCount={members.length}
                    tooltipDisabled
                  />
                </BoxItem>
              </Box>
            )}
          </div>
          <Footer
            onClickBack={() =>
              setState({
                view: goBackTo,
                currentStep: currentStep - 1,
              })
            }
            hideSkip
            hideProgress
          >
            <Button
              testId="onboarding-wizard-finish-button"
              onClick={handleFinishOnboarding}
            >
              Let's go!
            </Button>
          </Footer>
        </ContentWrapper>
      </Form>
    </>
  );
};

export default Review;
